



function checkRange(number) {
    if(number>=0 && number <1){
        return 1
    }
    else if(number>=1 && number <2){
        return 2
    }
    else if(number>=2 && number <3){
        return 3
    }
    else if(number>=3 && number <4){
        return 4
    }
    else if(number>=4 && number <5){
        return 5
    }
    else if(number>=5 && number <6){
        return 6
    }
    else if(number>=7 && number <8){
        return 7
    }
    else if(number>=8 && number <9){
        return 8
    }
    else if(number>=9 && number <10){
        return 9
    }
    else if(number>=10 && number <11){
        return 10
    }
    else if(number>=11 && number <12){
        return 11
    }
    else if(number>=12 && number <13){
        return 12
    }
    else if(number>=13 && number <14){
        return 13
    }
    else if(number>=14 && number <15){
        return 14
    }
else if(number>=15 && number <16){
        return 15
    }
    else if(number>=16 && number <17){
        return 16
    }
    else if(number>=17 && number <18){
        return 17
    }
    else if(number>=18 && number <19){
        return 18
    }
    else if(number>=19 && number <20){
        return 19
    }
    else if(number>=20 && number <21){
        return 21
    }
    else if(number>=21 && number <22){
        return 22
    }
    else if(number>=22 && number <23){
        return 23
    }
    else if(number>=23 && number <24){
        return 24
    }
    else if(number>=24 && number <25){
        return 25
    }
    else if(number>=25 && number <26){
        return 26
    }else if(number>=26 && number <27){
        return 27
    }else if(number>=27 && number <28){
        return 28
    }else if(number>=28 && number <29){
        return 29
    }else if(number>=29 && number <30){
        return 30
    }
    else if(number>=30){
        return 31
    }
}



export {checkRange}
