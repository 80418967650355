import React, {Component} from 'react'
import SvGraphics from "../../assets/SvGraphics";
import {filterPatientsTable} from "../../redux/actions/Tabs-Actions";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import { getDateFormat, getTime, getTimeFormat} from "../../utils/getDateTimeFormat";
import ReactTable from "react-table-v6";
import Pagination from "../../components/NovaTable/Pagination";
import utils from "../../utils/utils";

class Worth4DotBlockConnected extends Component {

    constructor(props) {
        super(props);
    }

    getColumns = (t) => {
        return [

            {
                accessor: 'Date', Header: t('admin.version.table.date'), resizable: false, Cell: ({original}) => {
                    return (
                        <div>
                            <label className={"first-label"}> {utils.getDateFormatWithYear(new Date(original.Date))} </label>
                            <label className={'second_table_column'}> {getTime(new Date(original.Date))} </label>
                        </div>
                    )
                },width:230
            },
/*            {accessor: 'ResultType', Header: t('ecp.info_filters.Result_Type'), resizable: false,width:120},
            {accessor: 'ResultValue', Header: t('ecp.info_filters.Result_Value'), resizable: false,width:115},*/
            {accessor: 'ResultDescription', Header: t('ecp.info_filters.Result_Description'), resizable: false,width:210},
            {accessor: 'Empty', Header: ' ', resizable: false,width:50},
            {accessor: 'Empty2', Header: ' ', resizable: false,width:50},
            {accessor: 'Page', Header: ' ', resizable: false, width:40, Cell: ({original}) => {
                    return (
                        <div>
                            <SvGraphics svgname={'notes'} className={'info-box-style'}/>
                        </div>
                    )
                }
            }
        ]
    }


    render() {
        let {t} = this.props
        let columns = this.getColumns(t)
        let lastVisits = this.props.lastVisitsData
        let visits=[]
        let showPagination = false

        let condition = ""
        if (this.props.svgName === "noglasses")
            condition="SC"
        else
            condition="CC"

        if(lastVisits){
            showPagination= lastVisits.length > 3
            for (const visit of lastVisits) {
                for (const testResults of visit.TestsResults) {
                    if (testResults.Worth4DotTestStorageID > 0 && testResults.OpticalCorrection === condition && testResults.TestResult.toLowerCase()==="test succeeded"){
                        if(testResults.Worth4DotResult){
                            visits.push({
                                Date: visit.VisitDate,
                                ResultDescription:testResults.Worth4DotResult.Worth4DotResultDescription
                            })

                        }

                    }
                }
            }
        }
        return(
            <div className={'patient-dashboard-AutoCS-area'}>
                <div className={'patient-chart-topping'}>
                    <div className={'title-svg'}>
                        <div className={'chart-title'}>{this.props.chartTitle}</div>
                        <SvGraphics className={'glasses-style'} svgname={this.props.svgName}/>
                    </div>
                </div>
                <ReactTable
                    className={'visit-history-table'}
                    data={visits}
                    columns={columns}
                    defaultPageSize={3}
                    showPagination={showPagination}
                    height
                    minRows={0}
                    maxRows={3}
                    sortable={true}
                    multiSort={true}
                    PaginationComponent={Pagination}
                    showPageSizeOptions={false}
                />
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return {
        filterPatientsTable:(filterType,payload) => dispatch(filterPatientsTable(filterType,payload)),
    };
}
const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData,
        patientsCount:state.patientsTableDataReducer.patientsCount,
        activePatientsCount:state.patientsTableDataReducer.activePatientsCount,
        pendingPatientsCount:state.patientsTableDataReducer.pendingPatientsCount,
        lockedPatientsCount:state.patientsTableDataReducer.lockedPatientsCount,
    };
};

const Worth4DotBlock = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Worth4DotBlockConnected)

export default Worth4DotBlock
