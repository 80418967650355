import React, {Component} from 'react'
import ReturnNav from "../../components/single_components/ReturnNav";
import '../../components_style/rtl_css/PatientForm.css'

import '../../components_style/PatientForm.css'
import utils from "../../utils/utils";
import {doctorApi} from "../../services/ApiService";
import * as Yup from "yup";
import validationHelper from "../../utils/validationHelper";
import FormElementContainer from "../../components/forms_builder/FormElementContainer";
import PatientDetails from "../../components/forms_builder/PatientDetails";
import PatientRemoteTesting from "../../components/forms_builder/PatientRemoteTesting";
import Address from "../../components/forms_builder/Address";
import PersonalNotes from "../../components/forms_builder/PersonalNotes";
import {makeError} from "../../components/modals/ErrorModal";
import AlertConfirm from "../../components/modals/Confirm";
import Constants from "../../utils/constants";
import Site from "../../components/forms_builder/Site";
import Eyecare_provider from "../../components/forms_builder/Eyecare_provider";
import PatientFormNavigator from "../../components/patient_profile/PatientFormNavigator";
import PatientFormPage1 from "../../components/patient/PatientFormPage1";
import AllUsersApi from "../../services/ApiServices/AllUsersApi";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import Reaptcha from "reaptcha";

class SingleRegistrationComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entry: {},
            errors: {},
            errorsOnSave:{},
        }
        this.recaptchaRef = React.createRef();
    }

    initValidation = async () => {
        let validationSchema = Yup.object().shape({
            FirstName:Yup.string().required('First Name is required'),
            LastName:Yup.string().required('Last Name is required'),
            Email:Yup.string().matches(Constants.emailRegExp, 'Email is not valid').required('Email is required'),
            PhoneNumber:Yup.string().nullable(),
            Profession:Yup.string().required('Profession is required'),

            SiteName:Yup.string().required('Site name is required'),
            SitePhoneNumber:Yup.string().nullable(),
            SiteEmail:Yup.string().matches(Constants.emailRegExp, 'Email is not valid').required('Email is required'),
            Country:Yup.string().required('Country is required'),
            // State: Yup.string().when("Country", {
            //     is: value => {
            //         let us = ["us","u.s","u.s.","usa","u.s.a","u.s.a.","united states","united states of america"]
            //         return us.includes(String(value).toLowerCase())
            //     },
            //     then: Yup.string().required(
            //         "State name in the U.S. is required"
            //     ).nullable(),
            //     otherwise: Yup.string().nullable()
            // })
        })
        let errors = await validationHelper.validate(this.state.entry,validationSchema)
        await this.setState({
            validationSchema,
            errors
        })

    }

    componentDidMount = async () => {
        await this.initValidation()
    }

    onNavBack = async (isClose) => {
        let {t}=this.props
        if (isClose){
            let confirm = await AlertConfirm({
                options:{
                    title: t('general.Withdraw_data'),
                }},t('general.withdraw_data_entered'))
            if (confirm) {
                this.props.history.push('/login')
            }
        } else {
            this.props.history.push('/login')
        }
    }

    onValueChange = async (name,value) => {
        let {entry,validationSchema} = this.state
        utils.set(entry,name,value)
        let entryValidator = utils.removeEmptyOrNull(entry)
        let errors = await validationHelper.validate(entryValidator, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;
        if (utils.get(errorsOnSave,name)){
            utils.set(errorsOnSave,name,utils.get(errors,name))
        }
        this.setState({
            entry,
            errors,
            errorsOnSave
        })
    }

    onSubmit = async () => {
        let {t}=this.props
        if (!utils.isEmpty(this.state.errors)){
            this.setState({errorsOnSave:this.state.errors});
            await makeError({
                proceedLabel: 'Ok',
                options:{
                    title: t('general.Could_not_save_data'),
                }
            },[t('general.Fill_all_required')]);
            return;
        }
        try {
            await this.recaptchaRef.execute();
        } catch (err) {
            this.setState({
                isErr: true,
                errMessage: 'Blocked By ReCAPTCHA',
            })
        }
    }

    submitData = async () => {
        let {t}=this.props
        let {entry} = this.state
        entry = utils.ObjectEmptiesToNulls(entry)
        entry = utils.removeEmptyOrNull(entry)
        let confirm = await AlertConfirm({
            options:{
                title: `Registration details`,
            }},`Are you sure you wish to create a new site?`)
        if (confirm) {

            let response = await AllUsersApi.selfRegister(entry)
            if (response) {
                let confirm = await AlertConfirm({
                    options:{
                        title: 'Registration successful',
                        cancelLabel: 'none',
                        proceedLabel: 'OK'
                    }},`A message has been sent to your email`)
                if (confirm){
                    this.setState({entry:{}})
                    await this.onNavBack(false)
                }
            }
        }
    }

    render() {
        let {errorsOnSave} = this.state
        let {t} = this.props

        return (
            <div className={'Patient-form'}>
                <div className={'nova-patient-form-header'}>
                    <div className={'nova-patient-form-navigator-title v-centered'}>{t('general.Registration')}</div>
                </div>
                <ReturnNav onNavBackClick={() => this.onNavBack(true)}/>
                <div className={'nova-patient-form-body container'}>
                    <FormElementContainer title={t('general.Eye_Care_Provider')}
                                        Component={Eyecare_provider}
                                        rest={{
                                            onValueChange:(name,value) => this.onValueChange(name,value) ,
                                            entry:this.state.entry,
                                            t: e => this.props.t(e),
                                            errors:errorsOnSave
                                        }}/>

                    <FormElementContainer title={t('admin.ecp.form.site')}
                                        Component={Site}
                                        rest={{
                                            onValueChange:(name,value) => this.onValueChange(name,value) ,
                                            entry:this.state.entry,
                                            t: e => this.props.t(e),
                                            errors:errorsOnSave,

                                        }}/>
                    <FormElementContainer title={t('general.Site_Address')}
                                          Component={Address}
                                          isSub={true}
                                          rest={{
                                              onValueChange:(name,value) => this.onValueChange(name,value) ,
                                              entry:this.state.entry,
                                              t: e => this.props.t(e),
                                              errors:errorsOnSave
                                          }}/>



                </div>

                <div className={'nova-patient-form-footer container'}>
                    <button type="button" className="btn btn-primary Patient-Form-next-button" onClick={this.onSubmit.bind(this)}>{t('admin.site.form.save')}</button>
                </div>
                <div className={'nova-patient-form-footer container'}/>
                <Reaptcha
                    ref={ref => (this.recaptchaRef = ref)}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    size={'invisible'}
                    explicit={false}
                    onVerify={token => {
                        if (!!token) this.submitData()
                        else {
                            this.setState({
                                isErr: true,
                                errMessage: 'Blocked By ReCAPTCHA',
                            })
                        }
                    }}
                    onExpire={() => {this.setState({
                        isErr: true,
                        errMessage: 'ReCAPTCHA Expired, please refresh the page',
                    })}}
                    onError={err => {this.setState({
                        isErr: true,
                        errMessage: err,
                    })}}
                />
            </div>
        )
    }
}

let SingleRegistration = compose(
    withTranslation()
)(SingleRegistrationComposed)
export default SingleRegistration;
