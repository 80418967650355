import React, {Component} from 'react'

import Middleware from "../../../routes/Middleware";
import PrivateRoute from "../../../routes/PrivateRoute";
import NovaTab from "../../../components/single_components/NovaTab";
import {history} from "../../../utils/history";
import Config from "../../../config/Config";
import signedUserHelper from "../../../utils/signedUserHelper";

import ECPsTab from "./ECPsTab";
import DevicesTab from "./DevicesTab";
import ECPsTabTools from "../../../components/ecp/ECPsTabTools";
import DevicesTabTools from "../../../components/device/DevicesTabTools";
import {adminApi, doctorApi} from "../../../services/ApiService";
import utils from "../../../utils/utils";

//REDUX
import { connect } from "react-redux";
import {
    setEcpData,
    filterEcpTable,
    addNewECP,
    SETECPsCount,
    setDevicesData,
    filterDevicesTable,
    SETDevicesCount,
} from "../../../redux/actions/Tabs-Actions.js";
import {authenticationService} from "../../../services/AuthenticationService";
import Switch from "react-bootstrap/Switch";
import {compose} from "redux";
import {withTranslation} from "react-i18next";



class SiteAdminTabsConComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentUser: null,
            isEcp: false,
            isDevices: false,
        }

    }

    async componentWillMount() {
        let ecps = await doctorApi.getAllECPs()
        if (ecps) this.props.setEcpData(ecps.data)
        let devices = await doctorApi.getAllDevices()
        if (devices && !utils.isEmpty(devices.data)) {
            this.props.setDevicesData(devices.data)
        }
        await this.handleNavigationButton(`/dashboard/siteadmin/ecps`,0)


    }

    componentDidMount = async ()  => {
        let currentUser = authenticationService.currentUserValue;
        this.props.SETECPsCount(currentUser ? currentUser.profile.NumOfECP : 0)
        this.props.SETDevicesCount(currentUser ? currentUser.profile.NumOfDevices : 0)
        this.setState({isEcp: true});
        let devices = await doctorApi.getAllDevices()
        if (devices && !utils.isEmpty(devices.data)) {
            this.props.setDevicesData(devices.data)
        }
        await this.handleNavigationButton(`/dashboard/siteadmin/ecps`,0)
    }

    async handleNavigationButton(path,tab_id){

        let isEcp, isDevices;
    if (tab_id === 0) {
            isEcp = true;
            isDevices = false;
            this.setState({isEcp, isDevices})
            let ecps = await doctorApi.getAllECPs()
            if (ecps) this.props.setEcpData(ecps.data)
        } else if (tab_id === 1) {
            isDevices = true
            isEcp = false
            this.setState({isEcp, isDevices})
            let devices = await doctorApi.getAllDevices()
            if (devices) {
                await this.props.setDevicesData(devices.data)
            }
        }

        history.push({
            pathname: path,
            state: {
                from: {
                    fromPath: `/dashboard/siteadmin`,
                }
            },
        })
    }

    navLinks = () => {
        let navLinks = [];
        let {t} = this.props
        navLinks.push(Middleware.linkToDisplay(['userAccessLink'],
            <NovaTab key={'ECPs'} status={this.state.isEcp} onClick={this.handleNavigationButton.bind(this, `/dashboard/siteadmin/ecps`, 0)}
                     value={t('admin.ecp.ecps')} count={this.props.ecpCount}/>,
            {roles: [Config.roles.Doctor],isSiteAdminRequired: true}));
        navLinks.push(Middleware.linkToDisplay(['userAccessLink'],
            <NovaTab key={'Devices'}  status={this.state.isDevices} onClick={this.handleNavigationButton.bind(this, `/dashboard/siteadmin/devices`, 1)}
                     value={t('admin.device.devices')} count={this.props.devicesCount}/>,
            {roles: [Config.roles.Doctor], isSiteAdminRequired: true}));
        return navLinks;
    }

    filterEcpTable(val){
        //this.render()
        this.setState({searchValue:val})
        this.props.filterEcpTable('search',val)
    }


    searchDevicesTable(val){
        //this.render()
        this.setState({searchValue:val})
        this.props.filterDevicesTable('search',val)
    }


    render() {
        return (
            <div className={'nova-context-area'}>
                <div className={'nova-table-tabs-container'}>
                    <div className={'nav'}>
                        <Switch>{this.navLinks()}</Switch>
                        {
                            this.state.isEcp
                            ? Middleware.linkToDisplay(['userAccessLink'],
                                <ECPsTabTools
                                    t={e => this.props.t(e)}
                                    filterEcpTable={val => this.filterEcpTable(val)}
                                    recipients={this.props.selectedEcpData}
                                    addNewECP={val => this.props.addNewECP(val)}
                                    siteData={this.props.siteData}/>,
                                {roles: [Config.roles.Doctor],isSiteAdminRequired: true})
                            : this.state.isDevices
                            ? Middleware.linkToDisplay(['userAccessLink'],
                                    <DevicesTabTools
                                        t={e => this.props.t(e)}
                                        recipients={this.props.selectedDevicesData}
                                        searchDevicesTable={val => this.searchDevicesTable(val)}/>,
                                                {roles: [Config.roles.Doctor],isSiteAdminRequired: true})
                            : <div/>
                        }

                    </div>
                    <div className={'context-area'}>
                        <Switch>
                            <PrivateRoute exact path={`/dashboard/siteadmin/ecps`} component={ECPsTab}/>
                            <PrivateRoute path={`/dashboard/siteadmin/devices`} component={DevicesTab}/>
                        </Switch>
                    </div>
                </div>
            </div>
        )
    }
}



function mapDispatchToProps(dispatch) {
    return {
        setEcpData: ecpData => dispatch(setEcpData(ecpData)),
        filterEcpTable:(searchType, payload) => dispatch(filterEcpTable(searchType, payload)),
        addNewECP: ecp => dispatch(addNewECP(ecp)),
        SETECPsCount: count => dispatch(SETECPsCount(count)),

        setDevicesData: deviceData => dispatch(setDevicesData(deviceData)),
        filterDevicesTable:(filterType,payload) => dispatch(filterDevicesTable(filterType,payload)),
        SETDevicesCount: count => dispatch(SETDevicesCount(count)),

    };
}
const mapStateToProps = state => {
    return {
        ecpData:state.ecpTableDataReducer.ecpData,
        ecpCount:state.ecpTableDataReducer.ecpCount,
        ecpTableFilter:state.ecpTableDataReducer.ecpTableFilter,
        selectedEcpData:state.ecpTableDataReducer.selectedEcpData,

        deviceData:state.deviceTableDataReducer.deviceData,
        devicesCount: state.deviceTableDataReducer.devicesCount,
        deviceTableFilter:state.deviceTableDataReducer.deviceTableFilter,
        selectedDevicesData:state.deviceTableDataReducer.selectedDevicesData,
    };
};

const SiteAdminTabs = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(SiteAdminTabsConComposed);

export default SiteAdminTabs;
