import React, {Component} from 'react'

import Switch from "react-bootstrap/Switch";
import Middleware from "../../../routes/Middleware";
import PrivateRoute from "../../../routes/PrivateRoute";
import NovaTab from "../../../components/single_components/NovaTab";
import {history} from "../../../utils/history";
import Config from "../../../config/Config";

import PatientsTab from "./PatientsTab";
import ArchiveTab from "./ArchiveTab"
import {doctorApi} from "../../../services/ApiService"

//REDUX
import { connect } from "react-redux";
import {
    setPatientsData,
    filterPatientsTable,
    SETPatientsCount,
    addNewPatient,

    setPatients_ArchiveData,
    SETPatients_ArchiveCount,
    filterPatients_ArchiveTable,
    setPatientsStatuses
} from "../../../redux/actions/Tabs-Actions";
import PatientsTabTools from "../../../components/patient/PatietntsTabTools";
import ArchiveTabTools from "../../../components/patient/ArchiveTabTools";
import {authenticationService} from "../../../services/AuthenticationService";
import {compose} from "redux";
import {withTranslation} from "react-i18next";



class DoctorTabsConComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isPatients: false,
            isArchive: false
        }
    }

    componentWillMount() {
        let currentUser = authenticationService.currentUserValue;
        if (this.props.patients_ArchiveCount === 0){
            this.props.SETPatients_ArchiveCount(currentUser.profile.NumOfArchivePatients)
        }
        if (this.props.patientsCount === 0){
            this.props.SETPatientsCount(currentUser.profile.NumOfPatients)
        }
    }

    componentDidMount = async () => {
        let path = history.location.pathname
        let tab = history.location.state.navTabTo? history.location.state.navTabTo:0
        await this.handleNavigationButton(path,tab)
    }

    async handleNavigationButton(path,tab_id){
        let isPatients, isArchive;
        if (tab_id === 0) {
            isPatients = true;
            isArchive = false;
            this.setState({isPatients, isArchive})
            let patients = await doctorApi.getAllPatients()
            if (patients) await this.props.setPatientsData(patients.data)
        } else if (tab_id === 1) {

            isArchive = true;
            isPatients = false
            this.setState({isPatients, isArchive})
            let patients = await doctorApi.getAllCompletedPatients()
            if (patients) this.props.setPatients_ArchiveData(patients.data)
        }
        history.push({
            pathname: path,
            state: {
                from: {
                    fromPath: `/dashboard`,
                }
            },
        })
        this.props.toggle('total')
        this.props.time_toggle(this.props.currentTimeToggle)
    }

    navLinks = () => {
        let navLinks = [];
        let {t} = this.props
        let patients_ArchiveCount = this.props.patients_ArchiveCount
        navLinks.push(Middleware.linkToDisplay(['userAccessLink'],
            <NovaTab key={'Patients'} status={this.state.isPatients} onClick={this.handleNavigationButton.bind(this, `/dashboard/patients`, 0)}
                     value={t('ecp.patient.patients')} count={this.props.patientsCount}/>,
            {roles: [Config.roles.Doctor]}));
        navLinks.push(Middleware.linkToDisplay(['userAccessLink'],
            <NovaTab key={'Archive'} status={this.state.isArchive} onClick={this.handleNavigationButton.bind(this, `/dashboard/archive`, 1)}
                     value={t('ecp.archive.archive')} count={patients_ArchiveCount}/>,
            {roles: [Config.roles.Doctor]}));
        return navLinks;
    }

    searchPatientsTable(val){
        this.setState({searchValue:val})
        this.props.filterPatientsTable('search',val)
    }

    async refreshPatientsTable() {
        let patients = await doctorApi.getAllPatients()
        if (patients) await this.props.setPatientsData(patients.data)
    }

    filterPatients_ArchiveTable(val){
        this.setState({searchValue:val})
        this.props.filterPatients_ArchiveTable('search',val)
    }


    render() {
        return (
            <div className={'nova-context-area'}>
                <div className={'nova-table-tabs-container'}>
                    <div className={'nav'}>
                        <Switch>{this.navLinks()}</Switch>
                        {
                            this.state.isPatients
                            ? Middleware.linkToDisplay(['userAccessLink'],
                            <PatientsTabTools
                                t={this.props.t}
                                recipients={this.props.selectedPatientsData}
                                searchPatientsTable={val => this.searchPatientsTable(val)}
                                refreshPatientsTable={() => this.refreshPatientsTable()}
                                addNewPatient={val => this.props.addNewPatient(val)}
                            />,
                                {roles: [Config.roles.Doctor]})
                            : this.state.isArchive
                            ? Middleware.linkToDisplay(['userAccessLink'],
                                <ArchiveTabTools
                                    t={this.props.t}
                                    recipients={this.props.selectedPatients_ArchiveData}
                                    filterArchiveTable={val => this.filterPatients_ArchiveTable(val)}/>,
                                {roles: [Config.roles.Doctor]})
                            : <div/>
                        }
                    </div>
                    <div className={'context-area'}>
                        <Switch>
                            <PrivateRoute exact path={`/dashboard/patients`} component={PatientsTab}/>
                            <PrivateRoute path={`/dashboard/archive`} component={ArchiveTab}/>
                        </Switch>
                    </div>
                </div>
            </div>
        )
    }
}



function mapDispatchToProps(dispatch) {
    return {
        setPatientsData: patientsData => dispatch(setPatientsData(patientsData)),
        filterPatientsTable:(filterType,payload) => dispatch(filterPatientsTable(filterType,payload)),
        SETPatientsCount: patientsData => dispatch(SETPatientsCount(patientsData)),
        addNewPatient: patientData => dispatch(addNewPatient(patientData)),

        SETPatients_ArchiveCount: patientsData => dispatch(SETPatients_ArchiveCount(patientsData)),
        setPatients_ArchiveData: patients_ArchiveData => dispatch(setPatients_ArchiveData(patients_ArchiveData)),
        filterPatients_ArchiveTable:(filterType,payload) => dispatch(filterPatients_ArchiveTable(filterType,payload))
    };
}
const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData,
        patientsTableFilter:state.patientsTableDataReducer.patientsTableFilter,
        patientsCount:state.patientsTableDataReducer.patientsCount,
        selectedPatientsData:state.patientsTableDataReducer.selectedPatientsData,

        patients_ArchiveData:state.patients_archiveTableDataReducer.patients_ArchiveData,
        patients_ArchiveTableFilter:state.patients_archiveTableDataReducer.patients_ArchiveTableFilter,
        patients_ArchiveCount:state.patients_archiveTableDataReducer.patients_ArchiveCount,
        selectedPatients_ArchiveData:state.patients_archiveTableDataReducer.selectedPatients_ArchiveData,

    };
};

const DoctorTabs = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(DoctorTabsConComposed)
export default DoctorTabs;
