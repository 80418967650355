import React from "react";
import set from 'set-value'
import Config from "../config/Config";

class utils {

    get(obj, path) {
        // Get the path as an array
        path = this.stringToPath(path);
        // Cache the current object
        let current = obj;
        // For each item in the path, dig into the object
        for (let i = 0; i < path.length; i++) {
            // If the item isn't found, return the default (or null)
            if (current === null || this.isUndefined(current) || this.isUndefined(current[path[i]]))
                return '';
            // Otherwise, update the current  value
            current = current[path[i]];
        }
        return current;
    };

    set(object, path, value) {
        set(object, path, value);
    }

    removeEmptyOrNull = (source) => {
        const obj = Object.assign({}, source);
        Object.keys(obj).forEach(k =>
            (obj[k] && typeof obj[k] === 'object') && this.removeEmptyOrNull(obj[k]) ||
            (obj[k] === undefined || obj[k] === null || obj[k].length ===0 ||
                (obj[k] instanceof String && obj[k].isEmpty()) || obj[k] === "" || obj[k] === '') && delete obj[k]
        );

        if (Array.isArray(obj) && obj.length < 1) {
            return null;
        }

        return obj;
    };

    /**
     * Checks if value is empty. Deep-checks arrays and objects
     * Note: isEmpty([]) == true, isEmpty({}) == true, isEmpty([{0:false},"",0]) == true, isEmpty({0:1}) == false
     * @param value
     * @returns {boolean}
     */
    isEmpty = (value) => {

        let is = this.isEmpty;

        let isEmptyObject = function (a) {
            if (typeof a.length === 'undefined') { // it's an Object, not an Array
                let hasNonempty = Object.keys(a).some(function nonEmpty(element) {
                    return !is(a[element]);
                });
                return hasNonempty ? false : isEmptyObject(Object.keys(a));
            }

            return !a.some(function nonEmpty(element) { // check if array is really not empty as JS thinks
                return !is(element); // at least one element should be non-empty
            });
        };

        let isEmptyString = function (s) {
            if (typeof s === 'string')
                return s.length === 0;
        };

        return (
            // value === false
            // ||
            typeof value === 'undefined'
            || value == null
            || (typeof value === 'object' && isEmptyObject(value))
            || (typeof value === 'string' && isEmptyString(value))
        );
    };

    isObject = (obj) => {
        return obj && typeof obj === 'object';
    };

    isUndefined = (obj) => {
        return typeof obj === 'undefined';
    };

    search = (newTerms, searchTerms, collection) => {

        searchTerms = searchTerms || [];

        let currentList = [], newList;

        let unique = searchTerms.filter(item => {
            return JSON.stringify(Object.keys(item.filterKeys)) !== JSON.stringify(Object.keys(newTerms.filterKeys))
        });

        unique.push(newTerms);

        unique = unique.filter((x) => {
            let clear = this.removeEmptyOrNull(x.filterKeys);
            return !this.isEmpty(clear);
        });

        if (unique) {
            currentList = collection;
            newList = currentList.filter(eachObj => {
                return unique.every((term) => {
                    let filterKeys = Object.keys(term.filterKeys);
                    return filterKeys.some(eachKey => {
                        if (!String(eachObj[eachKey])) {
                            return false;
                        }

                        let value = eachObj[eachKey];
                        let termValue = term.filterKeys[eachKey];

                        if (Array.isArray(termValue)) {
                            return termValue.some(termArr => {
                                return this.find(value, termArr, term.searchOption)
                            })
                        } else {
                            return this.find(value, termValue, term.searchOption)
                        }
                    });
                });
            });
        } else {
            newList = collection;
        }

        return {
            searchTerms: unique,
            filteredCollection: newList
        };
    };

    find(value, term, option) {

        let strValue = String(value);
        if (strValue) {
            strValue = strValue.toLowerCase()
        }

        let termValue = String(term);
        if (termValue) {
            termValue = termValue.toLowerCase()
        }

        if (value instanceof Date || Object.prototype.toString.call(value) === '[object Date]' && option !== Config.searchOptions.include) {
            strValue = value;
            termValue = term;
        }

        switch (option) {
            case Config.searchOptions.include: {
                return strValue.includes(termValue);
            }
            case Config.searchOptions.lessThan: {
                return strValue <= termValue ? strValue : null;
            }
            case Config.searchOptions.greaterThan: {
                return strValue >= termValue ? strValue : null;
            }
            default: {
                return value;
            }
        }
    }

    /**
     * If the path is a string, convert it to an array
     * @param  {String|Array} path The path
     * @return {Array}             The path array
     */
    stringToPath = (path) => {
        // If the path isn't a string, return it
        if (typeof path !== 'string') return path;

        // Create new array
        let output = [];

        // Split to an array with dot notation
        path.split('.').forEach(function (item) {

            // Split to an array with bracket notation
            item.split(/\[([^}]+)\]/g).forEach(function (key) {

                // Push to the new array
                if (key.length > 0) {
                    output.push(key);
                }
            });
        });

        return output;
    };

    toNumeric = function (val) {
        return val.replace(/\D/g,'').replace('e','')
    }

    ObjectEmptiesToNulls = function (source) {
        const obj = Object.assign({}, source);
        Object.keys(obj).forEach(k => {
            if((obj[k] && typeof obj[k] === 'object') && this.ObjectEmptiesToNulls(obj[k]) ||
            (obj[k] === undefined || obj[k].length ===0 ||
                (obj[k] instanceof String && obj[k].isEmpty()) || obj[k] === "" || obj[k] === ''))
            obj[k] = null
        });
        return obj;
    }

    ObjectNullsToUndefined = function (source) {
        const object = Object.assign({}, source);
        let newObject = object
        for (let [key, value] of Object.entries(object)) {
            if (value === null) newObject[key] = undefined;
        }
        return newObject;
    }

    getDateStringWithoutYear = function (date) {
        let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let today = date;
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = monthNames[today.getMonth()]
        return dd + ' ' + mm;
    };

    getDateStringWithYear = function (date) {
        let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let today = date;
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = monthNames[today.getMonth()]
        let yy = today.getFullYear().toString().substr(2, 2);
        return dd + ' ' + mm + ' ' + yy;
    };

    getDateDay(date) {
        if(date){
            return parseInt(date.substring(8, 10))
        }
    }
    getDateMonth(date) {
        if(date){
            return parseInt(date.substring(5, 7))
        }
    }
    getDateYear(date) {
        if(date){
            return parseInt(date.substring(0,4))
        }
    }
    getLastWeek(date) {
        if(date){
            let week = parseInt(date.substring(8, 10))-7
            return week.toString()
            /*
                        date.toISOString().substring(5, 7) + "/"+ date.toISOString().substring(0,4)
            */
        }
    }


    getDateFormatWithYear = function (date) {
        if (!date) return "NoDate"
        let today = date;
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = today.getMonth()+1
        if (mm.toString().length === 1) mm = '0'+mm.toString()
        let yyyy = today.getFullYear();
        return dd + '/' + mm + '/' + yyyy;
    };

    getAge = function (date){
        if(!date) return "NoDate"
        let today = new Date();
        return today.getFullYear()-date.getFullYear();
    }

    getTime=function (date){
        if (!date) return "NoDate"
        let today = date;
        let hh=today.getHours();
        let min=today.getMinutes();
        return hh + ':' + min;
    }

    getDateTimeFormatWithYear = function (date) {
        if (!date) return "NoDate"
        let today = date;
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = today.getMonth()+1
        if (mm.toString().length === 1) mm = '0'+mm.toString()
        let yyyy = today.getUTCFullYear();
        let hh=today.getHours();
        let min=today.getMinutes();
        return dd + '/' + mm + '/' + yyyy + '   ' + hh + ':' + min;
        };

    getTodayDateStringWithYear = function (day, month, year) {
        let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let today = new Date();
        let dd = day ? day : String(today.getDate()).padStart(2, '0');
        let mm = month ? monthNames[month - 1] : monthNames[today.getMonth()]
        let yyyy = year ? year : today.getUTCFullYear()();
        return dd + ' ' + mm + ' ' + yyyy;
    };

    getTodayDateStringWithoutYear = function (day, month) {
        let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let today = new Date();
        let dd = day ? day : String(today.getDate()).padStart(2, '0');
        let mm = month ? monthNames[month - 1] : monthNames[today.getMonth()]
        return dd + ' ' + mm;
    };

    getUID() {
        let dt = new Date().getTime();
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    time_convert(num)
    {
        if (num){
            let sign = num/60 < 0 ? "-" : "";
            let min = Math.floor(Math.abs(num/60))
            let sec = Math.floor((Math.abs(num/60) * 60) % 60);
            return sign + (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
        } else {
            return ""
        }
    }
}

export default new utils();
