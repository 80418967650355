import React, {Component} from 'react'
import '../../components_style/PatientNotes.css'
import '../../components_style/rtl_css/PatientNotes.css'
import PropTypes from "prop-types";
import utils from "../../utils/utils";


class PersonalNotes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entry: this.props.entry,
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.entry !== prevProps.entry) {
            this.setState({
                entry: this.props.entry
            })
        }
    }

    render() {
        return (
            <div className={'PatientsNotes-Form'}>
                <textarea className={'nova-form-input AdvanceNotes'}
                          defaultValue={utils.get(this.props.entry,'Notes') !== null &&
                          utils.get(this.props.entry,'Notes') !== undefined ?
                              utils.get(this.props.entry,'Notes') :''}
                          onChange={event => this.props.onValueChange('Notes',event.target.value)}
                          name={'Notes'}
                          maxLength={500}
                          id={'Notes'}/>
            </div>
        )
    }
}

PersonalNotes.propTypes = {
    onValueChange:PropTypes.func.isRequired,
    entry: PropTypes.object.isRequired,
}

export default PersonalNotes;
