import React, {Component} from 'react'
import PropTypes from "prop-types";
import utils from "../../utils/utils";
import Error from "../single_components/Error";
import Constants from "../../utils/constants";
import NovaSelect from "../single_components/NovaSelect";
import PhoneInput from "react-phone-input-2";
import {ConstantsArrT, ConstantsObjT} from "../../utils/ConstantsWithTranslate";
import {filterPatientsTable} from "../../redux/actions/Tabs-Actions";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

class Eyecare_providerConComposed extends Component {

    constructor(props) {
        super(props);
        this.MedicalLicense = {
            entry: this.props.entry,
        }
    }




    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.entry !== prevProps.entry) {
            this.setState({
                entry: this.props.entry
            })
        }
    }


    render() {
        let {t} = this.props
        return (
            <div className={'Patient-personal-Form'}>
                <div className={'Patient-personal-form-container'}>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('admin.ecp.form.first_name')} *</label>
                        <div className={'form-search'}>
                            <input
                                type={"text"}
                                disabled={this.props.isDisabled}
                                className={"nova-form-input"}
                                maxLength={20}
                                defaultValue={utils.get(this.props.entry,'FirstName') !== null &&
                                utils.get(this.props.entry,'FirstName') !== undefined ?
                                    utils.get(this.props.entry,'FirstName') :''}
                                name={'FirstName'}
                                onChange={(event) => this.props.onValueChange('FirstName', event.target.value)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'FirstName')} isShown={!!utils.get(this.props.errors, 'FirstName')}/>
                        </div>
                    </div>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('admin.ecp.form.last_name')} *</label>
                        <div className={'form-search'}>
                            <input
                                type={"text"}
                                disabled={this.props.isDisabled}
                                className={"nova-form-input"}
                                maxLength={50}
                                defaultValue={utils.get(this.props.entry,'LastName') !== null &&
                                utils.get(this.props.entry,'LastName') !== undefined ?
                                    utils.get(this.props.entry,'LastName') :''}
                                name={'LastName'}
                                onChange={(event) => this.props.onValueChange('LastName', event.target.value)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'LastName')} isShown={!!utils.get(this.props.errors, 'LastName')}/>
                        </div>
                    </div>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('admin.ecp.form.email')} *</label>
                        <div className={'form-search'}>
                            <input
                                type={"text"}
                                disabled={this.props.isDisabled}
                                className={"nova-form-input"}
                                maxLength={100}
                                defaultValue={utils.get(this.props.entry,'Email') !== null &&
                                utils.get(this.props.entry,'Email') !== undefined ?
                                    utils.get(this.props.entry,'Email') :''}
                                name={'Email'}
                                onChange={(event) => this.props.onValueChange('Email', event.target.value)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'Email')} isShown={!!utils.get(this.props.errors, 'Email')}/>
                        </div>
                    </div>
                </div>
                <div className={'Patient-personal-form-container'}>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('admin.ecp.form.profession')} *</label>
                        <div className={'form-search'}>
                            <NovaSelect
                                disabled={this.props.isDisabled}
                                name={'Profession'}
                                options={ConstantsArrT(t,Constants.Profession)}
                                className={'nova-form-input nova-form-searchableSelect'}
                                value={ConstantsObjT(t,Constants.Profession.find(x => x.value === utils.get(this.props.entry,'Profession')))}
                                placeholder={t('admin.ecp.form.enter_profession')}
                                onChange={e => {
                                    this.props.onValueChange('Profession', e.value)
                                }}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'Profession')} isShown={!!utils.get(this.props.errors, 'Profession')}/>
                        </div>
                    </div>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('admin.ecp.form.phone_number')} *</label>
                        <div className={'form-search'}>
                            <PhoneInput
                                country={'us'}
                                value={utils.get(this.props.entry,'PhoneNumber') !== null &&
                                utils.get(this.props.entry,'PhoneNumber') !== undefined?
                                    utils.get(this.props.entry,'PhoneNumber') :''}
                                inputClass={'nova-form-input'}
                                containerClass={'nova-PhoneInput-container'}
                                buttonClass={'nova-PhoneInput-button'}
                                dropdownClass={`nova-patient-PhoneInput-dropdown`}
                                searchClass={'nova-patient-PhoneInput-search'}
                                enableSearch={true}
                                countryCodeEditable={false}
                                autoFormat={true}
                                onChange={phone =>this.props.onValueChange('PhoneNumber',phone)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'PhoneNumber')} isShown={!!utils.get(this.props.errors, 'PhoneNumber')}/>
                        </div>
                    </div>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('admin.ecp.form.med_license')}</label>
                        <div className={'form-search'}>
                            <input
                                type={"text"}
                                disabled={this.props.isDisabled}
                                className={"nova-form-input"}
                                maxLength={50}
                                defaultValue={utils.get(this.props.entry,'MedicalLicense') !== null &&
                                utils.get(this.props.entry,'MedicalLicense') !== undefined ?
                                    utils.get(this.props.entry,'MedicalLicense') :''}
                                name={'MedicalLicense'}
                                onChange={(event) => this.props.onValueChange('MedicalLicense', event.target.value)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'MedicalLicense')} isShown={!!utils.get(this.props.errors, 'MedicalLicense')}/>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

Eyecare_providerConComposed.propTypes = {
    onValueChange:PropTypes.func.isRequired,
    entry: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool
}

function mapDispatchToProps(dispatch) {
    return {
        filterPatientsTable:(filterType,payload) => dispatch(filterPatientsTable(filterType,payload)),
    };
}
const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData,
        patientsCount:state.patientsTableDataReducer.patientsCount,
        activePatientsCount:state.patientsTableDataReducer.activePatientsCount,
        pendingPatientsCount:state.patientsTableDataReducer.pendingPatientsCount,
        lockedPatientsCount:state.patientsTableDataReducer.lockedPatientsCount,
    };
};

const Eyecare_provider = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Eyecare_providerConComposed)


export default Eyecare_provider;
