import React, {Component} from 'react'
import SvGraphics from "../../assets/SvGraphics";
import Prototypes from 'prop-types'
class Error extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = ()  => {
        if (this.props.tab_option){
            this.setState({tab_option:this.props.tab_option})
        }
    }


    render() {
        let divStyle = {display: this.props.isShown?'flex':'none', float:this.props.isNonFloat?'none':'left'}
        if (this.props.isPasswordValidationErr) {
            divStyle['height'] = 'max-content'
        }
        return (
            <div className={'error-message-label'} style={divStyle}>
                <SvGraphics svgname={'error'} style={{height:this.props.isPasswordValidationErr?'25px':'13px',width:'13px'}} className={`inline error-svg ${this.props.isPasswordValidationErr?'':'v-centered'}`}/>
                <label className={`nova-tab-label red inline error-label ${this.props.isPasswordValidationErr?'':'v-centered'}`} style={this.props.isPasswordValidationErr ? {height:'max-content'} : {}}>{`${this.props.errorMessage}`}</label>
            </div>
            // call: <Error errorMessage={'Email is required'} isShown={false}/>
        )
    }
}
Error.Prototypes = {
    errorMessage: Prototypes.string.isRequired,
    isShown: Prototypes.bool.isRequired
};
export default Error;
