import React, {Component} from "react";
import PropTypes from "prop-types";
import SimpleFormBuilder from "../forms_builder/SimpleFormBuilder";
import utils from "../../utils/utils";
import Prototypes from "prop-types";
import {makeError} from "../modals/ErrorModal";
import {adminApi} from "../../services/ApiService";
import * as Yup from "yup";
import validationHelper from "../../utils/validationHelper";
import Constants from "../../utils/constants";
import AlertConfirm from "../modals/Confirm";
import SvGraphics from "../../assets/SvGraphics";

class SiteEntryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entry: {},
            matrix :[2,1,1,2,2,2],
            errors: {},
            errorsOnSave:{}
        };
    }

    getFields = () => {
        let {t} = this.props
        return [
            {type:'text', max: 50, name:'SiteName',display:t('admin.site.form.site_name'), placeholder: t('admin.site.form.enter_site_name'), required:true, width:0.5, style:'inline'},
            {type:'phone', name:'SitePhoneNumber',display:t('admin.site.form.site_phone_number'), placeholder: t('admin.site.form.site_phone_number'), required:false, width:0.5, style:'inline'},
            {type:'text', max: 50, name:'SiteEmail',display:t('admin.site.form.site_email'), placeholder: t('admin.site.form.enter_email'), required:true, width:1, style:'inline'},
            {type:'label', display:t('admin.site.form.address'),  width:1},
            {type:'text', max: 50, name:'SiteStreet', placeholder: t('admin.site.form.enter_street'), display:t('admin.site.form.street'),required:false, width:0.5, style:'inline'},
            {type:'text', max: 50, name:'SiteApartment', placeholder: t('admin.site.form.enter_apartment'), display:t('admin.site.form.apartment'),required:false, width:0.5, style:'inline'},
            {type:'text', max: 50, name:'SiteCity', placeholder: t('admin.site.form.enter_city'), display:t('admin.site.form.city'),required:false, width:0.5, style:'inline'},
            {type:'text', max: 50, name:'SiteState', placeholder: t('admin.site.form.enter_state'), display:t('admin.site.form.state'),required:false, width:0.5, style:'inline'},
            {type:'text', max: 50, name:'SiteZipCode', placeholder: t('admin.site.form.zip_code'), display:t('admin.site.form.zip_code'),required:false, width:0.5, style:'inline'},
            {type:'text', max: 50, name:'SiteCountry', placeholder: t('admin.site.form.enter_country'), display:t('admin.site.form.country'),required:true, width:0.5, style:'inline'},
            {type:'default', name:'none', placeholder: '', display:'',required:false, width:0.4, style:'inline'}
        ]
    }

    initValidation = async () => {
        let {t} = this.props
        let validationSchema = Yup.object().shape({
            SiteName:Yup.string().required(t('admin.site.form.site_name_req')),
            SiteEmail:Yup.string().matches(Constants.emailRegExp, t('admin.site.form.site_email_invalid')).required(t('admin.site.form.site_email_req')),
            SiteCountry:Yup.string().required(t('admin.site.form.country_req')),
            // SiteState: Yup.string().when("SiteCountry", {
            //     is: value => {
            //         let us = ["us","u.s","u.s.","usa","u.s.a","u.s.a.","united states","united states of america"]
            //         return us.includes(String(value).toLowerCase())
            //     },
            //     then: Yup.string().required(
            //         t('admin.site.form.us_state_req')
            //     ).nullable(),
            //     otherwise: Yup.string().nullable()
            // })
        })
        let errors = await validationHelper.validate(this.state.entry,validationSchema)
        await this.setState({
            validationSchema,
            errors
        })
    }

    async componentWillMount() {
        if (this.props && this.props.entry) await this.setState({entry:this.props.entry})
        await this.initValidation()
    }

    async closeSideBar(){
        let {t} = this.props
        document.body.style.overflow = "auto"
        let confirm = await AlertConfirm({
            options:{
                title: t('admin.site.form.withdraw_new_site'),
            }},t('admin.site.form.withdraw_new_site_info'))
        if (confirm) {
            document.body.style.overflow = "auto"
            this.props.closeSideBar(false)
            this.setState({entry:null})
        } else {
            document.body.style.overflow = "hidden"
        }
    }

    async saveInfo(){
        let {t} = this.props
        if (!utils.isEmpty(this.state.errors)){
            this.setState({errorsOnSave:this.state.errors});
            await makeError({
                proceedLabel: t('admin.site.form.ok'),
                options:{
                    title: t('admin.site.form.site_not_created'),
                }
            },[t('admin.site.form.fill_all_req')]);
            return;
        }
        let {entry} = this.state
        document.body.style.overflow = "auto"
        let confirm = await AlertConfirm({
            options:{
                title: t('admin.site.form.add_new'),
            }},`${t('admin.site.form.confirm_add_new')}: ${entry.SiteName}?`)
        if (confirm) {
            entry = utils.ObjectEmptiesToNulls(entry)
            let response = await adminApi.AddNewSite(entry)
            if (response) {
                this.props.addNewSite(response.data)
                document.body.style.overflow = "auto"
                this.props.closeSideBar(false)
                this.setState({entry:{}})
            }
        } else {
            document.body.style.overflow = "hidden"
        }
    }

    onEntryChange = async event => {
        let {entry, validationSchema} = this.state
        let name = event.target.name
        let value = event.target.value
        utils.set(entry,name,value)
        let entryValidator = utils.removeEmptyOrNull(entry)
        let errors = await validationHelper.validate(entryValidator, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;
        if (utils.get(errorsOnSave,name)){
            utils.set(errorsOnSave,name,utils.get(errors,name))
        }
        this.setState({
            entry,
            errors,
            errorsOnSave
        })
        //this.props.onEntryChange(entry)
    }

    render() {
        let display = this.props.isSideBarOpen ? 'block' : 'none'
        let width = this.props.isSideBarOpen ? "600px" : "0px"
        let container_width = this.props.isSideBarOpen ? "100%" : "0px"
        let {errorsOnSave} = this.state;
        let {t} = this.props
        return (
            <div className={"sidenav-complete"} style={{width:container_width, height:"100%"}}>
                <div className={"sidenav"} style={{width:width, height:"100%", float:'right'}}>
                    <SvGraphics onClick={this.closeSideBar.bind(this)} className={'close-bar-x'} svgname={'close'} height={'15px'} width={'15px'} style={{
                        float: 'left',
                        top: '10px',
                        left: '10px',
                        position: 'absolute',
                        cursor: 'pointer'
                    }}/>
                    <div className={'register-form-title container block'}>
                        <h3 className={'h-centered block'}>{this.props.title}</h3>
                    </div>
                    <div className={'register-form container block'}>
                        <SimpleFormBuilder
                            errors={errorsOnSave}
                            entry={this.state.entry}
                            matrix={this.state.matrix}
                            fields={this.getFields()}
                            onEntryChange={e => this.onEntryChange(e)}
                        />
                    </div>
                </div>
                <div className={'register-form-nav-container block'} >
                    <div className={'register-form-nav'}  style={{width:width}}>
                        <label onClick={this.closeSideBar.bind(this)} style={{display:display}} className={"v-centered close-bar"}>
                            {t('admin.site.form.close')}
                        </label>
                        <button type={"button"} onClick={this.saveInfo.bind(this)} style={{display:display}} className={"btn btn-primary v-centered save-info"}>
                            {t('admin.site.form.save')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
SiteEntryForm.propTypes = {
    isSideBarOpen:Prototypes.bool.isRequired,
    addNewSite: PropTypes.func.isRequired,
    closeSideBar:Prototypes.func.isRequired,
    title: Prototypes.string.isRequired,
    entry: Prototypes.object
}

export default SiteEntryForm

