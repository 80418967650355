import React, {Component} from 'react'
import {authenticationService} from '../../services/AuthenticationService';

import SvGraphics from "../../assets/SvGraphics";
import * as Yup from "yup";
import validationHelper from "../../utils/validationHelper";
import utils from "../../utils/utils";
import Error from "../../components/single_components/Error";
import Config from "../../config/Config";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import Reaptcha from "reaptcha";

class Login2FAComposed extends Component {
    constructor(props) {
        super(props);

        if (!authenticationService.currentMfaUserValue) {
            this.props.history.push('/');
        }

        this.state = {
            twoFA:'',
            auth: {},
            isErr:false,
            errMessage:'',
            errors: {},
            errorsOnSave:{},
        }
        this.recaptchaRef = React.createRef();
    }

    initValidation = async () => {
        let {t} = this.props
        let validationSchema = Yup.object().shape({twoFA: Yup.string().required(t('defaults.TwoFA.auth_cd_req'))})
        let errors = await validationHelper.validate(this.state.auth,validationSchema)
        await this.setState({
            validationSchema,
            errors
        })
    }

    async componentWillMount() {
        await this.initValidation()
    }

    onSubmit = async () => {
        if (!utils.isEmpty(this.state.errors)){
            this.setState({errorsOnSave:this.state.errors});
            return;
        }
        try {
            await this.recaptchaRef.execute();
        } catch (err) {
            this.setState({
                isErr: true,
                errMessage: 'Blocked By ReCAPTCHA',
            })
        }
    }

    submitData = async () => {
        let {twoFA} = this.state
        let response = await authenticationService.submit2fa(twoFA);
        if (response.status < 400) {
            let data = response.data
            if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === 'pass') {
                if (data.hasOwnProperty('updatePassword') && data['updatePassword'] === true){
                    const {from} = {from: {pathname: "/create-password"}};
                    this.props.history.push(from);
                } else  if (data.hasOwnProperty('updatePassword') && data['updatePassword'] === false){
                    const {from} = {from: {pathname: "/dashboard"}};
                    this.props.history.push(from);
                }
            } else {
                await this.setUserName({target: {value: ''}})
                await this.setPassword({target: {value: ''}})
                this.setState({
                    twoFA:'',
                    isErr:true,
                    errMessage:response.data?response.data.message:'No connection to server',
                })
            }
        } else {
            this.setState({
                twoFA:'',
                isErr:true,
                errMessage:response.data?response.data.message:'No connection to server',
            })
        }
    }

    setMFA = async (e) => {
        let {auth,validationSchema} = this.state
        this.setState({twoFA:e.target.value})
        utils.set(auth,'twoFA',e.target.value)
        let errors = await validationHelper.validate(auth, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;
        if (utils.get(errorsOnSave,'twoFA')){
            utils.set(errorsOnSave,'twoFA',utils.get(errors,'twoFA'))
        }
        this.setState({
            auth,
            errors,
            errorsOnSave,
            isErr:false,errMessage:''
        })
    }

    render() {
        let {t} = this.props
        let SVGName ='eye_swift_blue'
        let bottomSVG= <SvGraphics svgname={'nova_sight'} className={'nova-sight-login-logo'} style={{width:'183px'}}/>
        if(process.env.REACT_APP_ENV === "essilorstaging" || process.env.REACT_APP_ENV === "essilorproduction" )
        {
            SVGName ='eyeviz'
            bottomSVG =<img src={require('../../assets/Essilor-img.png')} style={{width:'500px'}} />
        }

        return (
            <div className={"login-page"}>
                <div className={'h-centered eye-swift-login-logo-container'}>
                    <SvGraphics svgname={SVGName} className={'eye-swift-login-logo'} style={{width:'340px'}}/>
                </div>
                <div className={'centered eye-swift-login-main-board'}>
                    <label className={'eye-swift-login-label'}>{t('defaults.TwoFA.TwoFA')}</label>
                    <label className={'h-centered eye-swift-validation-label'}>{t('defaults.TwoFA.TwoFA_info')}</label>
                    <div className={'eye-swift-login-form-container'}>
                        <div className={'h-centered eye-swift-login-form'}>
                            <Error isNonFloat={true} errorMessage={utils.get(this.state.errorsOnSave ,'mfa')} isShown={!!utils.get(this.state.errorsOnSave ,'mfa')}/>
                            <div className={"login-input-wrapper form-group eye-swift-login-form-group"}>
                                <input className={"nova-input nova-form-input eye-swift-login-input-field"}
                                       placeholder={t('defaults.TwoFA.valid_cd')}
                                       type={"text"}
                                       name={"twoFA"}
                                       value={this.state.twoFA}
                                       onChange={e => this.setMFA(e)}
                                       onKeyPress={event => {
                                           if (event.key === 'Enter') {
                                               this.onSubmit()
                                           }
                                       }}/>
                                <SvGraphics className={"input-pic"} svgname={'lock'} style={{width: '20px', height: '20px'}}/>
                            </div>
                            <div className="form-group eye-swift-login-form-group">
                                <button className="btn h-centered btn-primary eye-swift-login-form-button" onClick={this.onSubmit}>{t('defaults.TwoFA.valid')}</button>
                            </div>

                        </div>
                    </div>
                    <div className={'eye-swift-login-form-group loginError red h-centered'}>
                        <Error isNonFloat={true} errorMessage={t('defaults.TwoFA.auth_err') + ': ' + this.state.errMessage} isShown={this.state.isErr}/>
                    </div>
                    <div className={'h-centered eye-swift-login-footer-container'}
                         onClick={() => this.props.history.goBack()}>
                        <a>{t('defaults.TwoFA.back_to_login')}</a>
                    </div>
                </div>
                <div className={'h-centered nova-sight-login-logo-container'}>
                    {/*<SvGraphics svgname={SVGLogo} className={'nova-sight-login-logo'} style={{width:'183px'}}/>*/}
                    {bottomSVG}
                </div>

                <div className={'login-version-element'}>
                    <label className={'login-version-element-label'}>Version:</label>
                    <label className={'login-version-element-value'}>{Config.version}</label>
                </div>
                <Reaptcha
                    ref={ref => (this.recaptchaRef = ref)}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    size={'invisible'}
                    explicit={false}
                    onVerify={token => {
                        if (!!token) this.submitData()
                        else {
                            this.setState({
                                isErr: true,
                                errMessage: 'Blocked By ReCAPTCHA',
                            })
                        }
                    }}
                    onExpire={() => {this.setState({
                        isErr: true,
                        errMessage: 'ReCAPTCHA Expired, please refresh the page',
                    })}}
                    onError={err => {this.setState({
                        isErr: true,
                        errMessage: err,
                    })}}
                />
            </div>

        )
    }
}

let Login2FA = compose(
    withTranslation()
)(Login2FAComposed)

export default Login2FA;
