import React, {Component} from 'react'
import SvGraphics from "../../assets/SvGraphics";
import { filterPatientsTable} from "../../redux/actions/Tabs-Actions";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import PatientChart from "../../PatientChart";
import {dateTimeArr, dateTimeArr2, getDateFormat, getTimeFormat} from "../../utils/getDateTimeFormat";
import PatientChartDirection from "../../PatientChartDirection";



class CTPatientBlockConnected extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visibleGraphData: 0,
            maxData: 3,
        }
    }

    render() {
        let {t}=this.props
        let categories=[]
        let  horizontalDeviationDriection=[]
        let  verticalDeviationDriection=[]
        let horizontalDeviation = {
            name: t('ecp.info_filters.horizontal_deviation'),
            data: []
        }
        let verticalDeviation = {
            name: t('ecp.info_filters.vertical_deviation'),
                data: []
        }

        let lastVisits = this.props.lastVisitsData
        let condition = ""
        if (this.props.svgName === "noglasses")
            condition="SC"
        else
            condition="CC"

        let maxY=1.5
        if(lastVisits){
            for (const visit of lastVisits) {
                for (const testResults of visit.TestsResults) {
                    if (testResults.CTTestStorageID > 0 && testResults.OpticalCorrection === condition && testResults.CTTestsResult.SelectedByUser===1){
                        categories.push(dateTimeArr2(new Date(visit.VisitDate)))
                        let horizontalDeviationValue=testResults.CTTestsResult.ResultInDioptersHorizontalValue
                        let verticalDeviationValue=testResults.CTTestsResult.ResultInDioptersVerticalValue

                        horizontalDeviation.data.push(horizontalDeviationValue===null?"":horizontalDeviationValue)
                        verticalDeviation.data.push(verticalDeviationValue===null?"":verticalDeviationValue)

                        let horizontalDeviationDriectionValue=testResults.CTTestsResult.ResultInDioptersHorizontalDirection?
                            testResults.CTTestsResult.ResultInDioptersHorizontalDirection:""
                        let verticalDeviationDriectionValue=testResults.CTTestsResult.ResultInDioptersVerticalDirection?
                            testResults.CTTestsResult.ResultInDioptersVerticalDirection:""

                        horizontalDeviationDriection.push(horizontalDeviationDriectionValue)

                        verticalDeviationDriection.push(verticalDeviationDriectionValue)


                        maxY=Math.max(horizontalDeviationValue, verticalDeviationValue,maxY)

                    }
                }
            }
        }

        let series=  [horizontalDeviation,verticalDeviation]

        return(
            <div className={'patient-dashboard-chart-area'}>
                <div className={'patient-chart-topping'}>
                    <div className={'title-svg'}>
                        <div className={'chart-title'}>{this.props.chartTitle}</div>
                        <SvGraphics className={'glasses-style'} svgname={this.props.svgName}/>
                    </div>
                    <div className={'patient-chart-indication-area'}>
                        <div className={'patient-chart-indication patient-chart-1st-indication'}>
                            <span className={'patient-chart-indication-color'}>&nbsp;</span>
                            <div className={'patient-chart-indication-text'}>{this.props.firstTopic}</div>
                        </div>
                        <div className={'patient-chart-indication patient-chart-2nd-indication'}>
                            <span className={'patient-chart-indication-color'}>&nbsp;</span>
                            <div className={'patient-chart-indication-text'}>{this.props.secondTopic}</div>
                        </div>
                    </div>
                </div>
                <div className={'ACT-chart'}>
                    <PatientChartDirection series={series} chartType={'bar'} colors={['#25a6ff','#0575e6']} minYValue={0} maxYValue={maxY+0.5} tickAmount={4}
                                           horizontalDeviationDriection={horizontalDeviationDriection}
                                           verticalDeviationDriection={verticalDeviationDriection}
                                           xCategories={categories} seriesNum={2} type={"ct"} ytitle={'[Δ]'}/>

                </div>
            </div>
        );
    }

}
function mapDispatchToProps(dispatch) {
    return {
        filterPatientsTable:(filterType,payload) => dispatch(filterPatientsTable(filterType,payload)),
    };
}
const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData,
        patientsCount:state.patientsTableDataReducer.patientsCount,
        activePatientsCount:state.patientsTableDataReducer.activePatientsCount,
        pendingPatientsCount:state.patientsTableDataReducer.pendingPatientsCount,
        lockedPatientsCount:state.patientsTableDataReducer.lockedPatientsCount,
    };
};
const CTPatientBlock = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(CTPatientBlockConnected)
export default CTPatientBlock
