import React, {Component} from "react";
import SvGraphics from "../../assets/SvGraphics";
import Prototypes from 'prop-types'
import {doctorApi} from "../../services/ApiService";
import {makeError} from "../modals/ErrorModal";
import {sendMessage} from "../modals/MessageModal";

class ArchiveTabTools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterArchiveTable:'',
            searchValue: '',
        };
    }

    componentDidMount() {
    }

    filterArchiveTable(val){
        this.setState({searchValue:val})
        this.props.filterArchiveTable(val)
    }

    sendMessage = async () => {
        let {t} = this.props
        if (this.props.recipients.length > 0){
            let Method = await sendMessage({
            },this.props.recipients);
            if (Method){
                let PatientsIDs = this.props.recipients.map(patient =>{
                    return patient.id
                })
                await doctorApi.sendMessageToPatients({Method,PatientsIDs})
            }
        } else {
            await makeError({
                proceedLabel: t('ecp.archive.tools.ok'),
                options:{
                    title: t('ecp.archive.tools.could_not_opn_msg'),
                }
            },[t('ecp.archive.tools.select_recipients')]);
        }
    }

    render() {
        return (
            <div className={`Doctor-TabTools custom-control`}>
                <div className={"TabTools-container"}>
                    <div className={"rounded-button-wrapper"}>
                        <button type={"button"} className={"btn btn-primary rounded-btn"} onClick={this.sendMessage.bind(this)}>
                            <SvGraphics className={"mail-pic centered"} svgname={'mail'} style={{width: '25px', height: '25px'}}/>
                        </button>
                    </div>
                    {/*<div className={"rounded-button-wrapper"}>
                        <button type={"button"} className={"btn btn-primary rounded-btn"} onClick={e => {
                            let filters = document.getElementsByClassName('table-filter')
                            for (let i = 0; i < filters.length; i++) {
                                if (filters[i].style.display === "none"){
                                    filters[i].style.display = "inline-block";
                                }
                                else filters[i].style.display = "none"
                            }
                        }}>
                            <SvGraphics className={"filter-pic centered"} svgname={'filter'} style={{width: '25px', height: '25px'}}/>
                        </button>
                    </div>*/}
                    <div className={"search-wrapper"}>
                        <input className={"nova-input"} placeholder={this.props.t('ecp.archive.tools.search')} type="text" name="search"
                               id={"search"}
                               onChange={event => this.filterArchiveTable(event.target.value)} value={this.state.searchValue}/>
                        <SvGraphics className={"input-pic"} svgname={'search'} style={{width: '20px', height: '20px'}}/>
                    </div>
                </div>
            </div>
        );
    }
}
ArchiveTabTools.Prototypes = {
    filterArchiveTable: Prototypes.func.isRequired,
}

export default ArchiveTabTools

