import React from "react";
let nova_sight = (
    <g>
        <g>
            <defs>
                <path id="SVGID_1_" d="M172.219,24.262l-3.955-2.988l-2.154,2.726c-1.494-1.012-3.252-1.231-4.965-1.231
			c-6.064,0-10.986,4.219-10.986,10.986c0,6.767,4.219,10.897,10.986,10.897c2.68,0,5.492,1.362,5.492,4.352
			c0,2.987-2.416,4.658-5.492,4.658s-5.668-1.846-5.668-4.658h-5.318c0,5.844,4.701,9.668,10.986,9.668
			c6.283,0,10.855-3.692,10.855-9.668c0-2.77-0.881-5.361-4.482-7.163c3.559-1.626,4.568-5.406,4.568-8.086
			c0-2.373-0.615-4.526-2.285-6.416L172.219,24.262z M166.77,33.754c0,3.735-2.549,6.021-5.625,6.021
			c-3.121,0-5.668-2.242-5.668-6.021c0-3.779,2.547-6.109,5.668-6.109C164.221,27.645,166.77,30.02,166.77,33.754z"/>
            </defs>
            <clipPath id="SVGID_2_">
                <use xlinkHref="#SVGID_1_"  overflow="visible"/>
            </clipPath>
            <g clipPath="url(#SVGID_2_)">
                <defs>
                    <rect id="SVGID_3_" x="3.541" y="5.475" width="213" height="55"/>
                </defs>
                <clipPath id="SVGID_4_">
                    <use xlinkHref="#SVGID_3_"  overflow="visible"/>
                </clipPath>
                <rect x="145.159" y="16.273" clipPath="url(#SVGID_4_)" fill="#004FD1" width="32.061" height="47.397"/>
            </g>
        </g>
        <g>
            <defs>
                <polygon id="SVGID_5_" points="23.316,14.33 23.316,33.446 8.243,14.374 3.541,14.374 3.541,45.136 9.341,45.136 9.341,24.394
			25.513,45.18 25.513,45.136 29.116,45.136 29.116,14.33 		"/>
            </defs>
            <clipPath id="SVGID_6_">
                <use xlinkHref="#SVGID_5_"  overflow="visible"/>
            </clipPath>
            <g clipPath="url(#SVGID_6_)">
                <defs>
                    <rect id="SVGID_7_" x="3.541" y="5.475" width="213" height="55"/>
                </defs>
                <clipPath id="SVGID_8_">
                    <use xlinkHref="#SVGID_7_"  overflow="visible"/>
                </clipPath>
                <rect x="-1.459" y="9.33" clipPath="url(#SVGID_8_)" fill="#004FD1" width="35.575" height="40.85"/>
            </g>
        </g>
        <g>
            <defs>
                <path id="SVGID_9_" d="M55.066,34.325c0-6.195-4.439-11.294-11.337-11.294c-6.9,0-11.207,5.099-11.207,11.294
			c0,6.241,4.219,11.293,11.25,11.293C50.803,45.618,55.066,40.566,55.066,34.325 M49.661,34.325c0,3.296-1.977,6.373-5.889,6.373
			c-3.911,0-5.888-3.077-5.888-6.373c0-3.252,2.021-6.416,5.888-6.416C47.375,27.909,49.661,31.073,49.661,34.325"/>
            </defs>
            <clipPath id="SVGID_10_">
                <use xlinkHref="#SVGID_9_"  overflow="visible"/>
            </clipPath>
            <g clipPath="url(#SVGID_10_)">
                <defs>
                    <rect id="SVGID_11_" x="3.541" y="5.475" width="213" height="55"/>
                </defs>
                <clipPath id="SVGID_12_">
                    <use xlinkHref="#SVGID_11_"  overflow="visible"/>
                </clipPath>
                <rect x="27.522" y="18.031" clipPath="url(#SVGID_12_)" fill="#004FD1" width="32.543" height="32.587"/>
            </g>
        </g>
        <g>
            <defs>
                <path id="SVGID_13_" d="M106.063,45.135V23.471h-5.142l-0.175,2.988c-1.231-2.153-4-3.515-6.899-3.515
			c-6.329-0.045-11.294,3.865-11.294,11.337c0,7.603,4.747,11.557,11.162,11.514c2.417-0.044,5.801-1.274,7.032-3.824l0.263,3.164
			H106.063z M94.33,40.873c-3.56,0-6.415-2.462-6.415-6.592c0-4.131,2.855-6.547,6.415-6.547
			C102.768,27.734,102.768,40.873,94.33,40.873z"/>
            </defs>
            <clipPath id="SVGID_14_">
                <use xlinkHref="#SVGID_13_"  overflow="visible"/>
            </clipPath>
            <g clipPath="url(#SVGID_14_)">
                <defs>
                    <rect id="SVGID_15_" x="3.541" y="5.475" width="213" height="55"/>
                </defs>
                <clipPath id="SVGID_16_">
                    <use xlinkHref="#SVGID_15_"  overflow="visible"/>
                </clipPath>
                <rect x="77.553" y="17.944" clipPath="url(#SVGID_16_)" fill="#004FD1" width="33.511" height="32.851"/>
            </g>
        </g>
        <g>
            <defs>
                <path id="SVGID_17_" d="M135.461,19.428c-2.328-4.745-7.293-6.109-12.084-6.109c-5.67,0.044-11.91,2.638-11.91,8.965
			c0,6.9,5.801,8.57,12.086,9.317c4.086,0.438,7.119,1.626,7.119,4.526c0,3.34-3.428,4.614-7.076,4.614
			c-3.734,0-7.293-1.494-8.656-4.879l-4.833,2.506c2.285,5.624,7.119,7.558,13.402,7.558c6.855,0,12.92-2.944,12.92-9.799
			c0-7.339-5.977-9.008-12.391-9.8c-3.691-0.439-6.857-1.187-6.857-3.867c0-2.284,2.066-4.087,6.373-4.087
			c3.34,0,6.24,1.671,7.295,3.428L135.461,19.428z"/>
            </defs>
            <clipPath id="SVGID_18_">
                <use xlinkHref="#SVGID_17_"  overflow="visible"/>
            </clipPath>
            <g clipPath="url(#SVGID_18_)">
                <defs>
                    <rect id="SVGID_19_" x="3.541" y="5.475" width="213" height="55"/>
                </defs>
                <clipPath id="SVGID_20_">
                    <use xlinkHref="#SVGID_19_"  overflow="visible"/>
                </clipPath>
                <rect x="105.106" y="8.318" clipPath="url(#SVGID_20_)" fill="#004FD1" width="36.322" height="42.607"/>
            </g>
        </g>
        <g>
            <defs>
                <path id="SVGID_21_" d="M176.485,14.374v30.761h5.361V34.062c0-3.253,2.199-5.933,5.361-5.933c2.857,0,4.924,1.67,4.924,5.625
			v11.381h5.359V33.709c0-6.371-2.725-10.546-8.877-10.546c-2.459,0-4.834,0.747-6.768,3.252V14.374H176.485z"/>
            </defs>
            <clipPath id="SVGID_22_">
                <use xlinkHref="#SVGID_21_"  overflow="visible"/>
            </clipPath>
            <g clipPath="url(#SVGID_22_)">
                <defs>
                    <rect id="SVGID_23_" x="3.541" y="5.475" width="213" height="55"/>
                </defs>
                <clipPath id="SVGID_24_">
                    <use xlinkHref="#SVGID_23_"  overflow="visible"/>
                </clipPath>
                <rect x="171.485" y="9.374" clipPath="url(#SVGID_24_)" fill="#004FD1" width="31.006" height="40.761"/>
            </g>
        </g>
        <g>
            <defs>
                <path id="SVGID_25_" d="M204.055,17.934v5.58h-4.043v4.615h4.043v9.36c0,5.404,3.076,8.086,7.777,7.909
			c1.627-0.044,2.902-0.308,4.439-0.923l-1.494-4.569c-0.791,0.396-1.758,0.658-2.594,0.658c-1.668,0-2.812-1.01-2.812-3.075v-9.36
			h6.021v-4.615h-5.977v-6.15L204.055,17.934z"/>
            </defs>
            <clipPath id="SVGID_26_">
                <use xlinkHref="#SVGID_25_"  overflow="visible"/>
            </clipPath>
            <g clipPath="url(#SVGID_26_)">
                <defs>
                    <rect id="SVGID_27_" x="3.541" y="5.475" width="213" height="55"/>
                </defs>
                <clipPath id="SVGID_28_">
                    <use xlinkHref="#SVGID_27_"  overflow="visible"/>
                </clipPath>
                <rect x="195.012" y="12.363" clipPath="url(#SVGID_28_)" fill="#004FD1" width="26.26" height="38.044"/>
            </g>
        </g>
        <g>
            <defs>
                <path id="SVGID_29_" d="M65.527,22.553c0,4.175,6.328,4.175,6.328,0S65.527,18.378,65.527,22.553"/>
            </defs>
            <clipPath id="SVGID_30_">
                <use xlinkHref="#SVGID_29_"  overflow="visible"/>
            </clipPath>
            <g clipPath="url(#SVGID_30_)">
                <defs>
                    <rect id="SVGID_31_" x="3.541" y="5.475" width="213" height="55"/>
                </defs>
                <clipPath id="SVGID_32_">
                    <use xlinkHref="#SVGID_31_"  overflow="visible"/>
                </clipPath>
                <rect x="60.527" y="14.421" clipPath="url(#SVGID_32_)" fill="#9650E6" width="16.328" height="16.263"/>
            </g>
        </g>
        <g>
            <defs>
                <polygon id="SVGID_33_" points="62.366,23.271 55.804,23.271 66.013,44.997 70.725,44.997 71.413,43.507 		"/>
            </defs>
            <clipPath id="SVGID_34_">
                <use xlinkHref="#SVGID_33_"  overflow="visible"/>
            </clipPath>
            <g clipPath="url(#SVGID_34_)">
                <defs>
                    <rect id="SVGID_35_" x="3.541" y="5.475" width="213" height="55"/>
                </defs>
                <clipPath id="SVGID_36_">
                    <use xlinkHref="#SVGID_35_"  overflow="visible"/>
                </clipPath>
                <rect x="50.804" y="18.271" clipPath="url(#SVGID_36_)" fill="#004FD1" width="25.608" height="31.726"/>
            </g>
        </g>
        <g>
            <defs>
                <path id="SVGID_37_" d="M143.299,19.421c-1.521,0-3.162,0.979-3.162,3.132c0,2.151,1.641,3.132,3.162,3.132
			c1.523,0,3.166-0.98,3.166-3.132C146.465,20.4,144.823,19.421,143.299,19.421"/>
            </defs>
            <clipPath id="SVGID_38_">
                <use xlinkHref="#SVGID_37_"  overflow="visible"/>
            </clipPath>
            <g clipPath="url(#SVGID_38_)">
                <defs>
                    <rect id="SVGID_39_" x="3.541" y="5.475" width="213" height="55"/>
                </defs>
                <clipPath id="SVGID_40_">
                    <use xlinkHref="#SVGID_39_"  overflow="visible"/>
                </clipPath>
                <rect x="135.137" y="14.421" clipPath="url(#SVGID_40_)" fill="#9650E6" width="16.328" height="16.264"/>
            </g>
        </g>
        <g>
            <defs>
                <path id="SVGID_41_" d="M143.301,28.126c-0.914,0-1.77-0.227-2.654-0.658v17.934h5.309V27.469
			C145.069,27.899,144.215,28.126,143.301,28.126"/>
            </defs>
            <clipPath id="SVGID_42_">
                <use xlinkHref="#SVGID_41_"  overflow="visible"/>
            </clipPath>
            <g clipPath="url(#SVGID_42_)">
                <defs>
                    <rect id="SVGID_43_" x="3.541" y="5.475" width="213" height="55"/>
                </defs>
                <clipPath id="SVGID_44_">
                    <use xlinkHref="#SVGID_43_"  overflow="visible"/>
                </clipPath>
                <rect x="135.647" y="22.468" clipPath="url(#SVGID_44_)" fill="#004FD1" width="15.309" height="27.934"/>
            </g>
        </g>
        <g>
            <defs>
                <path id="SVGID_45_" d="M66.019,44.997h6.003c0,0,7.339-30.664,33.544-39.51C105.567,5.487,75.935,5.93,66.019,44.997"/>
            </defs>
            <clipPath id="SVGID_46_">
                <use xlinkHref="#SVGID_45_"  overflow="visible"/>
            </clipPath>
            <g clipPath="url(#SVGID_46_)">
                <defs>
                    <rect id="SVGID_47_" x="3.541" y="5.475" width="213" height="55"/>
                </defs>
                <clipPath id="SVGID_48_">
                    <use xlinkHref="#SVGID_47_"  overflow="visible"/>
                </clipPath>
                <rect x="61.019" y="0.487" clipPath="url(#SVGID_48_)" fill="#0575E6" width="49.547" height="49.51"/>
            </g>
        </g>
    </g>
);

let eye_swift_white = (
    <g>
        <path fill="#33E800" d="M38.53,19.8c0,4.481,6.792,4.481,6.792,0C45.322,15.32,38.531,15.32,38.53,19.8 M158.607,16.44
		c-1.635,0-3.396,1.052-3.396,3.36c0,2.31,1.762,3.362,3.396,3.362s3.396-1.052,3.396-3.362
		C162.003,17.492,160.242,16.44,158.607,16.44"/>
        <path fill="#FFFFFF" d="M158.607,25.782c-0.98,0-1.899-0.243-2.85-0.706v19.246h5.697V25.077
		C160.505,25.54,159.589,25.782,158.607,25.782 M0.617,10.961v33.223h24.87v-6.168H6.835v-7.5h17.989v-5.838H6.833v-7.642h18.655
		v-6.075H0.617z"/>
        <path fill="#FFFFFF" d="M68.665,20.074c-7.594,0-12.435,5.126-12.435,12.244c0,7.5,4.794,12.483,12.862,12.483
		c3.56,0,7.594-1.233,10.062-3.797l-3.702-3.655c-1.329,1.377-4.129,2.185-6.265,2.185c-4.082,0-6.597-2.089-6.977-4.984h18.273
		C81.385,24.915,76.496,20.074,68.665,20.074 M74.93,29.756H62.305c0.854-3.037,3.417-4.556,6.55-4.556
		C72.177,25.2,74.55,26.72,74.93,29.756 M111.137,16.42c-2.517-5.126-7.88-6.597-13.053-6.597
		c-6.123,0.047-12.862,2.847-12.862,9.682c0,7.452,6.265,9.255,13.052,10.062c4.414,0.476,7.689,1.757,7.689,4.89
		c0,3.606-3.702,4.983-7.642,4.983c-4.034,0-7.879-1.613-9.35-5.269l-5.221,2.705c2.468,6.075,7.689,8.164,14.476,8.164
		c7.404,0,13.954-3.181,13.954-10.584c0-7.927-6.455-9.73-13.384-10.584c-3.987-0.475-7.404-1.282-7.404-4.177
		c0-2.468,2.23-4.414,6.882-4.414c3.607,0,6.739,1.803,7.878,3.702L111.137,16.42z"/>
        <path fill="#FFFFFF" d="M130.09,20.739l-5.078,17.608l-5.363-17.61h-6.455l8.209,23.542h6.74l2.422-6.931l2.088-7.83l2.089,7.83
		l2.42,6.931h6.739l8.164-23.541h-6.406l-5.364,17.608l-5.126-17.61L130.09,20.739z"/>
        <path fill="#FFFFFF" d="M176.259,44.186V26.197h6.882v-5.079h-6.882v-1.566c0-2.848,1.519-4.177,3.513-4.177
		c1.187,0,2.326,0.427,3.56,1.234l2.136-4.319c-1.9-1.234-4.035-1.898-6.074-1.898c-4.701,0-8.924,2.895-8.924,9.16v1.566h-4.367
		v5.08h4.367v17.987H176.259L176.259,44.186z M191.375,15.09v6.027h-4.367V26.1h4.367v10.11c0,5.838,3.322,8.733,8.4,8.543
		c1.757-0.047,3.133-0.332,4.795-0.997l-1.614-4.936c-0.854,0.427-1.899,0.712-2.8,0.712c-1.805,0-3.038-1.091-3.038-3.322V26.1
		h6.502v-4.983h-6.455v-6.645L191.375,15.09L191.375,15.09z"/>
        <path fill="#FFFFFF" d="M28.003,20.585L38.96,43.902h6.509L35.046,20.585H28.003z"/>
        <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="0.774" y="0.854" width="45.015" height="52.146">
            <feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
        </filter>
        <mask maskUnits="userSpaceOnUse" x="0.774" y="0.854" width="45.015" height="52.146" id="b">
            <path filter="url(#Adobe_OpacityMaskFilter)" id="a" fill="#FFFFFF" d="M0.774,0.854h45.015V53H0.774V0.854z"/>
        </mask>
        <path transform="translate(35.617 .646)" mask="url(#b)" fill="#FFFFFF" d="M6.39,33.765l0.004,0.008c0,0-1.973,5.692-3.004,9.479h0.022
		C2.246,47.473,1.057,51.934,0.774,53h6.593c0.872-4.244,2.537-10.136,3.155-12.276c2.49-7.873,11.865-31.97,35.267-39.87
		C45.789,0.854,19.022,1.292,6.39,33.765"/>
        <path fill="#FFFFFF" d="M213.605,26.52h0.797v-0.046l-1.324-2.394c0.316-0.112,0.592-0.316,0.792-0.585
		c0.19-0.26,0.285-0.566,0.285-0.918c0-0.552-0.171-0.973-0.513-1.262c-0.343-0.289-0.832-0.434-1.467-0.434h-1.867v5.64h0.748
		v-2.282h1.324L213.605,26.52L213.605,26.52z M211.057,21.49h1.139c0.393,0.002,0.693,0.096,0.899,0.282
		c0.21,0.187,0.312,0.454,0.312,0.803c0,0.321-0.11,0.577-0.327,0.77c-0.219,0.19-0.512,0.286-0.881,0.286h-1.143V21.49z"/>
        <path fill="none" stroke="#FFFFFF" strokeWidth="0.691" d="M212.062,28.377c2.515,0,4.554-2.039,4.554-4.554
		c0-2.515-2.039-4.554-4.554-4.554c-2.516,0-4.555,2.039-4.555,4.554C207.508,26.338,209.547,28.377,212.062,28.377z"/>
    </g>
);

let eyeviz = (
    <g transform="matrix(1.3333333,0,0,-1.3333333,0,193.33333) scale(0.10000000149011612) " id="g12">
        <path fill="#57c9f4" id="path14" d="m153.992,615.984l0,237.996l791.992,0l-4.004,-237.996l-787.988,0zm881.988,-351.996l0,-239.9997l-1035.98,0l137.996,239.9997l897.984,0zm-895.992,919.982l-139.988,240l1035.98,0l0,-240l-895.992,0"/>
        <path fill="#3ea4de" id="path16" d="m2619.98,1423.97l-453.99,-709.986l-126.01,211.996l298,497.99l282,0zm-1069.99,0l513.99,-869.986l0,-529.9957l-247.99,0l0,523.9997l-555.99,875.982l289.99,0"/>
        <path fill="#2585c8" id="path18" d="m2997.99,615.984l0,237.996l791.99,0l-4,-237.996l-787.99,0zm881.98,-351.996l0,-239.9997l-1035.98,0l138,239.9997l897.98,0zm-895.99,919.982l-139.99,240l1035.98,0l0,-240l-895.99,0"/>
        <path fill="#126bb4" id="path20" d="m5351.97,1095.98l-423.99,-1071.9917l-270,0l-553.99,1399.9817l257.99,0l430,-1093.982l431.99,1093.982l128,-327.99"/>
        <path fill="#126bb4" id="path22" d="m5819.98,1003.98l0,-979.9917l-243.99,0l0,1401.9817l243.99,-421.99"/>
        <path fill="#126bb4" id="path24" d="m6233.96,1183.97l-138,240l1113.98,0l0,-217.99l-589.99,-687.992l-128,221.996l380,443.986l-637.99,0zm975.98,-921.982l0,-237.9997l-1145.98,0l0,237.9997l1145.98,0"/>
        <path fill="#403b3a" id="path26" d="m8437.91,0c-86,0 -168,31.9883 -241.99,96c-64.01,55.988 -108,135.988 -132,240l160,0c20,-46.012 45.99,-84.012 77.99,-114.012c46,-40 96,-60 150,-60c62.01,0 118,26.012 166,78c46,42 70,102 72,184c-2,80.004 -26,144 -72,189.996c-46,44.004 -102,66.004 -172,66.004l-64,0l0,139.992l48,0c78,0 136,22 178,66c42,41.997 63.99,98 63.99,164l0,2c-1.99,70 -23.99,124 -65.99,165.99l0,2.01c-42,42 -96,63.99 -164,66c-50,0 -94,-16 -134,-48.01c-38,-31.99 -63.99,-75.99 -78,-129.99l-159.99,0c11.99,90 50,168 116,235.99c72,70 153.99,104 248,104c61.99,0 115.99,-10 163.99,-30c48,-20 90,-50 126,-90c70,-77.99 104,-167.99 105.99,-273.99c0,-50 -10,-102.003 -28,-154c-21.99,-46 -60,-90 -117.99,-128l-30,-20l30,-17.996c57.99,-38 100,-82.004 125.99,-135.996c24.01,-52.004 36.01,-108 36.01,-166c0,-65.988 -10,-125.988 -30,-178c-20,-50 -48,-94 -86,-132c-36,-35.988 -80,-63.988 -130,-85.988c-58,-24.0117 -112,-36 -162,-36"/>
        <path fill="#403b3a" id="path28" d="m9805.9,1307.97c-94,0 -169.99,-31.99 -228,-94c-58,-63.99 -88,-145.99 -88,-245.993l0,-489.989c0,-98 30,-180 88,-241.988c58.01,-64.012 134,-96.012 228,-96.012c94,0 170,32 228,96.012c58,61.988 88,143.988 88,241.988l0,489.989c0,100.003 -30,182.003 -88,245.993c-58,62.01 -134,94 -228,94zm0,-1307.97c-81.99,0 -158,16 -229.99,50c-72,33.9883 -130,83.988 -174.01,153.988c-45.99,70 -67.99,154 -67.99,254l0,531.992c0,102 22,186 67.99,256c44.01,67.99 102.01,119.99 174.01,154c71.99,33.99 148,49.99 229.99,49.99c82,0 158,-16 230,-49.99c72,-34.01 130,-86.01 176,-154c44,-70 66,-154 66,-256l0,-531.992c0,-100 -22,-184 -66,-254c-46,-70 -104,-119.9997 -176,-153.988c-72,-34 -148,-50 -230,-50"/>
        <path fill="#403b3a" id="path30" d="m11237.9,1307.97c-94,0 -170,-31.99 -228,-94c-58,-63.99 -88,-145.99 -88,-245.993l0,-489.989c0,-98 30,-180 88,-241.988c58,-64.012 134,-96.012 228,-96.012c94,0 170,32 228,96.012c58,61.988 88,143.988 88,241.988l0,489.989c0,100.003 -30,182.003 -88,245.993c-58,62.01 -134,94 -228,94zm0,-1307.97c-82,0 -158,16 -230,50c-72,33.9883 -130,83.988 -174,153.988c-46,70 -68,154 -68,254l0,531.992c0,102 22,186 68,256c44,67.99 102,119.99 174,154c72,33.99 148,49.99 230,49.99c82,0 158,-16 230,-49.99c72,-34.01 130,-86.01 176,-154c44,-70 66,-154 66,-256l0,-531.992c0,-100 -22,-184 -66,-254c-46,-70 -104,-119.9997 -176,-153.988c-72,-34 -148,-50 -230,-50"/>
    </g>
);
let eye_swift_blue = (
    <g fill="none" fillRule="evenodd">
        <defs>
            <path id="a" d="M.774.854h45.015V53H.774z"/>
        </defs>
        <path fill="#33E800" d="M37.914 19.3c0 4.481 6.79 4.481 6.79 0 0-4.48-6.79-4.48-6.79 0M157.99 15.94c-1.635 0-3.396 1.052-3.396 3.36 0 2.31 1.761 3.362 3.396 3.362s3.396-1.052 3.396-3.362c0-2.308-1.76-3.36-3.396-3.36"/>
        <path fill="#0599FF" d="M157.991 25.282c-.981 0-1.899-.243-2.849-.706v19.246h5.696V24.576c-.949.463-1.866.705-2.847.705M0 10.461v33.223h24.871v-6.169H6.217v-7.5h17.989v-5.837H6.217v-7.642h18.654v-6.075z"/>
        <g fill="#0599FF">
            <path d="M68.048 19.574c-7.594 0-12.435 5.126-12.435 12.245 0 7.499 4.794 12.483 12.862 12.483 3.56 0 7.594-1.234 10.062-3.797l-3.702-3.655c-1.329 1.377-4.129 2.184-6.265 2.184-4.082 0-6.597-2.089-6.977-4.984h18.273c.902-9.635-3.987-14.476-11.818-14.476m6.265 9.682H61.688c.854-3.037 3.417-4.556 6.55-4.556 3.322 0 5.695 1.519 6.075 4.556M110.52 15.92c-2.517-5.127-7.88-6.598-13.053-6.598-6.123.047-12.862 2.847-12.862 9.682 0 7.452 6.265 9.255 13.052 10.063 4.414.474 7.69 1.756 7.69 4.889 0 3.606-3.703 4.982-7.643 4.982-4.034 0-7.879-1.612-9.35-5.267l-5.22 2.705c2.467 6.075 7.688 8.164 14.475 8.164 7.404 0 13.954-3.18 13.954-10.584 0-7.928-6.455-9.73-13.384-10.585-3.987-.475-7.404-1.282-7.404-4.177 0-2.468 2.23-4.414 6.882-4.414 3.607 0 6.74 1.803 7.878 3.702l4.984-2.563z"/>
        </g>
        <path fill="#0599FF" d="M129.473 20.239l-5.078 17.609-5.364-17.61h-6.455l8.211 23.542h6.74l2.421-6.93 2.088-7.83 2.088 7.83 2.421 6.93h6.74l8.163-23.541h-6.407l-5.364 17.609-5.126-17.61z"/>
        <g fill="#0599FF">
            <path d="M175.642 43.685V25.697h6.882v-5.079h-6.882v-1.566c0-2.848 1.519-4.177 3.512-4.177 1.187 0 2.326.427 3.56 1.234l2.136-4.319c-1.9-1.234-4.035-1.898-6.075-1.898-4.7 0-8.923 2.895-8.923 9.16v1.566h-4.367v5.079h4.367v17.988h5.79zM190.758 14.59v6.027h-4.367V25.6h4.367v10.11c0 5.838 3.322 8.733 8.401 8.543 1.756-.047 3.132-.332 4.794-.997l-1.614-4.936c-.854.427-1.899.712-2.8.712-1.804 0-3.038-1.09-3.038-3.322V25.6h6.502v-4.983h-6.455v-6.645l-5.79.617z"/>
        </g>
        <path fill="#0599FF" d="M27.386 20.085l10.957 23.317h6.509L34.429 20.085z"/>
        <g>
            <g transform="translate(35 .146)">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a"/>
                </mask>
                <path fill="#0575E6" d="M6.39 33.765l.004.008s-1.973 5.692-3.004 9.48h.022C2.246 47.473 1.057 51.934.774 53h6.593c.872-4.243 2.537-10.135 3.155-12.276 2.49-7.873 11.865-31.97 35.267-39.87 0 0-26.767.438-39.4 32.91" mask="url(#b)"/>
            </g>
            <path fill="#0599FF" d="M212.988 26.02h.798v-.046l-1.325-2.394a1.69 1.69 0 0 0 .792-.585c.19-.26.285-.566.285-.918 0-.552-.171-.973-.513-1.262-.342-.289-.831-.434-1.466-.434h-1.867v5.639h.748v-2.281h1.324l1.224 2.281zm-2.548-5.031h1.138c.393.003.693.097.901.283.208.187.311.454.311.803 0 .321-.109.577-.327.769-.218.191-.512.287-.881.287h-1.142v-2.142z"/>
            <path stroke="#0599FF" strokeWidth=".691" d="M211.445 27.877a4.554 4.554 0 1 0 0-9.108 4.554 4.554 0 0 0 0 9.108z"/>
        </g>
    </g>
);

export {
    nova_sight,
    eye_swift_blue,
    eyeviz,
    eye_swift_white
}