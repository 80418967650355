//VersionsTab
import React, {Component} from 'react'
import { connect } from "react-redux";
import MyTable from "../../../components/NovaTable/Table";
import {cleanVersion,setVersionDataSelection, editVersion, deleteVersion, filterVersionsTable} from "../../../redux/actions/Tabs-Actions";
import {adminApi} from "../../../services/ApiService";
import AlertConfirm from "../../../components/modals/Confirm";
import VersionEditForm from "../../../components/version/VersionEditForm";
import SvGraphics from "../../../assets/SvGraphics";
import Constants from "../../../utils/constants";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {ConstantsArrT, ConstantsObjT} from "../../../utils/ConstantsWithTranslate";
import ConstantsUtils from "../../../utils/ConstantsUtils";

class VersionsTabConComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entry: null,
            isSideBarOpen: false
        }
    }

    async openSideBar(version){
        document.body.style.overflow = "hidden"
        await this.setState({entry:version,isSideBarOpen:true})
        this.forceUpdate();
    }

    componentWillUnmount() {
        this.props.cleanVersion()
    }

    onSelectionChange = (selected) => {
        this.setState({selected})
        this.props.setVersionDataSelection(selected)
    }

    editVersionDetails = async (versions) => {
        await this.openSideBar(versions)
    }

    removeVersion = async (row) => {
        let confirm = await AlertConfirm({
            options:{
                title: `Delete Version`,
            }},`Are you sure you want to delete this Version: ${row.VersionNO} of ${row.VersionType}?`)
        if (confirm) {
            let SystemVersionID = row.SystemVersionID
            let response = await adminApi.deleteVersion(SystemVersionID)
            if (response){
                await this.props.deleteVersion(response.data.SystemVersionID)
            }
        }
    }

    setVersionsMoreFunctions = () => {
        let {t} = this.props
        return [
            {
                name: 'Edit Version',
                display: t('admin.version.table.edit_ver'),
                call: (row => this.editVersionDetails(row))
            },
            {
                name: 'Remove Version',
                display: t('admin.version.table.rem_ver'),
                call: (row => this.removeVersion(row))
            }
        ]
    }

    onFilterDataSelected = (filter) => {
        this.props.filterVersionsTable(Object.keys(filter)[0],Object.values(filter)[0])
    }

    getColumns = (t) => {
        return [
            {accessor: 'Type', Header: t('admin.version.table.ver_type'), makeFilter: true, resizable: false,Cell: ({ original }) => {
                    return t(original.Type)
                }},
            {accessor: 'VersionNOInt', Header: t('admin.version.table.version'), resizable: false,Cell: ({ original }) => {
                    return <label>{original.VersionNO}</label>
                }},
            {accessor: 'VersionURL', Header: t('admin.version.table.url'), resizable: false,Cell: ({ original }) => {
                    return <a href={original.VersionURL} download target="_blank">{original.VersionURL}</a>
                }},
            //{accessor: 'FullName', Header: 'FullName', resizable: false},
            {accessor: 'AdminID', Header: t('admin.version.table.admin_id'), resizable: false},
            {accessor: 'Date', Header: t('admin.version.table.date'), resizable: false},
            {accessor: 'forceUpdate', Header: t('admin.version.form.isForceUpdate'), resizable: false,Cell: ({ original }) => {
                    if (original.forceUpdate){
                        return (<SvGraphics svgname={'true'} style={{height:'15px',width:'15px', marginLeft: '19px'}}/>)
                    } return ''
                }}
            ]
    }

    render() {
        let {t} = this.props
        let columns = this.getColumns(t)
        let {entry,isSideBarOpen} = this.state
        return (
            <div className={'context-area'}>
                <MyTable
                    data={this.props.versionData}
                    initialData={this.props.initiateVersionData}
                    columns={columns}
                    onSelectionChange={selected => this.onSelectionChange(selected)}
                    moreFunctions={this.setVersionsMoreFunctions()}
                    onFilterDataSelected={filter => this.onFilterDataSelected(filter)}
                    hasSelection={false}
                    hasMore={true}
                    hasShowInfo={false}
                />
                {isSideBarOpen && <VersionEditForm
                    title={'Edit Version'}
                    t={e => t(e)}
                    entry={entry}
                    editVersion={versionData => this.props.editVersion(versionData)}
                    isSideBarOpen={isSideBarOpen}
                    closeSideBar={() => this.setState({isSideBarOpen: false})}/>}
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setVersionDataSelection: versionData => dispatch(setVersionDataSelection(versionData)),
        editVersion:versionData => dispatch(editVersion(versionData)),
        deleteVersion: versionId => dispatch(deleteVersion(versionId)),
        cleanVersion: () => dispatch(cleanVersion()),
        filterVersionsTable: (type,payload) => dispatch(filterVersionsTable(type,payload))
    };
}
const mapStateToProps = state => {
    return {
        versionData:state.versionTableDataReducer.versionData,
        initiateVersionData:state.versionTableDataReducer.initiateVersionData
    };
};

const VersionsTab = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(VersionsTabConComposed);

export default VersionsTab;
