import {
    FILTER_PENDINGSDATA,
    PENDING_DATA,
    PENDINGS_COUNT,
    DELETE_PENDING,
} from "../../constants/Tabs-Action-Types";
import utils from "../../../utils/utils";


const initialState = {
    initiatePendingData:[],
    pendingData:[],
    pendingTableFilter:{},
    pendingsCount: 0
};


const pendingTableDataReducer = (state = initialState, action)=> {

    switch(action.type){

        case PENDINGS_COUNT:
            let collection = []
            action.payload.forEach(pending =>{
                collection.push({
                })
            })

            let _pdata = JSON.parse(JSON.stringify(collection));
            state = {
                ...state,
                initiatePendingData:_pdata,
                pendingData : _pdata,
                pendingsCount: collection.length
            };
            break;
        case PENDING_DATA:{
            let collection = []
            action.payload.forEach(user =>{

                collection.push({
                    id:user.id,
                    FirstName:user.FirstName,
                    LastName: user.LastName,
                    Email: user.Email,
                    Phone: user.PhoneNumber,
                    SiteName: user.SiteName,

                })
            })

            let _pdata = JSON.parse(JSON.stringify(collection));
            state = {
                ...state,
                initiatePendingData:_pdata,
                pendingData : _pdata,
                pendingsCount: collection.length
            };
            break;
        }

        case FILTER_PENDINGSDATA:{
            let type = action.searchType
            let payload = action.payload
            let pendingTableFilter = state.pendingTableFilter
            let filteredPendingsData = state.initiatePendingData
            let visibleFilterData = []
            let visiableData = []

            if (type === 'search' && (payload === '' || payload === null)){
                delete pendingTableFilter.search
            } else {
                utils.set(pendingTableFilter,type,payload)
            }

            for (const [key, value] of Object.entries(pendingTableFilter)) {
                if (key === 'search'){
                    let val = value.toLowerCase()
                    filteredPendingsData = filteredPendingsData.filter(x =>
                        String(x.FirstName).toLowerCase().indexOf(val) > -1 ||
                        String(x.LastName).toLowerCase().indexOf(val) > -1 ||
                        String(x.Email).toLowerCase().indexOf(val) > -1 ||
                        String(x.Phone).toLowerCase().indexOf(val) > -1 ||
                        String(x.SiteName).toLowerCase().indexOf(val) > -1
                    )
                    visibleFilterData = filteredPendingsData
                } else {
                    let pendings = JSON.parse(JSON.stringify(filteredPendingsData))
                    let valArr = JSON.parse(JSON.stringify(value))


                    if (Array.isArray(valArr)){
                        let res = []
                        valArr.forEach(val => {
                            let pendingArr = pendings.filter(pending => {
                                let pendingVal = pending[key]
                                return String(pendingVal).toLowerCase() === String(val).toLowerCase()
                            })
                            pendingArr.forEach(pending => res.push(pending))
                        })
                        filteredPendingsData = res
                    }
                }
            }
            visiableData = filteredPendingsData

            let _fpdata = JSON.parse(JSON.stringify(visibleFilterData));
            let _vdata = JSON.parse(JSON.stringify(visiableData));
            let _pfdata = JSON.parse(JSON.stringify(pendingTableFilter));
            state = {
                ...state,
                filteredPendingsData: _fpdata,
                pendingData: _vdata,
                pendingTableFilter: _pfdata
            };
            break;
        }

        case DELETE_PENDING: {
            let pendingId = action.pendingId

            let pendingData = state.pendingData
            let initiatePendingData = state.initiatePendingData

            let newpendingData = pendingData.filter(pending => pending.PendingStorageID !== pendingId)
            let newinitiatePendingData = initiatePendingData.filter(pending => pending.PendingStorageID !== pendingId)

            let _pdata = JSON.parse(JSON.stringify(newpendingData));
            let _idata = JSON.parse(JSON.stringify(newinitiatePendingData));

            state = {
                ...state,
                initiatePendingData:_idata,
                pendingData : _pdata,
                pendingsCount: newinitiatePendingData.length
            };
            break;
        }
        default:
            break;
    }
    return state;
};
export default pendingTableDataReducer;
