import React, {Component} from 'react'
import '../../components_style/PatientScreen.css'
import '../../components_style/rtl_css/PatientScreen.css'
import PropTypes from "prop-types";
import SvGraphics from "../../assets/SvGraphics";
import {doctorApi} from "../../services/ApiService";
import {sendMessage} from "../modals/MessageModal";
import AlertConfirm from "../modals/Confirm";
class PatientHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientName: "",
            patientId: null
        }
    }

    componentDidMount = ()  => {
        let patientName, patientId = ''
        if (this.props && this.props.patientName){
            patientName = this.props.patientName
        }
        if (this.props && this.props.patientId){
            patientId = this.props.patientId
        }
        this.setState({
            patientName, patientId
        })
    }

    sendMessage = async () => {
        let Method = await sendMessage({
        },[{id:this.props.patientId,name:this.props.patientName}]);
        if (Method){
            let PatientsIDs = [this.props.patientId]
            await doctorApi.sendMessageToPatients({Method,PatientsIDs})
        }
    }

    setPatientAsCompleted = async () => {
        //this.props.patientId
        let confirm = await AlertConfirm({
            options:{
                extraMessage:'This action cannot be undone',
                title: `Set patient as completed`,
                warningRed: true
            }},`Are you sure you want to set patient as completed?`)
        if (confirm) {
            let response = await doctorApi.editPatient({AccountStatus:3, UserStorageID: this.props.patientId},this.props.patientId)
            if (response){
                this.props.addNewArchivePatient(response.data)
                this.props.onNavBack()
            }
        }
    }


    render() {
        let {patientName} = this.state;
        return (
            <div className={'nova-patient-header'}>
                <div className={'nova-patient-header-name v-centered'}>
                    {patientName}
                </div>
                <div className={'nova-patient-header-options v-centered right'}>

                    <div className={"rounded-button-wrapper"}>
                        <button type={"button"} onClick={() => {window.print();}} className={"btn btn-primary rounded-btn"}>
                            <SvGraphics className={"filter-pic centered"} svgname={'printer'} style={{width: '25px', height: '25px'}}/>
                        </button>
                    </div>
                    <div className={"rounded-button-wrapper"}>
                        <button type={"button"} className={"btn btn-primary rounded-btn"} onClick={this.sendMessage.bind(this)}>
                            <SvGraphics className={"mail-pic centered"} svgname={'share'} style={{width: '25px', height: '25px'}}/>
                        </button>
                    </div>
                    {/*<div className={"button-wrapper"} style={{marginRight:'30px'}}>
                        <button disabled={this.props.completed} type={"button"}  className={"btn btn-primary"} onClick={this.setPatientAsCompleted.bind(this)}>Set As Completed</button>
                    </div>*/}
                </div>
            </div>
        )
    }
}
PatientHeader.propTypes = {
    patientName: PropTypes.string,
    patientId: PropTypes.string,
    addNewArchivePatient: PropTypes.func
}

export default PatientHeader;
