import {
    FILTER_ADMINSDATA,
    ADMINS_DATA,
    ADMINS_SELECTION,
    NEW_ADMIN,
    EDIT_ADMIN,
    ADMINS_COUNT,
    DELETE_ADMIN,
    ADMIN_LOCKED_STATUS, CLEAN_ADMIN
} from "../../constants/Tabs-Action-Types";
import utils from "../../../utils/utils";


const initialState = {
    initiateAdminData:[],
    adminData:[],
    adminTableFilter:{},
    adminsCount: 0
};

const adminTableDataReducer = (state = initialState, action)=> {

    switch(action.type){
        case ADMINS_COUNT:{
            state = {
                ...state,
                adminsCount: action.payload
            };
            break;
        }
        case ADMINS_DATA:
            let collection = []
            action.payload.forEach(admin =>{
                collection.push({
                    id: admin.UserStorageID,
                    UserStorageID: admin.UserStorageID,
                    TwoFA: admin.TwoFA,
                    Enabled: admin.Enabled,
                    FirstName:admin.PII.FirstName,
                    LastName: admin.PII.LastName,
                    Username: admin.PII.Email,
                    Email: admin.PII.Email,
                    PhoneNumber: admin.PII.PhoneNumber
                })
            })

            let _pdata = JSON.parse(JSON.stringify(collection));
            state = {
                ...state,
                initiateAdminData:_pdata,
                adminData : _pdata,
                adminsCount: collection.length
            };
            break;
        case CLEAN_ADMIN:{
            state = {
                ...state,
                adminTableFilter: {}
            };
            break;
        }
        case FILTER_ADMINSDATA:{
            let type = action.searchType
            let payload = action.payload
            let adminTableFilter = state.adminTableFilter
            let filteredAdminData = state.initiateAdminData
            let visibleFilterData = []
            let visiableData = []

            if (type === 'search' && (payload === '' || payload === null)){
                delete adminTableFilter.search
            } else {
                utils.set(adminTableFilter,type,payload)
            }

            for (const [key, value] of Object.entries(adminTableFilter)) {
                if (key === 'search'){
                    let val = value.toLowerCase()
                    filteredAdminData = filteredAdminData.filter(x =>
                        String(x.FirstName).toLowerCase().indexOf(val) > -1 ||
                        String(x.LastName).toLowerCase().indexOf(val) > -1 ||
                        String(x.PhoneNumber).toLowerCase().indexOf(val) > -1 ||
                        String(x.Username).toLowerCase().indexOf(val) > -1
                    )
                    visibleFilterData = filteredAdminData
                } else {
                    let admins = JSON.parse(JSON.stringify(filteredAdminData))
                    let valArr = JSON.parse(JSON.stringify(value))


                    if (Array.isArray(valArr)){
                        let res = []
                        valArr.forEach(val => {
                            let adminArr = admins.filter(admin => {
                                let adminVal = admin[key]
                                return String(adminVal).toLowerCase() === String(val).toLowerCase()
                            })
                            adminArr.forEach(admin => res.push(admin))
                        })
                        filteredAdminData = res
                    }
                }
            }
            visiableData = filteredAdminData

            let _fpdata = JSON.parse(JSON.stringify(visibleFilterData));
            let _vdata = JSON.parse(JSON.stringify(visiableData));
            let _pfdata = JSON.parse(JSON.stringify(adminTableFilter));
            state = {
                ...state,
                filteredAdminData: _fpdata,
                adminData: _vdata,
                adminTableFilter: _pfdata
            };
            break;
        }
        case ADMINS_SELECTION:{
            let selection = action.payload
            let initiateAdminData = state.initiateAdminData

            let newSelection = []
            Object.entries(selection).forEach(([key,value]) => {
                if (value){
                    let index = initiateAdminData.findIndex((obj => obj.UserStorageID === key));
                    let name = initiateAdminData[index].Username
                    newSelection.push({id:key,name})
                }
            })
            state = {
                ...state,
                selectedAdminData: newSelection
            };
            break;
        }
        case NEW_ADMIN:{
            let admin = action.payload
            let adminData = state.adminData
            let initiateAdminData = state.initiateAdminData

            let newData = [{
                id: admin.UserStorageID,
                UserStorageID: admin.UserStorageID,
                Enabled: admin.Enabled,
                TwoFA: admin.TwoFA,
                FirstName:admin.PIIProfile.FirstName,
                LastName: admin.PIIProfile.LastName,
                Username: admin.PIIProfile.Email,
                Email: admin.PIIProfile.Email,
                PhoneNumber: admin.PIIProfile.PhoneNumber
            }]

            let newadminData = adminData.concat(newData)
            let newinitiateAdminData = initiateAdminData.concat(newData)

            let _pdata = JSON.parse(JSON.stringify(newadminData));
            let _idata = JSON.parse(JSON.stringify(newinitiateAdminData));
            state = {
                ...state,
                initiateAdminData:_idata,
                adminData : _pdata,
                adminsCount: newinitiateAdminData.length
            };
            break;
        }
        case EDIT_ADMIN: {
            let admin = action.payload
            let adminData = state.adminData
            let initiateAdminData = state.initiateAdminData

            let indexData = adminData.findIndex((obj => obj.id === admin.id));
            let indexInit = initiateAdminData.findIndex((obj => obj.id === admin.id));

            Object.keys(admin).forEach(key => {
                let newVal = admin[key]
                if (adminData[indexData][key])
                    adminData[indexData][key] = newVal
                if (initiateAdminData[indexInit][key])
                    initiateAdminData[indexInit][key] = newVal
            })
            let _pdata = JSON.parse(JSON.stringify(adminData));
            let _idata = JSON.parse(JSON.stringify(initiateAdminData));
            state = {
                ...state,
                initiateAdminData:_idata,
                adminData : _pdata,
            };
            break;
        }
        case DELETE_ADMIN: {
            let adminId = action.payload
            let adminData = state.adminData
            let initiateAdminData = state.initiateAdminData
            let newadminData = adminData.filter(admin => admin.UserStorageID !== adminId)
            let newinitiateAdminData = initiateAdminData.filter(admin => admin.UserStorageID !== adminId)
            let _pdata = JSON.parse(JSON.stringify(newadminData));
            let _idata = JSON.parse(JSON.stringify(newinitiateAdminData));
            state = {
                ...state,
                initiateAdminData:_idata,
                adminData : _pdata,
                adminsCount: newinitiateAdminData.length
            };
            break;
        }
        case ADMIN_LOCKED_STATUS: {
            let admin = action.payload
            let adminData = state.adminData
            let initiateAdminData = state.initiateAdminData

            let indexData = adminData.findIndex((obj => obj.UserStorageID === admin.UserStorageID));
            let indexInit = initiateAdminData.findIndex((obj => obj.UserStorageID === admin.UserStorageID));
            let Enabled = admin.Enabled

            adminData[indexData]['Enabled'] = Enabled
            initiateAdminData[indexInit]['Enabled'] = Enabled

            let _pdata = JSON.parse(JSON.stringify(adminData));
            let _idata = JSON.parse(JSON.stringify(initiateAdminData));
            state = {
                ...state,
                initiateAdminData:_idata,
                adminData : _pdata
            };
            break;
        }
        default:
            break;
    }
    return state;
};
export default adminTableDataReducer;
