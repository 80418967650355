import React, {Component} from 'react'
import {Route, Router, Switch} from 'react-router-dom'
import  { Redirect } from 'react-router-dom'
import {authenticationService} from "./services/AuthenticationService";
import Login from './views/default/Login';
import Main from './views/default/Main';
import {history} from './utils/history';
import NotFound from "./views/Error/NotFound";
import './App.css';
import './app_rtl.css'
import PatientForm from "./views/eye_care_provider/PatientForm";
import NovaNavbar from "./components/single_components/NovaNavbar";
import Patient from "./views/eye_care_provider/Patient";
import EditPatient from "./views/eye_care_provider/EditPatient";
import ResetPassword from "./views/default/ResetPassword";
import Login2FA from "./views/default/Login2FA";
import CreatePassword from "./views/default/CreatePassword";
import PasswordConfirm from "./views/default/PasswordConfirm";
import SingleRegistration from "./views/default/SingleRegistration";
import PrivacyPdf from "./views/default/PrivacyPdf";
import SiteAdminDashboard from "./views/siteAdmin/SiteAdminDashbored";
import IdleTimer from "react-idle-timer";
import LicensePdf from "./views/default/LicensePdf";
import SLAPdf from "./views/default/SLAPdf";
import OnlineDpaPdf from "./views/default/OnlineDpaPdf";
import DataSubjectPdf from "./views/default/DataSubjectPdf";
import PrivacyNoticePdf from "./views/default/PrivacyNoticePdf";






class App extends Component {


    constructor(props) {
        super(props);

        this.state = {
            currentUser: null,
            navbarCollapsed: true,
            idleTime : 40
        }
        this.idleTimer = null
        this.handleOnIdle = this.handleOnIdle.bind(this)


    }

    componentDidMount = async ()  => {
        authenticationService.currentUser.subscribe(
            x => this.setState(
                {
                    currentUser: x
                })
        );

        console.log(history.location.pathname)
        if(! history.location.pathname.includes("Pdf")){
            history.push('/login')

        }

    }
    handleLogout = async ()  => {

        authenticationService.logout();
        history.push('/login')
    };


    async handleOnIdle (event) {

        await authenticationService.logout()
        history.push('/login')
    }

    render() {
        let {currentUser} = this.state
        let {idleTime} = this.state


        return (

            <Router history={history}>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * idleTime}
                    onIdle={this.handleOnIdle}
                    events={['keydown','wheel','DOMMouseScroll','mousewheel','mousedown','touchstart','touchmove','MSPointerDown','MSPointerMove']}
                    // debounce={250}
                />

             {/*   <Idle timeout='3600000' onChange={({idle}) => idle ? this.handleLogout() : console.log(idle)}
                      render={() =>*/}
                          <div className={'nova-app-container'}>
                              {currentUser && (<><NovaNavbar history={history} currentUser={currentUser}/><div className={'width-separator'} /></>)}
                              <Switch>
                                  <Route path="/dashboard/Patient" component={Patient}/>
                                  <Route path="/dashboard/AddPatient" component={PatientForm}/>
                                  <Route path="/dashboard/EditPatient" component={EditPatient}/>
                                  <Route path="/dashboard/siteadmin" component={SiteAdminDashboard}/>
                                  <Route path="/dashboard" component={Main}/>
                                  <Route path="/login" component={Login}/>
                                  <Route path="/2FA" component={Login2FA}/>
                                  <Route path="/create-password" component={CreatePassword}/>
                                  <Route path="/password-confirmed" component={PasswordConfirm}/>
                                  <Route path="/ResetPassword" component={ResetPassword}/>
                                  <Route path="/register" component={SingleRegistration}/>
                                  <Route path="/NotFound" component={NotFound}/>
                                  <Route path="/static/media/PrivacyPdf" component={PrivacyPdf}/>
                                  <Route path="/static/media/LicensePdf" component={LicensePdf}/>
                                  <Route path="/static/media/SLAPdf" component={SLAPdf}/>
                                  <Route path="/static/media/OnlineDpaPdf" component={OnlineDpaPdf}/>
                                  <Route path="/static/media/DataSubjectPdf" component={DataSubjectPdf}/>
                                  <Route path="/static/media/PrivacyNoticePdf" component={PrivacyNoticePdf}/>
{/*
                                  <Route path="/NovaSight License Agreement.pdf"/>
*/}
{/*
                                  <Redirect to='/NotFound'/>
*/}
                              </Switch>
                          </div>
                {/*</Idle>*/}
            </Router>

        )
    }
}

export default App;
