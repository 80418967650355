import React, {Component} from "react";
import SimpleFormBuilder from "../../forms_builder/SimpleFormBuilder";
import utils from "../../../utils/utils";
import Prototypes from "prop-types";
import {adminApi, doctorApi} from "../../../services/ApiService";
import Constants from "../../../utils/constants";
import * as Yup from "yup";
import validationHelper from "../../../utils/validationHelper";
import {makeError} from "../../modals/ErrorModal";
import AlertConfirm from "../../modals/Confirm";
import SvGraphics from "../../../assets/SvGraphics";
import {ConstantsArrT} from "../../../utils/ConstantsWithTranslate";

class SiteAdminEcpEditForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entry: {
                isSiteAdmin: false
            },
            siteOptions: [],
            matrix :[2,2,1,1,1,1,1],
            errors: {},
            errorsOnSave:{},
        };
    }

    initValidation = async () => {
        let {t} = this.props
        let validationSchema = Yup.object().shape({
            FirstName:Yup.string().required(t('admin.ecp.form.first_name_req')),
            LastName:Yup.string().required(t('admin.ecp.form.last_name_req')),
            Profession:Yup.string().required('Profession is required'),
            PhoneNumber:Yup.string().required(t('admin.ecp.form.phone_number_req')),
            Email:Yup.string().matches(Constants.emailRegExp, t('admin.ecp.form.email_invalid')).required(t('admin.ecp.form.email_req')),
            isSiteAdmin:Yup.boolean().required()
        })
        let errors = await validationHelper.validate(this.state.entry,validationSchema)
        await this.setState({
            validationSchema,
            errors
        })
    }

    async componentWillMount() {
        if (this.props && this.props.entry) {
            await this.setState({
                entry: utils.removeEmptyOrNull(this.props.entry),
                siteOptions: [{value: this.props.entry.SiteStorageID, label: this.props.entry.SiteName}]
            })

        }        await this.initValidation()
    }

    async closeSideBar(){
        let {t} = this.props
        document.body.style.overflow = "auto"
        let confirm = await AlertConfirm({
            options:{
                title: t('admin.ecp.form.withdraw_edit_ecp'),
            }},t('admin.ecp.form.withdraw_edit_ecp_info'))
        if (confirm) {
            this.props.closeSideBar(false)
            this.setState({entry:null})
        } else {
            document.body.style.overflow = "hidden"
        }
    }

    async saveInfo(){
        let {t} = this.props
        let {entry} = this.state
        if (!utils.isEmpty(this.state.errors)){
            this.setState({errorsOnSave:this.state.errors});
            await makeError({
                proceedLabel: t('admin.ecp.form.ok'),
                options:{
                    title: t('admin.ecp.form.ecp_not_edited'),
                }
            },[t('admin.ecp.form.fill_all_req')]);
            return;
        }
        document.body.style.overflow = "auto"
        let confirm = await AlertConfirm({
            options:{
                title: t('admin.ecp.form.edit_ecp'),
            }},`${t('admin.ecp.form.confirm_edit_ecp')}?`)
        if (confirm) {
            entry = utils.ObjectEmptiesToNulls(entry)
            let response = await doctorApi.EditECP(entry,entry.id)
            if (response) {
                utils.set(entry,'FullName', entry.FirstName + ' ' + entry.LastName,)
                document.body.style.overflow = "auto"
                this.props.closeSideBar(false)
                this.props.editECP(response.data)
                this.setState({entry:null})
            }
        } else {
            document.body.style.overflow = "hidden"
        }
    }

    onEntryChange = async event => {
        let {entry,validationSchema} = this.state
        let name = event.target.name
/*
        let isNumeric = this.getFields().find(input => input.name === name).isNumeric
*/
        let value = event.target.type === 'checkbox' ?
            !entry[name]
            :/*isNumeric?utils.toNumeric(event.target.value):*/event.target.value
        utils.set(entry,name,value)
        let entryValidator = utils.removeEmptyOrNull(entry)
        let errors = await validationHelper.validate(entryValidator, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;
        if (utils.get(errorsOnSave,name)){
            utils.set(errorsOnSave,name,utils.get(errors,name))
        }
        this.setState({
            entry,
            errors,
            errorsOnSave
        })
    }

    onEntryChangeByDropdown = async (name,value) => {
        let {entry,validationSchema} = this.state
        utils.set(entry,name,value)
        let entryValidator = utils.removeEmptyOrNull(entry)
        let errors = await validationHelper.validate(entryValidator, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;
        if (utils.get(errorsOnSave,name)){
            utils.set(errorsOnSave,name,utils.get(errors,name))
        }
        this.setState({
            entry,
            errors,
            errorsOnSave
        })
    }

    getFields = () => {
        let {t} = this.props
        return [
            {type:'text', max: 20, name:'FirstName',display:t('admin.ecp.form.first_name'), placeholder: t('admin.ecp.form.enter_first_name'), required:true, width:0.5, style:'inline'},
            {type:'text', max: 20, name:'LastName',display:t('admin.ecp.form.last_name'), placeholder: t('admin.ecp.form.enter_last_name'), required:true, width:0.5, style:'inline'},
            {type:'dropdown', name:'Profession',display:t('admin.ecp.form.profession'), placeholder: t('admin.ecp.form.enter_profession'), required:true, width:0.5, style:'inline', options: ConstantsArrT(t,Constants.Profession)},
            {type:'text', max: 20, name:'MedicalLicense', placeholder: t('admin.ecp.form.enter_med_license'), display:t('admin.ecp.form.med_license'),required:false, width:0.5, style:'inline'},
            {type:'phone', name:'PhoneNumber',  placeholder: t('admin.ecp.form.phone_number'), display:t('admin.ecp.form.phone_number'),required:true, width:0.5, style:'inline', isNumeric: true},
            {type:'text', max: 50, name:'Email',display:t('admin.ecp.form.email'), placeholder: t('admin.ecp.form.enter_email'), required:true, width:1, style:'inline'},
            {type:'searchableDropdown', disabled: true, max: 50, name:'SiteStorageID',display:t('admin.ecp.form.site'), placeholder: t('admin.ecp.form.select_site'), required:true, width:1, style:'block', options: []},
            {type:'checkbox', name:'isSiteAdmin',display: t('admin.ecp.form.is_admin'), width:1, style:'block'},
            {type:'textarea', name:'Notes',display:t('admin.ecp.form.note'), placeholder: t('admin.ecp.form.enter_note'), required:false, width:1, style:'block'}
        ]
    }

    render() {
        let display = this.props.isSideBarOpen ? 'block' : 'none'
        let width = this.props.isSideBarOpen ? "600px" : "0px"
        let container_width = this.props.isSideBarOpen ? "100%" : "0px"
        let {errorsOnSave} = this.state
        let {t} = this.props

        return (
            <div className={"sidenav-complete"} style={{width:container_width, height:"100%"}}>
                <div className={"sidenav"} style={{width:width, height:"100%", float:'right'}}>
                    <SvGraphics onClick={this.closeSideBar.bind(this)} className={'close-bar-x'} svgname={'close'} height={'15px'} width={'15px'} style={{
                        float: 'left',
                        top: '10px',
                        left: '10px',
                        position: 'absolute',
                        cursor: 'pointer'
                    }}/>
                    <div className={'register-form-title container block'}>
                        <h3 className={'h-centered block'}>{this.props.title}</h3>
                    </div>
                    <div className={'register-form container block'}>
                        <SimpleFormBuilder
                            errors={errorsOnSave}
                            entry={this.state.entry}
                            sites = {this.state.siteOptions}
                            matrix={this.state.matrix}
                            fields={this.getFields()}
                            onEntryChange={e => this.onEntryChange(e)}
                            onEntryChangeByDropdown={(name,val) => this.onEntryChangeByDropdown(name,val)}
                        />
                    </div>
                </div>
                <div className={'register-form-nav-container block'} >
                    <div className={'register-form-nav'}  style={{width:width}}>
                        <label onClick={this.closeSideBar.bind(this)} style={{display:display}} className={"v-centered close-bar"}>
                            {t('admin.ecp.form.close')}
                        </label>
                        <button type={"button"} onClick={this.saveInfo.bind(this)} style={{display:display}} className={"btn btn-primary v-centered save-info"}>
                            {t('admin.ecp.form.save')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
SiteAdminEcpEditForm.propTypes = {
    isSideBarOpen:Prototypes.bool.isRequired,
    editECP:Prototypes.bool.isRequired,
    closeSideBar:Prototypes.func.isRequired,
    title: Prototypes.string.isRequired,
    entry: Prototypes.object,
}

export default SiteAdminEcpEditForm

