import React, {Component} from 'react'

import {history} from "../../utils/history";
import DoctorTabs from "./doctor-tabs/DoctorTabs";

import '../../style/NovaInfo.css'
import '../../style/rtl_css/NovaInfo.css'
import '../../style/nova-dashboard-chart.css'
import {connect} from "react-redux";
import {
    filterPatientsTable
} from "../../redux/actions/Tabs-Actions";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import DoctorDashboardChart from "../../DoctorDashboardChart";
import {doctorApi} from "../../services/ApiService";

class DoctorDashboardConComposed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            patients: true,
            children: false,
            adults: false,
            test_num: false,
            allPatients: true,
            today: false,
            lastweek: false,
            lastyear: false,
            lastmonth: false,
            currentTimeToggle:'all',
            lastVisitsData: [],
            test_count:0,
            series:[],
        }
    }
    componentDidMount = async () => {

        let test_count=0
        let {loading}=this.state

/*
        let visitData = await doctorApi.getAllPatientVisitsData("all")
*/
        let visitData=null
        let count =  await doctorApi.getAllSiteTestsCount()

        test_count= count?count.data.totalTests:0

        loading=false

        let lastVisitsData = visitData?visitData.data:[]
        this.setState({test_count,loading,lastVisitsData})

    }

    componentWillMount = async () => {
        let navTabTo = !!history.location.state && !!history.location.state.navTabTo? history.location.state.navTabTo: 0
        let pathname = (history.location.pathname).includes('/dashboard/')? history.location.pathname: `/dashboard/patients`
        await history.push({
            pathname,
            state: {
                navTabTo,
                from: {
                    fromPath: `/dashboard`,
                }
            },
        })
        this.setState({total:true})
        this.state.loading=false
    }

    async toggle(state) {
        let patients,children,adults,test_num = false;
        switch (state) {
            case 'patients': {patients = true; break;}
            case 'children': {children = true; break;}
            case 'adults': {adults = true; break;}
            case 'test_num': {test_num = true; break;}
            default:  break;
        }

        this.setState({
            patients,children,adults,test_num
        })
        this.props.filterPatientsTable('status',state)
    }

    async time_toggle(state) {
        let today,lastweek,lastmonth,lastyear,allPatients = false;
        switch (state) {
            case 'day': {today = true; break;}
            case 'week': {lastweek = true; break;}
            case 'month': {lastmonth = true; break;}
            case 'year': {lastyear = true; break;}
            case 'all': {allPatients = true; break;}
            default:  break;
        }

        this.props.filterPatientsTable('visitPeriod',state)

        this.setState({
            today,lastweek,lastmonth,lastyear,allPatients,currentTimeToggle:state
        })

    }


    render() {
        let {patients,medium,children,adults,active,today,lastweek,lastmonth,lastyear,allPatients} = this.state
        let activeBtnClass = 'nova-info-active-btn'
        let activeClass = 'nova-info-active-block'
        let {t} = this.props
        let categories=["0","1","2","3","4","5","6","7","8","9","10", "11", "12","13","14","15","16","17","18","19",
            "20","21", "22", "23", "24","25","26","27","28","29","30+"]


        if (this.state.loading) {
            return <div class="loader"/>
        }
        return (
            <div className={'nova-ecp-dashboard'}>

                <div className={'nova-info'}>
                    <div className={'nova-info-main-container container'}>
                        <div className={'nova-info-container nova-info-patients-status-container v-centered'}>

                            <div className={'nova-info-time-status-info'}>

                                <label className={'single-nova-show-info-block-label'}>{t('ecp.info_filters.show')}:</label>

                                <div className={`single-nova-time-info-block ${allPatients?activeBtnClass:''}`} onClick={() => this.time_toggle('all')}>
                                    <label className={'single-nova-time-info-block-label'}>{t('ecp.info_filters.all')}</label>
                                </div>

                                <div className={`single-nova-time-info-block ${today?activeBtnClass:''}`} onClick={() => this.time_toggle('day')}>
                                    <label className={'single-nova-time-info-block-label'}>{t('ecp.info_filters.today')}</label>
                                </div>

                                <div className={`single-nova-time-info-block ${lastweek?activeBtnClass:''}`} onClick={() => this.time_toggle('week')}>
                                    <label className={'single-nova-time-info-block-label'}>{t('ecp.info_filters.lastweek')}</label>
                                </div>

                                <div className={`single-nova-time-info-block ${lastmonth?activeBtnClass:''}`} onClick={() => this.time_toggle('month')}>
                                    <label className={'single-nova-time-info-block-label'}>{t('ecp.info_filters.lastmonth')}</label>
                                </div>

                                <div className={`single-nova-time-info-block ${lastyear?activeBtnClass:''}`} onClick={() => this.time_toggle('year')}>
                                    <label className={'single-nova-time-info-block-label'}>{t('ecp.info_filters.lastyear')}</label>
                                </div>
                            </div>

                            <div className={'nova-info-patients-status-info'}>
                                <div className={`single-nova-info-block ${patients?activeClass:''}`} onClick={() => this.toggle('patients')}>

                                    <label className={'single-nova-info-block-value'}>{this.props.patientsCount}</label>

                                    <label className={'single-nova-info-block-label'}>{t('ecp.info_filters.patients')}</label>
                                </div>
                                <div className={`single-nova-info-block ${children?activeClass:''}`} onClick={() => this.toggle('children')}>

                                    <label className={'single-nova-info-block-value'}>{this.props.childrenCount }</label>

                                    <label className={'single-nova-info-block-label'}>{t('ecp.info_filters.children')}</label>
                                </div>
                                <div className={`single-nova-info-block ${adults?activeClass:''}`} onClick={() => this.toggle('adults')}>
                                    <label className={'single-nova-info-block-value'}>{this.props.adultsCount}</label>
                                    <label className={'single-nova-info-block-label'}>{t('ecp.info_filters.adults')}</label>
                                </div>
                                <div className={`single-nova-info-block ${medium?activeClass:''}`} onClick={() => this.toggle('test_num')}>
                                    <label className={'single-nova-info-block-value'}>{this.state.test_count}</label>
                                    <label className={'single-nova-info-block-label'}>{t('ecp.info_filters.test_num')}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'nova-ecp-dashboard container'}>
                    <DoctorTabs toggle={state => this.toggle(state)} time_toggle={state => this.time_toggle(state)} currentTimeToggle={this.state.currentTimeToggle}/>
                </div>

{/*
                <div className={'dashboard-charts-area container'}>
                    <div className={'dashboard-chart-area'}>
                        <div className={'chart-topping'}>
                            <div className={'chart-title'}>{t('general.Eye_Deviation')}</div>
                            <div className={'chart-indication-area'}>
                                <div className={'chart-indication chart-1st-indication'}>
                                    <span className={'chart-indication-color'}>&nbsp;</span>
                                    <div className={'chart-indication-text'}>{t('general.My_site')}</div>
                                </div>
                                <div className={'chart-indication chart-2st-indication'}>
                                    <span className={'chart-indication-color'}>&nbsp;</span>
                                    <div className={'chart-indication-text'}>{t('general.Global')}</div>
                                </div>
                            </div>
                        </div>
                        <div className={'act-deviation-chart chart-container'}>
                            <DoctorDashboardChart visitData={this.state.lastVisitsData}  xtitle={'Exo Deviation (Δ)'} ytitle={'Percentage of Tests'}
                                       chartType={'area'}
                                          minYValue={0} maxYValue={100} tickAmount={5} xCategories={categories} type={"exo"}/>
                        </div>
                    </div>
                    <div className={'dashboard-chart-area'}>
                        <div className={'chart-topping'}>
                            <div className={'chart-title'}>{t('general.Convergence_Break')}</div>
                            <div className={'chart-indication-area'}>
                                <div className={'chart-indication chart-1st-indication'}>
                                    <span className={'chart-indication-color'}>&nbsp;</span>
                                    <div className={'chart-indication-text'}>{t('general.My_site')}</div>
                                </div>
                                <div className={'chart-indication chart-2st-indication'}>
                                    <span className={'chart-indication-color'}>&nbsp;</span>
                                    <div className={'chart-indication-text'}>{t('general.Global')}</div>
                                </div>
                            </div>
                        </div>
                        <div className={'convergence-bp-chart chart-container'}>
                            <DoctorDashboardChart visitData={this.state.lastVisitsData}  xtitle={t('general.Convergence_Break')+'(Δ)'} ytitle={t('general.Percentage_of_Tests')}
                                       chartType={'area'}  minYValue={0} maxYValue={100} tickAmount={5}
                                                  xCategories={categories} type={"convergence"}/>
                        </div>
                    </div>
                </div>
*/}

            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        filterPatientsTable:(filterType,payload) => dispatch(filterPatientsTable(filterType,payload)),
    };
}
const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData,
        patientsCount:state.patientsTableDataReducer.patientsCount,
        activePatientsCount:state.patientsTableDataReducer.activePatientsCount,
        pendingPatientsCount:state.patientsTableDataReducer.pendingPatientsCount,
        childrenCount:state.patientsTableDataReducer.childrenCount,
        adultsCount:state.patientsTableDataReducer.adultsCount,
        lockedPatientsCount:state.patientsTableDataReducer.lockedPatientsCount,
    };
};

const DoctorDashboard = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(DoctorDashboardConComposed)

export default DoctorDashboard;
