import React from "react";
import utils from "./utils";
import Config from "../config/Config";
import * as Yup from "yup";
import signedUserHelper from "./signedUserHelper";

class validationHelper {


    validate = async (profile, validationSchema) => {
        let errors = {};

        if (utils.isEmpty(validationSchema))
            return errors;

        await validationSchema.validate(profile, {abortEarly: false}).catch(function (err) {
            err.inner.forEach((error) => {
                utils.set(errors, error.path, error.errors)
            });
        });

        return errors;
    };

}

export default new validationHelper();
