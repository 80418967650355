import React, {Component} from 'react'
import {connect} from "react-redux";
import MyTable from "../../../components/NovaTable/Table";
import SvGraphics from "../../../assets/SvGraphics";
import {ProgressBar} from "react-bootstrap";
import {
    setDevicesDataSelection, filterDevicesTable,
    cleanDevice
} from "../../../redux/actions/Tabs-Actions";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import DeviceInfoSideBar from "../../../components/device/DeviceInfoSideBar";

class DevicesTabConComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSideBarOpen: false,
            deviceInfo: null
        }
    }

    getVersionLabel = (isReq, version) => {
        if (isReq) {
            return (<span className={'version-update'}>
                        <label className={'version-update-label'} style={{marginBottom: '0px'}}>{version}</label>
                        <SvGraphics className={'version-update-img'} svgname={'update'} style={{
                            width: '17px',
                            position: 'relative',
                            transform: 'translate(-0%, -10%)'
                        }}/>
                    </span>
            )}
        return version
    }

    componentWillUnmount() {
        this.props.cleanDevice()
    }

    onSelectionChange = (selected) => {
        this.setState({selected})
        this.props.setDevicesDataSelection(selected)
    }

    onFilterDataSelected = (filter) => {
        this.props.filterDevicesTable(Object.keys(filter)[0], Object.values(filter)[0])
    }

    getColumns = (t) => {
        return [
            {accessor: 'DeviceID', Header: t('admin.device.table.device_id'), resizable: false},
            {accessor: 'SiteName', Header: t('admin.device.table.site_name'), resizable: false},
            {
                accessor: 'ESVersion', Header:  t('admin.device.table.es_v'), resizable: false, Cell: ({original}) => {
                    return this.getVersionLabel(original.ESVersionUpdateRequired,original.ESVersion)
                }, makeFilter: true, width: 120
            },
            {
                accessor: 'WindowsVersion', Header: t('admin.device.table.win_v'), resizable: false, Cell: ({original}) => {
                    return this.getVersionLabel(original.WindowsVersionUpdateRequired,original.WindowsVersion)
                }, makeFilter: true, width: 120
            },
            {
                accessor: 'ETFirmwareVersion', Header: t('admin.device.table.et_v'), resizable: false, Cell: ({original}) => {
                    return this.getVersionLabel(original.ETFirmwareVersionUpdateRequired,original.ETFirmwareVersion)
                }, makeFilter: true, width: 180
            },
            {
                accessor: 'EmitterFirmwareVersion', Header: t('admin.device.table.emitter_v'), resizable: false, Cell: ({original}) => {
                    return this.getVersionLabel(original.EmitterFirmwareVersionRequired,original.EmitterFirmwareVersion)
                }, makeFilter: true, width: 180
            },
            {
                accessor: 'TVVersion', Header: t('admin.device.table.tv_v'), resizable: false, Cell: ({original}) => {
                    return this.getVersionLabel(original.TVVersionUpdateRequired,original.TVVersion)
                }, makeFilter: true, width: 180
            },
            {accessor: 'LastRun', Header: t('admin.device.table.last_run'), resizable: false, width: 130},
            {accessor: 'SystemStatus', Header: t('admin.device.table.sys_status'), resizable: false, Cell: ({original}) => {
                    if (original.SystemStatus.toLowerCase() !== 'ok') {
                        return <label className={'red'}>{original.SystemStatus}</label>
                    }
                    return original.SystemStatus
                }, makeFilter: true, width: 160},
            {accessor: 'location', Header: t('admin.device.table.location'), resizable: false},
        ]
    }

    showInfoBar = (origin) => {
        this.setState({deviceInfo: origin,isSideBarOpen:true})
    }

    render() {
        let {t} = this.props
        let columns = this.getColumns(t)
        return (
            <div className={'context-area'}>
                <MyTable
                    className={'devices-table'}
                    data={this.props.deviceData}
                    initialData={this.props.initiateDeviceData}
                    onSelectionChange={selected => this.onSelectionChange(selected)}
                    onFilterDataSelected={filter => this.onFilterDataSelected(filter)}
                    columns={columns}
                    hasSelection={true}
                    t={e => t(e)}
                    hasMore={false}
                    showInfoBar={origin => this.showInfoBar(origin)}
                    hasShowInfo={true}
                />
                {
                    this.state.isSideBarOpen &&
                    <DeviceInfoSideBar t={e => t(e)} deviceInfo={this.state.deviceInfo} isSideBarOpen={this.state.isSideBarOpen} closeSideBar={() => this.setState({isSideBarOpen:false,deviceInfo: null})}/>
                }
            </div>

        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setDevicesDataSelection: deviceSelection => dispatch(setDevicesDataSelection(deviceSelection)),
        cleanDevice: () => dispatch(cleanDevice()),
        filterDevicesTable: (searchType, payload) => dispatch(filterDevicesTable(searchType, payload)),
    };
}

const mapStateToProps = state => {
    return {
        deviceData: state.deviceTableDataReducer.deviceData,
        initiateDeviceData: state.deviceTableDataReducer.initiateDeviceData
    };
};

const DevicesTab = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(DevicesTabConComposed);

export default DevicesTab;
