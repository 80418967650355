import React from "react";
import {authenticationService} from "../services/AuthenticationService";
import utils from "./utils";
import Config from "../config/Config";

class signedUserHelper {

    getSignedRole() {
        const current = authenticationService.currentUserValue;
        const currentRoles = utils.get(current, 'role') || [];

        if (currentRoles.includes(Config.roles.EyeSwiftAdmin)) {
            return Config.roles.EyeSwiftAdmin;
        } else if (currentRoles.includes(Config.roles.EyeSwiftSuperAdmin)) {
            return Config.roles.EyeSwiftSuperAdmin;
        }else if (currentRoles.includes(Config.roles.Doctor)) {
                return Config.roles.Doctor;
        }
    }
}

export default new signedUserHelper()
