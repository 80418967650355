import Constants from "./constants";


class ConstantsUtils {


    getVaConstantLabel (VaConstant){
        if (VaConstant !== undefined)
            return  Constants.VaConstants.find(VaConst => VaConst.value === VaConstant.toString()).label
    }


    convertFromLogMAR (to, value) {
        if (to !== undefined && value !== undefined){
            if (value === 0 || value === '0') value = parseFloat('0').toFixed(1)
            if (value === 1 || value === '1') value = parseFloat('1').toFixed(1)
            let VaConstants = Constants.VaConstants
            let VaConstFrom = VaConstants.find(elem => elem.value === "1")
            let VaConstTo = VaConstants.find(elem => elem.value === to.toString())
            let index = Constants[VaConstFrom.label].findIndex(con => con.value === value.toString())
            return Constants[VaConstTo.label][index]
        }
    }

    getPatient_AccountStatus (val) {
        return Constants.Patient_AccountStatus.find(status => status.value === val).label
    }


    getVersionTypeByVal = (val) => {
        if (val !== null && val !== undefined)
            return Constants.versions.find(version => version.value === val).label
    }
}

export default new ConstantsUtils();
