import {authenticationService} from "../AuthenticationService";
import axios from "axios";
import Config from "../../config/Config";

const doctorApi = {
    newPatient: async (patient_data) => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;
            let requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authenticationService.currentUserValue.token,
                },
            };

            return await axios.post(
                `${Config.globalUrl}/api/v1/webapp/eyeCareProviders/${doctor.profile.UserStorageID}/patients/signup`,
                patient_data,
                requestconfig
            );

    },

    getAllSiteTestsCount: async () => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return await axios.get(
            `${Config.globalUrl}/api/v1/webapp/eyeCareProviders/${doctor.profile.UserStorageID}/getAllSiteTestsCount`,
            requestconfig
        );
    },

    getAllPatients: async () => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;
            let requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authenticationService.currentUserValue.token,
                },
            };
            return await axios.get(
                `${Config.globalUrl}/api/v1/webapp/eyeCareProviders/${doctor.profile.UserStorageID}/getallpatients`,
                requestconfig
            );
},
    editPatient: async (patient_data,patient_id) => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;
            let requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authenticationService.currentUserValue.token,
                },
            };

            return await axios.post(
                `${Config.globalUrl}/api/v1/webapp/eyeCareProviders/${doctor.profile.UserStorageID}/patients/${patient_id}/profile`,
                patient_data,
                requestconfig
            );

    },
    getLastVisitByPatientId: async (patient_id) => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;
            let requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authenticationService.currentUserValue.token,
                },
            };

            return await axios.get(
                `${Config.globalUrl}/api/v1/webapp/eyeCareProviders/${doctor.profile.UserStorageID}/patients/${patient_id}/getLastVisit`,
                requestconfig
            );
},
    getAllPatientVisitsData: async (patient_id) => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return await axios.get(
            `${Config.globalUrl}/api/v1/webapp/eyeCareProviders/${doctor.profile.UserStorageID}/patients/${patient_id}/getVisitsData`,
            requestconfig
        );
    },

    addNewPatientVisit: async (patient_data,patient_id) => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;
            let requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authenticationService.currentUserValue.token,
                },
            };

            return await axios.post(
                `${Config.globalUrl}/api/v1/webapp/eyeCareProviders/${doctor.profile.UserStorageID}/patients/${patient_id}/addNewVisit`,
                patient_data,
                requestconfig
            );

    },
    gePatientById: async (patient_id) => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;
            let requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authenticationService.currentUserValue.token,
                },
            };
            return await axios.get(
                `${Config.globalUrl}/api/v1/webapp/eyeCareProviders/${doctor.profile.UserStorageID}/patients/${patient_id}/profile`,
                requestconfig
            );
},
    deletePatient: async (patient_id) => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;
            let requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authenticationService.currentUserValue.token,
                },
            };

            return await axios.delete(
                `${Config.globalUrl}/api/v1/webapp/eyeCareProviders/${doctor.profile.UserStorageID}/patients/${patient_id}/profile`,
                requestconfig
            );

    },
    sendMessageToPatients: async (data) => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;
            let requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authenticationService.currentUserValue.token,
                },
            };

            return await axios.post(
                `${Config.globalUrl}/api/v1/webapp/eyeCareProviders/${doctor.profile.UserStorageID}/patients/sendMessages`,
                data,
                requestconfig
            );

    },
    getAllCompletedPatients: async () => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;
            let requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authenticationService.currentUserValue.token,
                },
            };
            return await axios.get(
                `${Config.globalUrl}/api/v1/webapp/eyeCareProviders/${doctor.profile.UserStorageID}/getallpatients?archive=true`,
                requestconfig
            );
    },
    getAllECPs: async () => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;

        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return await axios.get(
            `${Config.globalUrl}/api/v1/webapp/eyeCareProviders/${doctor.profile.UserStorageID}/getAllECP`,
            requestconfig
        );

    },
    getAllDevices: async () => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;

        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return await axios.get(
            `${Config.globalUrl}/api/v1/webapp/eyeCareProviders/${doctor.profile.UserStorageID}/getAllDevices`,
            requestconfig
        );

    },
    AddNewECP: async (ecp) => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;

        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return (await axios.post(
            `${Config.globalUrl}/api/v1/webapp/eyeCareProviders/${doctor.profile.UserStorageID}/doctors/signup`,
            ecp,
            requestconfig
        ));
    },
    EditECP: async (ecp_data,ECP_UserStorageID) => {
        if (!authenticationService.currentUserValue)
            return;
        let doctor = authenticationService.currentUserValue;

        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return (await axios.post(
            `${Config.globalUrl}/api/v1/webapp/eyeCareProviders/${doctor.profile.UserStorageID}/${ECP_UserStorageID}/profile`,
            ecp_data,
            requestconfig
        ));
    },
}


export default doctorApi;
