import React, {Component} from 'react'
import SvGraphics from "../../assets/SvGraphics";
import PropTypes from "prop-types";
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

class InfoTools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            original: this.props.original
        }
    }

    render() {
        let PhoneNumber = this.props.original ? this.props.original.PhoneNumber : undefined
        let Email = this.props.original ? this.props.original.Email : undefined
        let GlassModel = this.props.original ? this.props.original.GlassModel : ""
        return (
            <OverlayTrigger
                placement={"left"}
                delay={{show: 250, hide: 250}}
                trigger={"click"}
                rootClose
                overlay={
                    <Popover id="popover-basic">
                        <Popover.Content>
                                    <table style={{width: '90%'}}>
                                        <tr className={'patient-personal-tr glassModel-td'}>
                                            <td className={'patient-personal-td-left'}><label
                                                className={'patient-personal-td-label'}>Glasses Model</label></td>
                                            {GlassModel && <td className={'patient-personal-td-right'}><label
                                                className={'patient-personal-td-label bolded'}>{GlassModel}</label>
                                            </td>}
                                        </tr>
                                        <tr className={'patient-personal-tr'}>
                                            <td className={'patient-personal-td-left'}><label
                                                className={'patient-personal-td-label'}>Phone Number 1</label></td>
                                            <td className={'patient-personal-td-right'}><label
                                                className={'patient-personal-td-label bolded'}>{PhoneNumber}</label>
                                            </td>
                                        </tr>
                                        <tr className={'patient-personal-tr'}>
                                            <td className={'patient-personal-td-left'}><label
                                                className={'patient-personal-td-label'}>Email 1</label></td>
                                            <td className={'patient-personal-td-right'}><label
                                                className={'patient-personal-td-label bolded'}>{Email}</label></td>
                                        </tr>
                                    </table>
                        </Popover.Content>
                    </Popover>
                }>
                <SvGraphics className={'table-more'} svgname={'arrow-left'} style={{width:'15px', height:'15px'}}/>
            </OverlayTrigger>
        );
    }
}

InfoTools.propTypes = {
    original:PropTypes.object.isRequired
};
export default InfoTools;

