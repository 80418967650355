const Config = {

//REGION BACKEND
    globalUrl: process.env.REACT_APP_API_ENDPOINT,

//Version
    version : 'V' + require('../../package.json')['version'],
//ENDREGION BACKEND

//LOGS
    logLevel: 3,
//END LOGS

//UI CONFIG
    subStatusEnabled: true,
    closeAlertEnabled: false,
//END UI CONFIG

    userSignIn: '/api/v1/webapp/signin',

    userRoles: ['EyeSwiftAdmin', 'EyeSwiftSuperAdmin', 'Doctor'],

    roles: {
        EyeSwiftAdmin: 'EyeSwiftAdmin',
        EyeSwiftSuperAdmin: 'EyeSwiftSuperAdmin',
        Doctor: 'Doctor'
    },

    searchOptions: {
        lessThan: '-1',
        greaterThan: '1',
        include: '0',
    },

    sortOrder: {
        desc: 'desc',
        asc: 'asc',
        none: 'none',
    },

    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    passwordRegExp: /^(?=^.{8,20}$)(?=.*\d)(?=.*[!@#%^&*]+)(?![.\n])(?!.*[${}\/<>\[\]\"\'\`])(?=.*[A-Z])(?=.*[a-z]).*$/
};

Object.freeze(Config);
export default Config;
