import React, {Component} from 'react'
import SvGraphics from "../../assets/SvGraphics";
import PropTypes from "prop-types";
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import '../../components_style/MoreTools.css'
import '../../components_style/rtl_css/MoreTools.css'

import utils from "../../utils/utils";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class MoreToolsComposed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            original: this.props.original
        }
    }

    onButtonClick(func_call,args){
        func_call(args)
        document.body.click()
    }

    getEcpMoreFunctions = () => {
        let functions = []
        this.props.content.forEach(func => {
            functions.push(
                <tr className={'more-func'} key={utils.getUID()}>
                    <button onClick={() => this.onButtonClick(func.call,this.props.original)} className={'more-func-name'} >
                        {func.display}
                    </button>
                </tr>
            )
        })
        return functions;
    }

    render() {
        let {t} = this.props
        return (
            <OverlayTrigger
                placement={t('popover')}
                delay={{show: 250, hide: 250}}
                trigger={"click"}
                rootClose

                overlay={
                    <Popover id="popover-basic">
                        <Popover.Content>
                            <table className={'more-func-table'}>
                                <tbody dir={'ltr'}>
                                {
                                    this.getEcpMoreFunctions()
                                }
                                </tbody>
                            </table>
                        </Popover.Content>
                    </Popover>
                }>
                <SvGraphics className={'table-more'} svgname={'more'} style ={{
                    width: '25px'
                }} onClick={(e)=>e.stopPropagation()}/>
            </OverlayTrigger>
        );
    }
}
let MoreTools = compose(
    withTranslation(),
)(MoreToolsComposed)

MoreTools.propTypes = {
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
    original:PropTypes.object.isRequired
};
export default MoreTools;

