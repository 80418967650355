import React, {Component} from "react";
import SvGraphics from "../../assets/SvGraphics";
import Prototypes from 'prop-types'
import '../../components_style/TabTools.css'
import '../../components_style/rtl_css/TabTools.css'
import AdminEntryForm from "./AdminEntryForm";

class AdminTabTools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterAdminsTable:'',
            searchValue: '',
            isSideBarOpen: false,
        };
    }

    componentDidMount() {
    }

    filterAdminsTable(val){
        this.setState({searchValue:val})
        this.props.filterAdminsTable(val)
    }

    openSideBar(){
        document.body.style.overflow = "hidden"
        this.setState({isSideBarOpen:true})
    }

    render() {
        let {t} = this.props
        let {isSideBarOpen} = this.state
        return (
            <div className={`EyeSwiftSuperAdmin-TabTools custom-control`}>
                <div className={"TabTools-container"}>
                    <div className={"rounded-button-wrapper"}>
                        <button disabled type={"button"} className={"btn btn-primary rounded-btn"}>
                            <SvGraphics className={"mail-pic centered"} svgname={'mail'} style={{width: '25px', height: '25px'}}/>
                        </button>
                    </div>
                    <div className={"rounded-button-wrapper"}>
                        <button type={"button"} className={"btn btn-primary rounded-btn"} onClick={() => {
                            let filters = document.getElementsByClassName('table-filter')
                            for (let i = 0; i < filters.length; i++) {
                                if (filters[i].style.display === "none"){
                                    filters[i].style.display = "inline-block";
                                }
                                else filters[i].style.display = "none"
                            }
                        }}>
                            <SvGraphics className={"filter-pic centered"} svgname={'filter'} style={{width: '25px', height: '25px'}}/>
                        </button>
                    </div>
                    <div className={"button-wrapper"}>
                        <button type={"button"} onClick={this.openSideBar.bind(this)} className={"btn btn-primary "}>+ {t('admin.admin.tools.add_new')}</button>
                    </div>
                    <div className={"search-wrapper"}>
                        <input className={"nova-input"} placeholder={t('admin.admin.tools.search')} type="text" name="search"
                               id={"search"}
                               onChange={event => this.filterAdminsTable(event.target.value)} value={this.state.searchValue}/>
                        <SvGraphics className={"input-pic"} svgname={'search'} style={{width: '20px', height: '20px'}}/>
                    </div>
                </div>

                {isSideBarOpen && <AdminEntryForm
                    t={e => t(e)}
                    title={t('admin.admin.tools.add_new')}
                    isSideBarOpen={isSideBarOpen}
                    addNewAdmin={admin => this.props.addNewAdmin(admin)}
                    closeSideBar={() => this.setState({isSideBarOpen: false})}/>}
            </div>
        );
    }
}
AdminTabTools.Prototypes = {
    filterAdminsTable: Prototypes.func.isRequired,
    addNewAdmin: Prototypes.func.isRequired,
}

export default AdminTabTools

