import React, {Component} from 'react'
import { connect } from "react-redux";
import MyTable from "../../../components/NovaTable/Table";
import {cleanEcp,setEcpDataSelection,editECP, setEcpLockedStatus,filterEcpTable} from "../../../redux/actions/Tabs-Actions";
import EcpEditForm from "../../../components/ecp/EcpEditForm";
import {doctorApi} from "../../../services/ApiService";
import SvGraphics from "../../../assets/SvGraphics";
import AlertConfirm from "../../../components/modals/Confirm";
import {sendMessage} from "../../../components/modals/MessageModal";
import Constants from "../../../utils/constants";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {ConstantsArrT} from "../../../utils/ConstantsWithTranslate";
import SiteAdminEcpEditForm from "../../../components/siteadmin/ecp/SiteAdminEcpEditForm";

class ECPsTabConnComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSideBarOpen: false,
            entry: null
        }
    }

    async openSideBar(ecp){
        await this.setState({entry:ecp,isSideBarOpen:true})
        document.body.style.overflow = "hidden"
        this.forceUpdate();
    }


    componentWillUnmount() {
        this.props.cleanEcp()
    }

    onSelectionChange = (selected) => {
        this.props.setEcpDataSelection(selected)
    }


    sendMessageToEcp = async (ecp) => {
        let Method = await sendMessage({
        },[{id:ecp.id,name:ecp.FullName}]);
        if (Method){
            let DoctorsIDs = [ecp.id]
            await doctorApi.sendMessageToEcp({Method,DoctorsIDs})
        }
    }

    editEcpDetails = (ecp) => {
        this.openSideBar(ecp)
    }

    lockEcp = async (ecp) => {
        let {t} = this.props
        let confirm = await AlertConfirm({
            options:{
                title: t('admin.ecp.table.lock_ecp'),
            }},`${t('admin.ecp.table.lock_ecp_confirm')}: ${ecp.FullName}?`)
        if (confirm){
            let data = {'Enabled':0}
            let response = await doctorApi.EditECP(data,ecp.UserStorageID)
            if (response){
                await this.props.setEcpLockedStatus(response.data)
            }
        }
    }

    unlockEcp = async (ecp) => {
        let {t} = this.props
        let confirm = await AlertConfirm({
            options:{
                title: t('admin.ecp.table.unlock_ecp'),
            }},`${t('admin.ecp.table.unlock_ecp_confirm')}: ${ecp.FullName}?`)
        if (confirm) {
            let data = {'Enabled':1}
            let response = await doctorApi.EditECP(data,ecp.UserStorageID)
            if (response){
                await this.props.setEcpLockedStatus(response.data)
            }
        }
    }

    setEcpMoreFunctions = () => {
        let {t} = this.props
        return [
            {
                name: 'Edit ECP details',
                display: t('admin.ecp.table.edit_ecp_details'),
                call: (id => this.editEcpDetails(id))
            },
            {
                name: 'Lock ECP',
                display: t('admin.ecp.table.lock_ecp'),
                call: (id => this.lockEcp(id))
            },
            {
                name: 'Unlock ECP',
                display: t('admin.ecp.table.unlock_ecp'),
                call: (id => this.unlockEcp(id))
            }
        ]
    }

    onFilterDataSelected = (filter) => {
        this.props.filterEcpTable(Object.keys(filter)[0],Object.values(filter)[0])
    }

    getColumns = (t) => {
        return [
            {accessor: 'FirstName', Header: t('admin.ecp.table.first_name'), resizable: false},
            {accessor: 'LastName', Header: t('admin.ecp.table.last_name'), resizable: false},
            {accessor: 'Email', Header: t('admin.ecp.table.username'), resizable: false},
            {accessor: 'ProfessionLabel', Header: t('admin.ecp.table.profession'), resizable: false,Cell: ({ original }) => {
                    return t(original.ProfessionLabel)
                }},
            {accessor: 'isSiteAdmin', Header: t('admin.ecp.table.is_admin'), resizable: false,Cell: ({ original }) => {
                return original.isSiteAdmin ? <SvGraphics svgname={'true'} style={{height:'15px',width:'15px', marginLeft: '19px'}}/> : ''
            }},
            {accessor: 'SiteName', Header: t('admin.ecp.table.site_name'), resizable: false,makeFilter: true},
            {accessor: 'Enabled', Header: t('admin.ecp.table.locked'), resizable: false,Cell: ({ original }) => {
                    if (original.Enabled){
                        return ''
                    } return (<SvGraphics svgname={'true'} style={{height:'15px',width:'15px', marginLeft: '19px'}}/>)
                }, makeFilter: true, filterConverter: ConstantsArrT(t,Constants.locked)}
        ]
    }

    render() {
        let {t} = this.props
        let {entry,isSideBarOpen} = this.state
        let columns = this.getColumns(t)
        return (
            <div className={'context-area'}>
                <MyTable
                    data={this.props.ecpData}
                    columns={columns}
                    initialData={this.props.initiateEcpData}
                    onSelectionChange={selected => this.onSelectionChange(selected)}
                    moreFunctions={this.setEcpMoreFunctions()}
                    onFilterDataSelected={filter => this.onFilterDataSelected(filter)}
                    hasSelection={true}
                    hasMore={true}
                    hasShowInfo={false}
                />
                {isSideBarOpen && <SiteAdminEcpEditForm
                    t={e => t(e)}
                    title={t('admin.ecp.form.edit_ecp')}
                    entry={entry}
                    editECP={ecpData => this.props.editECP(ecpData)}
                    isSideBarOpen={isSideBarOpen}
                    closeSideBar={() => this.setState({isSideBarOpen: false})}/>}
            </div>

        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setEcpLockedStatus: ecpData => dispatch(setEcpLockedStatus(ecpData)),
        editECP: ecpData => dispatch(editECP(ecpData)),
        setEcpDataSelection: ecpData => dispatch(setEcpDataSelection(ecpData)),
        filterEcpTable: (type,payload) => dispatch(filterEcpTable(type,payload)),
        cleanEcp: () => dispatch(cleanEcp())

    };
}

const mapStateToProps = state => {
    return {
        ecpData:state.ecpTableDataReducer.ecpData,
        initiateEcpData:state.ecpTableDataReducer.initiateEcpData
    };
};

const ECPsTab = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ECPsTabConnComposed);

export default ECPsTab;
