import ReactTable from "react-table-v6";
import React from "react";
import "react-table-v6/react-table.css"
import Pagination from "./Pagination";
import '../../components_style/MyTable.css'
import SvGraphics from "../../assets/SvGraphics";
import '../../components_style/rtl_css/MyTable.css'
import MoreTools from "./MoreTools";
import PropTypes from "prop-types";
import InfoTools from "./InfoTools";
import TheadComponent from "./TheadComponent";
import utils from "../../utils/utils";
import {compose} from "redux";
import {withTranslation} from "react-i18next";



class MyTableComposed extends React.Component {
    constructor(props) {
        super(props);
        this.state = { selected: {}, selectAll: 0};
        this.toggleRow = this.toggleRow.bind(this);
    }

    async toggleRow(id) {
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[id] = !this.state.selected[id];

        let selectAll =
            Object.values(newSelected).filter(value => value !== false).length
            !==
            this.props.data.filter(row => (row.Enabled !== null && row.Enabled !== undefined) &&  (row.Enabled !== '0' && row.Enabled !== 0)).length
                ? 1 : 2
        await this.setState({
            selected: newSelected,
            selectAll: selectAll
        });
        await this.props.onSelectionChange(newSelected)
    }

    async toggleAllRows() {

        if (this.areAllRowsSelected()){
            let newSelected = Object.assign({}, this.state.selected)
            for (let id in newSelected){
                delete newSelected[id]
            }
            await this.setState({
                selected: newSelected,
                selectAll:1
            });
            await this.props.onSelectionChange(newSelected)
        } else {
            let newSelected = {}
            this.props.data.forEach(row => {
                if ((row.Enabled !== null && row.Enabled !== undefined) && (row.Enabled !== '0' && row.Enabled !== 0))
                newSelected[row.id] = true
            })
            await this.setState({
                selected: newSelected,
                selectAll:2
            });
            await this.props.onSelectionChange(newSelected)
        }

    }

    areAllRowsSelected() {
        if (Object.values(this.state.selected).filter(value => value !== false).length
            !==
            this.props.data.filter(row => (row.Enabled !== null && row.Enabled !== undefined) &&  (row.Enabled !== '0' && row.Enabled !== 0)).length)
            return false
        let allSelected = true
        Object.values(this.state.selected).forEach(val => {
            allSelected = allSelected && val
        })
        return allSelected
    }

    makeColumns = () => {
        let {hasMore, hasShowInfo, hasSelection, columns} = this.props
        const checkbox_column = [
            {
                id: "checkbox",
                accessor: "",
                Header: (<input
                    type="checkbox"
                    className="checkbox"
                    checked={this.state.selectAll === 2}
                    onChange={() => this.toggleAllRows()}
                />),
                Cell: ({ original }) => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            disabled={(original.Enabled !== null && original.Enabled !== undefined) && (original.Enabled === '0' || original.Enabled === 0)}
                            checked={this.state.selected[original.id] === true}
                            onChange={() => this.toggleRow(original.id)}
                        />
                    );
                },
                sortable: false,
                resizable:false,
                width: 45,
                thClass: "checkbox-th"
            }
        ];

        const more_column = [
            {
                id: "more",
                accessor: "",
                Cell: ({ original }) => {
                    let moreFunctions;
                    if ((original.Enabled !== null && original.Enabled !== undefined) && (original.Enabled === '0' || original.Enabled === 0)){
                        moreFunctions = this.props.moreFunctions.filter(func => {
                            return func.name.indexOf('Lock ') === -1
                        })
                    } else {
                        moreFunctions = this.props.moreFunctions.filter(func => {
                            return func.name.indexOf('Unlock ') === -1
                        })
                    }
                    return (
                        <MoreTools original={original} content={moreFunctions} />
                    );
                },
                sortable: false,
                resizable:false,
                width: 30
            }
        ];

        const info_column = [
            {
                id: "more",
                accessor: "",
                Cell: ({ original }) => {
                    return (
                            <SvGraphics onClick={() => this.props.showInfoBar(original)} className={'table-more'} svgname={`arrow-${this.props.t('arrow')}`} style={{width:'15px', height:'15px'}}/>
                    );
                },
                sortable: false,
                resizable:false,
                width: 30
            }
        ];

        let cols = columns
        if (hasSelection){
            cols = checkbox_column.concat(cols)
        }
        if (hasMore){
            cols = cols.concat(more_column)
        }
        if (hasShowInfo){
            cols = cols.concat(info_column)
        }
        return cols;
    }

    getTrProps = (state, rowInfo, instance) => {
        let {original} = rowInfo;
        let deleted = true;
        if (original.hasOwnProperty('accountStatus') && original['accountStatus'] !== 1) {
            deleted = false;
        }
        if (rowInfo) {
            let style = {
                color: deleted ? 'black' : '#b4b4b4',
            };
            if (this.props.onRowClick || this.props.SubComponent)
                style['cursor'] = 'pointer';
            let className = deleted ? 'unlocked' : 'locked'
            if (rowInfo.className) className = className + ' ' + rowInfo.className
            let propsObj = {
                className: className,
                style
            };
            if (this.props.onRowClick)
                propsObj['onClick'] = () => this.props.onRowClick(original);
            else if (this.props.SubComponent)
                propsObj['onClick'] = () => this.expandRow(rowInfo);
            return propsObj
        }
        return {};
    };

    getTheadThProps = (state, column, instance) => {

        let field = instance.id
        let data = this.props.initialData
        let filterData = []
        data.forEach(obj => {
            filterData.push(obj[field])
        })
        let uniqFilterData = [...new Set(filterData)];
        let props = {
            data: uniqFilterData,
            onFilterDataSelected: filter => this.props.onFilterDataSelected(filter)
        }
        if (instance.id){
            utils.set(props,'field',instance.id)
        }
        if (instance.makeFilter){
            utils.set(props,'makeFilter',instance.makeFilter)
        }
        if (instance.thClass){
            utils.set(props,'thClass',instance.thClass)
        }
        if (instance.filterConverter){
            utils.set(props,'filterConverter',instance.filterConverter)
        }
        return props
    }

    render() {
        let pgSize = (this.props.data.length > 14) ? 14 : this.props.data.length;
        let {t}=this.props

        let NoDataConst = () => (
            <div>
            <SvGraphics className={'block h-centered'} svgname={'no-search'} height={'300px'}/>
            <label className={'h-centered'} style={{top: '850%', fontSize: '40px'}}>{t('general.No_Available_Data')}</label>
        </div>
    );

        return (
            <div>
                <ReactTable
                    className={this.props.className}
                    data={this.props.data}
                    columns={this.makeColumns()}
                    defaultPageSize={14}
                    showPagination={this.props.data.length>14}
                    //pageSize={pgSize}
                    height
                    minRows={0}
                    maxRows={14}
                    sortable={true}
                    multiSort={true}
                    getTrProps={this.getTrProps}
                    getTheadThProps={this.getTheadThProps}
                    ThComponent={TheadComponent}
                    //filterable={true}
                    //showPaginationBottom={false}
                    PaginationComponent={Pagination}
                    showPageSizeOptions={false}
                    NoDataComponent={NoDataConst}
                    //defaultSorted={[{ id: "id", desc: false }]}
                />
            </div>
        );
    }
}


let MyTable = compose(
    withTranslation()
)(MyTableComposed)

MyTable.propTypes = {
    onSelectionChange:PropTypes.func,
    onFilterDataSelected:PropTypes.func,
    showInfoBar:PropTypes.func,
    moreFunctions:PropTypes.array,
    data:PropTypes.array.isRequired,
    columns:PropTypes.array.isRequired,
    hasMore: PropTypes.bool.isRequired,
    hasShowInfo: PropTypes.bool.isRequired,
    hasSelection: PropTypes.bool.isRequired,
};

export default MyTable

