import React, {Component} from 'react'
import FilterTooltip from "./FilterTooltip";

class TheadComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterData: []
        }

    }


    render() {

        let style = {
            cursor: 'initial',
            ...this.props.style
        }
        let labelStyle = {
            cursor: 'pointer',
            ...this.props.children[0].props.className
        }
        let filterStyle = {
            width: '25px',
            height: '25px',
            display: 'none'
        }
        let makeFilter = !!this.props.makeFilter && !!this.props.data && this.props.data.length > 0
        let thClass = this.props.thClass ? this.props.thClass : ""
        let inClassName = this.props.children[0].props.className ? this.props.children[0].props.className : ""
        return (
            <div className={`rt-th ${this.props.className}`} role={this.props.role} tabIndex={this.props.tabIndex}
                 style={style}>
                {
                    makeFilter &&
                    <FilterTooltip field={this.props.field}
                                   filterStyle={filterStyle}
                                   filterData={this.props.data}
                                   filterConverter={this.props.filterConverter}
                                   onFilterDataSelected={filter => this.props.onFilterDataSelected(filter)}/>
                }
                <div onClick={e => this.props.toggleSort(e)} style={labelStyle} className={`${thClass} ${inClassName}`}>{this.props.children[0].props.children}</div>
            </div>

        )
    }
}

export default TheadComponent;

