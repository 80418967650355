import React, {Component} from "react";
import PropTypes from "prop-types";
import utils from "../../utils/utils";
import Prototypes from "prop-types";
import SvGraphics from "../../assets/SvGraphics";

class DeviceInfoSideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceInfo: null
        };
    }

    async componentWillMount() {
        if (this.props && this.props.deviceInfo) await this.setState({deviceInfo:this.props.deviceInfo})
    }

    async closeSideBar(){
        let {t} = this.props
        document.body.style.overflow = "auto"
        this.props.closeSideBar(false)
        this.setState({entry:null})
    }

    render() {
        let display = this.props.isSideBarOpen ? 'block' : 'none'
        let width = this.props.isSideBarOpen ? "800px" : "0px"
        let container_width = this.props.isSideBarOpen ? "100%" : "0px"
        let {t} = this.props
        return (
            <div className={"sidenav-complete"} style={{width:container_width, height:"100%"}}>
                <div className={"sidenav"} style={{width:width, height:"100%", float:'right'}}>
                    <SvGraphics onClick={this.closeSideBar.bind(this)} className={'close-bar-x'} svgname={'close'} height={'15px'} width={'15px'} style={{
                        float: 'left',
                        top: '10px',
                        left: '10px',
                        position: 'absolute',
                        cursor: 'pointer'
                    }}/>
                    <div className={'register-form-title container block'}>
                        <h3 className={'h-centered block'}>{t('admin.device.table.device_id')} {this.props.deviceInfo.DeviceID}</h3>
                    </div>
                    <div className={'register-form container block'}>
                        <div className={'device-teamviewer'}>
                            <h5 className={'device-teamviewer-label inline'}>{t('admin.device.info.tv_id')}</h5>
                            <h6 className={'device-teamviewer-value inline'}>21545634385</h6>
                        </div>
                        <div className={'device-mediapackage'}>
                            <h5 className={'device-teamviewer-label inline'}>{t('admin.device.info.media_p_v')}</h5>
                            <h6 className={'device-teamviewer-value inline'}>V01.01.01</h6>
                        </div>
                        <div className={'device-errors'}>
                            <h5>{t('admin.device.info.errors')}</h5>
                            <table className="device-errors-table">
                                <thead className={'device-errors-table-head'}>
                                <tr className={'device-errors-table-tr'}>
                                    <th className={'device-errors-table-th'} scope="col">{t('admin.device.info.date_time')}</th>
                                    <th className={'device-errors-table-th'} scope="col">{t('admin.device.info.error_txt')}</th>
                                </tr>
                                {}
                                </thead>
                                <tbody className={'device-errors-table-body'}>
                                {[...Array(25).keys()].map(index => {
                                    return  !!this.props.deviceInfo.DeviceErrors[index] ?
                                        <tr className={'device-errors-table-tr'}>
                                            <th className={'device-errors-table-td'} scope="row">{utils.getDateTimeFormatWithYear(
                                                new Date(this.props.deviceInfo.DeviceErrors[index].ErrorDate)
                                            )}</th>
                                            <td className={'device-errors-table-td'}>{this.props.deviceInfo.DeviceErrors[index].Error}</td>
                                        </tr> :
                                        <tr className={'device-errors-table-tr'}>
                                            <th className={'device-errors-table-td'} scope="row">{''}</th>
                                            <td className={'device-errors-table-td'}>{''}</td>
                                        </tr>
                                })}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <div className={'register-form-nav-container block'} >
                    <div className={'register-form-nav'}  style={{width:width}}>
                        <label onClick={this.closeSideBar.bind(this)} style={{display:display}} className={"v-centered close-bar"}>
                            {t('admin.ecp.form.close')}
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}
DeviceInfoSideBar.propTypes = {
    id:PropTypes.bool,
    isSideBarOpen:Prototypes.bool.isRequired,
    closeSideBar:Prototypes.func.isRequired,
}

export default DeviceInfoSideBar

