import React, {Component} from 'react'
import '../../components_style/PatientScreen.css'
import '../../components_style/rtl_css/PatientScreen.css'
import SvGraphics from "../../assets/SvGraphics";
import PatientChart from "../../PatientChart";
import {filterPatientsTable} from "../../redux/actions/Tabs-Actions";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {dateTimeArr, dateTimeArr2, getDateFormat, getTimeFormat} from "../../utils/getDateTimeFormat";
import PatientChartDirection from "../../PatientChartDirection";




class PatientReadingBlockConnected extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fixations: true,
            regressions:false,
            fixationDuration: false,
            readingRate: false,
        }
    }

    async results_toggle(state) {

        let fixations,regressions,fixationDuration,readingRate= false;
        switch (state) {
            case 'fixations': {fixations = true; break;}
            case 'regressions': {regressions = true; break;}
            case 'fixationDuration': {fixationDuration = true; break;}
            case 'readingRate': {readingRate = true; break;}
            default:  break;
        }

        this.setState({
            fixations,regressions,fixationDuration,readingRate
        })

    }

    render(string, type) {
        let activeBtnClass = 'nova-info-active-btn'
        let {t} = this.props
        let {fixations,regressions,fixationDuration,readingRate} = this.state

        let readingDescSC=[]
        let readingDescCC=[]

        let categoriesCC=[]
        let categoriesSC=[]
        let readingSC = {
            name: "",
            data: []
        }

        let readingCC = {
            name: "",
            data: []
        }

        let lastVisits = this.props.lastVisitsData
        let scMaxY=1.5
        let ccMaxY=1.5
        let title=""

        if(fixations){
            title= "#"
        }else if(regressions){
            title= "[%]"
        }else if(fixationDuration){
            title=t('general.msec')
        }else if(readingRate){
            title=t('words/sec')
        }
        if(lastVisits){
            for (const visit of lastVisits) {
                for (const testResults of visit.TestsResults) {
                    if (testResults.ReadingTestStorageID > 0 && testResults.OpticalCorrection === "SC"&&testResults.TestResult.toLowerCase()==="test succeeded"){
                        categoriesSC.push(dateTimeArr2(new Date(visit.VisitDate)))

                        if(fixations){
                            let readingValue=testResults.ReadingResult.TotalFixationsCount
                            readingSC.data.push(readingValue.toFixed(2))
                            scMaxY=Math.max(readingValue,scMaxY)

                        }else if(regressions){
                            let readingValue=testResults.ReadingResult.PercentRegressions
                            readingSC.data.push(readingValue.toFixed(2))
                            scMaxY=Math.max(readingValue,scMaxY)

                        }else if(fixationDuration){
                            let readingValue=testResults.ReadingResult.FixationAvgTimeAll
                            readingSC.data.push(readingValue.toFixed(2))
                            scMaxY=Math.max(readingValue,scMaxY)
                        } else{
                            let readingValue=testResults.ReadingResult.ReadingRate
                            readingSC.data.push(readingValue.toFixed(2))
                            scMaxY=Math.max(readingValue,scMaxY)
                        }
                        readingDescSC.push({textLevel:testResults.ReadingResult.TextLevel, readingLang:testResults.ReadingResult.ReadingLanguage})
                    }  if (testResults.ReadingTestStorageID > 0 && testResults.OpticalCorrection === "CC"&&testResults.TestResult.toLowerCase()==="test succeeded"){
                        categoriesCC.push(dateTimeArr2(new Date(visit.VisitDate)))

                        if(fixations){
                            let readingValue=testResults.ReadingResult.TotalFixationsCount
                            readingCC.data.push(readingValue.toFixed(2))
                            ccMaxY=Math.max(readingValue,scMaxY)
                        }else if(regressions){
                            let readingValue=testResults.ReadingResult.PercentRegressions
                            readingCC.data.push(readingValue.toFixed(2))
                            ccMaxY=Math.max(readingValue,scMaxY)
                        }else if(fixationDuration){
                            let readingValue=testResults.ReadingResult.FixationAvgTimeAll
                            readingCC.data.push(readingValue.toFixed(2))
                            ccMaxY=Math.max(readingValue,scMaxY)
                        }else{
                            let readingValue=testResults.ReadingResult.ReadingRate
                            readingCC.data.push(readingValue.toFixed(2))
                            ccMaxY=Math.max(readingValue,scMaxY)
                        }
                        readingDescCC.push({textLevel:testResults.ReadingResult.TextLevel, readingLang:testResults.ReadingResult.ReadingLanguage})

                    }
                }
            }
        }

        let seriesSC=  [readingSC]
        let seriesCC=  [readingCC]
        return(

            <div className={'patient-Charts-Block'} id={"reading"}>
                <div className={'patient-charts-block-label flex'}>
                    <div>
                    <label className={'patient-act-chart-text'}> {t('general.Reading')} </label>
                   {/*     <div className={'test-results-info-block-select-tests'}>
                            <label className={'test-results-info-block-label'}>{t('ecp.info_filters.select_values')}</label>
                        </div>*/}
                        <label className={'patient-show-info-block-label'}>{t('ecp.info_filters.showing')}</label>
                        <div className={`select-values-info-block ${fixations?activeBtnClass:''}`} onClick={() => this.results_toggle('fixations')}>
                            <label className={'test-results-info-block-label'}>{t('general.Fixations')}</label>
                        </div>
                        <div className={`select-values-info-block ${regressions?activeBtnClass:''}`} onClick={() => this.results_toggle('regressions')}>
                            <label className={'test-results-info-block-label'}>{t('general.Regressions')}</label>
                        </div>
                        <div className={`select-values-info-block ${fixationDuration?activeBtnClass:''}`} onClick={() => this.results_toggle('fixationDuration')}>
                            <label className={'test-results-info-block-label'}>{t('general.Fixation_duration')}</label>
                        </div>
                        <div className={`select-values-info-block ${readingRate?activeBtnClass:''}`} onClick={() => this.results_toggle('readingRate')}>
                            <label className={'test-results-info-block-label'}>{t('general.Reading_Rate')}</label>
                        </div>
                    </div>
                    <label className= {'patient-act-chart-last'}> {t('ecp.info_filters.lastTest')} {this.props.readingLastVisit}</label>
                </div>

                <div className={'patient_act_charts'}>
                <div className={'patient-dashboard-chart-area'}>
                    <div className={'patient-chart-topping'}>
                        <div className={'title-svg'}>
                            <div className={'chart-title'}>Without Glasses</div>
                            <SvGraphics className={'glasses-style'} svgname={'noglasses'}/>
                        </div>
                    </div>
                    <div className={'ACT-chart'}>
{/*
                        <PatientChart series={seriesSC} chartType={'bar'} colors={['#034e99']} xCategories={categoriesSC} maxYValue={scMaxY+0.5} ytitle={title}/>
*/}
                        <PatientChartDirection series={seriesSC} chartType={'bar'}  colors={['#034e99']}
                                               maxYValue={scMaxY+0.5}  ytitle={title}
                                               horizontalDeviationDriection={readingDescSC}
                                               xCategories={categoriesSC} seriesNum={1} />

                    </div>
                </div>

                <div className={'patient-dashboard-chart-area'}>
                    <div>
                        <div className={'title-svg'}>
                            <div className={'chart-title'}>With Glasses</div>
                            <SvGraphics className={'glasses-style'} svgname={'glasses'}/>
                        </div>
                    </div>
                    <div className={'ACT-chart'}>

                        <PatientChartDirection series={seriesCC} chartType={'bar'}  colors={['#034e99']}
                                                maxYValue={ccMaxY+0.5}  ytitle={title}
                                               horizontalDeviationDriection={readingDescCC}
                                               xCategories={categoriesCC} seriesNum={1} />
                    </div>
                </div>
                </div>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return {
        filterPatientsTable:(filterType,payload) => dispatch(filterPatientsTable(filterType,payload)),
    };
}
const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData,
        patientsCount:state.patientsTableDataReducer.patientsCount,
        activePatientsCount:state.patientsTableDataReducer.activePatientsCount,
        pendingPatientsCount:state.patientsTableDataReducer.pendingPatientsCount,
        lockedPatientsCount:state.patientsTableDataReducer.lockedPatientsCount,
    };
};

const PatientReadingBlock = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(PatientReadingBlockConnected)
    export default PatientReadingBlock