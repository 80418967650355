import React, {Component} from 'react'
import Pdf from '../../assets/PDF/NovaSight License Agreement.pdf';

class LicensePdf extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <embed src={Pdf} width="100%" height="2100px" />
        )
    }
}

export default LicensePdf;
