import React, {Component} from 'react'
import {authenticationService} from '../../services/AuthenticationService';

import SvGraphics from "../../assets/SvGraphics";
import * as Yup from "yup";
import validationHelper from "../../utils/validationHelper";
import utils from "../../utils/utils";
import Error from "../../components/single_components/Error";
import Config from "../../config/Config";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import Popover from "react-bootstrap/Popover";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Pdf from '../../assets/PDF/NovaSight Privacy Policy Eyeswift.pdf';
import Pdf2 from '../../assets/PDF/NovaSight License Agreement.pdf';
import Reaptcha from "reaptcha";
import {history} from "../../utils/history";

class CreatePasswordComposed extends Component {
    constructor(props) {
        super(props);

        if (!authenticationService.pwdUserValue) {
            this.props.history.push('/');
        }

        this.state = {
            password1:'',
            passwordConfirm: '',
            auth: {
                TermsOfUseSigned: false
            },
            isErr:false,
            errMessage:'',
            errors: {},
            errorsOnSave:{},
            showTermsOfUse:false,
        }
        this.recaptchaRef = React.createRef();
    }

    initValidation = async () => {
        let {t} = this.props

        let validationSchema = Yup.object().shape({
            password1: Yup.string().matches(Config.passwordRegExp,t('defaults.crt_pwd.pwd_validation')).required(t('defaults.crt_pwd.pwd_req')),
            passwordConfirm: Yup.string().oneOf([Yup.ref('password1'), null], t('defaults.crt_pwd.pwd_match')).test('match', t('defaults.crt_pwd.pwd_match'), function (password1) {
                return password1 === this.parent.passwordConfirm
            }).required(t('defaults.crt_pwd.pwd_confirm_req')),

        })
        let errors = await validationHelper.validate(this.state.auth,validationSchema)
        await this.setState({
            validationSchema,
            errors
        })
    }

    async componentWillMount() {
        await this.initValidation()
    }

    componentDidMount() {
    }

    onSubmit = async () => {
        if (!utils.isEmpty(this.state.errors)){
            this.setState({errorsOnSave:this.state.errors});
            return;
        }
        try {
            await this.recaptchaRef.execute();
        } catch (err) {
            this.setState({
                isErr: true,
                errMessage: 'Blocked By ReCAPTCHA',
            })
        }
    }

    submitData = async () => {
        let user=authenticationService.pwdUserValue?authenticationService.pwdUserValue:""
        let role=user.role?user.role[0]:""
        let TermsOfUseSigned = true
        let {auth,password1} = this.state
        if (!auth.TermsOfUseSigned){
            if (!!authenticationService.pwdUserValue && role==="Doctor") {
                TermsOfUseSigned = authenticationService.pwdUserValue ? !!authenticationService.pwdUserValue.profile.Doctor.TermsOfUseSigned : false;
                if (!!TermsOfUseSigned){
                    await this.setState({TermsOfUseSigned})
                } else {
                    this.setState({
                        isErr:true,
                        errMessage:'You must sign the terms of use first',
                    })
                    return;
                }
            }
        }
        let response = await authenticationService.changeNewPassword(password1,TermsOfUseSigned);
        if (!!response && response.status < 400) {
            let data = response.data
            if (data && data.hasOwnProperty('newPassword') && data['newPassword'] === true) {
                const {from} = {from: {pathname: "/dashboard"}};
                this.props.history.push(from);
            } else {
                await this.setPassword1({target: {value: '',name:'password1'}})
                await this.setpasswordConfirm({target: {value: '',name:'passwordConfirm'}})
                this.setState({
                    password1:'',
                    passwordConfirm:'',
                    isErr:true,
                    errMessage:response.data?response.data.message:'No connection to server',
                })
            }
        } else {
            if(response.data.message==="Doctor's site does not have any registered devices"){
                this.props.history.push("/password-confirmed");
            }
            this.setState({
                password1:'',
                passwordConfirm:'',
                isErr:true,
                errMessage:response.data?response.data.message:'No connection to server',
            })

        }
    }

    setPassword1 = async (e) => {
        let {auth,validationSchema} = this.state
        let name = 'password1'
        this.setState({password1:e.target.value})
        utils.set(auth,name,e.target.value)
        let errors = await validationHelper.validate(auth, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;
        if (utils.get(errorsOnSave,name)){
            utils.set(errorsOnSave,name,utils.get(errors,name))
        }
        this.setState({
            auth,
            errors,
            errorsOnSave,
            isErr:false,
            errMessage:''
        })
    }

    setPasswordConfirm = async (e) => {
        let {auth,validationSchema} = this.state
        let name = 'passwordConfirm'
        this.setState({passwordConfirm:e.target.value})
        utils.set(auth,name,e.target.value)
        let errors = await validationHelper.validate(auth, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;
        if (utils.get(errorsOnSave,name)){
            utils.set(errorsOnSave,name,utils.get(errors,name))
        }
        this.setState({
            auth,
            errors,
            errorsOnSave,
            isErr:false,
            errMessage:''
        })
    }

    showTermsOfUse=()=>{
        this.setState({showTermsOfUse:!this.state.showTermsOfUse})
    }
    setTermsOfUseSigned = async (e) => {
        let {auth,validationSchema} = this.state
        let name = 'TermsOfUseSigned'
        utils.set(auth,name,!auth.TermsOfUseSigned)
        let errors = await validationHelper.validate(auth, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;
        if (utils.get(errorsOnSave,name)){
            utils.set(errorsOnSave,name,utils.get(errors,name))
        }
        this.setState({
            auth,
            errors,
            errorsOnSave,
            isErr:false,
            errMessage:''
        })
    }

    openPDF(){

        window.open('/static/media/PrivacyPdf', '_blank');
       /* history.push({
            pathname: '/static/media/PrivacyPdf',
            state: {
                from: {
                    fromPath: `/create-password`,
                }
            },
        })*/

    }
    render() {
        let {t} = this.props
        let SVGName ='eye_swift_blue'
        let isSigned = true
        let user=authenticationService.pwdUserValue?authenticationService.pwdUserValue:""
        let role=user.role?user.role[0]:""
        if (role==="Doctor")
            isSigned = authenticationService.pwdUserValue ? !!authenticationService.pwdUserValue.profile.Doctor.TermsOfUseSigned : false;
        let bottomSVG= <SvGraphics svgname={'nova_sight'} className={'nova-sight-login-logo'} style={{width:'183px'}}/>
        if(process.env.REACT_APP_ENV === "essilorstaging" || process.env.REACT_APP_ENV === "essilorproduction" )
        {
            SVGName ='eyeviz'
            bottomSVG =<img src={require('../../assets/Essilor-img.png')} style={{width:'500px'}} />
        }

        let renderTooltip = (props) => (
            <Tooltip className="termsofuse-tooltip" id="button-tooltip" {...props}>
                By creating an account or registering to use the Services and clicking
                [Note: Insert word used on the button to submit registration],
                I hereby acknowledge that I have read, understood and agreed to the terms of the License Agreement
                [Note: Insert link to the License Agreement here.]. I hereby agree to be bound by the License Agreement and to comply with all applicable laws and regulations
                regarding my use of the Platform and the Services.
            </Tooltip>
        );
        return (
            <div className={"login-page"}>
                <div className={'h-centered eye-swift-login-logo-container'}>
                    <SvGraphics svgname={SVGName} className={'eye-swift-login-logo'} style={{width:'340px'}}/>
                </div>
                <div className={'centered eye-swift-login-main-board'}>
                    <label className={'eye-swift-login-label-reset'}>{t('defaults.crt_pwd.new_pwd')}</label>
                    <label className={'h-centered eye-swift-newPassword-label'}>{t('defaults.crt_pwd.new_pwd_info')}</label>
                    <div className={'eye-swift-login-form-container-reset'}>
                        <div className={'h-centered eye-swift-login-form'}>
                            <Error isNonFloat={true} errorMessage={utils.get(this.state.errorsOnSave ,'password1')} isPasswordValidationErr={true} isShown={!!utils.get(this.state.errorsOnSave ,'password1')}/>
                            <div className={"login-input-wrapper form-group eye-swift-login-form-group"}>
                                <input className={"nova-input nova-form-input eye-swift-login-input-field"}
                                       placeholder={t('defaults.crt_pwd.pwd')}
                                       type={"password"}
                                       name={"password1"}
                                       value={this.state.password1}
                                       onChange={e => this.setPassword1(e)}
                                       onKeyPress={event => {
                                           if (event.key === 'Enter') {
                                               this.onSubmit()
                                           }
                                       }}/>
                                <SvGraphics className={"input-pic"} svgname={'lock'} style={{width: '20px', height: '20px'}}/>
                            </div>
                            <Error isNonFloat={true} errorMessage={utils.get(this.state.errorsOnSave ,'passwordConfirm')} isShown={!!utils.get(this.state.errorsOnSave ,'passwordConfirm')}/>
                            <div className={"login-input-wrapper form-group eye-swift-login-form-group"}>
                                <input className={"nova-input nova-form-input eye-swift-login-input-field"}
                                       placeholder={t('defaults.crt_pwd.pwd_valid_lbl')}
                                       type={"password"}
                                       name={"passwordConfirm"}
                                       value={this.state.passwordConfirm}
                                       onChange={e => this.setPasswordConfirm(e)}
                                       onKeyPress={event => {
                                           if (event.key === 'Enter') {
                                               this.onSubmit()
                                           }
                                       }}/>
                                <SvGraphics className={"input-pic"} svgname={'lock'} style={{width: '20px', height: '20px'}}/>
                            </div>
                            {
                                role==="Doctor" &&
                                <div className={"login-input-wrapper form-group eye-swift-login-form-group"} style={{display: 'flex'}}>
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        checked={isSigned || this.state.auth.TermsOfUseSigned}
                                        disabled={isSigned}
                                        onMouseOver={()=>this.showTermsOfUse()}
                                        onChange={e => this.setTermsOfUseSigned(e)}
                                        // disabled={(original.Enabled !== null && original.Enabled !== undefined) && (original.Enabled === '0' || original.Enabled === 0)}
                                        // checked={this.state.selected[original.id] === true}
                                        // onChange={() => this.toggleRow(original.id)}
                                    />

                                  {/*  <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}>
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            checked={isSigned || this.state.auth.TermsOfUseSigned}
                                            disabled={isSigned}
                                            onMouseOver={()=>this.showTermsOfUse()}
                                            onChange={e => this.setTermsOfUseSigned(e)}/>

                                    </OverlayTrigger>*/}
                                    <label className={'termsofuse'}>By creating an account or registering to use the Services and clicking
                                        the checkbox, I hereby acknowledge that I have read, understood and agreed to the terms of the
                                   <a onClick={()=>this.openPDF()} className={'termsofuseclick'}> License Agreement. </a>
                                     I hereby agree to be bound by the License Agreement and to comply with all applicable laws and regulations
                                        regarding my use of the Platform and the Services.</label>

                                </div>
                            }

                            <div className="form-group eye-swift-login-form-group">
                                <button className="btn h-centered btn-primary eye-swift-login-form-button" onClick={this.onSubmit}>{t('defaults.crt_pwd.submit')}</button>
                            </div>

                        </div>
                    </div>
                    <div className={'eye-swift-login-form-group loginError red h-centered'}>
                        <Error isNonFloat={true} errorMessage={t('defaults.crt_pwd.auth_err') + ': ' + this.state.errMessage} isShown={this.state.isErr}/>
                    </div>
                    {/*<div className={'h-centered eye-swift-login-footer-container'}*/}
                    {/*     onClick={e => this.props.history.goBack()}>*/}
                    {/*    <a>Back to Login</a>*/}
                    {/*</div>*/}
                </div>
                <div className={'h-centered nova-sight-login-logo-container'}>
                    {/*<SvGraphics svgname={SVGLogo} className={'nova-sight-login-logo'} style={{width:'183px'}}/>*/}
                    {bottomSVG}
                </div>

                <div className={'login-version-element'}>
                    <label className={'login-version-element-label'}>Version:</label>
                    <label className={'login-version-element-value'}>{Config.version}</label>
                </div>
                <Reaptcha
                    ref={ref => (this.recaptchaRef = ref)}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    size={'invisible'}
                    explicit={false}
                    onVerify={token => {
                        if (!!token) this.submitData()
                        else {
                            this.setState({
                                isErr: true,
                                errMessage: 'Blocked By ReCAPTCHA',
                            })
                        }
                    }}
                    onExpire={() => {this.setState({
                        isErr: true,
                        errMessage: 'ReCAPTCHA Expired, please refresh the page',
                    })}}
                    onError={err => {this.setState({
                        isErr: true,
                        errMessage: err,
                    })}}
                />
            </div>

        )
    }
}

let CreatePassword = compose(
    withTranslation()
)(CreatePasswordComposed)

export default CreatePassword;
