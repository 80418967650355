export const ECP_DATA = "ECP_DATA";
export const FILTER_ECPDATA = "FILTER_ECPDATA";
export const ECP_SELECTION = "ECP_SELECTION";
export const NEW_ECP = "NEW_ECP";
export const EDIT_ECP = "EDIT_ECP";
export const ECPS_COUNT = "ECPS_COUNT";
export const ECP_LOCKED_STATUS = "ECP_LOCKED_STATUS";
export const CLEAN_ECP = "CLEAN_ECP"

export const SITES_DATA = "SITES_DATA";
export const FILTER_SITESDATA = "FILTER_SITESDATA";
export const SITES_SELECTION = "SITES_SELECTION";
export const NEW_SITE = "NEW_SITE";
export const EDIT_SITE = "EDIT_SITE";
export const SITES_COUNT = "SITES_COUNT";
export const CLEAN_SITE = "CLEAN_SITE"
export const DELETE_SITE = "DELETE_SITE"
export const DELETE_SITE_ECPS = "DELETE_SITE_ECPS"

export const DEVICES_DATA = "DEVICES_DATA";
export const FILTER_DEVICESDATA = "FILTER_DEVICESDATA";
export const DEVICES_COUNT = "DEVICES_COUNT";
export const DEVICES_SELECTION = "DEVICES_SELECTION";
export const DEVICES_STATUSES = "DEVICES_STATUSES";
export const CLEAN_DEVICE = "CLEAN_DEVICE"

export const ADMINS_DATA = "ADMINS_DATA";
export const FILTER_ADMINSDATA = "FILTER_ADMINSDATA";
export const ADMINS_SELECTION = "ADMINS_SELECTION";
export const NEW_ADMIN = "NEW_ADMIN";
export const EDIT_ADMIN = "EDIT_ADMIN";
export const ADMINS_COUNT = "ADMINS_COUNT";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const ADMIN_LOCKED_STATUS = "ADMIN_LOCKED_STATUS";
export const CLEAN_ADMIN = "CLEAN_ADMIN"


export const FILTER_PENDINGSDATA = "FILTER_PENDINGSDATA"
export const PENDING_DATA = "PENDING_DATA"
export const DELETE_PENDING = "DELETE_PENDING"
export const PENDINGS_COUNT = "PENDINGS_COUNT"

export const PATIENTS_DATA = "PATIENTS_DATA";
export const FILTER_PATIENTSDATA = "FILTER_PATIENTSDATA";
export const PATIENTS_SELECTION = "PATIENTS_SELECTION";
export const PATIENTS_COUNT = "PATIENTS_COUNT";
export const PATIENTS_STATUSES = "PATIENTS_STATUSES";
export const NEW_PATIENT = "NEW_PATIENT";
export const EDIT_PATIENT = "EDIT_PATIENT";
export const PATIENT_LOCKED_STATUS = "PATIENT_LOCKED_STATUS";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const CLEAN_PATIENT = "CLEAN_PATIENT"

export const PATIENTS_ARCHIVE_DATA = "PATIENTS_ARCHIVE_DATA";
export const NEW_ARCHIVE_PATIENT = "NEW_ARCHIVE_PATIENT";
export const FILTER_PATIENTS_ARCHIVEDATA = "FILTER_PATIENTS_ARCHIVEDATA";
export const PATIENTS_ARCHIVE_SELECTION = "PATIENTS_ARCHIVE_SELECTION";
export const PATIENTS_ARCHIVE_COUNT = "PATIENTS_ARCHIVE_COUNT";
export const CLEAN_PATIENT_ARCHIVE = "CLEAN_PATIENT_ARCHIVE"

export const VERSIONS_DATA = "VERSIONS_DATA";
export const FILTER_VERSIONSDATA = "FILTER_VERSIONSDATA";
export const VERSIONS_SELECTION = "VERSIONS_SELECTION";
export const NEW_VERSION = "NEW_VERSION";
export const EDIT_VERSION = "EDIT_VERSION";
export const DELETE_VERSION = "DELETE_VERSION";
export const VERSIONS_COUNT = "VERSIONS_COUNT";
export const CLEAN_VERSIONS = "CLEAN_VERSIONS"
