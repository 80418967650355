let  Constants = {
    VaConstants: [
        {label: "constants.VaConstants.logMar", value: "1"},
        {label: "constants.VaConstants.decimal", value: "4"},
        {label: "constants.VaConstants.feet", value: "2"},
        {label: "constants.VaConstants.meter", value: "3"}
    ],

    LogMAR : [
        {label: "constants.LogMar.1.3", value: "1.3"},
        {label: "constants.LogMar.1.2", value: "1.2"},
        {label: "constants.LogMar.1.1", value: "1.1"},
        {label: "constants.LogMar.1.0", value: "1.0"},
        {label: "constants.LogMar.0.9", value: "0.9"},
        {label: "constants.LogMar.0.8", value: "0.8"},
        {label: "constants.LogMar.0.7", value: "0.7"},
        {label: "constants.LogMar.0.6", value: "0.6"},
        {label: "constants.LogMar.0.5", value: "0.5"},
        {label: "constants.LogMar.0.4", value: "0.4"},
        {label: "constants.LogMar.0.3", value: "0.3"},
        {label: "constants.LogMar.0.2", value: "0.2"},
        {label: "constants.LogMar.0.1", value: "0.1"},
        {label: "constants.LogMar.0.0", value: "0.0"},
        {label: "constants.LogMar.-0.1", value: "-0.1"},
        {label: "constants.LogMar.-0.2", value: "-0.2"},
        {label: "constants.LogMar.-0.3", value: "-0.3"}
    ],

    Decimal: [
        {label: "constants.decimal.0.050", value: "0.05"},
        {label: "constants.decimal.0.063", value: "0.063"},
        {label: "constants.decimal.0.080", value: "0.08"},
        {label: "constants.decimal.0.10", value: "0.1"},
        {label: "constants.decimal.0.12", value: "0.12"},
        {label: "constants.decimal.0.16", value: "0.16"},
        {label: "constants.decimal.0.20", value: "0.2"},
        {label: "constants.decimal.0.25", value: "0.25"},
        {label: "constants.decimal.0.32", value: "0.32"},
        {label: "constants.decimal.0.40", value: "0.4"},
        {label: "constants.decimal.0.50", value: "0.5"},
        {label: "constants.decimal.0.63", value: "0.63"},
        {label: "constants.decimal.0.80", value: "0.8"},
        {label: "constants.decimal.1.00", value: "1"},
        {label: "constants.decimal.1.25", value: "1.25"},
        {label: "constants.decimal.1.60", value: "1.6"},
        {label: "constants.decimal.2.00", value: "2"}
    ],

    Feet : [
        {label: "constants.feet.20/400", value: "20/400"},
        {label: "constants.feet.20/320", value: "20/320"},
        {label: "constants.feet.20/250", value: "20/250"},
        {label: "constants.feet.20/200", value: "20/200"},
        {label: "constants.feet.20/160", value: "20/160"},
        {label: "constants.feet.20/125", value: "20/125"},
        {label: "constants.feet.20/100", value: "20/100"},
        {label: "constants.feet.20/80", value: "20/80"},
        {label: "constants.feet.20/63", value: "20/63"},
        {label: "constants.feet.20/50", value: "20/50"},
        {label: "constants.feet.20/40", value: "20/40"},
        {label: "constants.feet.20/32", value: "20/32"},
        {label: "constants.feet.20/25", value: "20/25"},
        {label: "constants.feet.20/20", value: "20/20"},
        {label: "constants.feet.20/16", value: "20/16"},
        {label: "constants.feet.20/12.5", value: "20/12.5"},
        {label: "constants.feet.20/10", value: "20/10"}
    ],

    Meter: [
        {label: "constants.meter.6/120", value: "6/120"},
        {label: "constants.meter.6/95", value: "6/95"},
        {label: "constants.meter.6/75", value: "6/75"},
        {label: "constants.meter.6/60", value: "6/60"},
        {label: "constants.meter.6/48", value: "6/48"},
        {label: "constants.meter.6/38", value: "6/38"},
        {label: "constants.meter.6/30", value: "6/30"},
        {label: "constants.meter.6/24", value: "6/24"},
        {label: "constants.meter.6/19", value: "6/19"},
        {label: "constants.meter.6/15", value: "6/15"},
        {label: "constants.meter.6/12", value: "6/12"},
        {label: "constants.meter.6/9.5", value: "6/9.5"},
        {label: "constants.meter.6/7.5", value: "6/7.5"},
        {label: "constants.meter.6/6.0", value: "6/6.0"},
        {label: "constants.meter.6/4.8", value: "6/4.8"},
        {label: "constants.meter.6/3.8", value: "6/3.8"},
        {label: "constants.meter.6/3.0", value: "6/3.0"}
    ],


    Profession: [
        {label: "constants.Profession.md", value: 1},
        {label: "constants.Profession.optometrist", value: 2},
        {label: "constants.Profession.ophthalmologist", value: 3},
        {label: "constants.Profession.orthoptist", value: 4},
        {label: "constants.Profession.optician", value: 5},
        {label: "constants.Profession.administrative", value: 6},
        {label: "constants.Profession.other", value: 7}
    ],


    Genders: [
        {label: "male", value: 1},
        {label: "female", value: 2},
        {label: "other", value: 3},
    ],


    Patient_AccountStatus : [
        {label: "constants.Patient_AccountStatus.pendingPairing", value: 1},
        {label: "constants.Patient_AccountStatus.active", value: 2},
        {label: "constants.Patient_AccountStatus.completed", value: 3}
    ],

    emailRegExp: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

    versions: [
        {value: "WIN", label: 'constants.versions.windows'},
        {value: "ESAPP", label: 'constants.versions.esapp'},
        {value: "ET", label: 'constants.versions.etFirmware'},
        {value: "EMITTER", label: 'constants.versions.emitterFirmware'},
        {value: "TV", label: 'constants.versions.teamViewer'}
    ],

    locked: [
        {value: 0, label: 'constants.locked.locked'},
        {value: 1, label: 'constants.locked.unlocked'}
    ],
}

export default Constants

