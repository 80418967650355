import React, {Component} from 'react'
import AdminTabs from "./admin-tabs/AdminTabs";

import '../../style/AdminDashboard.css'
import '../../style/rtl_css/AdminDashboard.css'
import {history} from "../../utils/history";
import {filterDevicesTable} from "../../redux/actions/Tabs-Actions";
import {connect} from "react-redux";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class AdminDashboardConComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            low: false,
            medium: false,
            high: false,
            total: false,
            onUpdate: false,
            onError: false,
        }
    }

    componentDidMount = async () => {
        this.setState({total:true})
        history.push({
            pathname: `/dashboard/ecps`,
            state: {
                from: {
                    fromPath: `/dashboard`,
                }
            },
        })
    }

    async toggle(state) {
        let total,onUpdate,onError = false;
        switch (state) {
            case 'total': {total = true; break;}
            case 'onUpdate': {onUpdate = true; break;}
            case 'onError': {onError = true; break;}
            default:  break;
        }
        this.setState({
            total,onUpdate,onError
        })
        this.props.filterDevicesTable('status',state)
    }

    render() {
        let {t} = this.props
        let {total,onUpdate,onError} = this.state
        let activeClass = 'nova-info-active-block'
        return (
            <div className={'nova-admin-dashboard'}>
                <div className={'nova-info'}>
                    <div className={'nova-info'}>
                        <div className={'nova-info-main-container container'}>
                            <div className={'nova-info-container nova-info-devices-status-container v-centered'}>
                                <label className={'nova-info-label nova-info-devices-status-label'}>{t('admin.info_filters.devices_status')}:</label>
                                <div className={'nova-info-devices-status-info'}>
                                    <div className={`single-nova-info-block nova-info-devices-status-total ${total?activeClass:''}`} onClick={() => this.toggle('total')}>
                                        <label className={'single-nova-info-block-value'}>{this.props.devicesCount} </label>
                                        <label className={'single-nova-info-block-label'}>{t('admin.info_filters.total_devices')}</label>
                                    </div>
                                    <div className={`single-nova-info-block nova-info-devices-status-active ${onUpdate?activeClass:''}`} onClick={() => this.toggle('onUpdate')}>
                                        <label className={'single-nova-info-block-value'}>{this.props.onUpdateDevicesCount} </label>
                                        <label className={'single-nova-info-block-label'}>{t('admin.info_filters.devices_req_update')}</label>
                                    </div>
                                    <div className={`single-nova-info-block nova-info-devices-status-pending ${onError?activeClass:''}`} onClick={() => this.toggle('onError')}>
                                        <label className={'single-nova-info-block-value'}>{this.props.onErrorDevicesCount} </label>
                                        <label className={'single-nova-info-block-label'}>{t('admin.info_filters.devices_have_err')}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'nova-admin-dashboard container'}>
                    <AdminTabs toggle={state => this.toggle(state)}/>
                </div>
            </div>

        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        filterDevicesTable: (filterType,payload) => dispatch(filterDevicesTable(filterType,payload))
    };
}
const mapStateToProps = state => {
    return {
        devicesCount:state.deviceTableDataReducer.devicesCount,
        onUpdateDevicesCount:state.deviceTableDataReducer.onUpdateDevicesCount,
        onErrorDevicesCount:state.deviceTableDataReducer.onErrorDevicesCount,
    };
};

const AdminDashboard = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(AdminDashboardConComposed);

export default AdminDashboard;
