import React, {Component} from 'react'
import ReactApexChart from "react-apexcharts";
import SvGraphics from "../../assets/SvGraphics";
import PatientChart from "../../PatientChart";
import {filterPatientsTable} from "../../redux/actions/Tabs-Actions";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {dateTimeArr, dateTimeArr2, getDateFormat, getTimeFormat} from "../../utils/getDateTimeFormat";

class PatientFusionalChartConnected extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {t}=this.props
        let categories=[]
        let convergence = {
            name: t('ecp.info_filters.convergence'),
            data: []
        }
        let recoveryPos = {
            name: t('ecp.info_filters.recovery_pos'),
            data: []
        }
        let recoveryNeg = {
            name: t('ecp.info_filters.recovery_neg'),
            data: []
        }
        let divergence = {
            name: t('ecp.info_filters.divergence'),
            data: []
        }

        let lastVisits = this.props.lastVisitsData
        let condition = ""
        if (this.props.svgName === "noglasses")
            condition="SC"
        else
            condition="CC"

        let maxY=1.5
        if(lastVisits){
            for (const visit of lastVisits) {
                for (const testResults of visit.TestsResults) {
                    if (testResults.FusionalReserveTestStorageID > 0 && testResults.OpticalCorrection === condition&&testResults.TestResult.toLowerCase()==="test succeeded"){
                        categories.push(dateTimeArr2(new Date(visit.VisitDate)))
                        let convergenceValue=testResults.FusionalReservesResult?testResults.FusionalReservesResult.FusionalConvergence:0
                        let recoveryPositive=testResults.FusionalReservesResult?testResults.FusionalReservesResult.FusionalPositiveRecovery:0
                        let divergenceValue=testResults.FusionalReservesResult?testResults.FusionalReservesResult.FusionalDivergence:0
                        let recoveryNegative=testResults.FusionalReservesResult?testResults.FusionalReservesResult.FusionalNegativeRecovery:0

                        convergence.data.push(convergenceValue===null?"":convergenceValue)
                        recoveryPos.data.push(recoveryPositive===null?"":recoveryPositive)
                        divergence.data.push(divergenceValue===null?"":divergenceValue)
                        recoveryNeg.data.push(recoveryNegative===null?"":recoveryNegative)


                        maxY=Math.max(convergenceValue, recoveryPositive,recoveryNegative,divergenceValue,maxY)


                    }
                }
            }
        }
        let series=  [convergence,recoveryPos,divergence,recoveryNeg]
        return (
            <div className={'patient-dashboard-chart-area'}>
                <div className={'patient-chart-topping'} style={{display:"block"}}>
                    <div className={'title-svg'}>
                        <div className={'chart-title'}>{this.props.chartTitle}</div>
                        <SvGraphics className={'glasses-style'} svgname={this.props.svgName}/>
                    </div>
                    <div className={'patient-fusional-chart-indication-area'}>
                        <div className={'patient-chart-indication patient-chart-1st-indication'}>
                            <span className={'patient-chart-indication-color'}>&nbsp;</span>
                            <div className={'patient-chart-indication-text'}>{t('ecp.info_filters.convergence')}</div>
                        </div>
                        <div className={'patient-chart-indication patient-chart-2nd-indication'}>
                            <span className={'patient-chart-indication-color'}>&nbsp;</span>
                            <div className={'patient-chart-indication-text'}>{t('ecp.info_filters.recovery_pos')}</div>
                        </div>
                        <div className={'patient-chart-indication patient-chart-3rd-indication'}>
                            <span className={'patient-chart-indication-color'}>&nbsp;</span>
                            <div className={'patient-chart-indication-text'}>{t('ecp.info_filters.divergence')}</div>
                        </div>
                        <div className={'patient-chart-indication patient-chart-4th-indication'}>
                            <span className={'patient-chart-indication-color'}>&nbsp;</span>
                            <div className={'patient-chart-indication-text'}>{t('ecp.info_filters.recovery_neg')}</div>
                        </div>
                    </div>
                </div>
                <div className={'ACT-chart'}>

                    <PatientChart series={series} chartType={'bar'} colors={['#25a6ff', '#0575e6','#034e99','#022e5a']}
                                  minYValue={-(0.5)} maxYValue={maxY+0.5} tickAmount={4}
                                  xCategories={categories} seriesNum={3} isFusional={true} ytitle={'[Δ]'}/>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        filterPatientsTable:(filterType,payload) => dispatch(filterPatientsTable(filterType,payload)),
    };
}
const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData,
        patientsCount:state.patientsTableDataReducer.patientsCount,
        activePatientsCount:state.patientsTableDataReducer.activePatientsCount,
        pendingPatientsCount:state.patientsTableDataReducer.pendingPatientsCount,
        lockedPatientsCount:state.patientsTableDataReducer.lockedPatientsCount,
    };
};

const PatientFusionalChart = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(PatientFusionalChartConnected)

export default PatientFusionalChart
