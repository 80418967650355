import React, {Component} from 'react'
import PropTypes from "prop-types";
import utils from "../../utils/utils";
import Error from "../single_components/Error";
import PhoneInput from "react-phone-input-2";

class Site extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entry: this.props.entry,
        }
    }




    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.entry !== prevProps.entry) {
            this.setState({
                entry: this.props.entry
            })
        }
    }


    render() {
        let{t}=this.props
        return (
            <div className={'Patient-personal-Form'}>
                <div className={'Patient-personal-form-container'}>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('admin.site.form.site_name')} *</label>
                        <div className={'form-search'}>
                            <input
                                type={"text"}
                                disabled={this.props.isDisabled}
                                className={"nova-form-input"}
                                maxLength={50}
                                defaultValue={utils.get(this.props.entry,'SiteName') !== null &&
                                utils.get(this.props.entry,'SiteName') !== undefined ?
                                    utils.get(this.props.entry,'SiteName') :''}
                                name={'SiteName'}
                                onChange={(event) => this.props.onValueChange('SiteName', event.target.value)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'SiteName')} isShown={!!utils.get(this.props.errors, 'SiteName')}/>
                        </div>
                    </div>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('admin.site.form.site_phone_number')} </label>
                        <div className={'form-search'}>
                            <PhoneInput
                                country={'us'}
                                value={utils.get(this.props.entry,'SitePhoneNumber') !== null &&
                                utils.get(this.props.entry,'SitePhoneNumber') !== undefined?
                                    utils.get(this.props.entry,'SitePhoneNumber') :''}
                                inputClass={'nova-form-input'}
                                containerClass={'nova-PhoneInput-container'}
                                buttonClass={'nova-PhoneInput-button'}
                                dropdownClass={`nova-patient-PhoneInput-dropdown`}
                                searchClass={'nova-patient-PhoneInput-search'}
                                enableSearch={true}
                                countryCodeEditable={false}
                                autoFormat={true}
                                onChange={phone =>this.props.onValueChange('SitePhoneNumber',phone)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'SitePhoneNumber')} isShown={!!utils.get(this.props.errors, 'SitePhoneNumber')}/>
                        </div>
                    </div>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('admin.site.form.site_email')} *</label>
                        <div className={'form-search'}>
                            <input
                                type={"text"}
                                disabled={this.props.isDisabled}
                                className={"nova-form-input"}
                                maxLength={100}
                                defaultValue={utils.get(this.props.entry,'SiteEmail') !== null &&
                                utils.get(this.props.entry,'SiteEmail') !== undefined ?
                                    utils.get(this.props.entry,'SiteEmail') :''}
                                name={'SiteEmail'}
                                onChange={(event) => this.props.onValueChange('SiteEmail', event.target.value)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'SiteEmail')} isShown={!!utils.get(this.props.errors, 'SiteEmail')}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Site.propTypes = {
    onValueChange:PropTypes.func.isRequired,
    entry: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool
}

export default Site;
