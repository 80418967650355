import React, {Component} from 'react'
import { connect } from "react-redux";
import MyTable from "../../../components/NovaTable/Table";
import {setAdminDataSelection, cleanAdmin, editAdmin, deleteAdmin, setAdminLockedStatus,filterAdminsTable} from "../../../redux/actions/Tabs-Actions";
import AdminEditForm from "../../../components/admin/AdminEditForm";
import {adminApi} from "../../../services/ApiService";
import SvGraphics from "../../../assets/SvGraphics";
import AlertConfirm from "../../../components/modals/Confirm";
import Constants from "../../../utils/constants";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {ConstantsArrT} from "../../../utils/ConstantsWithTranslate";

class AdminsTabConComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSideBarOpen: false,
            entry: null
        }
    }

    async openSideBar(admin){
        document.body.style.overflow = "hidden"
        await this.setState({entry:admin,isSideBarOpen:true})
        this.forceUpdate();
    }

    componentDidMount = async ()  => {
        await this.setState({
            visibleCollection: this.props.adminData,
            filteredCollection: this.props.adminData,
        })
    }

    componentWillUnmount() {
        this.props.cleanAdmin()
    }

    onSelectionChange = (selected) => {
        this.setState({selected})
        this.props.setAdminDataSelection(selected)
    }

    editAdminDetails = async (admin) => {
        await this.openSideBar(admin)
    }

    removeAdmin = async (row) => {
        let {t} = this.props
        let confirm = await AlertConfirm({
            options:{
                title: t('admin.admin.table.delete_admin'),
            }},`${t('admin.admin.table.delete_admin_confirm')}: ${row.FirstName + ' ' + row.LastName}?`)
        if (confirm) {
            let UserStorageID = row.UserStorageID
            let response = await adminApi.deleteAdmin(UserStorageID)
            if (response){
                await this.props.deleteAdmin(UserStorageID)
            }
        }
    }

    lockAdmin = async (row) => {
        let {t} = this.props
        let confirm = await AlertConfirm({
            options:{
                title: t('admin.admin.table.lock_admin'),
            }},`${t('admin.admin.table.lock_admin_confirm')}: ${row.FirstName + ' ' + row.LastName}?`)
        if (confirm) {
            let data = {'Enabled':0}
            let response = await adminApi.EditAdmin(data,row.UserStorageID)
            if (response){
                await this.props.setAdminLockedStatus(response.data)
            }
        }
    }

    unlockAdmin = async (row) => {
        let {t} = this.props
        let confirm = await AlertConfirm({
            options:{
                title: t('admin.admin.table.unlock_admin'),
            }},`${t('admin.admin.table.unlock_admin_confirm')}: ${row.FirstName + ' ' + row.LastName}?`)
        if (confirm) {
            let data = {'Enabled':1}
            let response = await adminApi.EditAdmin(data,row.UserStorageID)
            if (response){
                await this.props.setAdminLockedStatus(response.data)
            }
        }
    }

    setAdminsMoreFunctions = () => {
        let {t} = this.props
        return [
            {
                name: 'Edit Admin\'s Details',
                display: t('admin.admin.table.edit_admin_details'),
                call: (row => this.editAdminDetails(row))
            },
            {
                name: 'Remove Admin',
                display:t('admin.admin.table.delete_admin'),
                call: (row => this.removeAdmin(row))
            },
            {
                name: 'Lock Admin',
                display: t('admin.admin.table.lock_admin'),
                call: (row => this.lockAdmin(row))
            },
            {
                name: 'Unlock Admin',
                display: t('admin.admin.table.unlock_admin'),
                call: (row => this.unlockAdmin(row))
            }
        ]
    }

    onFilterDataSelected = (filter) => {
        this.props.filterAdminsTable(Object.keys(filter)[0],Object.values(filter)[0])
    }

    getColumns = (t) => {
        return [
            {accessor: 'FirstName', Header: t('admin.admin.table.first_name'), resizable:false},
            {accessor: 'LastName', Header: t('admin.admin.table.last_name'), resizable:false},
            {accessor: 'Email', Header: t('admin.admin.table.username'), resizable:false},
            {accessor: 'Enabled', Header: t('admin.admin.table.locked'), resizable: false,Cell: ({ original }) => {
                    if (original.Enabled){
                        return ''
                    } return (<SvGraphics svgname={'true'} style={{height:'15px',width:'15px', marginLeft: '19px'}}/>)
                }, makeFilter: true, filterConverter: ConstantsArrT(t,Constants.locked)},
            {accessor: 'PhoneNumber', Header: t('admin.admin.table.phone_number'), resizable:false}
        ]
    }

    render() {
        let {t} = this.props
        let columns = this.getColumns(t)
        let {entry,isSideBarOpen} = this.state
        return (
            <div className={'context-area'}>
                <MyTable
                    data={this.props.adminData}
                    initialData={this.props.initiateAdminData}
                    columns={columns}
                    onSelectionChange={selected => this.onSelectionChange(selected)}
                    moreFunctions={this.setAdminsMoreFunctions()}
                    onFilterDataSelected={filter => this.onFilterDataSelected(filter)}
                    hasSelection={true}
                    hasMore={true}
                    hasShowInfo={false}
                />
            {isSideBarOpen && <AdminEditForm
                t={e => t(e)}
                title={t('admin.admin.table.edit_admin')}
                entry={entry}
                editAdmin={adminData => this.props.editAdmin(adminData)}
                isSideBarOpen={isSideBarOpen}
                closeSideBar={() => this.setState({isSideBarOpen: false})}/>}
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setAdminLockedStatus: adminData => dispatch(setAdminLockedStatus(adminData)),
        filterAdminsTable: (type,payload) => dispatch(filterAdminsTable(type,payload)),
        editAdmin: adminData => dispatch(editAdmin(adminData)),
        deleteAdmin: adminId => dispatch(deleteAdmin(adminId)),
        cleanAdmin: () => dispatch(cleanAdmin()),
        setAdminDataSelection: adminData => dispatch(setAdminDataSelection(adminData)),
    };
}

const mapStateToProps = state => {
    return {
        adminData:state.adminTableDataReducer.adminData,
        initiateAdminData:state.adminTableDataReducer.initiateAdminData
    };
};

const AdminsTab = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(AdminsTabConComposed);

export default AdminsTab;
