import React, {Component} from "react";
import '../../components_style/RegisterForm.css'
import '../../components_style/rtl_css/RegisterForm.css'
import SimpleFormBuilder from "../forms_builder/SimpleFormBuilder";
import utils from "../../utils/utils";
import Prototypes from "prop-types";
import {adminApi} from "../../services/ApiService";
import * as Yup from "yup";
import validationHelper from "../../utils/validationHelper";
import {makeError} from "../modals/ErrorModal";
import Constants from "../../utils/constants";
import AlertConfirm from "../modals/Confirm";
import SvGraphics from "../../assets/SvGraphics";

class AdminEntryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entry: {
                TwoFA: true
            },
            matrix :[2,1,1,1],
            errors: {},
            errorsOnSave:{},
        };
    }

    initValidation = async () => {
        let {t} = this.props
        let validationSchema = Yup.object().shape({
            FirstName:Yup.string().required(t('admin.admin.form.first_name_req')),
            LastName:Yup.string().required(t('admin.admin.form.last_name_req')),
            PhoneNumber:Yup.string().required(t('admin.admin.form.phone_number_req')),
            Email:Yup.string().matches(Constants.emailRegExp, t('admin.admin.form.email_invalid')).required(t('admin.admin.form.email_req')),
            TwoFA : Yup.boolean()

        })
        let errors = await validationHelper.validate(this.state.entry,validationSchema)
        await this.setState({
            validationSchema,
            errors
        })
    }

    async componentWillMount() {

        await this.initValidation()
    }

    async closeSideBar(){
        let {t} = this.props
        document.body.style.overflow = "auto"
        let confirm = await AlertConfirm({
            options:{
                title: t('admin.admin.form.withdraw_new_admin'),
            }},t('admin.admin.form.withdraw_new_admin_info'))
        if (confirm) {
            document.body.style.overflow = "auto"
            await this.setState({entry:{}})
            this.props.closeSideBar(false)
        } else {
            document.body.style.overflow = "hidden"
        }
    }

    async saveInfo(){
        let {t} = this.props
        if (!utils.isEmpty(this.state.errors)){
            this.setState({errorsOnSave:this.state.errors});
            await makeError({
                proceedLabel: t('admin.admin.form.ok'),
                options:{
                    title: t('admin.admin.form.admin_not_created'),
                }
            },[t('admin.admin.form.fill_all_req')]);
            return;
        }
        let {entry} = this.state
        document.body.style.overflow = "auto"
        let confirm = await AlertConfirm({
            options:{
                title: t('admin.admin.form.add_new'),
            }},`${t('admin.admin.form.add_new_confirm')} ${entry.FirstName + ' ' + entry.LastName}?`)
        if (confirm) {
            entry = utils.ObjectEmptiesToNulls(entry)
            let response = await adminApi.AddNewAdmin(entry)
            if (response){
                this.props.addNewAdmin(response.data)
                document.body.style.overflow = "auto"
                await this.setState({entry:{}})
                this.props.closeSideBar(false)
            }
        } else {
            document.body.style.overflow = "hidden"
        }
    }

    onEntryChange = async event => {
        let {entry,validationSchema} = this.state
        let name = event.target.name
        let value = event.target.type === 'checkbox' ?
            !entry[name]
            : event.target.value
        utils.set(entry,name,value)
        let entryValidator = utils.removeEmptyOrNull(entry)
        let errors = await validationHelper.validate(entryValidator, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;
        if (utils.get(errorsOnSave,name)){
            utils.set(errorsOnSave,name,utils.get(errors,name))
        }
        this.setState({
            entry,
            errors,
            errorsOnSave
        })
    }

    getFields = (t) => {
        return [
            {type:'text', max: 20, name:'FirstName',display: t('admin.admin.form.first_name'), placeholder: t('admin.admin.form.enter_first_name'), required:true, width:0.5, style:'inline'},
            {type:'text', max: 20, name:'LastName',display: t('admin.admin.form.last_name'), placeholder: t('admin.admin.form.enter_last_name'), required:true, width:0.5, style:'inline'},
            {type:'phone', max: 50, name:'PhoneNumber',display: t('admin.admin.form.phone_number'), placeholder: t('admin.admin.form.phone_number'), required:true, width:1, style:'inline'},
            {type:'text', max: 50, name:'Email',display: t('admin.admin.form.email'), placeholder: t('admin.admin.form.enter_email'), required:true, width:1, style:'inline'},
            {type:'checkbox', name:'TwoFA',display: t('admin.admin.form.is_TwoFA_req'), width:1, style:'block'},
        ]
    }

    render() {
        let display = this.props.isSideBarOpen ? 'block' : 'none'
        let width = this.props.isSideBarOpen ? "600px" : "0px"
        let container_width = this.props.isSideBarOpen ? "100%" : "0px"
        let {errorsOnSave} = this.state
        let {t} = this.props
        let fields = this.getFields(t)
        return (
            <div className={"sidenav-complete"} style={{width:container_width, height:"100%"}}>
                <div className={"sidenav"} style={{width:width, height:"100%", float:'right'}}>
                    <SvGraphics onClick={this.closeSideBar.bind(this)} className={'close-bar-x'} svgname={'close'} height={'15px'} width={'15px'} style={{
                        float: 'left',
                        top: '10px',
                        left: '10px',
                        position: 'absolute',
                        cursor: 'pointer'
                    }}/>
                    <div className={'register-form-title container block'}>
                        <h3 className={'h-centered block'}>{this.props.title}</h3>
                    </div>
                    <div className={'register-form container block'}>
                        <SimpleFormBuilder
                            errors={errorsOnSave}
                            entry={this.state.entry}
                            matrix={this.state.matrix}
                            fields={fields}
                            onEntryChange={e => this.onEntryChange(e)}
                        />
                    </div>
                </div>
                <div className={'register-form-nav-container block'} >
                    <div className={'register-form-nav'}  style={{width:width}}>
                        <label onClick={this.closeSideBar.bind(this)} style={{display:display}} className={"v-centered close-bar"}>
                            {t('admin.site.form.close')}
                        </label>
                        <button type={"button"} onClick={this.saveInfo.bind(this)} style={{display:display}} className={"btn btn-primary v-centered save-info"}>
                            {t('admin.site.form.save')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
AdminEntryForm.propTypes = {
    isSideBarOpen:Prototypes.bool.isRequired,
    closeSideBar:Prototypes.func.isRequired,
    addNewAdmin:Prototypes.func.isRequired,
    title: Prototypes.string.isRequired,
}

export default AdminEntryForm

