import React, {Component} from 'react'
import '../../components_style/PatientScreen.css'
import '../../components_style/rtl_css/PatientScreen.css'
import SvGraphics from "../../assets/SvGraphics";
import {filterPatientsTable} from "../../redux/actions/Tabs-Actions";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import DoctorDashboardChart from "../../DoctorDashboardChart";
import {dateTimeArr, dateTimeArr2, getDateFormat, getTimeFormat} from "../../utils/getDateTimeFormat";
import PatientChart from "../../PatientChart";




class PatientStereoTestBlockConnected extends Component {

    constructor(props) {
        super(props);
    }

    render()
{
    let {t} = this.props

    let categoriesCC=[]
    let categoriesSC=[]
    let stereoTestSC = {
        name: "",
        data: []
    }

    let stereoTestCC = {
        name: "",
        data: []
    }

    let lastVisits = this.props.lastVisitsData
    let scMaxY = 1.5
    let ccMaxY = 1.5

    if(lastVisits){
        for (const visit of lastVisits) {
            for (const testResults of visit.TestsResults) {
                if (testResults.StereoAcuityTestStorageID > 0 && testResults.OpticalCorrection === "SC"){
                    let value=testResults.StereoAcuityResult.DisparityValue
                    categoriesSC.push(dateTimeArr2(new Date(visit.VisitDate)))
                    let stereoTestValue
                    if(testResults.StereoAcuityResult.TestResult.toLowerCase()==="test succeeded"){
                        stereoTestValue= value > 800 ? 1000 : value
                    }else{
                        stereoTestValue= 1001
                    }

                    stereoTestSC.data.push(stereoTestValue===null?"":stereoTestValue)
                    scMaxY=Math.max(stereoTestValue, scMaxY)

                }
                if (testResults.StereoAcuityTestStorageID > 0 && testResults.OpticalCorrection === "CC"){
                    let value=testResults.StereoAcuityResult.DisparityValue

                    categoriesCC.push(dateTimeArr2(new Date(visit.VisitDate)))

                    let stereoTestValue
                    if(testResults.StereoAcuityResult.TestResult.toLowerCase()==="test succeeded"){
                        stereoTestValue=value>800?1000:value
                    }else{
                        stereoTestValue=1001
                    }

                    stereoTestCC.data.push(stereoTestValue===null?"":stereoTestValue)

                    ccMaxY=Math.max(stereoTestValue, ccMaxY)
                }
            }
        }
    }

    let seriesSC=  [stereoTestSC]
    let seriesCC=  [stereoTestCC]
    if(scMaxY===0){
        scMaxY=60
    }
    if(ccMaxY===0){
        ccMaxY=60
    }
    return (

        <div className={'patient_act_charts'}>
            <div className={'patient-dashboard-chart-area'}>
                <div className={'patient-chart-topping'}>
                    <div className={'title-svg'}>
                        <div className={'chart-title'}>{t('ecp.info_filters.without_glasses')}</div>
                        <SvGraphics className={'glasses-style'} svgname={'noglasses'}/>
                    </div>
                </div>
                <div className={'ACT-chart'}>
                    <PatientChart series={seriesSC} chartType={'bar'} colors={['#034e99']} minYValue={0} maxYValue={scMaxY+0.5} tickAmount={4}
                                          xCategories={categoriesSC} type={"stereo"} ytitle={t('general.arcsec')}/>
                </div>
            </div>

            <div className={'patient-dashboard-chart-area'}>
                <div>
                    <div className={'title-svg'}>
                        <div className={'chart-title'}>{t('ecp.info_filters.with_glasses')}</div>
                        <SvGraphics className={'glasses-style'} svgname={'glasses'}/>
                    </div>
                </div>
                <div className={'ACT-chart'}>
                    <PatientChart series={seriesCC} chartType={'bar'} colors={['#034e99']} minYValue={0} maxYValue={ccMaxY+0.5} tickAmount={4}
                                          xCategories={categoriesCC} seriesNum={1} type={"stereo"} ytitle={t('general.arcsec')}/>
                </div>
            </div>
        </div>
    );
}
}
function mapDispatchToProps(dispatch) {
    return {
        filterPatientsTable:(filterType,payload) => dispatch(filterPatientsTable(filterType,payload)),
    };
}
const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData,
        patientsCount:state.patientsTableDataReducer.patientsCount,
        activePatientsCount:state.patientsTableDataReducer.activePatientsCount,
        pendingPatientsCount:state.patientsTableDataReducer.pendingPatientsCount,
        lockedPatientsCount:state.patientsTableDataReducer.lockedPatientsCount,
    };
};

const PatientStereoTestBlock = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(PatientStereoTestBlockConnected)

    export default PatientStereoTestBlock