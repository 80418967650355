import React, {Component} from 'react'
import {connect} from "react-redux";
import MyTable from "../../../components/NovaTable/Table";
import {
    cleanPatient,
    setPatientsDataSelection,
    editPatientData,
    deletePatient,
    filterPatientsTable
} from "../../../redux/actions/Tabs-Actions";
import SvGraphics from "../../../assets/SvGraphics";
import {history} from "../../../utils/history";
import {doctorApi} from "../../../services/ApiService";
import AlertConfirm from "../../../components/modals/Confirm";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import utils from "../../../utils/utils";

class PatientsTabConComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentWillUnmount() {
        this.props.cleanPatient()
    }

    onSelectionChange = (selected) => {
        this.props.setPatientsDataSelection(selected)
    }

    editPatientDetails = (patient) => {
        history.push({
            pathname: `/dashboard/EditPatient`,
            state: {
                patient,
                from: {
                    fromPath: `/dashboard`,
                }
            },
        })
    }

    deletePatient = async (patient) => {
        let {t} = this.props
        let confirm = await AlertConfirm({
            options: {
                extraMessage: t('ecp.patient.table.action_cannot_be_undone'),
                title: t('ecp.patient.table.del_patient'),
                warningRed: true
            }
        }, `${t('ecp.patient.table.confirm_del_patient')}: ${patient.FullName}?`)
        if (confirm) {
            let response = await doctorApi.deletePatient(patient.UserStorageID)
            if (response) {
                let UserStorageID = patient.UserStorageID
                await this.props.deletePatient(UserStorageID)
            }
        }
    }

    addNewPatientVisit = async (row) => {
        let response = await doctorApi.getLastVisitByPatientId(row.UserStorageID)
        if (response) {
            history.push({
                pathname: `/dashboard/NewVisit`,
                state: {
                    visits: response.data,
                    FullName: row.FullName,
                    from: {
                        fromPath: `/dashboard`,
                    }
                },
            })
        }
    }

    setPatientMoreFunctions = () => {
        let {t} = this.props
        return [
            {
                name: 'Edit details',
                display: t('ecp.patient.table.edit_details'),
                call: (row => this.editPatientDetails(row))
            },
            {
                name: 'Delete patient',
                display: t('ecp.patient.table.del_patient'),
                call: (row => this.deletePatient(row))
            }/*,
            {
                name: 'Add new visit',
                display: t('ecp.patient.table.new_visit'),
                call: (row => this.addNewPatientVisit(row))
            }*/
        ]
    }

    onRowClick = (rowInfo) => {
        history.push({
            pathname: `/dashboard/Patient`,
            state: {
                rowInfo,
                completed: false,
                from: {
                    fromPath: `/dashboard`,
                }
            },
        })
    }

    onFilterDataSelected = (filter) => {
        this.props.filterPatientsTable(Object.keys(filter)[0], Object.values(filter)[0])
    }

    getColumns = () => {
        let {t} = this.props
        return [
         /*   {
                accessor: 'UserStorageID',
                Header: t('ecp.patient.table.patient_id'),
                resizable: false,
                width: 130,
                Cell: ({original}) => {
                    return (<div onClick={e => {
                            e.stopPropagation();
                            this.onRowClick(original)
                        }} style={{/!*fontWeight:'550',*!/cursor: 'pointer', width: 'fit-content'}}>
                            {original.UserStorageID}
                        </div>
                    );
                }
            },*/
            {
                accessor: 'LastName',
                Header: t('ecp.patient.table.last_name'),
                resizable: false,
                Cell: ({original}) => {
                    return (
                        <div onClick={e => {
                            e.stopPropagation();
                            this.onRowClick(original)
                        }} style={{/*fontWeight:'550',*/cursor: 'pointer', width: 'fit-content'}}>
                            {original.LastName}
                        </div>
                    );
                }
            },
            {
                accessor: 'FirstName',
                Header: t('ecp.patient.table.first_name'),
                resizable: false,
                Cell: ({original}) => {
                    return (
                        <div onClick={e => {
                            e.stopPropagation();
                            this.onRowClick(original)
                        }} style={{/*fontWeight:'550',*/cursor: 'pointer', width: 'fit-content'}}>
                            {original.FirstName}
                        </div>
                    );
                }
            },
            {accessor: 'SocialID', Header: t('ecp.patient.table.socialID'), resizable: false,
                Cell: ({original}) => {
                    return (
                        <div onClick={e => {
                            e.stopPropagation();
                            this.onRowClick(original)
                        }} style={{/*fontWeight:'550',*/cursor: 'pointer', width: 'fit-content'}}>
                            {original.SocialID}
                        </div>
                    );
                }},//

            {accessor: 'BirthdateVal', Header: t('ecp.patient.table.dob'), resizable: false, Cell: ({original}) => {
                    if (!!original.Birthdate)
                        return (
                            <div onClick={e => {
                                e.stopPropagation();
                                this.onRowClick(original)
                            }} style={{/*fontWeight:'550',*/cursor: 'pointer', width: 'fit-content'}}>
                                {utils.getDateFormatWithYear(new Date(original.Birthdate))}
                            </div>
                        )
                    else return ''
                }},
            {accessor: 'Gender', Header: t('ecp.patient.table.gender'), resizable: false,Cell: ({original}) => {
                    return (
                        <div onClick={e => {
                            e.stopPropagation();
                            this.onRowClick(original)
                        }} style={{/*fontWeight:'550',*/cursor: 'pointer', width: 'fit-content'}}>
                            {original.Gender}
                        </div>
                    );
                }},
            {accessor: 'LastVisit', Header: t('ecp.patient.table.last_visit'), resizable: false, Cell: ({original}) => {
                    return (
                        <div onClick={e => {
                            e.stopPropagation();
                            this.onRowClick(original)
                        }} style={{/*fontWeight:'550',*/cursor: 'pointer', width: 'fit-content'}}>
                            {original.LastVisit?utils.getDateFormatWithYear(new Date(original.LastVisit)):''}
                        </div>
                    );
                }},
            {accessor: 'Category', Header: t('ecp.patient.table.category'), resizable: false,Cell: ({original}) => {
                    return (
                        <div onClick={e => {
                            e.stopPropagation();
                            this.onRowClick(original)
                        }} style={{/*fontWeight:'550',*/cursor: 'pointer', width: 'fit-content'}}>
                            {original.Category}
                        </div>
                    );
                }},

          /*  {
                accessor: 'Status', Header: t('ecp.patient.table.status'), resizable: false, Cell: ({original}) => {
                    if (original.Status === 1) {
                        return (<SvGraphics svgname={'warning-red'} style={{width: '17px', marginLeft: '15px'}}/>)
                    }
                    return ''
                }, width: 90
            }*/
        ]
    }

    render() {
        let columns = this.getColumns()
        return (
            <div className={'context-area'}>
                <MyTable
                    onRowClick={original=>this.onRowClick(original)}
                    data={this.props.patientsData}
                    initialData={this.props.initiatePatientsData}
                    columns={columns}
                    onSelectionChange={selected => this.onSelectionChange(selected)}
                    onFilterDataSelected={filter => this.onFilterDataSelected(filter)}
                    moreFunctions={this.setPatientMoreFunctions()}
                    hasSelection={true}
                    hasMore={true}
                    hasShowInfo={false}
                />
            </div>

        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        cleanPatient: () => dispatch(cleanPatient()),
        setPatientsDataSelection: patientsData => dispatch(setPatientsDataSelection(patientsData)),
        editPatientData: patientData => dispatch(editPatientData(patientData)),
        deletePatient: patientData => dispatch(deletePatient(patientData)),
        filterPatientsTable: (type, payload) => dispatch(filterPatientsTable(type, payload)),
    };
}

const mapStateToProps = state => {
    return {
        patientsData: state.patientsTableDataReducer.patientsData,
        initiatePatientsData: state.patientsTableDataReducer.initiatePatientsData
    };
};

const PatientsTab = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(PatientsTabConComposed);

export default PatientsTab;
