function ConstantsArrT (t,arr) {

    let options = []
    arr.forEach(obj => {
        if(obj){
            let label = obj.label
            options.push({label:t(label),value:obj.value})
        }

    })

    return options;
}

function ConstantsObjT (t,obj) {
    if(obj) {
        let label = obj.label
        return {label: t(label), value: obj.value}
    }
}

export {ConstantsArrT,ConstantsObjT}
