import React, {Component} from 'react'
import PropTypes from "prop-types";
import FormElementContainer from "../forms_builder/FormElementContainer";
import PersonalNotes from "../forms_builder/PersonalNotes";
import '../../components_style/PatientFormPage1.css'
import '../../components_style/rtl_css/PatientFormPage1.css'
import Address from "../forms_builder/Address";
import PatientRemoteTesting from "../forms_builder/PatientRemoteTesting";
import PatientDetails from "../forms_builder/PatientDetails";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

class PatientFormPage1Connected extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {

    }


    onValueChange = (name,value) => {
        this.props.onValueChange(name,value)
    }

    render() {

        let {t}=this.props
        return (
            <div className={'nova-patient-form-body container'}>
                <FormElementContainer title={t('ecp.patient.form.patient_det')}
                                      Component={PatientDetails}
                                      rest={{
                                          onValueChange:(name,value) => this.onValueChange(name,value) ,
                                          entry:this.props.entry,
                                          t:this.props.t,
                                          errors:this.props.errors
                                      }}/>

                <FormElementContainer title={t('ecp.patient.form.remote_testing')}
                                      Component={PatientRemoteTesting}
                                      rest={{
                                          onValueChange:(name,value) => this.onValueChange(name,value) ,
                                          entry:this.props.entry,
                                          errors:this.props.errors,
                                          t:this.props.t,

                                      }}/>

                <FormElementContainer title={t('ecp.patient.form.address')}
                                      Component={Address}
                                      rest={{
                                          onValueChange:(name,value) => this.onValueChange(name,value) ,
                                          entry:this.props.entry,
                                          t:this.props.t,
                                          errors:this.props.errors
                                      }}/>

                <FormElementContainer title={t('ecp.patient.form.notes')}
                                      Component={PersonalNotes}
                                      rest={{
                                          onValueChange:(name,value) => this.onValueChange(name,value) ,
                                          entry:this.props.entry,
                                          errors:this.props.errors
                                      }}/>

            </div>
        )
    }
}

PatientFormPage1Connected.propTypes = {
    onValueChange:PropTypes.func.isRequired,
    entry: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool
}


const PatientFormPage1 = compose(
    withTranslation(),
    connect(
    )
)(PatientFormPage1Connected)

export default PatientFormPage1;
