import {BehaviorSubject} from 'rxjs';
import {AllUsersApi} from "./ApiService";
import Translate from "../utils/Translate";

const currentUserSubject = new BehaviorSubject();
const mfaUserSubject = new BehaviorSubject();
const pwdUserSubject = new BehaviorSubject();
let userLoggedIn = false;
let mfaUserLoggedIn = false
let pwdUserLoggedIn = false;
export const authenticationService = {
    login,
    logout,
    submit2fa,
    changeNewPassword,
    resetPassword,
    userLoggedIn,
    mfaUserLoggedIn,
    pwdUserLoggedIn,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    },
    currentMfaUser: mfaUserSubject.asObservable(),
    get currentMfaUserValue() {
        return mfaUserSubject.value
    },
    pwdUser: pwdUserSubject.asObservable(),
    get pwdUserValue() {
        return pwdUserSubject.value
    }
};

async function login(username, password) {

    let response = await AllUsersApi.signIn(username, password);
    if (response.status < 400) {
        let data = response.data
        // utils.set(data,'status','pass')
        // utils.set(data,'updatePassword',true)
        if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === '2fa'){
            mfaUserLoggedIn = true;
            mfaUserSubject.next(data);
        } else if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === 'pass'){
            Translate.setExternal(data.language)
            if (data.hasOwnProperty('updatePassword') && data['updatePassword'] === true){
                pwdUserSubject.next(data)
                pwdUserLoggedIn = true
            } else  if (data.hasOwnProperty('updatePassword') && data['updatePassword'] === false){
                userLoggedIn = true;
                currentUserSubject.next(data);
            }
        }
    }

    return response;
}

async function submit2fa(mfaCode) {
    let response = await AllUsersApi.submit2fa(mfaCode);
    if (response.status < 400) {
        let data = response.data
        if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === 'pass'){
            Translate.setExternal(data.language)
            if (data.hasOwnProperty('updatePassword') && data['updatePassword'] === true){
                pwdUserSubject.next(data)
                pwdUserLoggedIn = true
            } else  if (data.hasOwnProperty('updatePassword') && data['updatePassword'] === false){
                userLoggedIn = true;
                currentUserSubject.next(data);
            }
            mfaUserSubject.next(null);
            mfaUserLoggedIn = false
        }
    }

    return response;
}

async function changeNewPassword(password,TermsOfUseSigned) {
    let response = await AllUsersApi.changeNewPassword(password,TermsOfUseSigned);

    if (response && response.hasOwnProperty('data') &&
        response.data.hasOwnProperty('newPassword') &&
        response.data['newPassword'] === true) {
        let val =  Object.assign({},pwdUserSubject.value)
        pwdUserSubject.next(null);
        pwdUserLoggedIn = false
        currentUserSubject.next(val);
        userLoggedIn = true;
    }
    return response;
}

async function resetPassword(username,) {
    return await AllUsersApi.resetPassword(username);
}

function logout() {
    document.getElementById('root').click();
    userLoggedIn = false;
    currentUserSubject.next(null);
}
