import React, {Component} from 'react'
import ReturnNav from "../../components/single_components/ReturnNav";
import PatientFormNavigator from "../../components/patient_profile/PatientFormNavigator";
import '../../components_style/rtl_css/PatientForm.css'
import '../../components_style/PatientForm.css'
import utils from "../../utils/utils";
import PatientFormPage1 from "../../components/patient/PatientFormPage1";
import ConstantsUtils from "../../utils/ConstantsUtils";
import {doctorApi} from "../../services/ApiService";
import {connect} from "react-redux";
import * as Yup from 'yup';
import {addNewPatient} from "../../redux/actions/Tabs-Actions";
import validationHelper from "../../utils/validationHelper";
import {makeError} from "../../components/modals/ErrorModal";
import Constants from "../../utils/constants";
import AlertConfirm from "../../components/modals/Confirm";
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class PatientFormConnected extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entry: {},
            errors: {},
            errorsOnSave:{},
            validationSchema:null
        }
    }


    async componentWillMount() {
        await this.initValidation()
    }


    initValidation = async () => {

        let validationSchema = Yup.object().shape({
            FirstName:Yup.string().required('First Name is required'),
            LastName:Yup.string().required('Last Name is required'),
            Gender:Yup.string()/*.required('Gender is required')*/,
            Birthdate:Yup.string().required('Birth date is required'),
            PhoneNumber:Yup.string(),
            Email:Yup.string().matches(Constants.emailRegExp, 'Email is not valid'),
            Country:Yup.string()/*.required('Country is required')*/,
            // State: Yup.string().when("Country", {
            //     is: value => {
            //         let us = ["us","u.s","u.s.","usa","u.s.a","u.s.a.","united states","united states of america"]
            //         return us.includes(String(value).toLowerCase())
            //     },
            //     then: Yup.string().required(
            //         "State name in the U.S. is required"
            //     ).nullable(),
            //     otherwise: Yup.string().nullable()
            // })
        })

        let errors = await validationHelper.validate(this.state.entry,validationSchema)
        await this.setState({
            validationSchema,
            errors
        })

    }

    onNavBack = async (val) => {
        let confirm = await AlertConfirm({
            options:{
                title: `Withdraw new Patient's data`,
            }},`Are you sure you want to withdraw the new patient's data?`)
        if (confirm) {
            this.props.history.goBack()
        }
    }

    onValueChange = async (name,value) => {
        let {entry,validationSchema} = this.state
        utils.set(entry,name,value)
        let entryValidator = utils.removeEmptyOrNull(entry)
        let errors = await validationHelper.validate(entryValidator, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;
        if (utils.get(errorsOnSave,name)){
            utils.set(errorsOnSave,name,utils.get(errors,name))
        }
        this.setState({
            entry,
            errors,
            errorsOnSave
        })
    }


    savePatient = async () => {
        let {t}=this.props
        if (!utils.isEmpty(this.state.errors)){
            this.setState({errorsOnSave:this.state.errors});
            await makeError({
                proceedLabel: 'Ok',
                options:{
                    title: 'Could not save patient',
                }
            },[t('general.Fill_all_required')]);
            return;
        }
        let {entry} = this.state
        let confirm = await AlertConfirm({
            options:{
                title: `Add new patient`,
            }},`Are you sure you want to add a new patient: ${entry.FirstName + ' ' + entry.LastName}?`)
        if (confirm) {
            entry = utils.ObjectEmptiesToNulls(entry)
            entry['VisitDate'] = (new Date()).toISOString()
            entry['NextVisitDate'] = (new Date()).toISOString()
            let response = await doctorApi.newPatient(entry)
            if (response){
                this.props.addNewPatient(response.data)
                this.props.history.goBack()
                this.setState({entry:{}})
            }
        }
    }

    render() {
        let {t}=this.props
        let {errorsOnSave} = this.state
        return (
            <div className={'Patient-form'}>
                <ReturnNav onNavBackClick={this.onNavBack.bind(this)}/>
                <div className={'nova-patient-form-header'}>
                    <div className={'nova-patient-form-navigator-title v-centered'}>{t('ecp.patient.form.add_new')}</div>
                    <PatientFormNavigator/>
                </div>
                <PatientFormPage1 onValueChange={(name, value) => this.onValueChange(name, value)}
                                  entry={this.state.entry}
                                  errors={errorsOnSave}/>
                <div className={'nova-patient-form-footer container'}>
                    <button type="button" className="btn btn-primary Patient-Form-next-button" onClick={() => this.savePatient()}>{t('ecp.patient.form.save')}</button>
                </div>

                <div className={'nova-patient-form-footer container'}/>
            </div>

        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addNewPatient: patientData => dispatch(addNewPatient(patientData)),
    };
}

const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData
    };
};


const PatientForm = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(PatientFormConnected)

export default PatientForm;

