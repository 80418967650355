import axios from 'axios'
import Config from "../../config/Config";
import {authenticationService} from "../AuthenticationService";




const AllUsersApi = {
    signIn: async (username, password) => {

            let requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
                }
            };
            let body = {
                "Email":username,
                "Password":password
            };
            try {
                return await axios.post(
                    `${Config.globalUrl}/api/v1/webapp/signin`,
                    body,
                    requestconfig
                );
            } catch (err) {
                return err
            }


    },
    submit2fa: async (code) => {

        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentMfaUserValue.token,
            }
        };
        try {
            return await axios.post(
                `${Config.globalUrl}/api/v1/webapp/signin/2fa`,
                {TwoFaCode: code.split(" ").join("")},
                requestconfig
            );
        } catch (err) {
            return err
        }

    },
    changeNewPassword: async (password,TermsOfUseSigned) => {
         if (!authenticationService.pwdUserValue) return;
        let token = authenticationService.pwdUserValue.token
        let url = `${Config.globalUrl}/api/v1/webapp/users/${authenticationService.pwdUserValue.profile.UserStorageID}/changePass`
        try {
            return  await axios.post(
                url,
                {
                    TermsOfUseSigned,
                    newPassword: password.split(" ").join("")
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token,
                        //'app_client_version': version
                    }
                }
            );
        } catch (err) {
            return err
        }

    },
    resetPassword: async (email) => {
            let requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
                }
            };
            let body = {
                "Email":email,
            };
            try {
                return await axios.post(
                    `${Config.globalUrl}/api/v1/webapp/forgetPass`,
                    body,
                    requestconfig
                );
            } catch (err) {
                return err
            }


    },

    changeLanguage: async (language) => {
        if (!authenticationService.currentUserValue)
            return;
        let user = authenticationService.currentUserValue;

        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return (await axios.post(
            `${Config.globalUrl}/api/v1/webapp/users/${user.profile.UserStorageID}/changeLanguage`,
            language,
            requestconfig
        ));
    },
    selfRegister: async (entry) => {
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return await axios.post(
            `${Config.globalUrl}/api/v1/webapp/users/self/signup`,
            entry,
            requestconfig
        );

    },
};

export default AllUsersApi
