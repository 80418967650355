import React, {Component} from 'react'
import {Redirect, Switch} from 'react-router-dom'
import {rolesConfig} from '../../config/roles'
import Middleware from "../../routes/Middleware"
import * as Routes from '../../routes/index';
import PrivateRoute from "../../routes/PrivateRoute";
import utils from "../../utils/utils";
import {authenticationService} from "../../services/AuthenticationService";
import '../../style/main.css'
class Dashboard extends Component {

    constructor(props) {
        super(props);
        if (!authenticationService.currentUserValue) {
            this.props.history.push('/login');
        }
        this.state = {
            currentUser: null
        }
    }

    componentDidMount = () => {
        authenticationService.currentUser.subscribe(
            currentUser => this.setState(
                {
                    currentUser
                })
        );

    }

    buildRoutes = () => {

        let routes = [];
        let middlewares = ['userAcessRoute'];
        let redirect = null;

        rolesConfig.routes.forEach((obj, index) => {
            if (!utils.isEmpty(Routes[obj.component])) {
                const component = Middleware.routeToDisplay(
                    middlewares,
                    <PrivateRoute
                        key={index}

                        path={obj.url}
                        render={(props) => Routes[obj.component]({props})}/>,
                    {roles: obj.access, isSiteAdminRequired: obj.isSiteAdminRequired}
                );

                if (!utils.isEmpty(component)) {
                    routes.push(component);
                    if (utils.isEmpty(redirect) && obj.exact) {
                        redirect = <PrivateRoute path="/dashboard"
                                                 component={() => <Redirect to={`${obj.url}`}/>}/>
                    }
                }

            }
        });

        routes.push(redirect);

        routes = routes.filter((x) => {
            return !utils.isEmpty(x);
        });

        return routes;
    };

    render() {
        const routes = this.buildRoutes();
        return (
            <div className={'nova-main-container'}>
                <Switch>{routes}</Switch>
            </div>

        )
    }
}

export default Dashboard;
