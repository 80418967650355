import React from 'react'
import {authenticationService} from "../services/AuthenticationService";
import utils from "../utils/utils";


class Middleware {
    routeToDisplay(middlewares = [], routeToVisit = '', extra = {}) {
        const mware = {
            userAcessRoute: (routeToVisit) => this.userAcessRoute(routeToVisit, extra),
        };
        let ret = null;
        try {
            for (let i = 0; i < middlewares.length; i++) {
                ret = mware[middlewares[i]](routeToVisit, extra);
                if (ret.status === false) {
                    break
                }
            }
            return ret.routeObject
        } catch (e) {
            return this._getRouteReturn(false, null).routeObject
        }
    }

    linkToDisplay(middlewares = [], linkToVisit = '', extra = {}) {
        const mware = {
            userAccessLink: (linkToVisit) => this.userAccessLink(linkToVisit, extra),
        };
        let ret = null;
        try {
            for (let i = 0; i < middlewares.length; i++) {
                ret = mware[middlewares[i]](linkToVisit, extra);
                if (ret.status === false) {
                    break
                }
            }
            return ret.routeObject
        } catch (e) {
            return this._getRouteReturn(false, null).routeObject
        }
    }

    _getRouteReturn(status, routeObject) {
        return {status, routeObject}
    }

    userAcessRoute(component, extra) {

        let includes;
        const current = authenticationService.currentUserValue;
        const currentRoles = utils.get(current, 'role') || [];
        const isSiteAdmin = current?.profile?.Doctor?.isSiteAdmin

        if (extra.isSiteAdminRequired && isSiteAdmin) {
            if (Array.isArray(extra.roles)) {
                includes = currentRoles.some(x => extra.roles.includes(x));
            } else {
                includes = (currentRoles || []).some((obj) => {
                    return obj === extra.roles
                });
            }
        } else {
            if (Array.isArray(extra.roles)) {
                includes = currentRoles.some(x => extra.roles.includes(x));
            } else {
                includes = (currentRoles || []).some((obj) => {
                    return obj === extra.roles
                });
            }
        }

        if (includes) {
            return this._getRouteReturn(true, component)
        }
        return this._getRouteReturn(false, null)
    }

    userAccessLink(component, extra) {

        let includes;
        const current = authenticationService.currentUserValue;
        const currentRoles = utils.get(current, 'role') || [];
        const isSiteAdmin = current?.profile?.Doctor?.isSiteAdmin

        if (extra.isSiteAdminRequired && isSiteAdmin){
            if (Array.isArray(extra.roles)) {
                includes = currentRoles.some(x => extra.roles.includes(x));
            } else {
                includes = (currentRoles || []).some((obj) => {
                    return obj === extra.roles
                });
            }
        } else {
            if (Array.isArray(extra.roles)) {
                includes = currentRoles.some(x => extra.roles.includes(x));
            } else {
                includes = (currentRoles || []).some((obj) => {
                    return obj === extra.roles
                });
            }
        }


        if (includes) {
            return this._getRouteReturn(true, component)
        }
        return this._getRouteReturn(false, null)
    }

}

export default new Middleware();
