import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './config/i18n';
import './style/login.css'
import './style/rtl_css/login.css'
//REDUX
import { applyMiddleware, createStore } from 'redux';
import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n";

import reducer from './redux/reducers/index.js'
import {Provider} from "react-redux";
const store = createStore(reducer, /*applyMiddleware(logger)*/);

document.getElementById("eyeswifttitle").innerHTML  =
    process.env.REACT_APP_ENV === "essilorstaging" || process.env.REACT_APP_ENV === "essilorproduction"?"Eye Viz":"Eye Swift";
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  </I18nextProvider>
  , document.getElementById('root')
);

let list = document.getElementsByClassName("btn-primary");
for (let index = 0; index < list.length; ++index) {
  list[index].setAttribute("tabIndex", "-1");
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
