import {
    CLEAN_VERSIONS,
    DELETE_VERSION,
    EDIT_VERSION,
    FILTER_VERSIONSDATA,
    NEW_VERSION,
    VERSIONS_COUNT,
    VERSIONS_DATA,
} from "../../constants/Tabs-Action-Types";
import ConstantsUtils from "../../../utils/ConstantsUtils";
import utils from "../../../utils/utils";


const initialState = {
    initiateVersionData:[],
    versionData:[],
    versionsCount:0,
    versionTableFilter:{},
};

const versionTableDataReducer = (state = initialState, action)=> {

    switch(action.type){
        case VERSIONS_DATA:
            let collection = []
            action.payload.forEach(version =>{
                collection.push({
                    id:version.SystemVersionID,
                    SystemVersionID: version.SystemVersionID,
                    VersionType: version.VersionType,
                    forceUpdate: version.forceUpdate,
                    VersionNO: version.VersionNO,
                    VersionNOInt: (version.VersionNO.split('.')).join(''),
                    VersionURL: version.VersionURL,
                    Type: ConstantsUtils.getVersionTypeByVal(version.VersionType),
                    Date: utils.getDateFormatWithYear(new Date(version.createdAt)),
                    createdAt: version.createdAt,
                    AdminID: version.AdminID,
                    FullName: version.PII.FirstName + ' ' + version.PII.LastName
                })
            })

            let _pdata = JSON.parse(JSON.stringify(collection));
            state = {
                ...state,
                initiateVersionData:_pdata,
                versionData : _pdata,
                versionsCount: collection.length
            };
            break;
        case CLEAN_VERSIONS:{
            state = {
                ...state,
                versionTableFilter: {}
            };
            break;
        }
        case FILTER_VERSIONSDATA:{
            let type = action.searchType
            let payload = action.payload
            let versionTableFilter = state.versionTableFilter
            let filteredVersionData = state.initiateVersionData
            let visibleFilterData = []
            let visiableData = []

            if (type === 'search' && (payload === '' || payload === null)){
                delete versionTableFilter.search
            } else {
                utils.set(versionTableFilter,type,payload)
            }

            for (const [key, value] of Object.entries(versionTableFilter)) {
                if (key === 'search'){
                    let val = value.toLowerCase()
                    filteredVersionData = filteredVersionData.filter(x =>
                        String(x.VersionType).toLowerCase().indexOf(val) > -1 ||
                        String(x.VersionNO).toLowerCase().indexOf(val) > -1 ||
                        String(x.VersionURL).toLowerCase().indexOf(val) > -1 ||
                        String(x.Date).toLowerCase().indexOf(val) > -1 ||
                        String(x.AdminID).toLowerCase().indexOf(val) > -1 /*||
                        String(x.FullName).toLowerCase().indexOf(val) > -1*/
                    )
                    visibleFilterData = filteredVersionData
                } else {
                    let versions = JSON.parse(JSON.stringify(filteredVersionData))
                    let valArr = JSON.parse(JSON.stringify(value))


                    if (Array.isArray(valArr)){
                        let res = []
                        valArr.forEach(val => {
                            let versionArr = versions.filter(version => {
                                let versionVal = version[key]
                                return String(versionVal).toLowerCase() === String(val).toLowerCase()
                            })
                            versionArr.forEach(version => res.push(version))
                        })
                        filteredVersionData = res
                    }
                }
            }
            visiableData = filteredVersionData

            let _fpdata = JSON.parse(JSON.stringify(visibleFilterData));
            let _vdata = JSON.parse(JSON.stringify(visiableData));
            let _pfdata = JSON.parse(JSON.stringify(versionTableFilter));
            state = {
                ...state,
                filteredVersionData: _fpdata,
                versionData: _vdata,
                versionTableFilter: _pfdata
            };
            break;

        }
        case NEW_VERSION:{
            let version = action.payload
            let versionData = state.versionData
            let initiateVersionData = state.initiateVersionData

            let newData = [{
                id:version.SystemVersionID,
                SystemVersionID: version.SystemVersionID,
                VersionType: version.VersionType,
                VersionNO: version.VersionNO,
                VersionNOInt: (version.VersionNO.split('.')).join(''),
                forceUpdate: version.forceUpdate,
                VersionURL: version.VersionURL,
                Type: ConstantsUtils.getVersionTypeByVal(version.VersionType),
                Date: utils.getDateFormatWithYear(new Date(version.createdAt)),
                createdAt: version.createdAt,
                AdminID: version.AdminID,
            }]


            let newversionData = versionData.concat(newData)
            let newinitiateVersionData = initiateVersionData.concat(newData)

            let _pdata = JSON.parse(JSON.stringify(newversionData));
            let _idata = JSON.parse(JSON.stringify(newinitiateVersionData));
            state = {
                ...state,
                initiateVersionData:_idata,
                versionData : _pdata,
                versionsCount: newinitiateVersionData.length
            };
            break;
        }
        case EDIT_VERSION: {
            let version = action.payload
            let versionData = state.versionData
            let initiateVersionData = state.initiateVersionData

            let indexData = versionData.findIndex((obj => obj.SystemVersionID === version.SystemVersionID));
            let indexInit = initiateVersionData.findIndex((obj => obj.SystemVersionID === version.SystemVersionID));

            versionData[indexData]['VersionType']= version.VersionType
            versionData[indexData]['Type']= ConstantsUtils.getVersionTypeByVal(version.VersionType)
            versionData[indexData]['VersionNO']= version.VersionNO
            versionData[indexData]['VersionNOInt']=(version.VersionNO.split('.')).join('')
            versionData[indexData]['VersionURL']= version.VersionURL
            versionData[indexData]['forceUpdate']= version.forceUpdate

            initiateVersionData[indexInit]['VersionType']= version.VersionType
            initiateVersionData[indexInit]['Type']= ConstantsUtils.getVersionTypeByVal(version.VersionType)
            initiateVersionData[indexInit]['VersionNO']= version.VersionNO
            initiateVersionData[indexInit]['VersionNOInt']= (version.VersionNO.split('.')).join('')
            initiateVersionData[indexInit]['VersionURL']= version.VersionURL
            initiateVersionData[indexInit]['forceUpdate']= version.forceUpdate

            let _pdata = JSON.parse(JSON.stringify(versionData));
            let _idata = JSON.parse(JSON.stringify(initiateVersionData));
            state = {
                ...state,
                initiateVersionData:_idata,
                versionData : _pdata,
            };
            break;
        }
        case DELETE_VERSION: {

            let SystemVersionID = action.payload
            let versionData = state.versionData
            let initiateVersionData = state.initiateVersionData

            let newversionData = versionData.filter(version => version.SystemVersionID !== SystemVersionID)
            let newinitiateVersionData = initiateVersionData.filter(version => version.SystemVersionID !== SystemVersionID)

            let _pdata = JSON.parse(JSON.stringify(newversionData));
            let _idata = JSON.parse(JSON.stringify(newinitiateVersionData));
            state = {
                ...state,
                initiateVersionData:_idata,
                versionData : _pdata,
                versionsCount: newinitiateVersionData.length
            };
            break;
        }
        case VERSIONS_COUNT:{
            let _idata = JSON.parse(JSON.stringify(action.payload));
            state = {
                ...state,
                versionsCount: _idata
            };
            break;
        }
        default:
            break;
    }
    return state;
};
export default versionTableDataReducer;
