import React, {Component} from 'react'
import {authenticationService} from '../../services/AuthenticationService';

import SvGraphics from "../../assets/SvGraphics";
import {history} from "../../utils/history";
import * as Yup from "yup";
import validationHelper from "../../utils/validationHelper";
import utils from "../../utils/utils";
import Error from "../../components/single_components/Error";
import Constants from "../../utils/constants";
import Config from "../../config/Config";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import Reaptcha from 'reaptcha';

class LoginComposed extends Component {
    constructor(props) {
        super(props);

        if (authenticationService.currentUserValue) {
            this.props.history.push('/dashboard');
        }

        this.state = {
            username:'',
            password:'',
            auth: {},
            isErr:false,
            errMessage:'',
            errors: {},
            errorsOnSave:{},
        }
        this.recaptchaRef = React.createRef();
    }

    initValidation = async () => {
        let {t} = this.props
        let validationSchema = Yup.object().shape({
            username:Yup.string().min(6,t('defaults.login.username_empty_inv')).matches(Constants.emailRegExp, t('defaults.login.username_inv')).required(t('defaults.login.username_req')),
            password: Yup.string().required(t('defaults.login.pwd_req')),
            //.matches(Config.passwordRegExp,'Password must contain at least one of: a-z, A-z, 0-9 and (!,@,#,$,%,^,*,) with length of 8-20')
        })
        let errors = await validationHelper.validate(this.state.auth,validationSchema)
        await this.setState({
            validationSchema,
            errors
        })
    }

    async componentWillMount() {
        await this.initValidation()
    }

    onSubmit = async () => {
        if (!utils.isEmpty(this.state.errors)){
            this.setState({errorsOnSave:this.state.errors});
            return;
        }
        try {
            await this.recaptchaRef.execute();
        } catch (err) {
            this.setState({
                isErr: true,
                errMessage: 'Blocked By ReCAPTCHA',
            })
        }
    }

    submitData = async () => {
        let {username,password} = this.state
        let response = await authenticationService.login(username, password);

        if (response.status < 400) {
            let data = response.data
            // utils.set(data,'status','pass')
            // utils.set(data,'updatePassword',true)
            if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === 'pass') {
                if (data.hasOwnProperty('updatePassword') && data['updatePassword'] === true){
                    const {from} = {from: {pathname: "/create-password"}};
                    this.props.history.push(from);
                } else  if (data.hasOwnProperty('updatePassword') && data['updatePassword'] === false){
                    const {from} = {from: {pathname: "/dashboard"}};
                    this.props.history.push(from);
                }
            } else if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === '2fa'){
                const {from} = {from: {pathname: "/2FA"}};
                this.props.history.push(from);
            } else {
                await this.setUserName({target: {value: ''}})
                await this.setPassword({target: {value: ''}})
                this.setState({
                    isErr: true,
                    errMessage: response.data && response.data.message? response.data.message : 'No connection to server',
                })
            }
        } else {
            await this.setUserName({target: {value: ''}})
            await this.setPassword({target: {value: ''}})
            this.setState({
                isErr: true,
                errMessage: response.data && response.data.message ? response.data.message : 'No connection to server',
            })
        }
    }

    setUserName = async (e) => {
        let {auth,validationSchema} = this.state
        this.setState({username:e.target.value})
        utils.set(auth,'username',e.target.value)
        let errors = await validationHelper.validate(auth, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;
        if (utils.get(errorsOnSave,'username')){
            utils.set(errorsOnSave,'username',utils.get(errors,'username'))
        }
        this.setState({
            auth,
            errors,
            errorsOnSave,
            isErr:false,errMessage:''
        })
    }

    setPassword = async (e) => {
        let {auth,validationSchema} = this.state
        this.setState({password:e.target.value})
        utils.set(auth,'password',e.target.value)
        let errors = await validationHelper.validate(auth, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;
        if (utils.get(errorsOnSave,'password')){
            utils.set(errorsOnSave,'password',utils.get(errors,'password'))
        }
        this.setState({
            auth,
            errors,
            errorsOnSave,
            isErr:false,errMessage:''
        })
    }

    resetPassword = () => {
        history.push({
            pathname: '/ResetPassword',
            state: {
                Email:this.state.username,
                from: {
                    fromPath: `/login`,
                }
            },
        })
    }

    render() {
        let {t} = this.props
        let SVGName ='eye_swift_blue'
        let bottomSVG= <SvGraphics svgname={'nova_sight'} className={'nova-sight-login-logo'} style={{width:'183px'}}/>
        if(process.env.REACT_APP_ENV === "essilorstaging" || process.env.REACT_APP_ENV === "essilorproduction" )
        {
             SVGName ='eyeviz'
             bottomSVG =<img src={require('../../assets/Essilor-img.png')} style={{width:'500px'}} />
        }

        return (
            <div className={"login-page"}>
                <div className={'h-centered eye-swift-login-logo-container'}>
                    <SvGraphics svgname={SVGName} className={'eye-swift-login-logo'} style={{width:'340px'}}/>
                </div>
                <div className={'centered eye-swift-login-main-board'}>
                    <label className={'eye-swift-login-label'}>{t('defaults.login.login')}</label>
                    <div className={'eye-swift-login-form-container'}>
                        <div className={'h-centered eye-swift-login-form'}>
                            <Error isNonFloat={true} errorMessage={utils.get(this.state.errorsOnSave ,'username')} isShown={!!utils.get(this.state.errorsOnSave ,'username')}/>
                            <div className={"login-input-wrapper form-group eye-swift-login-form-group"}>
                                <input className={"nova-input nova-form-input eye-swift-login-input-field"}
                                       placeholder={t('defaults.login.username')}
                                       type={"text"}
                                       name={"Username"}
                                       id={"search"}
                                       value={this.state.username}
                                       onChange={e => this.setUserName(e)}
                                       onKeyPress={event => {
                                           if (event.key === 'Enter') {
                                               this.onSubmit()
                                           }
                                       }}/>
                                <SvGraphics className={"input-pic"} svgname={'person'} style={{width: '20px', height: '20px'}}/>
                            </div>
                            <Error isNonFloat={true} errorMessage={utils.get(this.state.errorsOnSave ,'password')} isShown={!!utils.get(this.state.errorsOnSave ,'password')}/>
                            <div className={"login-input-wrapper form-group eye-swift-login-form-group"}>
                                <input className={"nova-input nova-form-input eye-swift-login-input-field"}
                                       placeholder={t('defaults.login.pwd')}
                                       type={"password"}
                                       name={""}
                                       value={this.state.password}
                                       onChange={e => this.setPassword(e)}
                                       onKeyPress={event => {
                                           if (event.key === 'Enter') {
                                               this.onSubmit()
                                           }
                                       }}/>
                                <SvGraphics className={"input-pic"} svgname={'lock'} style={{width: '20px', height: '20px'}}/>
                            </div>
                            <div className="form-group eye-swift-login-form-group">
                                <button className="btn h-centered btn-primary eye-swift-login-form-button" onClick={this.onSubmit}>{t('defaults.login.login')}</button>
                            </div>

                        </div>
                    </div>
                    <div className={'eye-swift-login-form-group loginError red h-centered'}>
                        <Error isNonFloat={true} errorMessage={t('defaults.login.auth_err') + ': ' + this.state.errMessage} isShown={this.state.isErr}/>
                    </div>
                    <div className={'h-centered eye-swift-login-footer-container'} onClick={this.resetPassword.bind(this)}>
                        <a>{t('defaults.login.forgot_pwd')}?</a>
                    </div>
                    <div className={'h-centered eye-swift-login-footer-container eye-swift-device-register'} onClick={() => {
                        history.push({
                            pathname: '/register',
                            state: {
                                Email:this.state.username,
                                from: {
                                    fromPath: `/login`,
                                }
                            },
                        })
                    }}>
                        <a>{t('defaults.login.Register')}</a>
                    </div>
                </div>
                <div className={'h-centered nova-sight-login-logo-container'}>
                    {/*<SvGraphics svgname={SVGLogo} className={'nova-sight-login-logo'} style={{width:'183px'}}/>*/}
                    {bottomSVG}
                </div>

                <div className={'login-version-element'}>
                    <label className={'login-version-element-label'}>Version:</label>
                    <label className={'login-version-element-value'}>{Config.version}</label>
                </div>
                <Reaptcha
                    ref={ref => (this.recaptchaRef = ref)}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    size={'invisible'}
                    explicit={false}
                    onVerify={token => {
                        if (!!token) this.submitData()
                        else {
                            this.setState({
                                isErr: true,
                                errMessage: 'Blocked By ReCAPTCHA',
                            })
                        }
                    }}
                    onExpire={() => {this.setState({
                        isErr: true,
                        errMessage: 'ReCAPTCHA Expired, please refresh the page',
                    })}}
                    onError={err => {this.setState({
                        isErr: true,
                        errMessage: err,
                    })}}
                />
            </div>

        )
    }
}
let Login = compose(
    withTranslation()
)(LoginComposed)
export default Login;
