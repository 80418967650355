import React from 'react';
import {Redirect, Route} from 'react-router-dom';

import {authenticationService} from "../services/AuthenticationService";
import NotFound from "../views/Error/NotFound";

export const PrivateRoute = ({component: Component = null, render: Render = null, ...rest}) => (
    <Route
        {...rest}
        render={props => {

            const currentUser = authenticationService.currentUserValue;
            if (!currentUser) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
            }
            return Render ? (Render(props)) : Component ? (<Component {...props} />) : (<NotFound/>)
        }}
    />
);

export default PrivateRoute
