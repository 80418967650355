import AllUsersApi from "./ApiServices/AllUsersApi";
import adminApi from "./ApiServices/adminApi";
import doctorApi from "./ApiServices/doctorApi";
import axios from "axios";
import {makeError} from "../components/modals/ErrorModal";
import {history} from "../utils/history";
import {authenticationService} from "./AuthenticationService";
export {
    AllUsersApi,
    adminApi,
    doctorApi
};

// declare a response interceptor
axios.interceptors.response.use(
    async (response) => {
            return response;
        },
    async (error) => {
    if (error.response) { // client received an error response (5xx, 4xx)
        // keys to identify request
        let method = String(error.response.config.method).toLowerCase()
        let endpoint = String(error.response.config.url)
            .replace(/http[s]+\:\/\/(api\-[a-z]+\.eye-swift\.com|localhost|127\.0\.0\.1)\/api\/v1\/webapp\//,'')
        let response = error.response

        // Error building tools
        let title = 'Unknown Error'
        let messageResponse= response.data.message

        let errors = response.data.body
        let messages = []

        if (Array.isArray(errors)){
            errors.forEach(err => messages.push(err.message.replace(/"/g, "")))
        } else {
            if (response.hasOwnProperty('data') && response.data.hasOwnProperty('message'))
            messages.push(response.data.message.replace(/"/g, ""))
            else if (response.hasOwnProperty('data'))
            messages.push(String(response.data).replace(/"/g, ""))
            else {
                messages.push(String(response).replace(/"/g, ""))
            }
        }
        // find request by endpoint and modify err popup accordingly
        if (endpoint.includes('users') && endpoint.includes('self') && endpoint.includes('signup')){
            title = 'Self registration'
            messages = messages? messages: ['Could not register site, Please try again later']
            await makeError({proceedLabel: 'Ok', options:{title}},messages);
            if(messageResponse.toLowerCase() === "no such user"){
                await authenticationService.logout()
                history.push('/login')
            }
            if (endpoint.includes('signup')&& (endpoint.includes('site') || endpoint.includes('sites'))){
                messages = ['Site Name must be unique']
                await authenticationService.logout()
                history.push('/login')
            }
        } else if (endpoint.includes('changePass') || endpoint.includes('signin') || endpoint.includes('forgetPass')){
            return Promise.reject(error.response)
        } else if (endpoint.includes('eyeSwiftAdmins')){
            if (endpoint.includes('site') || endpoint.includes('sites')){
                if (endpoint.includes('signup')){
                    title = 'Site was not created'
                    messages = ['Site Name must be unique']
                } else if (endpoint.includes('profile') && method.includes('post')){
                    title = 'Site was not edited'
                    messages =error.response.data.message.includes('Validation')? ['Site Name must be unique']:[error.response.data.message]
                } else if (endpoint.includes('getAllSites')){
                    title = 'Sites'
                    messages = messages? messages: ['Could not get sites, Please try again later']
                }else if (method.includes('delete')){
                    title = 'Failed to delete Site'
                    messages = messages? messages: ['Could not delete site']
                }
            } else if (endpoint.includes('doctors')){
                if (endpoint.includes('signup')){
                    title = 'ECP was not created'
                    messages = ['Email must be unique']
                } else if (endpoint.includes('profile') && method.includes('post')){
                    title = 'ECP was not edited'
                    messages = ['Email must be unique']
                } else if (endpoint.includes('getAllECP')){
                    title = 'Eye Care Providers'
                    messages = messages? messages: ['Could not get eye care providers, Please try again later']
                }
            } else if (endpoint.includes('admins')){
                if (endpoint.includes('signup')){
                    title = 'Admin was not created'
                    messages = ['Email must be unique']
                } else if (endpoint.includes('profile') && method.includes('post')){
                    title = 'Admin was not edited'
                    messages = ['Email must be unique']
                } else if (endpoint.includes('profile') && method.includes('delete')){
                    title = 'Admin was not deleted'
                    messages = messages? messages: ['Could not delete admin, Please try again later']
                } else if (endpoint.includes('getAllAdmins')){
                    title = 'Admins'
                    messages = messages? messages: ['Could not get admins, Please try again later']
                }
            } else if (endpoint.includes('devices')){
                if (endpoint.includes('getAllDevices')){
                    title = 'Devices'
                    messages = messages? messages: ['Could not get devices, Please try again later']
                }
            } else if (endpoint.includes('versions')){
                if (endpoint.includes('addNewVersion')){
                    title = `Version was not created`
                    messages = ['Could not creat version']
                } else if (endpoint.includes('editVersion')){
                    title = 'Version was not edited'
                    messages = ['Could not edit version']
                } else if (endpoint.includes('getAllVersions')){
                    title = 'Versions'
                    messages = messages? messages: ['Could not get versions, Please try again later']
                } else if (method.includes('delete')){
                    title = 'Delete version'
                    messages = ['Could not delete version']
                }
            } if (endpoint.includes('patients') && endpoint.includes('sendMessages')){

            } else if (endpoint.includes('doctors') && endpoint.includes('sendMessages')){

            }
            await makeError({proceedLabel: 'Ok', options:{title}},messages);
            if(messageResponse.toLowerCase() === "no such user"){
                await authenticationService.logout()
                history.push('/login')
            }
        } else if (endpoint.includes('eyeCareProviders')){
            if (endpoint.includes('signup')){
                title = 'Could not save patient'
            } else if (endpoint.includes('getallpatients') && endpoint.includes('archive=true')){
                title = 'Archived patients'
                messages = messages? messages: ['Could not get archived patients, Please try again later']
            } else if (endpoint.includes('getallpatients')){
                title = 'Active Patients'
                messages = messages? messages: ['Could not get active patients, Please try again later']
            } else if (endpoint.includes('profile') && method.includes('post')){
                title = 'Edit Patients'
            } else if (endpoint.includes('getallpatients')){
                title = 'Last Visit'
                messages = messages? messages: ['Could not get last visit, Please try again later']
            } else if (endpoint.includes('addNewVisit')){
                title = 'Could not add new visit'
            } else if (endpoint.includes('profile') && method.includes('get')){
                title = 'Could not get patient\'s profile'
            } else if (endpoint.includes('profile') && method.includes('delete')){
                title = 'Could not delete patient '
            } else if (endpoint.includes('sendMessages')){
                title = 'Message was not sent'
            }
            await makeError({proceedLabel: 'Ok', options:{title}},messages);
            if(messageResponse.toLowerCase() === "no such user"){
                await authenticationService.logout()
                history.push('/login')
            }
        }

    } else if (error.request) {// client never received a response, or request never left
        let endpoint = String(error.request.responseURL)
            .replace(/http[s]+\:\/\/(api\-[a-z]+\.eye-swift\.com|localhost|127\.0\.0\.1)\/api\/v1\/webapp\//,'')
        if (endpoint.includes('signin') || endpoint.includes('forgetPass')){
            return Promise.reject({data:{message:'connection error or Timed out'}})
        } else if (endpoint.includes('eyeSwiftAdmins') || endpoint.includes('eyeCareProviders')){
            await makeError({proceedLabel: 'Ok', options:{title:'Connection Error'}},['connection error or Timed out']);
        } else {
            await makeError({proceedLabel: 'Ok', options:{title:'Connection Error'}},['connection error or Timed out']);
            return Promise.reject({error,data:{message:'connection error or Timed out'}})
        }
    } else {
        await makeError({proceedLabel: 'Ok', options:{title:'Connection Error'}},['Connection error']);
        return Promise.reject({error,request: error.request,data:{message:'Connection Error'}})
    }
});
