import React, {Component} from 'react'
import PropTypes from "prop-types";
import '../../components_style/rtl_css/FormElementContainer.css'
import '../../components_style/FormElementContainer.css'

class FormElementContainer extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {title,Component,rest,isSub} = this.props
        return (
            <div className={isSub ? 'Patient-form-sub-component' : 'Patient-form-component'}>
                <div className={isSub ? 'Patient-form-component-sub-header' : 'Patient-form-component-header'}>
                    <label className={isSub ? 'Patient-form-component-sub-header-label': 'Patient-form-component-header-label'}>
                        {title}
                    </label>
                </div>
                <Component {...rest} />
            </div>

        )
    }
}

FormElementContainer.propTypes = {
    title: PropTypes.string.isRequired,
    Component: PropTypes.elementType.isRequired,
    rest: PropTypes.object
};

export default FormElementContainer;
