import React, {Component} from 'react'
import PropTypes from "prop-types";
import utils from "../../utils/utils";
import Constants from "../../utils/constants";
import NovaSelect from "../single_components/NovaSelect";
import NovaDate from "../single_components/NovaDate";
import Error from "../single_components/Error";

class PatientDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entry: this.props.entry,
        }
    }




    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.entry !== prevProps.entry) {
            this.setState({
                entry: this.props.entry
            })
        }
    }


    render() {
        let {t}=this.props

        return (
            <div className={'Patient-personal-Form'}>
                <div className={'Patient-personal-form-container'}>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('ecp.patient.form.first_name')} *</label>
                        <div className={'form-search'}>
                            <input
                                type={"text"}
                                disabled={this.props.isDisabled}
                                className={"nova-form-input"}
                                maxLength={20}
                                defaultValue={utils.get(this.props.entry,'FirstName') !== null &&
                                utils.get(this.props.entry,'FirstName') !== undefined ?
                                    utils.get(this.props.entry,'FirstName') :''}
                                name={'FirstName'}
                                onChange={(event) => this.props.onValueChange('FirstName', event.target.value)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'FirstName')} isShown={!!utils.get(this.props.errors, 'FirstName')}/>
                        </div>
                    </div>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('ecp.patient.form.last_name')} *</label>
                        <div className={'form-search'}>
                            <input
                                type={"text"}
                                disabled={this.props.isDisabled}
                                className={"nova-form-input"}
                                maxLength={20}
                                defaultValue={utils.get(this.props.entry,'LastName') !== null &&
                                utils.get(this.props.entry,'LastName') !== undefined ?
                                    utils.get(this.props.entry,'LastName') :''}
                                name={'LastName'}
                                onChange={(event) => this.props.onValueChange('LastName', event.target.value)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'LastName')} isShown={!!utils.get(this.props.errors, 'LastName')}/>
                        </div>
                    </div>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('ecp.patient.form.id')}</label>
                        <div className={'form-search'}>
                            <input
                                type={"text"}
                                disabled={this.props.isDisabled}
                                className={"nova-form-input"}
                                maxLength={20}
                                defaultValue={utils.get(this.props.entry,'SocialID') !== null &&
                                utils.get(this.props.entry,'SocialID') !== undefined ?
                                    utils.get(this.props.entry,'SocialID') :''}
                                name={'SocialID'}
                                onChange={(event) => this.props.onValueChange('SocialID', event.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className={'Patient-personal-form-container'}>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('ecp.patient.form.gender')}</label>
                        <div className={'form-search'}>
                            <NovaSelect
                                name={'PatientAddressBaseRight'}
                                options={Constants.Genders}
                                className={'nova-form-input nova-form-searchableSelect'}
                                defaultValue={utils.get(this.props.entry,'Gender') !== null &&
                                utils.get(this.props.entry,'Gender') !== undefined ?
                                    Constants.Genders.find(base => base.value === utils.get(this.props.entry,'Gender'))
                                    :''}
                                placeholder={"Select a Gender"}
                                onChange={e => this.props.onValueChange('Gender',e.value)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'Gender')} isShown={!!utils.get(this.props.errors, 'Gender')}/>
                        </div>
                    </div>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('ecp.patient.form.dob')}*</label>
                        <div className={'form-search'}>
                            <NovaDate onSearchTermChange={(val) => this.props.onValueChange('Birthdate', val)}
                                      term={utils.get(this.props.entry,'Birthdate') !== null &&
                                      utils.get(this.props.entry,'Birthdate') !== undefined ?
                                          utils.get(this.props.entry,'Birthdate') :''}
                                      allowFuture={false}/>
                            <Error errorMessage={utils.get(this.props.errors,'Birthdate')} isShown={!!utils.get(this.props.errors, 'Birthdate')}/>
                        </div>
                    </div>
                    <div className={'Patient-personal-form-element'}/>

                </div>
            </div>
        )
    }
}

PatientDetails.propTypes = {
    onValueChange:PropTypes.func.isRequired,
    entry: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool
}

export default PatientDetails;
