import React from "react";
import PropTypes from "prop-types";
import {Modal} from 'react-bootstrap'
import {confirmable, createConfirmation} from "react-confirm";
import '../../components_style/rtl_css/Modal.css'
import '../../components_style/Modal.css'
import SvGraphics from "../../assets/SvGraphics";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class ConfirmComposed extends React.Component {

    componentDidMount(){
        document.getElementById('modal-ok-button').focus();
    }

    render() {
        let {
            proceedLabel,
            cancelLabel,
            title,
            message,
            extraMessage,
            extraExtraMessage,
            show,
            proceed,
            warningRed,
            red,
            enableEscape = true,
            t
        } = this.props;

        return (
            <Modal

                id={'Confirm-modal-body'}
                centered={true}
                show={show}
                onHide={() => proceed(false)}
                backdrop={enableEscape ? true : "static"}
                keyboard={enableEscape}
                dialogClassName={'nova-modal'}>

                <Modal.Body className={'nova-modal-body'}>
                    <SvGraphics  onClick={() => proceed(false)} svgname={'close'} height={'15px'} width={'15px'} style={{
                        float: 'left',
                        top: '10px',
                        left: '10px',
                        position: 'absolute',
                        cursor: 'pointer'
                    }}/>
                    <label className={`nova-modal-title ${red?'red':''}`}>{title}</label>
                    <div className={'nova-modal-content'}>
                        {message}
                        {extraMessage !== null && extraMessage !== undefined && <div className={`nova-modal-content-extra ${warningRed?'bolded red':''}`}>
                            {extraMessage}
                        </div>}
                        {extraExtraMessage !== null && extraExtraMessage !== undefined && <div className={'nova-modal-content-extra'}>
                            {extraExtraMessage}
                        </div>}
                    </div>
                    <div className={'nova-modal-footer h-centered'}>
                        <button id={'modal-ok-button'} className={`btn btn-primary modal-confirm ${cancelLabel === 'none' ? 'noMarginRight' : ''}`}
                                onClick={() => proceed(true)} onKeyPress={event => {
                            if (event.key === 'Enter' && cancelLabel === 'none') {
                                proceed(true)
                            }
                        }}>{proceedLabel === "Yes"?t('modal.yes'):proceedLabel}</button>
                        {(cancelLabel !== 'none') ? <button className={'btn btn-primary modal-close'}
                                                            onClick={() => proceed(false)}>{cancelLabel === "No"?t('modal.no'):cancelLabel}</button> : <div/>}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

let Confirm = compose(
    withTranslation()
)(ConfirmComposed)

Confirm.propTypes = {
    proceedLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    extraMessage: PropTypes.string,
    extraExtraMessage: PropTypes.string,
    show: PropTypes.bool,
    warningRed: PropTypes.bool,
    red: PropTypes.bool,
    proceed: PropTypes.func, // called when ok button is clicked.
    enableEscape: PropTypes.bool
};



let AlertConfirm = function AlertConfirm({proceedLabel: proceedLabel = "Yes", cancelLabel: cancelLabel = "No", options: options = {}}, message) {
    return createConfirmation(confirmable(Confirm))({
        message,
        proceedLabel,
        cancelLabel,
        ...options
    });
}

// let AlertConfirm = compose(
//     withTranslation()
// )(AlertConfirmComposed)

export default AlertConfirm