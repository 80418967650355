import React, {Component} from 'react'
import { connect } from "react-redux";
import MyTable from "../../../components/NovaTable/Table";
import {cleanPatient_Archive,setPatients_ArchiveDataSelection,filterPatients_ArchiveTable} from "../../../redux/actions/Tabs-Actions";
import {history} from "../../../utils/history";
import SvGraphics from "../../../assets/SvGraphics";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class ArchiveTabConComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected:null
        }
    }

    componentWillUnmount() {
        this.props.cleanPatient_Archive()
    }

    onSelectionChange = (selected) => {
        this.setState({selected})
        this.props.setPatients_ArchiveDataSelection(selected)
    }

    onRowClick = (rowInfo) => {
        history.push({
            pathname: `/dashboard/Patient`,
            state: {
                rowInfo,
                completed:true,
                from: {
                    fromPath: `/dashboard`,
                }
            },
        })
    }

    onFilterDataSelected = (filter) => {
        this.props.filterPatients_ArchiveTable(Object.keys(filter)[0],Object.values(filter)[0])
    }

    getColumns = () => {
        let {t} = this.props
        return [
            {
                accessor: 'UserStorageID',
                Header: t('ecp.archive.table.patient_id'),
                resizable: false,
                width: 130,
                Cell: ({original}) => {
                    return (<div onClick={e => {
                            e.stopPropagation();
                            this.onRowClick(original)
                        }} style={{/*fontWeight:'550',*/cursor: 'pointer', width: 'fit-content'}}>
                            {original.UserStorageID}
                        </div>
                    );
                }
            },
            {
                accessor: 'FirstName',
                Header: t('ecp.archive.table.first_name'),
                resizable: false,
                Cell: ({original}) => {
                    return (
                        <div onClick={e => {
                            e.stopPropagation();
                            this.onRowClick(original)
                        }} style={{/*fontWeight:'550',*/cursor: 'pointer', width: 'fit-content'}}>
                            {original.FirstName}
                        </div>
                    );
                }
            },
            {
                accessor: 'LastName',
                Header: t('ecp.archive.table.last_name'),
                resizable: false,
                Cell: ({original}) => {
                    return (
                        <div onClick={e => {
                            e.stopPropagation();
                            this.onRowClick(original)
                        }} style={{/*fontWeight:'550',*/cursor: 'pointer', width: 'fit-content'}}>
                            {original.LastName}
                        </div>
                    );
                }
            },
            {accessor: 'Birthdate', Header: t('ecp.archive.table.dob'), resizable: false},
            {accessor: 'Gender', Header: t('ecp.archive.table.gender'), resizable: false},
            {accessor: 'LastVisit', Header: t('ecp.archive.table.last_visit'), resizable: false},
            {accessor: 'Category', Header: t('ecp.archive.table.category'), resizable: false},
            {
                accessor: 'Status', Header: t('ecp.archive.table.status'), resizable: false, Cell: ({original}) => {
                    if (original.Status === 1) {
                        return (<SvGraphics svgname={'warning-red'} style={{width: '17px', marginLeft: '15px'}}/>)
                    }
                    return ''
                }, width: 90
            }
        ]
    }

    render() {
        let columns = this.getColumns()
        return (
            <div className={'context-area'}>
                <MyTable
                    data={this.props.patients_ArchiveData}
                    initialData={this.props.initiatePatients_ArchiveData}
                    columns={columns}
                    onSelectionChange={selected => this.onSelectionChange(selected)}
                    onFilterDataSelected={filter => this.onFilterDataSelected(filter)}
                    hasSelection={true}
                    hasMore={false}
                    hasShowInfo={false}
                />
            </div>

        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        cleanPatient_Archive: () => dispatch(cleanPatient_Archive()),

        setPatients_ArchiveDataSelection: patients_ArchiveData => dispatch(setPatients_ArchiveDataSelection(patients_ArchiveData)),
        filterPatients_ArchiveTable: (type,payload) => dispatch(filterPatients_ArchiveTable(type,payload))
    };
}

const mapStateToProps = state => {
    return {
        patients_ArchiveData:state.patients_archiveTableDataReducer.patients_ArchiveData,
        initiatePatients_ArchiveData:state.patients_archiveTableDataReducer.initiatePatients_ArchiveData
    };
};


const ArchiveTab = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ArchiveTabConComposed);

export default ArchiveTab;
