import React, {Component} from "react";
import SimpleFormBuilder from "../forms_builder/SimpleFormBuilder";
import utils from "../../utils/utils";
import Prototypes from "prop-types";
import {adminApi} from "../../services/ApiService";
import * as Yup from "yup";
import validationHelper from "../../utils/validationHelper";
import {makeError} from "../modals/ErrorModal";
import Constants from "../../utils/constants";
import AlertConfirm from "../modals/Confirm";
import SvGraphics from "../../assets/SvGraphics";
import {ConstantsArrT, ConstantsObjT} from "../../utils/ConstantsWithTranslate";


class VersionEditForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entry: {},
            matrix :[2,1,1],
            errors: {},
            errorsOnSave:{},
        };
    }

    initValidation = async () => {
        let {t} = this.props
        let validationSchema = Yup.object().shape({
            VersionType:Yup.string().required(t('admin.version.form.ver_type_req')),
            VersionNO:Yup.string().required(t('admin.version.form.ver_num_req')),
            VersionURL:Yup.string().required(t('admin.version.form.ver_url_req')),
        })
        let errors = await validationHelper.validate(this.state.entry,validationSchema)
        await this.setState({
            validationSchema,
            errors
        })
    }

    async componentWillMount() {
        if (this.props && this.props.entry) await this.setState({entry:utils.removeEmptyOrNull(this.props.entry)})
        await this.initValidation()
    }

    async closeSideBar(){
        let {t} = this.props
        document.body.style.overflow = "auto"
        let confirm = await AlertConfirm({
            options:{
                title: t('admin.version.form.withdraw_edit_ver'),
            }},t('admin.version.form.withdraw_edit_ver_info'))
        if (confirm) {
            document.body.style.overflow = "auto"
            this.props.closeSideBar(false)
            this.setState({entry:null})
        }  else {
            document.body.style.overflow = "hidden"
        }
    }

    async saveInfo(){
        let {t} = this.props
        if (!utils.isEmpty(this.state.errors)){
            this.setState({errorsOnSave:this.state.errors});
            await makeError({
                proceedLabel: t('admin.version.form.ok'),
                options:{
                    title: t('admin.version.form.version_not_edited'),
                }
            },[t('admin.version.form.fill_all_req')]);
            return;
        }
        document.body.style.overflow = "auto"
        let confirm = await AlertConfirm({
            options:{
                title: t('admin.version.form.edit_ver'),
            }},t('admin.version.form.edit_ver_confirm'))
        if (confirm) {
            let {entry} = this.state
            entry = utils.ObjectEmptiesToNulls(entry)
            let response = await adminApi.editVersion(entry)
            if (response) {
                this.props.editVersion(entry)
                document.body.style.overflow = "auto"
                this.props.closeSideBar(false)
                this.setState({entry:{}})
            }
        } else {
            document.body.style.overflow = "hidden"
        }
    }

    onEntryChange = async event => {
        let {entry,validationSchema} = this.state
        let name = event.target.name
        let value = event.target.type === 'checkbox' ?
            !entry[name]
            : event.target.value
        utils.set(entry,name,value)
        let entryValidator = utils.removeEmptyOrNull(entry)
        let errors = await validationHelper.validate(entryValidator, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;
        if (utils.get(errorsOnSave,name)){
            utils.set(errorsOnSave,name,utils.get(errors,name))
        }
        this.setState({
            entry,
            errors,
            errorsOnSave
        })
    }

    onEntryChangeByDropdown = async (name,value) => {
        let {entry,validationSchema} = this.state
        utils.set(entry,name,value)
        let errors = await validationHelper.validate(entry, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;
        if (utils.get(errorsOnSave,name)){
            utils.set(errorsOnSave,name,utils.get(errors,name))
        }
        this.setState({
            entry,
            errors,
            errorsOnSave
        })
    }

    getFields = () => {
        let {t} = this.props
        // let versions = [
        //     {value: "WIN", label: t('admin.version.table.win_v')},
        //     {value: "ES", label: t('admin.version.table.es_v')},
        //     {value: "ET", label: t('admin.version.table.et_v')},
        //     {value: "EMITTER", label: t('admin.version.table.emitter_v')},
        //     {value: "TV", label: t('admin.version.table.tv_v')}
        // ]
        return [
            {type:'dropdown', name:'VersionType',display:t('admin.version.form.ver_type'), placeholder: t('admin.version.form.enter_ver_type'), required:true, width:0.5, style:'inline',options:ConstantsArrT(t,Constants.versions)},
            {type:'text', max: 20, name:'VersionNO',display:t('admin.version.form.ver_num'), placeholder: t('admin.version.form.enter_ver_num'), required:true, width:0.5, style:'inline'},
            {type:'text', max: 100, name:'VersionURL',display:t('admin.version.form.url'), placeholder: t('admin.version.form.enter_url'), required:true, width:1, style:'inline'},
            {type:'checkbox', name:'forceUpdate',display: t('admin.version.form.isForceUpdate'), width:1, style:'block'},
        ]
    }

    render() {
        let display = this.props.isSideBarOpen ? 'block' : 'none'
        let width = this.props.isSideBarOpen ? "600px" : "0px"
        let container_width = this.props.isSideBarOpen ? "100%" : "0px"
        let {errorsOnSave} = this.state
        let {t} = this.props
        return (
            <div className={"sidenav-complete"} style={{width:container_width, height:"100%"}}>
                <div className={"sidenav"} style={{width:width, height:"100%", float:'right'}}>
                    <SvGraphics onClick={this.closeSideBar.bind(this)} className={'close-bar-x'} svgname={'close'} height={'15px'} width={'15px'} style={{
                        float: 'left',
                        top: '10px',
                        left: '10px',
                        position: 'absolute',
                        cursor: 'pointer'
                    }}/>
                    <div className={'register-form-title container block'}>
                        <h3 className={'h-centered block'}>{this.props.title}</h3>
                    </div>
                    <div className={'register-form container block'}>
                        <SimpleFormBuilder
                            errors={errorsOnSave}
                            entry={this.state.entry}
                            matrix={this.state.matrix}
                            fields={this.getFields()}
                            onEntryChange={e => this.onEntryChange(e)}
                            onEntryChangeByDropdown={(name,val) => this.onEntryChangeByDropdown(name,val)}
                        />
                    </div>
                </div>
                <div className={'register-form-nav-container block'} >
                    <div className={'register-form-nav'}  style={{width:width}}>
                        <label onClick={this.closeSideBar.bind(this)} style={{display:display}} className={"v-centered close-bar"}>
                            {t('admin.ecp.form.close')}
                        </label>
                        <button type={"button"} onClick={this.saveInfo.bind(this)} style={{display:display}} className={"btn btn-primary v-centered save-info"}>
                            {t('admin.ecp.form.save')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
VersionEditForm.propTypes = {
    isSideBarOpen:Prototypes.bool.isRequired,
    closeSideBar:Prototypes.func.isRequired,
    editVersion:Prototypes.func.isRequired,
    title: Prototypes.string.isRequired,
    entry: Prototypes.object
}

export default VersionEditForm
