import React, {Component} from 'react'
import {connect} from "react-redux";
import {cleanSite,setSiteDataSelection,editSite,filterSitesTable,deleteSite,
    deleteSiteEcps} from "../../../redux/actions/Tabs-Actions";
import MyTable from "../../../components/NovaTable/Table";
import SiteEditForm from "../../../components/site/SiteEditForm";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {adminApi} from "../../../services/ApiService";
import AlertConfirm from "../../../components/modals/Confirm";

class SitesTabConComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected:null,
            isSideBarOpen: false,
            entry: null,
        }
    }

    async openSideBar(site){
        await this.setState({entry:site,isSideBarOpen:true})
        document.body.style.overflow = "hidden"
        this.forceUpdate();
    }

    componentWillUnmount() {
        this.props.cleanSite()
    }

    onSelectionChange = (selected) => {
        this.setState({selected})
        this.props.setSiteDataSelection(selected)
    }

    editSiteDetails = (site) => {
        this.openSideBar(site)

    }

    setSiteMoreFunctions = () => {
        let {t} = this.props
        return [
            {
                name: 'Edit Site Details',
                display: t('admin.site.table.edit_site_details'),
                call: (site => this.editSiteDetails(site))
            },
            {
                name: 'Edit Site Details',
                display: t('admin.site.table.delete_site'),
                call: (site => this.deleteSite(site))
            }
        ]
    }

    async deleteSite(site) {
        let {t} = this.props
        let confirm = await AlertConfirm({
            options:{
                title: t('admin.admin.table.delete_site'),
            }},`${t('admin.admin.table.delete_site_confirm')}`)
        if (confirm) {
            let resp = await adminApi.deleteSite(site.SiteStorageID)
            let data = resp  ? resp['data']:""
            let status = data  ? data.status :""
            if (status >= 200){

                this.props.deleteSite(data.SiteStorageID)
                    this.props.deleteSiteEcps(data.SiteStorageID)
                }
            }

    }

    onFilterDataSelected = (filter) => {
        this.props.filterSitesTable(Object.keys(filter)[0],Object.values(filter)[0])
    }

    getColumns = (t) => {
        return [
            {accessor: 'SiteName', Header: t('admin.site.table.site_name'), resizable:false},
            {accessor: 'LinkedECPs', Header: t('admin.site.table.linked_ecps'), resizable:false},
            {accessor: 'LinkedDevices', Header: t('admin.site.table.linked_devices'), resizable:false},
            {accessor: 'SiteEmail', Header: t('admin.site.table.email'), resizable:false},
            {accessor: 'SitePhoneNumber', Header: t('admin.site.table.phone'), resizable:false},
        ]
    }

    render() {
        let {t} = this.props
        let columns = this.getColumns(t)
        let {entry,isSideBarOpen} = this.state
        return (
            <div className={'context-area'}>
                <MyTable
                    data={this.props.siteData}
                    columns={columns}
                    initialData={this.props.initiateSiteData}
                    onSelectionChange={selected => this.onSelectionChange(selected)}
                    moreFunctions={this.setSiteMoreFunctions()}
                    onFilterDataSelected={filter => this.onFilterDataSelected(filter)}
                    hasSelection={true}
                    hasMore={true}
                    hasShowInfo={false}
                />
                {isSideBarOpen && <SiteEditForm
                    t={e => t(e)}
                    title={t('admin.site.form.edit_site')}
                    entry={entry}
                    editSite={site => this.props.editSite(site)}
                    isSideBarOpen={isSideBarOpen}
                    closeSideBar={() => this.setState({isSideBarOpen: false})}/>}
            </div>

        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        editSite: siteData => dispatch(editSite(siteData)),

        deleteSite: siteId => dispatch(deleteSite(siteId)),
        deleteSiteEcps: siteId => dispatch(deleteSiteEcps(siteId)),

        cleanSite: () => dispatch(cleanSite()),
        setSiteDataSelection: siteData => dispatch(setSiteDataSelection(siteData)),
        filterSitesTable: (type,payload) => dispatch(filterSitesTable(type,payload))
    };
}

const mapStateToProps = state => {
    return {
        siteData:state.siteTableDataReducer.siteData,
        initiateSiteData:state.siteTableDataReducer.initiateSiteData
    };
};


const SitesTab = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(SitesTabConComposed);

export default SitesTab;
