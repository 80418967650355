import React, {Component} from "react";
import SvGraphics from "../../assets/SvGraphics";
import Prototypes from 'prop-types'
import {adminApi} from "../../services/ApiService";
import {makeError} from "../modals/ErrorModal";
import {sendMessage} from "../modals/MessageModal";
import {authenticationService} from "../../services/AuthenticationService";

class DevicesTabTools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterDevicesTable:'',
            searchValue: '',
        };
    }

    componentDidMount() {
    }

    searchDevicesTable(val){
        this.setState({searchValue:val})
        this.props.searchDevicesTable(val)
    }

    sendMessage = async () => {
        let {t} = this.props
        if (this.props.recipients.length > 0){
            let Method = await sendMessage({
            },this.props.recipients);
            if (Method){
                let PatientsIDs = this.props.recipients.map(user =>{
                    return user.id
                })
                await adminApi.sendMessageToPatients({Method,PatientsIDs})
            }
        } else {
            await makeError({
                proceedLabel: t('admin.device.tools.ok'),
                options:{
                    title: t('admin.device.tools.could_not_opn_msg'),
                }
            },[t('admin.device.tools.select_recipients')]);
        }
    }


    render() {
        let {t} = this.props
        let current  = authenticationService.currentUserValue
        let TabTools_Size =current?.profile?.Doctor?.isSiteAdmin ? 'SiteAdmin-TabTools' :  this.props.isSmall ? 'EyeSwiftSuperAdmin-TabTools' : 'Admin-TabTools'
        return (
            <div className={`${TabTools_Size} custom-control`}>
                <div className={"TabTools-container"}>
                    <div className={"rounded-button-wrapper"}>
                        <button disabled type={"button"} className={"btn btn-primary rounded-btn"} onClick={this.sendMessage.bind(this)}>
                            <SvGraphics className={"mail-pic centered"} svgname={'mail'} style={{width: '25px', height: '25px'}}/>
                        </button>
                    </div>
                    <div className={"rounded-button-wrapper"}>
                        <button type={"button"} className={"btn btn-primary rounded-btn"} onClick={() => {
                            let filters = document.getElementsByClassName('table-filter')
                            for (let i = 0; i < filters.length; i++) {
                                if (filters[i].style.display === "none"){
                                    filters[i].style.display = "inline-block";
                                }
                                else filters[i].style.display = "none"
                            }
                        }}>
                            <SvGraphics className={"filter-pic centered"} svgname={'filter'} style={{width: '25px', height: '25px'}}/>
                        </button>
                    </div>
                    <div className={"search-wrapper"}>
                        <input className={"nova-input"} placeholder={t('admin.device.tools.search')} type="text" name="search"
                               id={"search"}
                               onChange={event => this.searchDevicesTable(event.target.value)} value={this.state.searchValue}/>
                        <SvGraphics className={"input-pic"} svgname={'search'} style={{width: '20px', height: '20px'}}/>
                    </div>
                </div>

            </div>
        );
    }
}
DevicesTabTools.Prototypes = {
    searchDevicesTable: Prototypes.func.isRequired,
}

export default DevicesTabTools

