import React, {Component} from 'react'
import ReactApexChart from "react-apexcharts";
import SvGraphics from "../../assets/SvGraphics";
import PatientChart from "../../PatientChart";
import {filterPatientsTable} from "../../redux/actions/Tabs-Actions";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {dateTimeArr, dateTimeArr2, getDateFormat, getTimeFormat} from "../../utils/getDateTimeFormat";

class ChartCSBlockConnected extends React.Component {

    constructor(props) {
        super(props);
    }

    getVisualUnitsValue(value){
        switch (this.props.visualUnit){
            case "6/6": {
                return  6 / (Math.pow(10, -value))
            }
            case "20/20": {
                return  20 / (Math.pow(10, -value))
            }
            case "Decimal": {
                return Math.pow(10, -value)
            }
            default: {
                return value
            };
        }
    }
    render() {
        let {t}=this.props
        let categories=[]
        let left = {
            name: t('ecp.info_filters.left'),
            data: []
        }
        let right = {
            name: t('ecp.info_filters.right'),
            data: []
        }
        let binocular = {
            name: t('ecp.info_filters.binocular'),
            data: []
        }

        let lastVisits = this.props.lastVisitsData
        let condition = ""
        if (this.props.svgName === "noglasses")
            condition="SC"
        else
            condition="CC"

        let maxY=1.5
        if(lastVisits){
            for (const visit of lastVisits) {
                for (const testResults of visit.TestsResults) {
                    if (testResults.ChartsCSTestStorageID > 0 && testResults.OpticalCorrection === condition&&testResults.TestResult.toLowerCase()==="test succeeded"){
                        categories.push(dateTimeArr2(new Date(visit.VisitDate)))

                        let leftValue=testResults.ChartCSTest.LeftEyeLogMarValue!== null ?
                            this.getVisualUnitsValue(testResults.ChartCSTest.LeftEyeLogMarValue):""
                        let  rightValue= testResults.ChartCSTest.RightEyeLogMarValue!== null ?
                            this.getVisualUnitsValue(testResults.ChartCSTest.RightEyeLogMarValue):""
                        let  binocularValue=testResults.ChartCSTest.BinocularLogMarValue!== null ?
                            this.getVisualUnitsValue(testResults.ChartCSTest.BinocularLogMarValue):""

                        left.data.push(leftValue)
                        right.data.push(rightValue)
                        binocular.data.push(binocularValue)
                        maxY=Math.max(leftValue, rightValue,binocularValue,maxY)

                    }
                }
            }
        }

        let series=  [left,right,binocular]
        return (
            <div className={'patient-dashboard-chart-area'}>
                <div className={'patient-chart-topping'}>
                    <div className={'title-svg'}>
                        <div className={'chart-title'}>{this.props.chartTitle}</div>
                        <SvGraphics className={'glasses-style'} svgname={this.props.svgName}/>
                    </div>
                    <div className={'patient-chart-indication-area'}>
                        <div className={'patient-chart-indication patient-chart-1st-indication'}>
                            <span className={'patient-chart-indication-color'}>&nbsp;</span>
                            <div className={'patient-chart-indication-text'}>{t('ecp.info_filters.left')}</div>
                        </div>
                        <div className={'patient-chart-indication patient-chart-2nd-indication'}>
                            <span className={'patient-chart-indication-color'}>&nbsp;</span>
                            <div className={'patient-chart-indication-text'}>{t('ecp.info_filters.right')}</div>
                        </div>
                        <div className={'patient-chart-indication patient-chart-3rd-indication'}>
                            <span className={'patient-chart-indication-color'}>&nbsp;</span>
                            <div className={'patient-chart-indication-text'}>{t('ecp.info_filters.binocular')}</div>
                        </div>
                    </div>
                </div>
                <div className={'ACT-chart'}>
                    <PatientChart series={series} chartType={'bar'} colors={['#25a6ff', '#0575e6','#034e99']}
                                  minYValue={-(0.5)} maxYValue={maxY+0.5} tickAmount={4}
                                  xCategories={categories} seriesNum={3} ytitle={this.props.visualUnit}/>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        filterPatientsTable:(filterType,payload) => dispatch(filterPatientsTable(filterType,payload)),
    };
}
const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData,
        patientsCount:state.patientsTableDataReducer.patientsCount,
        activePatientsCount:state.patientsTableDataReducer.activePatientsCount,
        pendingPatientsCount:state.patientsTableDataReducer.pendingPatientsCount,
        lockedPatientsCount:state.patientsTableDataReducer.lockedPatientsCount,
    };
};

const ChartCSBlock = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ChartCSBlockConnected)
export default ChartCSBlock
