import React, {Component} from 'react'
import BackButton from "./BackButton";
import '../../components_style/BackButton.css'
import '../../components_style/rtl_css/BackButton.css'
import PropTypes from "prop-types";
class ReturnNav extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount = ()  => {

    }

    onNavBack = () => {
        this.props.onNavBackClick()
    }

    render() {

        return (
            <div className={'return-nav'}>
                <BackButton onBackClick={this.onNavBack.bind(this)}/>
            </div>
        )
    }
}

ReturnNav.propTypes = {
    onNavBackClick: PropTypes.func.isRequired
}


export default ReturnNav;
