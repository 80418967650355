import React, {Component} from 'react'
import '../../components_style/PatientScreen.css'
import '../../components_style/rtl_css/PatientScreen.css'
import Popover from "react-bootstrap/Popover";
import {Button, OverlayTrigger} from "react-bootstrap";
import {addNewArchivePatient, deletePatient, filterPatientsTable} from "../../redux/actions/Tabs-Actions";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import SvGraphics from "../../assets/SvGraphics";
import {logger} from "redux-logger/src";





class PatientTestResultConnected extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showedTests: {
                act: false,
                ct:false,
                chartsva: false,
                chartscs: false,
                fusional:false,
                reading:false,
                stereotest:false,
                autocs:false,
                autova:false,
                colortest:false,
                worth4dot: false,
            },
            checkAll:true,
            actCheck:true,
            ctCheck: true,
            chartVACheck:true,
            chartCSCheck:true,
            fusionalCheck:true,
            readingCheck:true,
            stereoCheck:true,
            autoCSCheck:true,
            autoVACheck:true,
            colorCheck:true,
            worth4DotCheck:true,
            allTestsSearch:true,
            actSearch:true,
            ctSearch: true,
            chartVASearch:true,
            chartCSSearch:true,
            fusionalSearch:true,
            readingSearch:true,
            stereoSearch:true,
            autoCSSearch:true,
            autoVASearch:true,
            colorSearch:true,
            worth4DotSearch:true,
        }

    }

    async results_toggle(state) {

        let act,ct,chartsva,chartscs,fusional,reading,stereotest,autocs,autova,colortest,worth4dot= false;
        switch (state) {
            case 'act': {
                {
                    let elmntToView = document.getElementById("act");
                    elmntToView.scrollIntoView();
                    act = true;
            }
            break;
            }
            case 'ct': {
                {
                    let elmntToView = document.getElementById("ct");
                    elmntToView.scrollIntoView();
                    ct = true;
                }
                break;}
            case 'chartsva': {{
                let elmntToView = document.getElementById("chartva");
                elmntToView.scrollIntoView();
                chartsva = true;
            }
                break;}
            case 'chartscs':{{
                let elmntToView = document.getElementById("chartscs");
                elmntToView.scrollIntoView();
                chartscs = true;
            }
                break;}
            case 'fusional': {{
                let elmntToView = document.getElementById("fusional");
                elmntToView.scrollIntoView();
                fusional = true;
            }
                break;}
            case 'reading': {{
                let elmntToView = document.getElementById("reading");
                elmntToView.scrollIntoView();
                reading = true;
            }
                break;}
            case 'stereotest': {{
                let elmntToView = document.getElementById("stereotest");
                elmntToView.scrollIntoView();
                stereotest = true;
            }
                break;}
            case 'autocs': {{
                let elmntToView = document.getElementById("autocs");
                elmntToView.scrollIntoView();
                autocs = true;
            }
                break;}
            case 'autova': {{
                let elmntToView = document.getElementById("autova");
                elmntToView.scrollIntoView();
                autova = true;
            }
                break;}
            case 'colortest': {{
                let elmntToView = document.getElementById("colortest");
                elmntToView.scrollIntoView();
                colortest = true;
            }
                break;}
            case 'worth4dot': {{
                let elmntToView = document.getElementById("worth4dot");
                elmntToView.scrollIntoView();
                worth4dot = true;
            }
                break;}
            default:  break;
        }

        this.setState({
            act,ct,chartsva,chartscs,fusional,reading,stereotest,autocs,autova,colortest,worth4dot
        })
    }

    selectCheckBox(checkbox){
        if (checkbox ==="checkAll" ) {
            this.setState({checkAll: !this.state.checkAll })
            let boolCheck= !this.state.checkAll
            this.setState({actCheck: boolCheck})
            this.setState({ctCheck: boolCheck})
            this.setState({chartVACheck: boolCheck})
            this.setState({chartCSCheck: boolCheck })
            this.setState({fusionalCheck: boolCheck})
            this.setState({readingCheck:boolCheck })
            this.setState({stereoCheck: boolCheck})
            this.setState({autoCSCheck: boolCheck})
            this.setState({autoVACheck: boolCheck})
            this.setState({colorCheck: boolCheck })
            this.setState({worth4DotCheck: boolCheck})
        }
        if (checkbox ==="actCheck" )
            this.setState({actCheck: !this.state.actCheck})
        if (checkbox === "ctCheck")
                this.setState({ctCheck: ! this.state.ctCheck })
        if (checkbox ===  "chartVACheck")
                this.setState({chartVACheck: ! this.state.chartVACheck })
        if (checkbox === "chartCSCheck")
                this.setState({chartCSCheck: ! this.state.chartCSCheck })
        if (checkbox ===  "fusionalCheck")
                this.setState({fusionalCheck: ! this.state.fusionalCheck })
        if (checkbox ===  "readingCheck")
                this.setState({readingCheck: ! this.state.readingCheck })
        if (checkbox === "stereoCheck")
                this.setState({stereoCheck: ! this.state.stereoCheck })
        if (checkbox ===  "autoCSCheck")
                this.setState({autoCSCheck: ! this.state.autoCSCheck })
        if (checkbox ===  "autoVACheck")
                this.setState({autoVACheck: ! this.state.autoVACheck })
        if (checkbox ===  "colorCheck")
                this.setState({colorCheck: ! this.state.colorCheck })
        if (checkbox ===  "worth4DotCheck")
                this.setState({worth4DotCheck: ! this.state.worth4DotCheck })


        if(this.state.checkAll && checkbox!=="checkAll"){
            this.setState({checkAll: !this.state.checkAll })
        }

        this.props.updateCheckbox(checkbox)
    }

    filterTests(){
        let text = document.getElementById("search-label-input");
        let {t} =this.props
        if (text.value===""){
            this.setState({ allTestsSearch: true })
            this.setState({ actSearch: true })
            this.setState({ ctSearch: true })
            this.setState({ chartVASearch: true })
            this.setState({ chartCSSearch: true })
            this.setState({ fusionalSearch: true })
            this.setState({ readingSearch: true })
            this.setState({ stereoSearch: true })
            this.setState({ autoCSSearch: true })
            this.setState({ autoVASearch: true })
            this.setState({ colorSearch: true })
            this.setState({ worth4DotSearch: true })
        }
        else{
            this.setState({ allTestsSearch: false })
            text.value === t('general.ACT') ? this.setState({ actSearch: true }) : this.setState({ actSearch: false })
            text.value === t('general.CT') ? this.setState({ ctSearch: true }) : this.setState({ ctSearch: false })
            text.value === t('general.ChartVA') ? this.setState({ chartVASearch: true }) : this.setState({ chartVASearch: false })
            text.value === t('general.ChartCS') ? this.setState({ chartCSSearch: true }) : this.setState({ chartCSSearch: false })
            text.value === t('general.Fusional') ? this.setState({ fusionalSearch: true }) : this.setState({ fusionalSearch: false })
            text.value ===  t('general.Reading') ? this.setState({ readingSearch: true }) : this.setState({ readingSearch: false })
            text.value === t('general.Stereo_Test')  ? this.setState({ stereoSearch: true }) : this.setState({ stereoSearch: false })
            text.value === t('general.AutoCS') ? this.setState({ autoCSSearch: true }) : this.setState({ autoCSSearch: false })
            text.value === t('general.AutoVA') ? this.setState({ autoVASearch: true }) : this.setState({ autoVASearch: false })
            text.value === t('general.Color_Test') ? this.setState({ colorSearch: true }) : this.setState({ colorSearch: false })
            text.value === t('general.Worth4Dot') ? this.setState({ worth4DotSearch: true }) : this.setState({ worth4DotSearch: false })
        }

    }
    render() {
        let activeBtnClass = 'nova-info-active-btn'
        let {act,ct,chartsva,chartscs,fusional,reading,stereotest,autocs,autova,colortest,worth4dot} = this.state
        let {t} = this.props

        return(
            <div className={'test-results-tabs'}>

                <label className={'test-results-show-info-block-label'}>{t('ecp.info_filters.showing')}</label>
                {!! this.props.actLastVisit && this.state.actCheck &&
                <div className={`test-results-info-block ${act?activeBtnClass:''}`} onClick={() => this.results_toggle('act') }>
                    <label className={'test-results-info-block-label'}>{t('general.ACT')}</label>
                </div>
                }
                {!!this.props.ctLastVisit && this.state.ctCheck &&
                <div className={`test-results-info-block ${ct ? activeBtnClass : ''}`}
                     onClick={() => this.results_toggle('ct')}>
                    <label className={'test-results-info-block-label'}>{t('general.CT')}</label>
                </div>
                }
                {!!this.props.chartVALastVisit && this.state.chartVACheck &&
                <div className={`test-results-info-block ${chartsva ? activeBtnClass : ''}`}
                     onClick={() => this.results_toggle('chartsva')}>
                    <label className={'test-results-info-block-label'}>{t('general.ChartVA')}</label>
                </div>
                }
                {!!this.props.chartCSLastVisit && this.state.chartCSCheck &&
                <div className={`test-results-info-block ${chartscs ? activeBtnClass : ''}`}
                     onClick={() => this.results_toggle('chartscs')}>
                    <label className={'test-results-info-block-label'}>{t('general.ChartCS')}</label>
                </div>
                }
                {!!this.props.fusionalLastVisit && this.state.fusionalCheck &&
                <div className={`test-results-info-block ${fusional ? activeBtnClass : ''}`}
                     onClick={() => this.results_toggle('fusional')}>
                    <label className={'test-results-info-block-label'}>{t('general.Fusional')} </label>
                </div>
                }
                {!!this.props.readingLastVisit && this.state.readingCheck &&
                <div className={`test-results-info-block ${reading ? activeBtnClass : ''}`}
                     onClick={() => this.results_toggle('reading')}>
                    <label className={'test-results-info-block-label'}>{t('general.Reading')}</label>
                </div>
                }
                {!!this.props.stereoLastVisit && this.state.stereoCheck &&
                <div className={`test-results-info-block ${stereotest ? activeBtnClass : ''}`}
                     onClick={() => this.results_toggle('stereotest')}>
                    <label className={'test-results-info-block-label'}>{t('general.Stereo_Test')}</label>
                </div>
                }
                {!!this.props.autoCSLastVisit && this.state.autoCSCheck &&
                <div className={`test-results-info-block ${autocs ? activeBtnClass : ''}`}
                     onClick={() => this.results_toggle('autocs')}>
                    <label className={'test-results-info-block-label'}>{t('general.AutoCS')}</label>
                </div>
                }
                {!!this.props.autoVALastVisit && this.state.autoVACheck &&
                <div className={`test-results-info-block ${autova ? activeBtnClass : ''}`}
                     onClick={() => this.results_toggle('autova')}>
                    <label className={'test-results-info-block-label'}>{t('general.AutoVA')}</label>
                </div>
                }
                {!!this.props.colorLastVisit && this.state.colorCheck &&
                <div className={`test-results-info-block ${colortest ? activeBtnClass : ''}`}
                     onClick={() => this.results_toggle('colortest')}>
                    <label className={'test-results-info-block-label'}>{t('general.Color_Test') }</label>
                </div>
                }
                {!!this.props.worth4dotLastVisit && this.state.worth4DotCheck &&
                <div className={`test-results-info-block ${worth4dot ? activeBtnClass : ''}`}
                     onClick={() => this.results_toggle('worth4dot')}>
                    <label className={'test-results-info-block-label'}>{t('general.Worth4Dot')}</label>
                </div>
                }

                <div className={'test-results-info-block-select'}>
                    <OverlayTrigger
                        trigger="click"
                        key="bottom"
                        placement="bottom"
                        overlay= {
                            <Popover id="select-test-popover">
                            <Popover.Content className={"select-test-popover-body"}>
                                <div className={'select-tests-popover-items flex'}>
                                    <label className={'select-tests-popup-label'}> Select Tests</label>
                                    <label className={'search-label'}>
                                        <SvGraphics svgname={'search'} className={'search-box-style'}/>
                                        <input id = {'search-label-input'} className={'search-label-input'} type="text" onChange={() => this.filterTests()}/>
                                    </label>

                                    <div className={"all-labels"}>
                                        {this.state.allTestsSearch &&
                                            <div className={'select-tests-checkbox-label'}>
                                            <input id={"checkAll"} type={"checkbox"} className={"checkbox-popup"}
                                                   checked={this.state.checkAll}
                                                   onChange={() => this.selectCheckBox("checkAll")}/>
                                            <label className={'select-tests-popup-label'}> {t('general.Select_All')}</label>
                                        </div>}
                                        {(!! this.props.actLastVisit && this.state.actSearch) &&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input id={"actCheck"} type={"checkbox"} className={"checkbox-popup"} checked={this.state.actCheck} onChange={() => this.selectCheckBox("actCheck") }/>
                                            <label className={'select-tests-popup-label'}> {t('general.ACT')}</label>
                                        </div>
                                        }
                                        {!!this.props.ctLastVisit && this.state.ctSearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input id={"ctCheck"} type={"checkbox"} className={"checkbox-popup"} checked={this.state.ctCheck} onChange={() => this.selectCheckBox("ctCheck") }/>
                                            <label className={'select-tests-popup-label'}> {t('general.CT')}</label>
                                        </div>
                                        }
                                        {!!this.props.chartVALastVisit && this.state.chartVASearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input id={"chartVACheck"} type={"checkbox"} className={"checkbox-popup"} checked={this.state.chartVACheck} onChange={() => this.selectCheckBox("chartVACheck") }/>
                                            <label className={'select-tests-popup-label'}>{t('general.ChartVA')}</label>
                                        </div>
                                        }
                                        {!!this.props.chartCSLastVisit && this.state.chartCSSearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input id={"chartCSCheck"} type={"checkbox"} className={"checkbox-popup"} checked={this.state.chartCSCheck} onChange={() => this.selectCheckBox("chartCSCheck") }/>
                                            <label className={'select-tests-popup-label'}>{t('general.ChartCS')}</label>
                                        </div>
                                        }
                                        {!!this.props.fusionalLastVisit && this.state.fusionalSearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input  id={"fusionalCheck"} type={"checkbox"} className={"checkbox-popup"} checked={this.state.fusionalCheck} onChange={() => this.selectCheckBox("fusionalCheck") }/>
                                            <label className={'select-tests-popup-label'}> {t('general.Fusional')}</label>
                                        </div>
                                        }
                                        {!!this.props.readingLastVisit && this.state.readingSearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input  id={"readingCheck"} type={"checkbox"} className={"checkbox-popup"} checked={this.state.readingCheck} onChange={() => this.selectCheckBox("readingCheck") }/>
                                            <label className={'select-tests-popup-label'}> {t('general.Reading')}</label>
                                        </div>
                                        }
                                        {!!this.props.stereoLastVisit && this.state.stereoSearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input id={"stereoCheck"} type={"checkbox"} className={"checkbox-popup"} checked={this.state.stereoCheck} onChange={() => this.selectCheckBox("stereoCheck") }/>
                                            <label className={'select-tests-popup-label'}> {t('general.Stereo_Test')}</label>
                                        </div>
                                        }
                                        {!!this.props.autoCSLastVisit && this.state.autoCSSearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input id={"autoCSCheck"} type={"checkbox"} className={"checkbox-popup"} checked={this.state.autoCSCheck} onChange={() => this.selectCheckBox("autoCSCheck") }/>
                                            <label className={'select-tests-popup-label'}>{t('general.AutoCS')}</label>
                                        </div>
                                        }
                                        {!!this.props.autoVALastVisit && this.state.autoVASearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input  id={"autoVACheck"} type={"checkbox"} className={"checkbox-popup"} checked={this.state.autoVACheck} onChange={() => this.selectCheckBox("autoVACheck") }/>
                                            <label className={'select-tests-popup-label'}>{t('general.AutoVA')}</label>
                                        </div>
                                        }
                                        {!!this.props.colorLastVisit && this.state.colorSearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input id={"colorCheck"} type={"checkbox"} className={"checkbox-popup"} checked={this.state.colorCheck} onChange={() => this.selectCheckBox("colorCheck") }/>
                                            <label className={'select-tests-popup-label'}>{t('general.Color_Test')}</label>
                                        </div>
                                        }
                                        {!!this.props.worth4dotLastVisit && this.state.worth4DotSearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input id={"worth4DotCheck"} type={"checkbox"} className={"checkbox-popup"} checked={this.state.worth4DotCheck} onChange={() => this.selectCheckBox("worth4DotCheck") }/>
                                            <label className={'select-tests-popup-label'}>{t('general.Worth4Dot')}</label>
                                        </div>
                                        }

                                        <div className={'select-tests-checkbox-label-not-performed-title'}>
                                            <label className={'select-tests-popup-label-not-performed'}> Not Performed</label>
                                        </div>
                                        {!this.props.actLastVisit && !this.state.actSearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input type={"checkbox"} className={"checkbox-popup"} disabled="disabled"/>
                                            <label className={'select-tests-popup-label'}> {t('general.ACT')}</label>
                                        </div>
                                        }
                                        {!this.props.ctLastVisit && !this.state.ctSearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input type={"checkbox"} className={"checkbox-popup"} disabled="disabled"/>
                                            <label className={'select-tests-popup-label'}> {t('general.CT')}</label>
                                        </div>
                                        }
                                        {!this.props.chartVALastVisit && !this.state.chartVASearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input type={"checkbox"} className={"checkbox-popup"} disabled="disabled"/>
                                            <label className={'select-tests-popup-label'}>{t('general.ChartVA')}</label>
                                        </div>
                                        }
                                        {!this.props.chartCSLastVisit && !this.state.chartCSSearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input type={"checkbox"} className={"checkbox-popup"} disabled="disabled"/>
                                            <label className={'select-tests-popup-label'}> {t('general.ChartCS')}</label>
                                        </div>
                                        }
                                        {!this.props.fusionalLastVisit && !this.state.fusionalSearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input type={"checkbox"} className={"checkbox-popup"} disabled="disabled"/>
                                            <label className={'select-tests-popup-label'}> {t('general.Fusional')}</label>
                                        </div>
                                        }
                                        {!this.props.readingLastVisit && !this.state.readingSearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input type={"checkbox"} className={"checkbox-popup"} disabled="disabled"/>
                                            <label className={'select-tests-popup-label'}> {t('general.Reading')}</label>
                                        </div>
                                        }
                                        {!this.props.stereoLastVisit && !this.state.stereoSearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input type={"checkbox"} className={"checkbox-popup"} disabled="disabled"/>
                                            <label className={'select-tests-popup-label'}>{t('general.Stereo_Test')}</label>
                                        </div>
                                        }
                                        {!this.props.autoCSLastVisit && !this.state.autoCSSearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input type={"checkbox"} className={"checkbox-popup"} disabled="disabled"/>
                                            <label className={'select-tests-popup-label'}>{t('general.AutoCS')}</label>
                                        </div>
                                        }
                                        {!this.props.autoVALastVisit && !this.state.autoVASearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input type={"checkbox"} className={"checkbox-popup"} disabled="disabled"/>
                                            <label className={'select-tests-popup-label'}> {t('general.AutoVA')}</label>
                                        </div>
                                        }
                                        {!this.props.colorLastVisit && !this.state.colorSearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input type={"checkbox"} className={"checkbox-popup"} disabled="disabled"/>
                                            <label className={'select-tests-popup-label'}> {t('general.Color_Test') }</label>
                                        </div>
                                        }
                                        {!this.props.worth4dotLastVisit && !this.state.worth4DotSearch&&

                                        <div className={'select-tests-checkbox-label'}>
                                            <input type={"checkbox"} className={"checkbox-popup"} disabled="disabled"/>
                                            <label className={'select-tests-popup-label'}> {t('general.Worth4Dot')}</label>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </Popover.Content>
                        </Popover>
                        }>
                        <label className={'test-results-info-block-label'} >{t('ecp.info_filters.selectTests')}</label>
                    </OverlayTrigger>
                </div>


            </div>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return {
        filterPatientsTable:(filterType,payload) => dispatch(filterPatientsTable(filterType,payload)),
    };
}
const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData,
        patientsCount:state.patientsTableDataReducer.patientsCount,
        activePatientsCount:state.patientsTableDataReducer.activePatientsCount,
        pendingPatientsCount:state.patientsTableDataReducer.pendingPatientsCount,
        lockedPatientsCount:state.patientsTableDataReducer.lockedPatientsCount,
    };
};

const PatientTestResult = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(PatientTestResultConnected)

    export default PatientTestResult