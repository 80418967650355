import React, {Component} from 'react'
import PropTypes from "prop-types";
import utils from "../../utils/utils";
import Error from "../single_components/Error";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

class AddressConnected extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entry: this.props.entry,
        }
    }




    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.entry !== prevProps.entry) {
            this.setState({
                entry: this.props.entry
            })
        }
    }


    render() {
        let{t}=this.props
        return (
            <div className={'Patient-personal-Form'}>
                <div className={'Patient-personal-form-container'}>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('ecp.patient.form.street')}</label>
                        <div className={'form-search'}>
                            <input
                                type={"text"}
                                disabled={this.props.isDisabled}
                                className={"nova-form-input"}
                                maxLength={50}
                                defaultValue={utils.get(this.props.entry,'Street') !== null &&
                                utils.get(this.props.entry,'Street') !== undefined ?
                                    utils.get(this.props.entry,'Street') :''}
                                name={'Street'}
                                onChange={(event) => this.props.onValueChange('Street', event.target.value)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'Street')} isShown={!!utils.get(this.props.errors, 'Street')}/>
                        </div>
                    </div>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('ecp.patient.form.apartment')}</label>
                        <div className={'form-search'}>
                            <input
                                type={"text"}
                                disabled={this.props.isDisabled}
                                className={"nova-form-input"}
                                maxLength={50}
                                defaultValue={utils.get(this.props.entry,'Apartment') !== null &&
                                utils.get(this.props.entry,'Apartment') !== undefined ?
                                    utils.get(this.props.entry,'Apartment') :''}
                                name={'Apartment'}
                                onChange={(event) => this.props.onValueChange('Apartment', event.target.value)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'Apartment')} isShown={!!utils.get(this.props.errors, 'Apartment')}/>
                        </div>
                    </div>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('ecp.patient.form.city')}</label>
                        <div className={'form-search'}>
                            <input
                                type={"text"}
                                disabled={this.props.isDisabled}
                                className={"nova-form-input"}
                                maxLength={50}
                                defaultValue={utils.get(this.props.entry,'City') !== null &&
                                utils.get(this.props.entry,'City') !== undefined ?
                                    utils.get(this.props.entry,'City') :''}
                                name={'City'}
                                onChange={(event) => this.props.onValueChange('City', event.target.value)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'City')} isShown={!!utils.get(this.props.errors, 'City')}/>
                        </div>
                    </div>
                </div>
                <div className={'Patient-personal-form-container'}>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>
                            {`${'State'} ${["us","u.s","u.s.","usa","u.s.a","u.s.a.","united states","united states of america"]
                                .includes(String(utils.get(this.props.entry,'Country'))
                                    .toLowerCase()) ? '*':''}`}
                        </label>
                        <div className={'form-search'}>
                            <input
                                type={"text"}
                                disabled={this.props.isDisabled}
                                className={"nova-form-input"}
                                maxLength={50}
                                defaultValue={utils.get(this.props.entry,'State') !== null &&
                                utils.get(this.props.entry,'State') !== undefined ?
                                    utils.get(this.props.entry,'State') :''}
                                name={'State'}
                                onChange={(event) => this.props.onValueChange('State', event.target.value)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'State')} isShown={!!utils.get(this.props.errors, 'State')}/>
                        </div>
                    </div>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('ecp.patient.form.country')} *</label>
                        <div className={'form-search'}>
                            <input
                                type={"text"}
                                disabled={this.props.isDisabled}
                                className={"nova-form-input"}
                                maxLength={50}
                                defaultValue={utils.get(this.props.entry,'Country') !== null &&
                                utils.get(this.props.entry,'Country') !== undefined ?
                                    utils.get(this.props.entry,'Country') :''}
                                name={'Country'}
                                onChange={(event) => this.props.onValueChange('Country', event.target.value)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'Country')} isShown={!!utils.get(this.props.errors, 'Country')}/>
                        </div>
                    </div>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('ecp.patient.form.zip_code')}</label>
                        <div className={'form-search'}>
                            <input
                                type={"text"}
                                disabled={this.props.isDisabled}
                                className={"nova-form-input"}
                                maxLength={50}
                                defaultValue={utils.get(this.props.entry,'ZIPCode') !== null &&
                                utils.get(this.props.entry,'ZIPCode') !== undefined ?
                                    utils.get(this.props.entry,'ZIPCode') :''}
                                name={'ZIPCode'}
                                onChange={(event) => this.props.onValueChange('ZIPCode', event.target.value)}
                            />
                        </div>
                        <Error errorMessage={utils.get(this.props.errors,'ZIPCode')} isShown={!!utils.get(this.props.errors, 'ZIPCode')}/>
                    </div>
                </div>
            </div>
        )
    }
}

const Address = compose(
    withTranslation(),
    connect(
    )
)(AddressConnected)

Address.propTypes = {
    onValueChange:PropTypes.func.isRequired,
    entry: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool
}

export default Address;
