import React, {Component} from "react";
import SvGraphics from "../../assets/SvGraphics";
import Prototypes from 'prop-types'
import VersionEntryForm from "./VersionEntryForm";


class VersionsTabTools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterVersionsTable:'',
            searchValue: '',
            isSideBarOpen: false,
        };
    }

    componentDidMount() {
    }

    filterVersionsTable(val){
        this.setState({searchValue:val})
        this.props.filterVersionsTable(val)
    }

    openSideBar(){
        document.body.style.overflow = "hidden"
        this.setState({isSideBarOpen:true})
    }

    render() {
        let {isSideBarOpen} = this.state
        let {t} = this.props
        let TabTools_Size = this.props.isSmall ? 'EyeSwiftSuperAdmin-TabTools' : 'Admin-TabTools'
        return (
            <div className={`${TabTools_Size} custom-control`}>
                <div className={"TabTools-container"}>
                    <div className={"rounded-button-wrapper"}>
                        <button type={"button"} className={"btn btn-primary rounded-btn"} onClick={e => {
                            let filters = document.getElementsByClassName('table-filter')
                            for (let i = 0; i < filters.length; i++) {
                                if (filters[i].style.display === "none"){
                                    filters[i].style.display = "inline-block";
                                }
                                else filters[i].style.display = "none"
                            }
                        }}>
                            <SvGraphics className={"filter-pic centered"} svgname={'filter'} style={{width: '25px', height: '25px'}}/>
                        </button>
                    </div>
                    <div className={"button-wrapper"}>
                        <button type={"button"} onClick={this.openSideBar.bind(this)} className={"btn btn-primary "}>+ {t('admin.version.tools.add_new')}</button>
                    </div>
                    <div className={"search-wrapper"}>
                        <input className={"nova-input"} placeholder={t('admin.version.tools.search')} type="text" value="" name="search"
                               id={"search"}
                               onChange={event => this.filterVersionsTable(event.target.value)} value={this.state.searchValue}/>
                        <SvGraphics className={"input-pic"} svgname={'search'} style={{width: '20px', height: '20px'}}/>
                    </div>
                </div>

                {isSideBarOpen && <VersionEntryForm
                    t={e => t(e)}
                    title={t('admin.version.tools.add_new')}
                    isSideBarOpen={isSideBarOpen}
                    addNewVersion={admin => this.props.addNewVersion(admin)}
                    closeSideBar={() => this.setState({isSideBarOpen: false})}/>}
            </div>
        );
    }
}
VersionsTabTools.Prototypes = {
    filterVersionsTable: Prototypes.func.isRequired,
    addNewVersion: Prototypes.func.isRequired,
}

export default VersionsTabTools

