import React, {Component} from 'react'
import '../../components_style/PatientScreen.css'
import '../../components_style/rtl_css/PatientScreen.css'
import SvGraphics from "../../assets/SvGraphics";
import {filterPatientsTable} from "../../redux/actions/Tabs-Actions";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import Pagination from "../../components/NovaTable/Pagination";
import ReactTable from "react-table-v6";
import MakeDate from "../../components/modals/DateModal";
import {doctorApi} from "../../services/ApiService";
import utils from "../../utils/utils";
import Popover from "react-bootstrap/Popover";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {history} from "../../utils/history";



class PatientVisitsHistoryConComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nextVisitDate: this.props.nextVisitDate,
            urlToOpen:""
        }

    }

    componentDidMount() {
        this.render()
    }

    handleAddDateClicked = async () => {
        let date = await MakeDate({
            options:{

            }
        });
        if (date){

            let response = await doctorApi.editPatient({NextVisitDate:date},this.props.patientId)

            if (response){
                 this.setState({nextVisitDate:utils.getDateFormatWithYear(new Date(date))})
                this.props.onNextVisitChange(date)
            }
        }

    }
    exitButton = () => {
        document.activeElement.blur();
    }

    openUrl(original){
        window.open(original);

        window.open(this.state.urlToOpen);
    }
    openUrlLastVisit(){
        let lastDate= this.props.lastVisitsData
        let url= lastDate[0].downloadUrl
        window.open(url);
    }
    getColumns = (t) => {
        return [
            {accessor: 'Date', Header: t('admin.version.table.date'), resizable: false},
            {accessor: 'NumberOfTests', Header: t('ecp.info_filters.test_num'), resizable: false/*,Cell: ({original}) => {
                        return (
                            <div>
                                <label className={'label-table'}> {original.NumberOfTests} </label>
                                <SvGraphics svgname={'info'} className={'info-box-style'}/>
                            </div>
                        )
                }*/
            },
            {accessor: 'thirdColumn', Header: ' ', resizable: false},
            {accessor: 'DownloadUrl', Header: ' ', resizable: false, Cell: ({original}) => {
                    return(
                        <p> <a href={original.DownloadUrl} target="_blank">{t('ecp.info_filters.see_test_results')}</a> </p>
                    )
        }
            }

        ]
    }

    render() {
        let {t} = this.props

         let lastVisits = this.props.lastVisitsData
        let visits=[]

            for (const visit of lastVisits) {
                if (visit) {
                    let testResults = visit.TestsResults
                    visits.push({
                        Date: visit.VisitDate.substring(0,10),
                        NumberOfTests: testResults.length,
                        thirdColumn: '',
                        DownloadUrl: visit.downloadUrl
                    })
                }
        }

        let columns = this.getColumns(t)
        return(
            <div className={'nova-patient-visits-history'}>
                 <div className={'nova-patient-visits-history-title'}>{t('ecp.info_filters.visitsHistory')}</div>

                    <div className={'nova-patient-visits-history-boxes'}>
                        <div className={'nova-patient-visits-history-table-box'}>

                            <ReactTable
                                className={'visit-history-table'}
                                data={visits}
                                columns={columns}
                                defaultPageSize={4}
                                showPagination={lastVisits.length>4}
                                height
                                minRows={0}
                                maxRows={4}
                                sortable={true}
                                multiSort={true}
                                PaginationComponent={Pagination}
                                showPageSizeOptions={false}
                            />
                        </div>

                        <div className={'nova-patient-visits-history-right-box'}>
                            <div className={'nova-patient-previous-visits-history-box-title'}>{t('ecp.info_filters.visits')}</div>
                            <div className={'see-test-result-label visits-box h-centered'}>{t('ecp.info_filters.prev_visit')}</div>
                            <div className={'last-visit-date'}> {this.props.lastVisitDate}</div>
                            <label className={'see-test-result-label visits-box h-centered'} onClick={() =>this.openUrlLastVisit()}> {t('ecp.info_filters.see_test_results')} </label>
                        </div>

                        <div  className={'nova-patient-visits-history-right-box'}>
                            <div className={'nova-patient-visits-history-box-title'}>{t('ecp.info_filters.remoteTest')} </div>
                            <div className={'nova-patient-remote-data'}>
                                <label className={'first-data-label'}>{t('ecp.info_filters.prev_test')}</label>
                                <label className={'second-label'}></label>
{/*
                                <label className={'see-test-result-label remote-box h-centered'} onClick={() =>this.openUrl()}> {t('ecp.info_filters.see_test_results')} </label>
*/}
                                <label className={'see-test-result-label remote-box h-centered'}> {t('ecp.info_filters.see_test_results')} </label>
                            </div>
                            <div className={'nova-patient-remote-data'}>
                                <label className={'first-data-label'}>{t('ecp.info_filters.next_test')}</label>
                                <label className={'second-label'}>{this.state.nextVisitDate}</label>

                               {/* <input id={'date_div_input'} className={"second-label"} type="date" name="trip-start"
                                       defaultValue = {new Date().toISOString().substr(0,10)}
                                />*/}

                                {/*<button
                                    id={'PatientAddAVisitDate'}
                                    disabled={this.props.completed}
                                    className={'reschedule-button'}
                                    onClick={async e => {
                                        await this.handleAddDateClicked(e)
                                        this.exitButton(e)
                                    }}>
                                    {t('ecp.info_filters.reschedule')}
                                </button>*/}
                                <button
                                    id={'PatientAddAVisitDate'}
                                    disabled={this.props.completed}
                                    className={'reschedule-button'}
                                    onClick={async e => {
                                     /*   await this.handleAddDateClicked(e)
                                        this.exitButton(e)*/
                                    }}>
                                    {t('ecp.info_filters.reschedule')}
                                </button>

{/*
                                <button className={'reschedule-button'}>  {t('ecp.info_filters.reschedule')} </button>
*/}
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return {
        filterPatientsTable:(filterType,payload) => dispatch(filterPatientsTable(filterType,payload)),
    };
}
const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData,
        patientsCount:state.patientsTableDataReducer.patientsCount,
        activePatientsCount:state.patientsTableDataReducer.activePatientsCount,
        pendingPatientsCount:state.patientsTableDataReducer.pendingPatientsCount,
        lockedPatientsCount:state.patientsTableDataReducer.lockedPatientsCount,
    };
};

const PatientVisitsHistory = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(PatientVisitsHistoryConComposed)


 export default PatientVisitsHistory

