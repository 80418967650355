import React, {Component} from 'react'
import {authenticationService} from '../../services/AuthenticationService';

import SvGraphics from "../../assets/SvGraphics";
import * as Yup from "yup";
import validationHelper from "../../utils/validationHelper";
import utils from "../../utils/utils";
import Error from "../../components/single_components/Error";
import Config from "../../config/Config";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class PasswordConfirmComposed extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    async componentWillMount() {
    }

    componentDidMount() {
    }

    onSubmit = async () => {
        this.props.history.push("/login");

    }


    render() {
        let {t} = this.props
        let SVGName ='eye_swift_blue'
        let isSigned = true
        let user=authenticationService.pwdUserValue?authenticationService.pwdUserValue:""
        let role=user.role?user.role[0]:""
        if (role==="Doctor")
            isSigned = authenticationService.pwdUserValue ? !!authenticationService.pwdUserValue.profile.Doctor.TermsOfUseSigned : false;
        let bottomSVG= <SvGraphics svgname={'nova_sight'} className={'nova-sight-login-logo'} style={{width:'183px'}}/>
        if(process.env.REACT_APP_ENV === "essilorstaging" || process.env.REACT_APP_ENV === "essilorproduction" )
        {
            SVGName ='eyeviz'
            bottomSVG =<img src={require('../../assets/Essilor-img.png')} style={{width:'500px'}} />
        }
        return (
            <div className={"login-page"}>
                <div className={'h-centered eye-swift-login-logo-container'}>
                    <SvGraphics svgname={SVGName} className={'eye-swift-login-logo'} style={{width:'340px'}}/>
                </div>
                <div className={'centered eye-swift-login-main-board'}>
                    <label className={'eye-swift-login-label'}>{t('defaults.crt_pwd.pwd_confirm')}</label>
                    <div className={'eye-swift-login-form-container'}>
                        <div className={'h-centered eye-swift-login-form'}>

                            <label className={'eye-swift-password-confirm-label'}>{t('defaults.crt_pwd.pwd_confirm_text')}</label>

                            <div className="form-group eye-swift-login-form-group">
                                <button className="btn h-centered btn-primary eye-swift-login-form-button" onClick={this.onSubmit}>{t('defaults.crt_pwd.back_Login')}</button>
                            </div>

                        </div>
                    </div>
                    <div className={'eye-swift-login-form-group loginError red h-centered'}>
                        <Error isNonFloat={true} errorMessage={t('defaults.crt_pwd.auth_err') + ': ' + this.state.errMessage} isShown={this.state.isErr}/>
                    </div>
                    {/*<div className={'h-centered eye-swift-login-footer-container'}*/}
                    {/*     onClick={e => this.props.history.goBack()}>*/}
                    {/*    <a>Back to Login</a>*/}
                    {/*</div>*/}
                </div>
                <div className={'h-centered nova-sight-login-logo-container'}>
                    {/*<SvGraphics svgname={SVGLogo} className={'nova-sight-login-logo'} style={{width:'183px'}}/>*/}
                    {bottomSVG}
                </div>

                <div className={'login-version-element'}>
                    <label className={'login-version-element-label'}>Version:</label>
                    <label className={'login-version-element-value'}>{Config.version}</label>
                </div>
            </div>

        )
    }
}

let PasswordConfirm = compose(
    withTranslation()
)(PasswordConfirmComposed)

export default PasswordConfirm;
