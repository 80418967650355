import {authenticationService} from "../AuthenticationService";
import axios from "axios";
import Config from "../../config/Config";

const adminApi = {

    AddNewSite: async (site) => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;

        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return (await axios.post(
            `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/site/signup`,
            site,
            requestconfig
        ));
    },
    EditSite: async (site_data,SiteStorageID) => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;

        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return (await axios.post(
            `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/sites/${SiteStorageID}/profile`,
            site_data,
            requestconfig
        ));
    },
    getAllSites: async () => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;

            let requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authenticationService.currentUserValue.token,
                },
            };
            return await axios.get(
                `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/sites/getAllSites`,
                requestconfig
            );

    },
    getAllPendings: async () => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;

            let requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authenticationService.currentUserValue.token,
                },
            };
            return await axios.get(
                `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/pendings/getAllPendings`,
                requestconfig
            );

    },
    AddNewECP: async (ecp) => {
        if (!authenticationService.currentUserValue) return;
        let SystemAdmin = authenticationService.currentUserValue;

            let requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authenticationService.currentUserValue.token,
                },
            };
            return (await axios.post(
                `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/doctors/signup`,
                ecp,
                requestconfig
            ));
    },
    EditECP: async (ecp_data,ECP_UserStorageID) => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;

            let requestconfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authenticationService.currentUserValue.token,
                },
            };
            return (await axios.post(
                `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/doctors/${ECP_UserStorageID}/profile`,
                ecp_data,
                requestconfig
            ));
            },
    getAllECPs: async () => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return await axios.get(
            `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/doctors/getAllECP`,
            requestconfig
        );
        },
    AddNewAdmin: async (admin) => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return await axios.post(
            `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/admins/signup`,
            admin,
            requestconfig)
    },
    EditAdmin: async (admin_data,Admin_UserStorageID) => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return (await axios.post(
            `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/admins/${Admin_UserStorageID}/profile`,
            admin_data,
            requestconfig
        ));
        },
    deleteAdmin: async (Admin_UserStorageID) => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return (await axios.delete(
            `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/admins/${Admin_UserStorageID}/profile`,
            requestconfig
        ));
        },

    getAllAdmins: async () => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return await axios.get(
            `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/admins/getAllAdmins`,
            requestconfig
        );
        },
    getAllDevices: async () => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return await axios.get(
            `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/devices/getAllDevices`,
            requestconfig
        );
        },
    AddNewVersion: async (version) => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return (await axios.post(
            `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/versions/addNewVersion`,
            version,
            requestconfig
        ));
        },
    editVersion: async (version_data) => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return (await axios.post(
            `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/versions/editVersion`,
            version_data,
            requestconfig
        ));
        },
    getAllVersions: async () => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return await axios.get(
            `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/versions/getAllVersions`,
            requestconfig
        );
        },
    deleteVersion: async (VersionID) => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return (await axios.delete(
            `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/versions/${VersionID}`,
            requestconfig
        ));
        },
    sendMessageToPatients: async (data) => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return (await axios.post(
            `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/patients/sendMessages`,
            data,
            requestconfig
        ));
        },
    sendMessageToEcp: async (data) => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return (await axios.post(
            `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/doctors/sendMessages`,
            data,
            requestconfig
        ));
        },
    deleteSite: async (SiteStorageID) => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return (await axios.delete(
            `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/sites/${SiteStorageID}`,
            requestconfig
        ));
        },

    deletePending: async (pending_id) => {
        if (!authenticationService.currentUserValue)
            return;
        let SystemAdmin = authenticationService.currentUserValue;
        let requestconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationService.currentUserValue.token,
            },
        };
        return (await axios.delete(
            `${Config.globalUrl}/api/v1/webapp/eyeSwiftAdmins/${SystemAdmin.profile.UserStorageID}/pending/${pending_id}`,
            requestconfig
        ));
    },

}

    export default adminApi;
