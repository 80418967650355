import React, {Component} from 'react'
import SvGraphics from "../../assets/SvGraphics";
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import '../../components_style/Faqs.css'
import '../../components_style/rtl_css/Faqs.css'
import Config from "../../config/Config";
import {authenticationService} from "../../services/AuthenticationService";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class SettingsComposed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        }
    }


    render() {
        let {t}=this.props
        let history = this.props.history
        return (
            <OverlayTrigger
                placement={"bottom"}
                delay={{show: 250, hide: 250}}
                trigger={"click"}
                rootClose

                overlay={
                    <Popover id="popover-faqs">
                        <Popover.Content>
                            <div className={'faqs-element'}>
                                {
                                    history.location.pathname.includes('/dashboard/siteadmin') &&
                                    <div>
                                        <div className={'faqs-element-value pointer'} onClick={() => {
                                            history.push('/dashboard/patients');
                                            this.setState(this.state);
                                        }}>{t('general.Switch_to_ECP_view')}</div>
                                    </div>
                                }
                                {
                                    !history.location.pathname.includes('/dashboard/siteadmin') &&
                                    <div>
                                        <div className={'faqs-element-label pointer'} onClick={() => {
                                            history.push('/dashboard/siteadmin');
                                            this.setState(this.state);
                                        }}>{t('general.Switch_to_site_admin_view')}</div>
                                    </div>
                                }
                            </div>
                        </Popover.Content>
                    </Popover>
                }>
                <SvGraphics svgname={'setting-gear'} className={'pointer'} width={'20px'} height={'20px'}/>
            </OverlayTrigger>
        );
    }
}

let Settings = compose(
    withTranslation()
)(SettingsComposed)
export default Settings;

