import React, {Component} from "react";
import SvGraphics from "../../assets/SvGraphics";
import Prototypes from 'prop-types'
import {history} from "../../utils/history";
import {sendMessage} from "../modals/MessageModal";
import {makeError} from "../modals/ErrorModal";
import {doctorApi} from "../../services/ApiService";

class PatientsTabTools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterPatientsTable:'',
            searchValue: '',
        };
    }

    componentDidMount() {
        this.searchPatientsTable('')
    }

    searchPatientsTable(val){
        this.setState({searchValue:val})
        this.props.searchPatientsTable(val)
    }

     refreshPatientsTable() {
        this.props.refreshPatientsTable()
    }

    crateNewPatient(){
        history.push({
            pathname: `/dashboard/AddPatient`,
            state: {
                from: {
                    fromPath: `/dashboard`,
                }
            },
        })
    }

    sendMessage = async () => {
        let {t} = this.props
        if (this.props.recipients.length > 0){
            let Method = await sendMessage({
            },this.props.recipients);
            if (Method){
                let PatientsIDs = this.props.recipients.map(patient =>{
                    return patient.id
                })
                await doctorApi.sendMessageToPatients({Method,PatientsIDs})
            }
        } else {
            await makeError({
                proceedLabel: t('ecp.patient.tools.ok'),
                options:{
                    title: t('ecp.patient.tools.could_not_opn_msg'),
                }
            },[t('ecp.patient.tools.select_recipients')]);
        }
    }

    render() {
        let {t} = this.props
        return (
            <div className={`Doctor-TabTools custom-control`}>
                <div className={"TabTools-container"}>
                  <div className={"rounded-button-wrapper"}>
                        <button type={"button"} className={"btn btn-primary rounded-btn"} onClick={this.refreshPatientsTable.bind(this)}>
                            <SvGraphics className={"mail-pic centered"} svgname={'refreshIcon'} style={{width: '25px', height: '25px'}}/>
                        </button>
                    </div>
                    {/*
                    <div className={"rounded-button-wrapper"}>
                        <button type={"button"} className={"btn btn-primary rounded-btn"} onClick={this.sendMessage.bind(this)}>
                            <SvGraphics className={"mail-pic centered"} svgname={'mail'} style={{width: '25px', height: '25px'}}/>
                        </button>
                    </div>
                    <div className={"rounded-button-wrapper"}>
                        <button type={"button"} className={"btn btn-primary rounded-btn"} onClick={() => {
                            let filters = document.getElementsByClassName('table-filter')
                            for (let i = 0; i < filters.length; i++) {
                                if (filters[i].style.display === "none"){
                                    filters[i].style.display = "inline-block";
                                }
                                else filters[i].style.display = "none"
                            }
                        }}>
                            <SvGraphics className={"filter-pic centered"} svgname={'filter'} style={{width: '25px', height: '25px'}}/>
                        </button>
                    </div>*/}
                    <div className={"button-wrapper"}>
                        <button type={"button"} onClick={this.crateNewPatient.bind(this)} className={"btn btn-primary "}>{'+'+t('ecp.patient.tools.add_new')}</button>
                    </div>
                    <div className={"search-wrapper"}>
                        <input className={"nova-input"} placeholder={t('ecp.patient.tools.search')} type="text" name="search"
                               id={"search"}
                               onChange={event => this.searchPatientsTable(event.target.value)} value={this.state.searchValue}/>
                        <SvGraphics className={"input-pic"} svgname={'search'} style={{width: '20px', height: '20px'}}/>
                    </div>
                </div>
            </div>
        );
    }
}


PatientsTabTools.Prototypes = {
    searchPatientsTable: Prototypes.func.isRequired,
    refreshPatientsTable: Prototypes.func.isRequired,
    addNewPatient: Prototypes.func.isRequired
}

export default PatientsTabTools

