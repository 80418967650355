import React, {Component} from 'react'
import ReactApexChart from "react-apexcharts";
import SvGraphics from "./assets/SvGraphics";
import utils from "./utils/utils";
import {getDateFormat, getTimeFormat} from "./utils/getDateTimeFormat";
import {authenticationService} from "./services/AuthenticationService";
import {checkRange} from "./utils/CheckRange";

class DoctorDashboardChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visibleGraphData: 0,
            maxData: 3,
        }
    }

    slideGraphBy(val){
        this.setState({visibleGraphData:this.state.visibleGraphData+val})
    }
    render() {

        let options = {
            legend: {show: false},
            visibleGraphData: 0,
            varMySite: 0,
            varGlobal: 0,
            bar:{
                columnWidth: '20%',
            },
            chart: {
                background: '#fff',
                dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: false
                }, zoom: {
                    enabled: false,
                },

            },
            colors: ['#37bc9b','#25a6ff']
            ,
            plotOptions: {
                bar: {
                    columnWidth: '40px',
                    distributed: true
                }
            },
            stroke: {
                display: false,
                curve: 'smooth',
            },
            grid: {
                borderColor: 'white',
                row: {
                    opacity: 0.5
                }
            },
            markers: {
                size: 1
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val;
                },
            },
            xaxis: {
                max:31,
                tickAmount: 5,
                categories: this.props.xCategories,
                title: {
                    text: this.props.xtitle,
                    style: {
                        fontSize: '15px',
                        fontFamily: 'OpenSan, sans-serif',
                        fontWeight: 300,
                        letterSpacing: '0.35px'
                    }
                },
                labels: {
                    formatter: function (value) {
                        return value;
                    }
                }

            },
            yaxis: {
                min: this.props.minYValue,
                max: this.props.maxYValue,
                tickAmount: this.props.tickAmount,
                forceNiceScale: true,
                title: {
                    text: this.props.ytitle,
                    style: {
                        fontSize: '15px',
                        fontFamily: 'OpenSan, sans-serif',
                        fontWeight: 300,
                        letterSpacing: '0.35px',
                    }
                },  labels: {
                    formatter: function (val, index) {
                        return val;
                        /*return val;*/
                    }
                }, axisBorder: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0
                },
            }, tooltip: {
            y: {
                formatter: function (val) {
                return val
            }
        }
    }
        }
        let firstSeries=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
        let secondSeries=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
        let lastVisits = this.props.visitData
        let series =[]
        let current  = authenticationService.currentUserValue.profile.Doctor.SiteStorageID
        let rangeIndex=0
        let mySiteCount=0
        let globalSitesCount=0
        if(lastVisits){
            for (const visit of lastVisits) {
                for (const testResults of visit.TestsResults) {
                    if(this.props.type === "exo"){
                        if (testResults.ACTTestStorageID > 0) {
                            if(testResults.ACTTestResult.ResultInDioptersHorizontalDirection==="exo"){
                                if(visit.SiteStorageID === current){
                                    rangeIndex=checkRange(testResults.ACTTestResult.ResultInDioptersHorizontalValue)
                                    if(rangeIndex>0){
                                        mySiteCount=mySiteCount+1
                                        firstSeries[rangeIndex-1]=firstSeries[rangeIndex-1]+1

                                    }
                                }else{
                                    rangeIndex=checkRange(testResults.ACTTestResult.ResultInDioptersHorizontalValue)
                                    if(rangeIndex>0)
                                    {
                                        globalSitesCount=globalSitesCount+1
                                        secondSeries[rangeIndex-1]=firstSeries[rangeIndex-1]+1

                                    }
                                }
                            }
                        }
                    }else{
                        if (testResults.FusionalReserveTestStorageID > 0) {
                            if(visit.SiteStorageID === current){
                                rangeIndex=checkRange(testResults.FusionalReservesResult.FusionalConvergence)
                                if(rangeIndex>0) {
                                    mySiteCount=mySiteCount+1
                                    firstSeries[rangeIndex-1]=firstSeries[rangeIndex-1]+1

                                }
                            }else{
                                rangeIndex=checkRange(testResults.FusionalReservesResult.FusionalConvergence)
                                if(rangeIndex>0)
                                {
                                    globalSitesCount=globalSitesCount+1
                                    secondSeries[rangeIndex-1]=firstSeries[rangeIndex-1]+1

                                }
                            }
                        }
                    }
                }
            }

            for (let i = 0; i < firstSeries.length; i++) {
                if(firstSeries[i]!==0)
                    firstSeries[i] = Math.round((firstSeries[i]/mySiteCount) * 100) ;
            }
            for (let i = 0; i < secondSeries.length; i++) {
                if(secondSeries[i]!==0)
                    secondSeries[i] = Math.round((secondSeries[i]/globalSitesCount) * 100 );
            }

            series = [{
                name: 'My Site',
                data: firstSeries
            }, {
                name: 'Global',
                data: secondSeries
            }]
        }


        return(

            <div className={'chart-area'}>
                <div className={'chart-container'}>
                        <ReactApexChart options={options} series={series} type={this.props.chartType}/>
                </div>
            </div>


        );
    }


}
export default DoctorDashboardChart
