import React, {Component} from 'react'
import SvGraphics from "../../assets/SvGraphics";
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import '../../components_style/Faqs.css'
import '../../components_style/rtl_css/Faqs.css'
import Config from "../../config/Config";

class Faqs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        }
    }


    render() {
        return (
            <OverlayTrigger
                placement={"bottom"}
                delay={{show: 250, hide: 250}}
                trigger={"click"}
                rootClose

                overlay={
                    <Popover id="popover-faqs">
                        <Popover.Content>
                            <div className={'faqs-element'}>
                                <label className={'faqs-element-label'}>Version:</label>
                                <label className={'faqs-element-value'}>{Config.version}</label>
                            </div>
                        </Popover.Content>
                    </Popover>
                }>
                <SvGraphics svgname={'faqs'} width={'20px'} height={'20px'}/>
            </OverlayTrigger>
        );
    }
}

Faqs.propTypes = {

};
export default Faqs;

