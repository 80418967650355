import React, {Component} from "react";
import SvGraphics from "../../assets/SvGraphics";
import Prototypes from 'prop-types'
import EcpEntryForm from "./EcpEntryForm";
import {adminApi} from "../../services/ApiService";
import {makeError} from "../modals/ErrorModal";
import {sendMessage} from "../modals/MessageModal";
import {authenticationService} from "../../services/AuthenticationService";
import SiteAdminEcpEntryForm from "../siteadmin/ecp/SiteAdminEcpEntryForm";

class ECPsTabTools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterEcpTable:'',
            searchValue: '',
            isSideBarOpen: false,
        };
    }

    componentDidMount() {
    }

    filterEcpTable(val){
        this.setState({searchValue:val})
        this.props.filterEcpTable(val)
    }

    openSideBar(){
        document.body.style.overflow = "hidden"
        this.setState({isSideBarOpen:true})
    }

    sendMessage = async () => {
        let {t} = this.props
        if (this.props.recipients.length > 0){
            let Method = await sendMessage({
            },this.props.recipients);
            if (Method){
                let DoctorsIDs = this.props.recipients.map(user =>{
                    return user.id
                })
                await adminApi.sendMessageToEcp({Method,DoctorsIDs})
            }
        } else {
            await makeError({
                proceedLabel: t('admin.ecp.tools.ok'),
                options:{
                    title: t('admin.ecp.tools.could_not_opn_msg'),
                }
            },[t('admin.ecp.tools.select_recipients')]);
        }
    }

    render() {
        let {t} = this.props
        let {isSideBarOpen} = this.state
        let current  = authenticationService.currentUserValue
        let TabTools_Size =current?.profile?.Doctor?.isSiteAdmin ? 'SiteAdmin-TabTools' :  this.props.isSmall ? 'EyeSwiftSuperAdmin-TabTools' : 'Admin-TabTools'
        return (
            <div className={`${TabTools_Size} custom-control`}>
                <div className={"TabTools-container"}>
                    <div className={"rounded-button-wrapper"}>
                        <button disabled type={"button"} className={"btn btn-primary rounded-btn"} onClick={this.sendMessage.bind(this)}>
                            <SvGraphics className={"mail-pic centered"} svgname={'mail'} style={{width: '25px', height: '25px'}}/>
                        </button>
                    </div>
                    <div className={"rounded-button-wrapper"}>
                        <button type={"button"} className={"btn btn-primary rounded-btn"} onClick={() => {
                            let filters = document.getElementsByClassName('table-filter')
                            for (let i = 0; i < filters.length; i++) {
                                if (filters[i].style.display === "none"){
                                    filters[i].style.display = "inline-block";
                                }
                                else filters[i].style.display = "none"
                            }
                        }}>
                            <SvGraphics className={"filter-pic centered"} svgname={'filter'} style={{width: '25px', height: '25px'}}/>
                        </button>
                    </div>
                    <div className={"button-wrapper"}>
                        <button type={"button"} onClick={this.openSideBar.bind(this)} className={"btn btn-primary "}>+ {t('admin.ecp.tools.add_new')}</button>
                    </div>
                    <div className={"search-wrapper"}>
                        <input className={"nova-input"} placeholder={t('admin.ecp.tools.search')} type="text" name="search"
                               id={"search"}
                               onChange={event => this.filterEcpTable(event.target.value)} value={this.state.searchValue}/>
                       <SvGraphics className={"input-pic"} svgname={'search'} style={{width: '20px', height: '20px'}}/>
                    </div>
                </div>
                {isSideBarOpen && current?.profile?.Doctor?.isSiteAdmin && <SiteAdminEcpEntryForm
                    t={e => t(e)}
                    title={t('admin.ecp.form.add_new')}
                    isSideBarOpen={isSideBarOpen}
                    addNewECP={ecp => this.props.addNewECP(ecp)}
                    closeSideBar={() => this.setState({isSideBarOpen: false})}/>}
                {isSideBarOpen && !current?.profile?.Doctor?.isSiteAdmin && <EcpEntryForm
                    t={e => t(e)}
                    title={t('admin.ecp.form.add_new')}
                    isSideBarOpen={isSideBarOpen}
                    addNewECP={ecp => this.props.addNewECP(ecp)}
                    closeSideBar={() => this.setState({isSideBarOpen: false})}/>}
            </div>
        );
    }
}

ECPsTabTools.Prototypes = {
    filterEcpTable: Prototypes.func.isRequired,
    addNewECP: Prototypes.func.isRequired,
}

export default ECPsTabTools

