import React, {Component} from 'react'
import Prototypes from 'prop-types'
import '../../components_style/NovaTableTabs.css'
import '../../components_style/rtl_css/NovaTableTabs.css'
class NovaTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab_option: 'tab-inactive'
        }
    }

    componentDidMount = ()  => {
        if (this.props.tab_option){
            this.setState({tab_option:this.props.tab_option})
        }
    }


    render() {
        let active_tab = 'tab-active';
        let inactive_tab = 'tab-inactive'
        return (
            <div onClick={this.props.onClick} data-toggle="pill" className={`nova-tab-container inline ${this.props.status?active_tab:inactive_tab}`}>
                <label className={`nova-tab-label centered`}>{`${this.props.value}${this.props.count?` (${this.props.count})`:''}`}</label>
            </div>

        )
    }
}
NovaTab.Prototypes = {
    tab_option: Prototypes.string
};
export default NovaTab;
