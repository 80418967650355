import { combineReducers } from 'redux'

import pendingTableDataReducer from './admin/pendingTableDataReducer'
import ecpTableDataReducer from './admin/ecpTableDataReducer'
import siteTableDataReducer from './admin/siteTableDataReducer'
import deviceTableDataReducer from './admin/deviceTableDataReducer'
import adminTableDataReducer from './admin/adminTableDataReducer'
import versionTableDataReducer from './admin/versionTableDataReducer'
import patientsTableDataReducer from './doctor/patientTableDataReducer'
import patients_archiveTableDataReducer from './doctor/patients_archiveTableDataReducer'

export default combineReducers({
    ecpTableDataReducer,
    pendingTableDataReducer,
    siteTableDataReducer,
    deviceTableDataReducer,
    adminTableDataReducer,
    versionTableDataReducer,
    patientsTableDataReducer,
    patients_archiveTableDataReducer,
})
