import React from "react";
import PropTypes from "prop-types";
import {Modal} from 'react-bootstrap'
import {confirmable, createConfirmation} from "react-confirm";

import SvGraphics from "../../assets/SvGraphics";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class MessageModalComposed extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value:null,
            sms:false,
            email: false,
            both:false
        }
    }

    componentDidMount() {
        document.getElementById('err-modal-ok-button').focus();
        this.toggle.bind(this)
    }

    toggle(event,state) {
        let sms,email,both = false;
        switch (state) {
            case 'SMS': {sms = true; break;}
            case 'EMAIL': {email = true; break;}
            case 'BOTH': {both = true; break;}
            default:  break;
        }
        this.setState({
            sms,email,both, value:state
        })
    }

    render() {
        let {
            recipients,
            show,
            proceed,
            enableEscape = true,
            t
        } = this.props;

        let title
        if (recipients.length === 1){
            title = `${t('modal.snd_msg_to')} ${recipients[0].name}`
        } else title = String(t('modal.snd_msg_to_x_users')).replace('@#R!TR',recipients.length)

        let {sms,email,both} = this.state
        return (
            <Modal
                centered={true}
                show={show}
                onHide={() => proceed(false)}
                backdrop={enableEscape ? true : "static"}
                keyboard={enableEscape}
                dialogClassName={'nova-modal'}>

                <Modal.Body className={'nova-modal-body'}>
                    <SvGraphics svgname={'close'} height={'15px'} width={'15px'} style={{
                        float: 'left',
                        top: '10px',
                        left: '10px',
                        position: 'absolute',
                        cursor: 'pointer'
                    }}
                              onClick={() => proceed(false)}/>
                    <label className={'nova-modal-title'}>{title}</label>
                    <div className={'nova-modal-content'}>
                            <div className={' Message-modal-content h-centered'}>
                                <input
                                    type={"radio"} disabled={this.props.isNewVisit || this.props.isDisabled}
                                    className={"radio-button"}
                                    value={"SMS"}
                                    defaultChecked={sms}
                                    name={'Message'}
                                    id={'SMS'}
                                    onChange={(event) => this.toggle(event,'SMS')}
                                />
                                <label className={`MessageRadiosLabel-container SMS-container${sms?'-active':''}`} htmlFor={'SMS'}>
                                    <div className={'MessageRadiosLabel'}>{t('modal.sms')}</div>
                                </label>
                                <input
                                    type={"radio"} disabled={this.props.isNewVisit || this.props.isDisabled}
                                    className={"radio-button"}
                                    value={"EMAIL"}
                                    defaultChecked={email}
                                    name={'Message'}
                                    id={'EMAIL'}
                                    onChange={(event) => this.toggle(event,'EMAIL')}
                                />
                                <label className={`MessageRadiosLabel-container EMAIL-container${email?'-active':''}`} htmlFor={'EMAIL'}>
                                    <div className={'MessageRadiosLabel'}>{t('modal.email')}</div>
                                </label>
                                <input
                                    type={"radio"} disabled={this.props.isNewVisit || this.props.isDisabled}
                                    className={"radio-button"}
                                    value={"BOTH"}
                                    name={'Message'}
                                    defaultChecked={both}
                                    id={'BOTH'}
                                    onChange={(event) => this.toggle(event,'BOTH')}
                                />
                                <label className={`MessageRadiosLabel-container BOTH-container${both?'-active':''}`} htmlFor={'BOTH'}>
                                    <div className={'MessageRadiosLabel'}>{t('modal.both')}</div>
                                </label>
                            </div>
                    </div>
                    <div className={'nova-modal-footer h-centered'}>
                        <button id={'err-modal-ok-button'} className={'btn btn-primary modal-confirm'}
                                onClick={() => {
                                    if (this.state.value !== null)
                                    proceed(this.state.value)
                                }} onKeyPress={event => {
                            if (event.key === 'Enter') {
                                if (this.state.value !== null)
                                    proceed(this.state.value)
                            }
                        }}>{t('modal.send')}</button>
                        <button className={'btn btn-primary modal-close'} onClick={() => proceed(false)}>{t('modal.cancel')}</button> <div/>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

let MessageModal = compose(
    withTranslation()
)(MessageModalComposed)

MessageModal.propTypes = {
    title: PropTypes.string,
    recipients: PropTypes.array,
    show: PropTypes.bool,
    proceed: PropTypes.func, // called when ok button is clicked.
    enableEscape: PropTypes.bool
};
export function sendMessage({options: options = {}},recipients) {
    return createConfirmation(confirmable(MessageModal))({
        recipients,
        ...options
    });
}
