import React, {Component} from 'react'
import PropTypes from "prop-types";

class NotFound extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {

        let {title} = this.props;
        title = title ? title : 'Sorry , this page not available';

        return (
            <div style={{
                textAlign: 'center', height: '100%', fontFamily: 'Archivo Narrow , sans-serif',
                fontSize: '44px', paddingTop: '200px'
            }}>
                {title}
            </div>
        );
    }
}

NotFound.propTypes = {
    title: PropTypes.string
};

export default NotFound;
