import React, {Component} from 'react'
import PropTypes from "prop-types";
import Select from "react-select";

class NovaSelect extends Component {


    render() {
        let {rest} = this.props
        return <Select
            isSearchable={this.props.isSearchable}
            placeholder={this.props.placeholder}
            name={this.props.name}
            options={this.props.options}
            className={`Nova-Select ${this.props.className}`}
            isDisabled={this.props.disabled}
            value={this.props.value}
            defaultValue={this.props.defaultValue}
            onInputChange={inputValue =>
                this.props.maxLength? (inputValue.length <= 20 ? inputValue : inputValue.substr(0, 20)) : inputValue
            }
            onChange={e => this.props.onChange(e)}
            styles={{
                dropdownIndicator: (base, state) => ({
                    ...base,
                    color: "#0575e6",
                    display: this.props.isSearchable ? "none" : 'block'
                }),
                indicatorSeparator: (base, state) => ({
                    ...base,
                    display: "none"
                }),
                container: (base, state) => ({
                    ...base,
                    padding: "0px",
                    height: '40px',
                    cursor: this.props.isSearchable ? 'text' : 'default',
                    boxShadow: "0",
                }),
                control: (base, state) => ({
                    ...base,
                    padding: "0px",
                    cursor: this.props.isSearchable ? 'text' : 'default',
                    boxShadow: "0",
                    border:state.isFocused ? 'solid 0.5px #0575e6' : 'solid 0.5px #e1e1e1',
                    height: '40px',
                    "&:hover": {
                        outline: "solid 1px #e1e1e1;"
                    },
                }),
            }}
            {...rest}
        />
    }
}

/*.css-2b097c-container{
    padding: 0px!important;
    outline: none;
}
.styled__control-is-focused{
    outline: yellow;
}
.css-19bqh2r{
    display: none!important;
}
. css-1wy0on6{
    display: none!important;
}*/

NovaSelect.propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    className: PropTypes.string,
    defaultValue: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    isSearchable: PropTypes.bool,
    rest: PropTypes.object,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number
}

export default NovaSelect;
