import {
    CLEAN_PATIENT,
    DELETE_PATIENT,
    EDIT_PATIENT,
    FILTER_PATIENTSDATA,
    NEW_PATIENT,
    PATIENT_LOCKED_STATUS,
    PATIENTS_COUNT,
    PATIENTS_DATA,
    PATIENTS_SELECTION,
    PATIENTS_STATUSES
} from "../../constants/Tabs-Action-Types";
import ConstantsUtils from "../../../utils/ConstantsUtils";
import {doctorApi} from "../../../services/ApiService";
import Constants from "../../../utils/constants";
import {logger} from "redux-logger/src";
import utils from "../../../utils/utils";

const initialState = {
    initiatePatientsData:[],
    patientsData:[],
    filterPatientsData:[],
    selectedPatientsData:[],
    patientsTableFilter:{},
    patientsCount: 0,
    activePatientsCount: 0,
    pendingPatientsCount: 0,
    childrenCount : 0,
    adultsCount : 0,
    lockedPatientsCount: 0
};

const getTreatmentDurationLabel = (visit,DateOfActivation) => {
    let TreatmentDuration  =  visit ?  visit['TreatmentDuration']: undefined
    if (DateOfActivation !== null && DateOfActivation !== undefined){
        let total = (TreatmentDuration*30).toString()
        let TreatmentStart = new Date(DateOfActivation);
        let today = new Date();
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const diffDays = Math.round(Math.abs((today - TreatmentStart) / oneDay)).toString();
        return diffDays + '/' + total + ' Days'

    } else {
        let total = (TreatmentDuration*30).toString()
        return '0/' + total + ' Days'
    }

}

const getTreatmentDuration = (visit,DateOfActivation) => {
    let TreatmentDuration  =  visit ?  visit['TreatmentDuration']: undefined
    if (DateOfActivation !== null && DateOfActivation !== undefined){
        let total = TreatmentDuration*30
        let TreatmentStart = new Date(DateOfActivation);
        let today = new Date();
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const diffDays = Math.round(Math.abs((today - TreatmentStart) / oneDay)).toString();
        return ((diffDays/total)*100).toFixed(2)
    } else {
        return 0
    }
}

const patientsTableDataReducer = (state = initialState, action)=> {

    switch(action.type){
        case PATIENTS_COUNT: {
            let _idata = JSON.parse(JSON.stringify(action.payload));
            state = {
                ...state,
                patientsCount: _idata
            };
            break;
        }
        case PATIENTS_DATA: {
            let collection = []

            let active = 0
            let pending = 0
            let locked = 0
            let childrenCount = 0;
            let adultsCount = 0;
            let lastvisit= 0;
            action.payload.forEach(patient => {
                if (patient.PII !== undefined && patient.PII !== null) {

                    let GenderConstant = Constants.Genders
                    let gender = GenderConstant.find(elem => elem.value === patient.PII.Gender)
                    if(gender){
                        gender= gender.label
                    }else{
                        gender=""
                    }

                    let firstName= patient.PII.FirstName!=="null"?patient.PII.FirstName:" "
                    let lastName= patient.PII.LastName!=="null"?patient.PII.LastName:" "

                let Age = utils.getAge(new Date(patient.PII.Birthdate))
                    if (patient.AccountStatus === 1) pending++
                    if (patient.AccountStatus === 2) active++
                    if( Age < 18) childrenCount++;
                    else adultsCount++;
                    let birthday= !!patient.PII.Birthdate ? new Date(patient.PII.Birthdate) : ''
                    collection.push({
                        id: patient.UserStorageID,
                        UserStorageID: patient.UserStorageID,
                        DoctorStorageID: patient.DoctorStorageID,
                        TermsOfUseSigned: patient.TermsOfUseSigned,
                        NextVisit: patient.NextVisit !== null && patient.NextVisit !== undefined ? utils.getDateFormatWithYear(new Date(patient.NextVisit)) : '',
                        Notes: patient.Notes,
                        LastNotification: patient.LastNotification,
                        FullName: firstName +' '+lastName,
                        FirstName: firstName,
                        LastName: lastName,
                        Email: patient.PII.Email,
                        Gender: gender, //missing is 0
                        Birthdate: birthday,
                        BirthdateVal:!!patient.PII.Birthdate ? new Date(patient.PII.Birthdate).getTime() : '',
                        Age : Age,
                        LastVisit: patient.VisitDate !== undefined ? patient.VisitDate :'',
                        // LastVisit: !!patient.Visits && patient.Visits.length > 0? (patient.Visits[0])['VisitDate'] : null,
                        SocialID: patient.PII?.SocialID,
                        /*ID: patient.PII.SocialID,*/
                        Country: patient.PII.Country,
                        State: patient.PII.State,
                        City: patient.PII.City,
                        Street: patient.PII.Street,
                        Category: patient.Category,
                        Apartment: patient.PII.Apartment,
                        ZIPCode: patient.PII.ZIPCode,
                        PhoneNumber: patient.PII.PhoneNumber,
                        /*Role: patient.PII.User.Role[0],*/
                        /*Enabled: patient.PII.User.Enabled,*/
                        AccountStatus: patient.AccountStatus,

                        // AccountStatusLabel: ConstantsUtils.getPatient_AccountStatus(patient.AccountStatus),
                        Status: 0
                    })
                }
            })

            let _pdata = JSON.parse(JSON.stringify(collection));
            let _aadata = JSON.parse(JSON.stringify(active));
            let _apdata = JSON.parse(JSON.stringify(pending));
            let _sldata = JSON.parse(JSON.stringify(locked));
            state = {
                ...state,
                initiatePatientsData: _pdata,
                patientsData: _pdata,
                filterPatientsData: _pdata,
                patientsCount: collection.length,
                activePatientsCount: _aadata,
                pendingPatientsCount: _apdata,
                childrenCount:childrenCount,
                adultsCount:adultsCount,
                lockedPatientsCount: _sldata
            };
            break;
        }
        case NEW_PATIENT: {
            let patient = action.payload
            let patientsData = state.patientsData
            let initiatePatientsData = state.initiatePatientsData

            let newData = [{
                id:patient.UserStorageID,
                UserStorageID:patient.UserStorageID,
                DoctorStorageID:patient.Patient.DoctorStorageID,
                TermsOfUseSigned: patient.TermsOfUseSigned,
                NextVisit: patient.NextVisit !== null && patient.NextVisit !== undefined ?utils.getDateFormatWithYear(new Date(patient.NextVisit)):'',
                Notes: patient.Notes,
                LastNotification:patient.LastNotification,
                FullName: patient.PIIProfile.FirstName + ' ' + patient.PIIProfile.LastName,
                FirstName: patient.PIIProfile.FirstName,
                LastName: patient.PIIProfile.LastName,
                Email: patient.PIIProfile.Email,
                Gender: patient.PIIProfile.Gender,
                Birthdate: utils.getDateFormatWithYear(new Date(patient.PIIProfile.Birthdate)),
                SocialID: patient.PIIProfile.SocialID,
                ID: patient.PIIProfile.SocialID,
                Country: patient.PIIProfile.Country,
                State: patient.PIIProfile.State,
                City: patient.PIIProfile.City,
                Street: patient.PIIProfile.Street,
                Apartment: patient.PIIProfile.Apartment,
                ZIPCode: patient.PIIProfile.ZIPCode,
                PhoneNumber: patient.PIIProfile.PhoneNumber,
                Role: patient.Role[0],
                Enabled:patient.Enabled,
                AccountStatus: patient.Patient.AccountStatus,
                // AccountStatusLabel: ConstantsUtils.getPatient_AccountStatus(patient.Patient.AccountStatus),
                Status:1,
            }]

            let newpatientsData = patientsData.concat(newData)
            let newinitiatePatientsData = initiatePatientsData.concat(newData)

            let _pdata = JSON.parse(JSON.stringify(newpatientsData));
            let _idata = JSON.parse(JSON.stringify(newinitiatePatientsData));
            state = {
                ...state,
                initiatePatientsData:_idata,
                patientsData : _pdata,
                patientsCount: newinitiatePatientsData.length,
                pendingPatientsCount: state.activePatientsCount+1,
            };
            break;
        }
        case CLEAN_PATIENT:{
            state = {
                ...state,
                patientsTableFilter: {},
                selectedPatientsData:[]
            };
            break;
        }
        case FILTER_PATIENTSDATA: {
            let type = action.searchType
            let payload = action.payload
            let patientsTableFilter = state.patientsTableFilter
            let filteredPatientsData = state.initiatePatientsData
            let visibleFilterData = []
            let visiableData = []
            if (type === 'status' && payload === 'total'){
                delete patientsTableFilter.status
            } else {
                utils.set(patientsTableFilter,type,payload)
            }

            for (const [key, value] of Object.entries(patientsTableFilter)) {
                if (key === 'search'){
                    let val = value.toLowerCase()
                    filteredPatientsData = filteredPatientsData.filter(x =>
                        String(x.FullName).toLowerCase().indexOf(val) > -1 ||
                        String(x.Birthdate).toLowerCase().indexOf(val) > -1 ||
                        String(x.UserStorageID).toLowerCase().indexOf(val) > -1 ||
                        String(x.SocialID).toLowerCase().indexOf(val) > -1 ||
                        String(x.AccountStatusLabel).toLowerCase().indexOf(val) > -1 ||
                        String(x.NextVisit).toLowerCase().indexOf(val) > -1
                    )
                    visibleFilterData = filteredPatientsData
                } else if (key === 'status') {

                    switch (value) {
                        case 'all':{
                            filteredPatientsData = filteredPatientsData.filter(patient => patient['AccountStatus'] === 2)
                            break;
                        }
                        case 'children':{
                            filteredPatientsData = filteredPatientsData.filter(patient => patient['Age'] < 18)
                            break;
                        }

                        case 'adults':{
                            filteredPatientsData = filteredPatientsData.filter(patient => patient['Age'] >= 18)
                            break;
                        }
                        case 'pending':{
                            filteredPatientsData = filteredPatientsData.filter(patient => patient['AccountStatus'] === 1)
                            break;
                        }
                        case 'lock':{
                            filteredPatientsData = filteredPatientsData.filter(patient => patient['Enabled'] === 0)
                            break;
                        }
                        default:{
                            break;
                        }
                    }
                    visibleFilterData = filteredPatientsData
                } else if (key === 'visitPeriod') {
                    switch (payload) {
                        case 'day': {
                            let startDateTime = (new Date()).toISOString()
                            let day = utils.getDateDay(startDateTime)
                            let month = utils.getDateMonth(startDateTime)
                            let year = utils.getDateYear(startDateTime)
                            filteredPatientsData = filteredPatientsData.filter(patient =>
                               utils.getDateDay(patient['LastVisit']) === day
                            && utils.getDateMonth(patient['LastVisit']) === month
                                && utils.getDateYear(patient['LastVisit']) === year
                            )
                            break;
                        }
                        case 'week': {
                            let startDateTime = (new Date()).toISOString()
                            let day = utils.getDateDay(startDateTime)
                            let month = utils.getDateMonth(startDateTime)
                            let year = utils.getDateYear(startDateTime)
                            let minDay = day-7
                            filteredPatientsData = filteredPatientsData.filter(patient =>
                                utils.getDateDay(patient['LastVisit']) <= day
                            && utils.getDateDay(patient['LastVisit']) >= minDay
                            && utils.getDateMonth(patient['LastVisit']) === month
                            &&utils.getDateYear(patient['LastVisit']) === year
                            )
                            break;
                        }
                        case 'month': {
                            let startDateTime = (new Date()).toISOString()
                            let day = utils.getDateDay(startDateTime)
                            let month = utils.getDateMonth(startDateTime)
                            let year = utils.getDateYear(startDateTime)
                            let minMonth = month-1
                            filteredPatientsData = filteredPatientsData.filter(patient =>
                                ( utils.getDateDay(patient['LastVisit']) <= day && utils.getDateMonth(patient['LastVisit']) === month && utils.getDateYear(patient['LastVisit']) === year)
                                ||( utils.getDateDay(patient['LastVisit']) >= day &&utils.getDateMonth(patient['LastVisit']) === minMonth && utils.getDateYear(patient['LastVisit']) === year)
                            )
                            break;
                        }
                        case 'year': {
                            let startDateTime = (new Date()).toISOString()
                            let day = utils.getDateDay(startDateTime)
                            let month = utils.getDateMonth(startDateTime)
                            let year = utils.getDateYear(startDateTime)
                            let minYear  = year-1
                            filteredPatientsData = filteredPatientsData.filter(patient =>
                                ( utils.getDateDay(patient['LastVisit']) <= day && utils.getDateMonth(patient['LastVisit']) === month && utils.getDateYear(patient['LastVisit']) === year)
                                ||( utils.getDateMonth(patient['LastVisit']) < month && utils.getDateYear(patient['LastVisit']) === year)
                                ||(utils.getDateDay(patient['LastVisit']) >= day && utils.getDateMonth(patient['LastVisit']) === month && utils.getDateYear(patient['LastVisit']) === minYear)
                                ||(utils.getDateMonth(patient['LastVisit']) > month && utils.getDateYear(patient['LastVisit']) === minYear)
                            )
                            break;
                        }
                        default:{
                            break;
                        }
                    }

                } else {
                        let patients = JSON.parse(JSON.stringify(filteredPatientsData))
                        let valArr = JSON.parse(JSON.stringify(value))


                        if (Array.isArray(valArr)){
                            let res = []
                            valArr.forEach(val => {
                                let patientArr = patients.filter(patient => {
                                    let patientVal = patient[key]
                                    return String(patientVal).toLowerCase() === String(val).toLowerCase()
                                })
                                patientArr.forEach(patient => res.push(patient))
                            })
                            filteredPatientsData = res
                        }
                }
            }
            visiableData = filteredPatientsData
            let _fpdata = JSON.parse(JSON.stringify(visibleFilterData));
            let _vdata = JSON.parse(JSON.stringify(visiableData));
            let _pfdata = JSON.parse(JSON.stringify(patientsTableFilter));
            state = {
                ...state,
                filterPatientsData: _fpdata,
                patientsData: _vdata,
                patientsTableFilter: _pfdata
            };
            break;
        }
        case PATIENTS_SELECTION:{
            let selection = action.payload
            let initiatePatientsData = state.initiatePatientsData

            let newSelection = []
            Object.entries(selection).forEach(([key,value]) => {
                if (value){
                    let index = initiatePatientsData.findIndex((obj => obj.UserStorageID === key));
                    let name = initiatePatientsData[index].FullName
                    newSelection.push({id:key,name})
                }
            })
            state = {
                ...state,
                selectedPatientsData: newSelection
            };
            break;
        }
        case EDIT_PATIENT: {
            let patient = action.payload
            let patientsData = state.patientsData
            let initiatePatientsData = state.initiatePatientsData

            let locked = state.lockedPatientsCount

            let indexData = patientsData.findIndex((obj => obj.UserStorageID === patient.UserStorageID));
            let indexInit = initiatePatientsData.findIndex((obj => obj.UserStorageID === patient.UserStorageID));

            let isLocked = patientsData[indexData]['Enabled'] === 0

            Object.keys(patient).forEach(key => {
                let newVal = patient[key]
                if (patientsData[indexData][key])
                    patientsData[indexData][key] = newVal
                if (initiatePatientsData[indexInit][key])
                    initiatePatientsData[indexInit][key] = newVal
            })

            if (isLocked && patientsData[indexData]['Enabled'] === 1) locked--
            else if (!isLocked && patientsData[indexData]['Enabled'] === 0 ) locked++


            let _pdata = JSON.parse(JSON.stringify(patientsData));
            let _idata = JSON.parse(JSON.stringify(initiatePatientsData));

            let _sldata = JSON.parse(JSON.stringify(locked));

            state = {
                ...state,
                initiatePatientsData:_idata,
                patientsData : _pdata,
                filterPatientsData: _pdata,
                lockedPatientsCount: _sldata
            };
            break;
        }
        case PATIENT_LOCKED_STATUS: {
            let patient = action.payload
            let patientsData = state.patientsData
            let initiatePatientsData = state.initiatePatientsData

            let locked = state.lockedPatientsCount

            let indexData = patientsData.findIndex((obj => obj.UserStorageID === patient.UserStorageID));
            let indexInit = initiatePatientsData.findIndex((obj => obj.UserStorageID === patient.UserStorageID));

            let isLocked = patientsData[indexData]['Enabled'] === 0

            let Enabled = patient.PII.User.Enabled

            patientsData[indexData]['Enabled'] = Enabled
            initiatePatientsData[indexInit]['Enabled'] = Enabled

            if (isLocked && patientsData[indexData]['Enabled'] === 1) locked--
            else if (!isLocked && patientsData[indexData]['Enabled'] === 0 ) locked++

            let _pdata = JSON.parse(JSON.stringify(patientsData));
            let _idata = JSON.parse(JSON.stringify(initiatePatientsData));

            let _sldata = JSON.parse(JSON.stringify(locked));
            state = {
                ...state,
                initiatePatientsData:_idata,
                patientsData : _pdata,
                filterPatientsData: _pdata,
                lockedPatientsCount: _sldata
            };
            break;
        }
        case DELETE_PATIENT: {
            let patientId = action.payload
            let patientsData = state.patientsData
            let initiatePatientsData = state.initiatePatientsData
            let locked = state.lockedPatientsCount
            let active = state.activePatientsCount
            let pending = state.pendingPatientsCount

            let indexData = patientsData.findIndex((obj => obj.UserStorageID === patientId));
            let isLocked = patientsData[indexData]['Enabled'] === 0

            let isActive = patientsData[indexData]['AccountStatus'] === 2
            let isPending = patientsData[indexData]['AccountStatus'] === 1

            let newPatientData = patientsData.filter(patient => patient.UserStorageID !== patientId)
            let newInitiatePatientsData = initiatePatientsData.filter(patient => patient.UserStorageID !== patientId)

            if (isLocked) locked--
            if (isActive) active--
            if (isPending) pending--

            let _pdata = JSON.parse(JSON.stringify(newPatientData));
            let _idata = JSON.parse(JSON.stringify(newInitiatePatientsData));

            let _aadata = JSON.parse(JSON.stringify(active));
            let _apdata = JSON.parse(JSON.stringify(pending));
            let _sldata = JSON.parse(JSON.stringify(locked));
            state = {
                ...state,
                initiatePatientsData:_idata,
                patientsData : _pdata,
                filterPatientsData: _pdata,
                patientsCount: newInitiatePatientsData.length,
                activePatientsCount: _aadata,
                pendingPatientsCount: _apdata,
                lockedPatientsCount: _sldata
            };
            break;
        }
        case PATIENTS_STATUSES: {

            break;
        }
        default:
            break;
    }
    return state;
};
export default patientsTableDataReducer;
