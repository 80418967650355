import React, {Component} from 'react'
import SvGraphics from "../../assets/SvGraphics";
import '../../components_style/PatientFormNavigator.css'
import '../../components_style/rtl_css/PatientFormNavigator.css'
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
class PatientFormNavigatorConnected extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0
        }
    }

    componentDidMount = () => {

    }



    render() {
        let{t}=this.props
        return (
            <div className={'nova-patient-form-navigator h-centered'}>
                <div className={'nova-patient-form-navigator-top v-centered'}>
                    <div className={'h-centered nova-patient-form-navigator-top-cont'}>
                        <div className={'nova-patient-form-navigator-step-container h-centered active'}><div className={'pageIndicator-number'}>1</div></div>
                    </div>
                </div>
                <div className={'nova-patient-form-navigator-bottom v-centered'}>
                    <div className={'nova-patient-form-navigator-step-info'}>{t('ecp.patient.form.personal_info')}</div>
                </div>
            </div>
        )
    }
}

const PatientFormNavigator = compose(
    withTranslation(),
    connect(
    )
)(PatientFormNavigatorConnected)

export default PatientFormNavigator;
