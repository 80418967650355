import {
    CLEAN_PATIENT_ARCHIVE,
    FILTER_PATIENTS_ARCHIVEDATA,
    NEW_ARCHIVE_PATIENT,
    PATIENTS_ARCHIVE_COUNT,
    PATIENTS_ARCHIVE_DATA,
    PATIENTS_ARCHIVE_SELECTION
} from "../../constants/Tabs-Action-Types";
import utils from "../../../utils/utils";


const initialState = {
    initiatePatients_ArchiveData:[],
    patients_ArchiveData:[],
    filterPatients_ArchiveData:[],
    selectedPatients_ArchiveData:[],
    patients_ArchiveTableFilter:{},
    patients_ArchiveCount: 0
};

const getTreatmentDurationLabel = (visit,DateOfActivation) => {
    let TreatmentDuration  =  visit ?  visit['TreatmentDuration']: undefined
    if (DateOfActivation !== null && DateOfActivation !== undefined){
        let total = (TreatmentDuration*30).toString()
        let TreatmentStart = new Date(DateOfActivation);
        let today = new Date();
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const diffDays = Math.round(Math.abs((today - TreatmentStart) / oneDay)).toString();
        return diffDays + '/' + total + ' Days'

    } else {
        let total = (TreatmentDuration*30).toString()
        return '0/' + total + ' Days'
    }

}

const getTreatmentDuration = (visit,DateOfActivation) => {
    let TreatmentDuration  =  visit ?  visit['TreatmentDuration']: undefined
    if (DateOfActivation !== null && DateOfActivation !== undefined){
        let total = TreatmentDuration*30
        let TreatmentStart = new Date(DateOfActivation);
        let today = new Date();
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const diffDays = Math.round(Math.abs((today - TreatmentStart) / oneDay)).toString();
        return ((diffDays/total)*100).toFixed(2)
    } else {
        return 0
    }
}

const patients_archiveTableDataReducer = (state = initialState, action)=> {
    switch(action.type){
        case PATIENTS_ARCHIVE_COUNT:{
            let _idata = JSON.parse(JSON.stringify(action.payload));
            state = {
                ...state,
                patients_ArchiveCount: _idata
            };
            break;
        }
        case PATIENTS_ARCHIVE_DATA:
            let collection = []

            action.payload.forEach(patient => {
                if (patient.PII !== undefined && patient.PII !== null) {
                    collection.push({
                        id: patient.UserStorageID,
                        UserStorageID: patient.UserStorageID,
                        DoctorStorageID: patient.DoctorStorageID,
                        DeviceID: patient.DeviceID,
                        DateOfActivation: patient.DateOfActivation,
                        TermsOfUseSigned: patient.TermsOfUseSigned,
                        NextVisit: patient.NextVisit !== null && patient.NextVisit !== undefined ? utils.getDateFormatWithYear(new Date(patient.NextVisit)) : '',
                        Notes: patient.Notes,
                        LastNotification: patient.LastNotification,
                        FullName: patient.PII.FirstName + ' ' + patient.PII.LastName,
                        FirstName: patient.PII.FirstName,
                        LastName: patient.PII.LastName,
                        Email: patient.PII.Email,
                        Gender: patient.PII.Gender, //missing is 0
                        Birthdate: patient.PII.Birthdate,
                        SocialID: patient.PII.SocialID,
                        ID: patient.PII.SocialID,
                        Country: patient.PII.Country,
                        State: patient.PII.State,
                        City: patient.PII.City,
                        Street: patient.PII.Street,
                        Apartment: patient.PII.Apartment,
                        ZIPCode: patient.PII.ZIPCode,
                        PhoneNumber: patient.PII.PhoneNumber,
                        Role: patient.PII.User.Role[0],
                        Enabled: patient.PII.User.Enabled,
                        AccountStatus: patient.AccountStatus,
                        TreatmentProgressValue: getTreatmentDuration(patient.Visits[0], patient.DateOfActivation),
                        TreatmentProgressLabel: getTreatmentDurationLabel(patient.Visits[0], patient.DateOfActivation),
                        Status: 0
                    })
                }
            })

            let _pdata = JSON.parse(JSON.stringify(collection));
            state = {
                ...state,
                initiatePatients_ArchiveData: _pdata,
                patients_ArchiveData: _pdata,
                patients_ArchiveCount: collection.length
            };
            break;
        case CLEAN_PATIENT_ARCHIVE:{
            state = {
                ...state,
                selectedPatients_ArchiveData:[],
                patients_ArchiveTableFilter:{}
            };
            break;
        }
        case FILTER_PATIENTS_ARCHIVEDATA:{
            let type = action.searchType
            let payload = action.payload
            let patients_ArchiveTableFilter = state.patients_ArchiveTableFilter
            let filteredPatientsData = state.initiatePatients_ArchiveData
            let visibleFilterData = []
            let visiableData = []
            if (type === 'status' && payload === 'total'){
                delete patients_ArchiveTableFilter.status
            } else {
                utils.set(patients_ArchiveTableFilter,type,payload)
            }

            for (const [key, value] of Object.entries(patients_ArchiveTableFilter)) {
                if (key === 'search'){
                    let val = value.toLowerCase()
                    filteredPatientsData = filteredPatientsData.filter(x =>
                        String(x.FullName).toLowerCase().indexOf(val) > -1 ||
                        String(x.UserStorageID).toLowerCase().indexOf(val) > -1 ||
                        String(x.SocialID).toLowerCase().indexOf(val) > -1 ||
                        String(x.DeviceID).toLowerCase().indexOf(val) > -1 ||
                        String(x.TreatmentProgressLabel).toLowerCase().indexOf(val) > -1
                    )
                    visibleFilterData = filteredPatientsData
                } else {
                    let patients = JSON.parse(JSON.stringify(filteredPatientsData))
                    let valArr = JSON.parse(JSON.stringify(value))


                    if (Array.isArray(valArr)){
                        let res = []
                        valArr.forEach(val => {
                            let patientArr = patients.filter(patient => {
                                let patientVal = patient[key]
                                return String(patientVal).toLowerCase() === String(val).toLowerCase()
                            })
                            patientArr.forEach(patient => res.push(patient))
                        })
                        filteredPatientsData = res
                    }
                }
            }
            visiableData = filteredPatientsData

            let _fpdata = JSON.parse(JSON.stringify(visibleFilterData));
            let _vdata = JSON.parse(JSON.stringify(visiableData));
            let _pfdata = JSON.parse(JSON.stringify(patients_ArchiveTableFilter));
            state = {
                ...state,
                filterPatients_ArchiveData: _fpdata,
                patients_ArchiveData: _vdata,
                patients_ArchiveTableFilter: _pfdata
            };
            break;
        }

        case PATIENTS_ARCHIVE_SELECTION:{
            let selection = action.payload
            let initiatePatients_ArchiveData = state.initiatePatients_ArchiveData

            let newSelection = []
            Object.entries(selection).forEach(([key,value]) => {
                if (value){
                    let index = initiatePatients_ArchiveData.findIndex((obj => obj.UserStorageID === key));
                    let name = initiatePatients_ArchiveData[index].FullName
                    newSelection.push({id:key,name})
                }
            })
            state = {
                ...state,
                selectedPatients_ArchiveData: newSelection
            };
            break;
        }
        case NEW_ARCHIVE_PATIENT:{
            let patient = action.payload
            let patients_ArchiveCount = state.patients_ArchiveCount

            if (patient.hasOwnProperty('AccountStatus') && (patient.AccountStatus === '3' || patient.AccountStatus === 3))
                patients_ArchiveCount++

            let _cdata = JSON.parse(JSON.stringify(patients_ArchiveCount));
            state = {
                ...state,
                patients_ArchiveCount: _cdata,
            };
            break;
        }
        default:
            break;
    }
    return state;
};
export default patients_archiveTableDataReducer;
