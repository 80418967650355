import React from "react";
import en from '../locales/en/translation.json'
import de from '../locales/de/translation.json'
import he from '../locales/he/translation.json'
import cn from '../locales/cn/translation.json'
import fr from '../locales/fr/translation.json'
import LanguageDetector from 'i18next-browser-languagedetector';
import postProcessor from 'i18next-sprintf-postprocessor';
import {initReactI18next} from "react-i18next";
import Cache from 'i18next-localstorage-cache';
import i18next from 'i18next';
import {AllUsersApi} from "../services/ApiService";
import utils from "./utils";

class Translate {

    //Language Config
    language = !!localStorage.getItem('language') ? localStorage.getItem('language') : 'en'
    fallbackLng = !!localStorage.getItem('language') ? localStorage.getItem('language') : 'en'
    load = 'all'
    languages = {
        en, de, he, cn,fr
    }
    cache = Cache
    languageDetector = LanguageDetector
    postProcessor = postProcessor
    initReactI18next = initReactI18next
    country_language = {US:'en',DE:'de',IL:'he',CN:'cn',FR:'fr'}
    rtl = ['he']


    //Language Utils

    getLanguage(country) {
        if (this.country_language[country]) return this.country_language[country]
        else return 'en'
    }

    getCurrentCountry(language) {
        if (!language) language = localStorage.getItem('language')
        let country = Object.keys(this.country_language).find(key => this.country_language[key] === language)
        if (country !== undefined) return country
        else return 'US'
    }

    async setLanguage(lang) {
        let res = await AllUsersApi.changeLanguage({language:lang})
        if (res.data){
            localStorage.setItem('language', res.data.language)
            return res.data.language
        } else {
            localStorage.setItem('language', lang)
            return lang
        }
    }



    getCountries() {
        return Object.keys(this.country_language)
    }

    getLanguages() {
        return Object.values(this.country_language)
    }

    setDirection(dir) {
        if (dir) {
            document.getElementsByTagName("html")[0].setAttribute("dir", dir)
        } else {
            let currentLanguage = this.getCurrentCountry()
            if (this.rtl.includes(currentLanguage.toLowerCase())) document.getElementsByTagName("html")[0].setAttribute("dir", "rtl")
            else document.getElementsByTagName("html")[0].setAttribute("dir", "ltr")
        }
    }

    setExternal(language) {
        i18next.changeLanguage(language)
        let languages = this.getLanguages()
        if (languages.includes(language.toLowerCase())){
            if (this.rtl.includes(language.toLowerCase()))
                document.getElementsByTagName("html")[0].setAttribute("dir", "rtl")
            else
                document.getElementsByTagName("html")[0].setAttribute("dir", "ltr")
            localStorage.setItem('language', language)
            return  this.getCurrentCountry(language.toLowerCase())
        } else {
            document.getElementsByTagName("html")[0].setAttribute("dir", "ltr")
            localStorage.setItem('language', 'en')
            return 'US';
        }
    }
}

export default new Translate();
