import React from "react";
import PropTypes from "prop-types";
import {Modal} from 'react-bootstrap'
import {confirmable, createConfirmation} from "react-confirm";

import SvGraphics from "../../assets/SvGraphics";
import NovaDate from "../single_components/NovaDate";
import Error from "../single_components/Error";
import utils from "../../utils/utils";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class DateModalComposed extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            date: null,
            isErr: false
        }
    }

    componentDidMount(){
        document.getElementById('modal-ok-button').focus();
    }

    render() {
        let {
            proceedLabel,
            cancelLabel,
            show,
            proceed,
            enableEscape = true,
            t
        } = this.props;

        return (
            <Modal

                id={'Confirm-modal-body'}
                centered={true}
                show={show}
                onHide={() => proceed(false)}
                backdrop={enableEscape ? true : "static"}
                keyboard={enableEscape}
                dialogClassName={'nova-modal'}>

                <Modal.Body className={'nova-modal-body'}>
                    <SvGraphics onClick={() => proceed(false)}  svgname={'close'} height={'15px'} width={'15px'} style={{
                        float: 'left',
                        top: '10px',
                        left: '10px',
                        position: 'absolute',
                        cursor: 'pointer'
                    }}/>
                    <label className={'nova-modal-title'}>{t('modal.pic_date_lbl')}</label>
                    <div className={'nova-modal-content'}>
                        <div className={'nova-modal-date-container'}>
                            <NovaDate onSearchTermChange={val => {
                                if (val === null || val === undefined || val === ''){
                                    this.setState({isErr: true})
                                } else {
                                    this.setState({isErr: false})
                                }
                                this.setState({date:val})
                            }}
                                      allowFuture={true}
                                      allowPast={false}
                                      className={'nova-modal-date-input'}
                                      placeholder={t('modal.pick_date')}
                            term={this.state.date?this.state.date:''}/>
                        </div>
                        <Error errorMessage={utils.get(this.props.errors,'StereoAcuityBookType')} isShown={this.state.isErr}/>
                    </div>
                    <div className={'nova-modal-footer h-centered'}>
                        <button id={'modal-ok-button'} className={`btn btn-primary modal-confirm ${cancelLabel === 'none' ? 'noMarginRight' : ''}`}
                                onClick={() => {
                                    if (this.state.date === null || this.state.date === undefined || this.state.date === ''){
                                        this.setState({isErr: true})
                                    }
                                    proceed(this.state.date)
                                }} onKeyPress={event => {
                            if (event.key === 'Enter' && cancelLabel === 'none') {
                                proceed(true)
                            }
                        }}>{proceedLabel === "Save"?t('modal.save'):proceedLabel}</button>
                        {(cancelLabel !== 'none') ? <button className={'btn btn-primary modal-close'}
                                                            onClick={() => proceed(false)}>{cancelLabel === "Cancel"?t('modal.cancel'):cancelLabel}</button> : <div/>}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

let DateModal = compose(
    withTranslation()
)(DateModalComposed)

DateModal.propTypes = {
    okLabbel: PropTypes.string,
    cancelLabel: PropTypes.string,
    show: PropTypes.bool,
    proceed: PropTypes.func, // called when ok button is clicked.
    enableEscape: PropTypes.bool
};

export default function MakeDate({proceedLabel: proceedLabel = "Save", cancelLabel: cancelLabel = "Cancel", options: options = {}}) {
    return createConfirmation(confirmable(DateModal))({
        proceedLabel,
        cancelLabel,
        ...options
    });
}
