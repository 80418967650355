import React, {Component} from 'react';
import PropTypes from "prop-types";
import SvGraphics from "../../assets/SvGraphics";
import utils from "../../utils/utils";
import NovaSelect from "../single_components/NovaSelect";
import Error from "../single_components/Error";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class SimpleFormBuilder extends Component{

    constructor(props) {
        super(props);
        this.state = {
            entry: this.props.entry,
            fields: this.props.fields,
            errors: this.props.errors,
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.entry !== prevProps.entry) {
            this.setState({
                entry: this.props.entry
            })
        }
        if (this.props.fields !== prevProps.fields) {
            this.setState({
                fields: this.props.fields
            });
        }
    }

    getForm = () => {
        let rows = []
        let input_index = 0
        let matrix = this.props.matrix;
        matrix.forEach(row_count => {
            if (row_count === 1){
                if (this.state.fields[input_index].type === 'label'){
                    let row = (
                        <div className={'register-form-section-label block'}>
                            {this.state.fields[input_index].display}
                        </div>
                    )
                    rows.push(row);
                } else {
                    let row = (
                        <div className={this.state.fields[input_index].type === 'checkbox' ? 'register-form-row register-checkbox-row' : 'register-form-row'}>
                            {this.getSingleInputRow(input_index)}
                        </div>
                    )
                    rows.push(row);
                }
            } else {
                let row = (
                    <div className={'register-form-row multi-input-row'}>
                        {this.getDoubledInputRow(input_index)}
                    </div>
                )
                rows.push(row);
            }
            input_index += row_count;
        })
        return rows
    }

    getSingleInputRow = (i) => {
        let input_data = this.state.fields[i]
        let required = input_data.required?'*':''
        return (
            <div className={'register-form-row'}>
                <div className={'register-form-input'}>
                    <label className={'nova-form-label'}>
                        {input_data.display + required}
                    </label>
                    {this.getInput(input_data)}
                    <Error errorMessage={utils.get(this.props.errors, input_data.name)}
                           isShown={!!utils.get(this.props.errors, input_data.name)}/>
                </div>
            </div>
        );

    }

    getDoubledInputRow = (i) => {
        let input_data_i = this.state.fields[i]
        let input_data_ii = this.state.fields[i+1]
        return (
            <div className={'register-form-row multi-input-row'}>
                {this.getRowInputs(input_data_i, input_data_ii)}
            </div>
        );
    }

    getRowInputs = (input_data_i, input_data_ii) => {
        let left_width = input_data_i.width
        let right_width = input_data_ii.width
        if (left_width+right_width !== 1){
            return
        }

        let left_class = ''
        let right_class = ''

        if (left_width === 0.5){
            left_class = 'half-row';
            right_class = 'half-row';
        } else if (left_width === 0.8){
            left_class = 'eighty-percent-row';
            right_class = 'twenty-percent-row';
        } else if (left_width === 0.2){
            left_class = 'twenty-percent-row';
            right_class = 'eighty-percent-row';
        } else if (left_width === 0.6){
            left_class = 'sixty-percent-row';
            right_class = 'forty-percent-row';
        } else if (left_width === 0.4){
            left_class = 'forty-percent-row';
            right_class = 'sixty-percent-row';
        }
        let required_i = input_data_i.required?'*':''
        let required_ii = input_data_ii.required?'*':''
        let row_i = (
            <div className={`register-form-input ${left_class} inline`}>
                <label className={'nova-form-label'}>{input_data_i.display + required_i}</label>
                {this.getInput(input_data_i)}
                <Error errorMessage={utils.get(this.props.errors,input_data_i.name)} isShown={!!utils.get(this.props.errors, input_data_i.name)}/>

            </div>
        )
        let row_ii = (
            <div className={`register-form-input ${right_class} inline`}>
                <label className={'nova-form-label'}>{input_data_ii.display + required_ii}</label>
                {this.getInput(input_data_ii)}
                <Error errorMessage={utils.get(this.props.errors,input_data_ii.name)} isShown={!!utils.get(this.props.errors, input_data_ii.name)}/>

            </div>
        )
        return [row_i,row_ii]
    }

    getInput(input_data){
        let type = input_data.type
        let placeholder = input_data.placeholder
        let i_name = input_data.name
        switch (type) {
            case 'text':
                return (
                    <input
                        name={i_name}
                        className={'nova-form-input'}
                        onChange={e => this.props.onEntryChange(e,input_data.isNumeric)}
                        defaultValue={utils.get(this.props.entry,i_name)}
                        placeholder={placeholder}
                        maxLength={input_data.max}
                    />
                )
            case 'checkbox':
                return <input
                    name={i_name}
                    placeholder={placeholder}
                    className={'nova-checkbox-input'}
                    defaultChecked={utils.get(this.props.entry,i_name)}
                    type={'checkbox'}
                    onChange={e => this.props.onEntryChange(e)}
                />
            case 'dropdown': {
                let dropdownOptions = input_data.name === 'SiteStorageID' ?
                    this.props.sites : input_data.options
                let dropdownValue = dropdownOptions ? dropdownOptions.find(option => option.value === utils.get(this.props.entry, i_name)):''
                return (
                    <div className={'form-search'}>
                        <NovaSelect
                            name={i_name}
                            placeholder={placeholder}
                            options={input_data.options}
                            className={'nova-form-input nova-form-searchableSelect'}
                            value={dropdownValue}
                            isSearchable={false}
                            onChange={e => this.props.onEntryChangeByDropdown(i_name, e.value)}
                        />
                    </div>
                )
            }
            case 'phone':
                return (
                <PhoneInput
                    country={'us'}
                    value={utils.get(this.props.entry,i_name)}
                    inputClass={'nova-form-input'}
                    containerClass={'nova-PhoneInput-container'}
                    buttonClass={'nova-PhoneInput-button'}
                    dropdownClass={`nova-PhoneInput-dropdown`}
                    searchClass={'nova-form-PhoneInput-search'}
                    enableSearch={true}
                    countryCodeEditable={false}
                    autoFormat={true}
                    placeholder={placeholder}
                    onChange={phone =>this.props.onEntryChange({target:{name:i_name, value:phone}})}
                />
                )
            case 'searchableDropdown': {
                let dropdownOptions = input_data.name === 'SiteStorageID' ?
                    this.props.sites : input_data.options
                let dropdownValue = dropdownOptions ? dropdownOptions.find(option => option.value === utils.get(this.props.entry, i_name)):''
                return (
                    <div className={'form-search'}>
                        <NovaSelect
                            name={i_name}
                            isSearchable
                            disabled={input_data.disabled}
                            maxLength={input_data.max}
                            options={this.props.sites ? this.props.sites : input_data.options}
                            placeholder={placeholder}
                            className={'nova-form-input nova-form-searchableSelect'}
                            onChange={e => this.props.onEntryChangeByDropdown(i_name, e.value)}
                            value={dropdownValue}
                        />
                        <SvGraphics className={"input-pic"} svgname={'search'} style={{width: '20px', height: '20px'}}/>
                    </div>
                )
            }
            case 'textarea':
                return (
                    <textarea
                        name={i_name}
                        placeholder={placeholder}
                        defaultValue={utils.get(this.props.entry,i_name)}
                        className={'nova-form-input nova-form-textarea'}
                        onChange={e => this.props.onEntryChange(e)}
                        maxLength={500}
                    />
                )
            default:
                return null
        }
    }

    render() {
        return (
            this.props.fields?
            <div className={'register-form-container container abs'}>
                {this.getForm()}
            </div>
                :<div/>
        );
    }
}

SimpleFormBuilder.propTypes = {
    entry: PropTypes.object.isRequired,
    matrix: PropTypes.array.isRequired,
    fields: PropTypes.array.isRequired,
    onEntryChange: PropTypes.func.isRequired,
    onEntryChangeByDropdown: PropTypes.func,
    errors : PropTypes.object
};

export default SimpleFormBuilder;
