import React, {Component} from 'react'
import SvGraphics from "../../assets/SvGraphics";
import PatientChart from "../../PatientChart";
import { filterPatientsTable} from "../../redux/actions/Tabs-Actions";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {dateTimeArr, dateTimeArr2, getDateFormat, getTimeFormat} from "../../utils/getDateTimeFormat";
import utils from "../../utils/utils";
import PatientChartDirection from "../../PatientChartDirection";

class ACTPatientChartConnected extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visibleGraphData: 0,
            maxData: 3,
        }
    }


    render() {
        let {t}=this.props
        let categories=[]
        let  horizontalDeviationDriection=[]
        let  verticalDeviationDriection=[]
        let horizontalDeviation = {
            name: "HorizontalDeviation",
            data: [],
        }
        let verticalDeviation = {
            name: "VerticalDeviation",
            data: [],
        }

        let lastVisits = this.props.lastVisitsData
        let condition = ""
        if (this.props.svgName === "noglasses")
            condition="SC"
        else
            condition="CC"


        let maxY=1.5
        if(lastVisits){
            for (const visit of lastVisits) {
                for (const testResults of visit.TestsResults) {
                    if (testResults.ACTTestStorageID > 0 && testResults.OpticalCorrection === condition && testResults.ACTTestResult.SelectedByUser===1){

/*
                        this.props.setTotalTest()
*/
                        categories.push(dateTimeArr2(new Date(visit.VisitDate)))
                        let horizontalDeviationValue=testResults.ACTTestResult.ResultInDioptersHorizontalValue
                        let verticalDeviationValue=testResults.ACTTestResult.ResultInDioptersVerticalValue



                        horizontalDeviationValue=horizontalDeviationValue===null?"":horizontalDeviationValue
                        verticalDeviationValue=verticalDeviationValue===null?"":verticalDeviationValue

                        let horizontalDeviationDriectionValue=testResults.ACTTestResult.ResultInDioptersHorizontalDirection?
                            testResults.ACTTestResult.ResultInDioptersHorizontalDirection:""
                        let verticalDeviationDriectionValue=testResults.ACTTestResult.ResultInDioptersVerticalDirection?
                            testResults.ACTTestResult.ResultInDioptersVerticalDirection:""



                        horizontalDeviation.data.push(horizontalDeviationValue)
                        verticalDeviation.data.push(verticalDeviationValue)



                        horizontalDeviationDriection.push(horizontalDeviationDriectionValue)

                        verticalDeviationDriection.push(verticalDeviationDriectionValue)
                        maxY=Math.max(horizontalDeviationValue, verticalDeviationValue,maxY)

                    }
                }
            }
        }

        let series=  [horizontalDeviation,verticalDeviation]


        return(
            <div className={'patient-dashboard-chart-area'}>
                <div className={'patient-chart-topping'}>
                    <div className={'title-svg'}>
                        <div className={'chart-title'}>{this.props.chartTitle}</div>
                        <SvGraphics className={'glasses-style'} svgname={this.props.svgName}/>
                    </div>
                    <div className={'patient-chart-indication-area'}>
                        <div className={'patient-chart-indication patient-chart-1st-indication'}>
                            <span className={'patient-chart-indication-color'}>&nbsp;</span>
                            <div className={'patient-chart-indication-text'}>{this.props.firstTopic}</div>
                        </div>
                        <div className={'patient-chart-indication patient-chart-2nd-indication'}>
                            <span className={'patient-chart-indication-color'}>&nbsp;</span>
                            <div className={'patient-chart-indication-text'}>{this.props.secondTopic}</div>
                        </div>
                    </div>
                </div>
                <div className={'ACT-chart'}>
                    <PatientChartDirection series={JSON.parse(JSON.stringify(series))} chartType={'bar'} name={"ACT"} colors={['#25a6ff','#0575e6']}
                                  minYValue={(-0.5)} maxYValue={maxY+0.5} tickAmount={5} ytitle={'Δ'} type={"ACT"}
                                           horizontalDeviationDriection={horizontalDeviationDriection}
                                           verticalDeviationDriection={verticalDeviationDriection}
                                  xCategories={categories} lastVisitsData={lastVisits} seriesNum={2} />
                </div>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return {
        filterPatientsTable:(filterType,payload) => dispatch(filterPatientsTable(filterType,payload)),
    };
}
const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData,
        patientsCount:state.patientsTableDataReducer.patientsCount,
        activePatientsCount:state.patientsTableDataReducer.activePatientsCount,
        pendingPatientsCount:state.patientsTableDataReducer.pendingPatientsCount,
        lockedPatientsCount:state.patientsTableDataReducer.lockedPatientsCount,
    };
};

const ACTPatientChart = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ACTPatientChartConnected)
export default ACTPatientChart
