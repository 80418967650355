import {
    FILTER_ECPDATA,
    ECP_DATA,
    ECP_SELECTION, EDIT_ECP, NEW_ECP, ECPS_COUNT, ECP_LOCKED_STATUS, CLEAN_ECP, DELETE_SITE_ECPS
} from "../../constants/Tabs-Action-Types";
import utils from "../../../utils/utils";
import Constants from "../../../utils/constants";
import {ConstantsObjT} from "../../../utils/ConstantsWithTranslate";
import {filterPatientsTable} from "../../actions/Tabs-Actions";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";


const initialState = {
    initiateEcpData:[],
    ecpData:[],
    selectedEcpData:[],
    ecpTableFilter:{},
    ecpCount: 0
};



const ecpTableDataReducer = (state = initialState, action)=> {

    switch(action.type){
        case ECPS_COUNT:{
            let _idata = JSON.parse(JSON.stringify(action.payload));
            state = {
                ...state,
                ecpCount: _idata
            };
            break;
        }
        case ECP_DATA:
            let collection = []
            action.payload.forEach(ecp =>{
                collection.push({
                    id:ecp.UserStorageID,
                    UserStorageID:ecp.UserStorageID,
                    SiteName:ecp.Site.SiteName,
                    SiteStorageID: ecp.SiteStorageID,
                    DefaultVAUnit: ecp.DefaultVAUnit,
                    MedicalLicense: ecp.MedicalLicense,
                    Profession: ecp.Profession,
                    ProfessionLabel: Constants.Profession.find(prof => prof.value === ecp.Profession)?.label,
                    Notes: ecp.Notes,
                    SocialID: ecp.PII?.SocialID,
                    isSiteAdmin: ecp.isSiteAdmin,
                    FirstName: ecp.PII?.FirstName,
                    LastName: ecp.PII?.LastName,
                    Email: ecp.PII?.Email,
                    PhoneNumber: ecp.PII?.PhoneNumber,
                    Enabled: ecp.PII?.User.Enabled,
                    FullName: ecp.PII?.FirstName + ' ' + ecp.PII?.LastName,
                    Patients: ecp.NumOfPatients,
                })
            })

            let _pdata = JSON.parse(JSON.stringify(collection));
            state = {
                ...state,
                initiateEcpData:_pdata,
                ecpData : _pdata,
                ecpCount: collection.length
            };
            break;
        case CLEAN_ECP:{
            state = {
                ...state,
                ecpTableFilter: {},
                selectedEcpData:[]
            };
            break;
        }

        case FILTER_ECPDATA:{
            let type = action.searchType
            let payload = action.payload
            let ecpTableFilter = state.ecpTableFilter
            let filteredEcpsData = state.initiateEcpData
            let visibleFilterData = []
            let visiableData = []

            if (type === 'search' && (payload === '' || payload === null)){
                delete ecpTableFilter.search
            } else {
                utils.set(ecpTableFilter,type,payload)
            }

            for (const [key, value] of Object.entries(ecpTableFilter)) {
                if (key === 'search'){
                    let val = value.toLowerCase()
                    filteredEcpsData = filteredEcpsData.filter(x =>
                        String(x.FullName).toLowerCase().indexOf(val) > -1 ||
                        String(x.MedicalLicense).toLowerCase().indexOf(val) > -1 ||
                        String(x.SiteName).toLowerCase().indexOf(val) > -1 ||
                        String(x.Patients).toLowerCase().indexOf(val) > -1 ||
                        String(x.Email).toLowerCase().indexOf(val) > -1 ||
                        String(x.ProfessionLabel).toLowerCase().indexOf(val) > -1
                    )
                    visibleFilterData = filteredEcpsData
                } else {
                    let ecps = JSON.parse(JSON.stringify(filteredEcpsData))
                    let valArr = JSON.parse(JSON.stringify(value))


                    if (Array.isArray(valArr)){
                        let res = []
                        valArr.forEach(val => {
                            let ecpArr = ecps.filter(ecp => {
                                let ecpVal = ecp[key]
                                return String(ecpVal).toLowerCase() === String(val).toLowerCase()
                            })
                            ecpArr.forEach(ecp => res.push(ecp))
                        })
                        filteredEcpsData = res
                    }
                }
            }
            visiableData = filteredEcpsData

            let _fpdata = JSON.parse(JSON.stringify(visibleFilterData));
            let _vdata = JSON.parse(JSON.stringify(visiableData));
            let _pfdata = JSON.parse(JSON.stringify(ecpTableFilter));
            state = {
                ...state,
                filteredEcpsData: _fpdata,
                ecpData: _vdata,
                ecpTableFilter: _pfdata
            };
            break;
        }
        case ECP_SELECTION:{
            let selection = action.payload
            let initiateEcpData = state.initiateEcpData

            let newSelection = []
            Object.entries(selection).forEach(([key,value]) => {
                if (value){
                    let index = initiateEcpData.findIndex((obj => obj.UserStorageID === key));
                    let name = initiateEcpData[index].FullName
                    newSelection.push({id:key,name})
                }
            })
            state = {
                ...state,
                selectedEcpData: newSelection
            };
            break;
        }
        case DELETE_SITE_ECPS:{
            let siteId = action.siteId
            let ecpData = state.ecpData
            let initiateEcpData = state.initiateEcpData

            let newecpData = ecpData.filter(ecp => ecp.SiteStorageID !== siteId)
            let newinitiateEcpData = initiateEcpData.filter(ecp => ecp.SiteStorageID !== siteId)

            let _pdata = JSON.parse(JSON.stringify(newecpData));
            let _idata = JSON.parse(JSON.stringify(newinitiateEcpData));

            state = {
                ...state,
                initiateEcpData:_idata,
                ecpData : _pdata,
                ecpCount: newinitiateEcpData.length
            };
            break;
        }
        case NEW_ECP: {
            let ecp = action.payload
            let ecpData = state.ecpData
            let initiateEcpData = state.initiateEcpData
            let newData = [{
                id:ecp.UserStorageID,
                UserStorageID:ecp.UserStorageID,
                SiteName:ecp.Site.SiteName,
                SiteStorageID: ecp.Site.SiteStorageID,
                DefaultVAUnit: ecp.Doctor.DefaultVAUnit,
                MedicalLicense: ecp.Doctor.MedicalLicense,
                Profession: ecp.Doctor.Profession,
                Notes: ecp.Doctor.Notes,
                isSiteAdmin: ecp.Doctor.isSiteAdmin,
                SocialID: ecp.PIIProfile.SocialID,
                FirstName: ecp.PIIProfile.FirstName,
                LastName: ecp.PIIProfile.LastName,
                Email: ecp.PIIProfile.Email,
                PhoneNumber: ecp.PIIProfile.PhoneNumber,
                Enabled: ecp.Enabled,
                FullName: ecp.PIIProfile.FirstName + ' ' + ecp.PIIProfile.LastName,
                Patients: ecp.NumOfPatients?ecp.NumOfPatients:0,
            }]

            let newecpData = ecpData.concat(newData)
            let newinitiateEcpData = initiateEcpData.concat(newData)

            let _pdata = JSON.parse(JSON.stringify(newecpData));
            let _idata = JSON.parse(JSON.stringify(newinitiateEcpData));
            state = {
                ...state,
                initiateEcpData:_idata,
                ecpData : _pdata,
                ecpCount: newinitiateEcpData.length
            };
            break;
        }
        case EDIT_ECP: {
            let ecp = action.payload

            let ecpData = state.ecpData
            let initiateEcpData = state.initiateEcpData

            let indexData = ecpData.findIndex((obj => obj.UserStorageID === ecp.UserStorageID));
            let indexInit = initiateEcpData.findIndex((obj => obj.UserStorageID === ecp.UserStorageID));

                ecpData[indexData]['SiteName'] = ecp.Site.SiteName
                ecpData[indexData]['SiteStorageID']= ecp.Site.SiteStorageID
                ecpData[indexData]['DefaultVAUnit']= ecp.DefaultVAUnit
                ecpData[indexData]['MedicalLicense']= ecp.MedicalLicense
                ecpData[indexData]['Profession']= ecp.Profession
                ecpData[indexData]['Notes']= ecp.Notes
                ecpData[indexData]['isSiteAdmin']= ecp.isSiteAdmin
                ecpData[indexData]['SocialID']= ecp.PII?.SocialID
                ecpData[indexData]['FirstName']= ecp.PII?.FirstName
                ecpData[indexData]['LastName']= ecp.PII?.LastName
                ecpData[indexData]['Email']= ecp.PII?.Email
                ecpData[indexData]['PhoneNumber']= ecp.PII?.PhoneNumber
                ecpData[indexData]['FullName']= ecp.PII?.FirstName + ' ' + ecp.PII?.LastName

                initiateEcpData[indexInit]['SiteName'] = ecp.Site.SiteName
                initiateEcpData[indexInit]['SiteStorageID']= ecp.Site.SiteStorageID
                initiateEcpData[indexInit]['DefaultVAUnit']= ecp.DefaultVAUnit
                initiateEcpData[indexInit]['MedicalLicense']= ecp.MedicalLicense
                initiateEcpData[indexInit]['isSiteAdmin']= ecp.isSiteAdmin
                initiateEcpData[indexInit]['Profession']= ecp.Profession
                initiateEcpData[indexInit]['Notes']= ecp.Notes
                initiateEcpData[indexInit]['SocialID']= ecp.PII?.SocialID
                initiateEcpData[indexInit]['FirstName']= ecp.PII?.FirstName
                initiateEcpData[indexInit]['LastName']= ecp.PII?.LastName
                initiateEcpData[indexInit]['Email']= ecp.PII?.Email
                initiateEcpData[indexInit]['PhoneNumber']= ecp.PII?.PhoneNumber
                initiateEcpData[indexInit]['FullName']= ecp.PII?.FirstName + ' ' + ecp.PII?.LastName

            let _pdata = JSON.parse(JSON.stringify(ecpData));
            let _idata = JSON.parse(JSON.stringify(initiateEcpData));
            state = {
                ...state,
                initiateEcpData:_idata,
                ecpData : _pdata
            };
            break;
        }
        case ECP_LOCKED_STATUS: {
            let ecp = action.payload
            let ecpData = state.ecpData
            let initiateEcpData = state.initiateEcpData

            let indexData = ecpData.findIndex((obj => obj.UserStorageID === ecp.UserStorageID));
            let indexInit = initiateEcpData.findIndex((obj => obj.UserStorageID === ecp.UserStorageID));
            let Enabled = ecp.PII?.User.Enabled

            ecpData[indexData]['Enabled'] = Enabled
            initiateEcpData[indexInit]['Enabled'] = Enabled

            let _pdata = JSON.parse(JSON.stringify(ecpData));
            let _idata = JSON.parse(JSON.stringify(initiateEcpData));
            state = {
                ...state,
                initiateEcpData:_idata,
                ecpData : _pdata
            };
            break;
        }
        default:
            break;
    }
    return state;
};


function mapDispatchToProps(dispatch) {
    return {
        filterPatientsTable:(filterType,payload) => dispatch(filterPatientsTable(filterType,payload)),
    };
}
const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData,
        patientsCount:state.patientsTableDataReducer.patientsCount,
        activePatientsCount:state.patientsTableDataReducer.activePatientsCount,
        pendingPatientsCount:state.patientsTableDataReducer.pendingPatientsCount,
        lockedPatientsCount:state.patientsTableDataReducer.lockedPatientsCount,
    };
};

export default ecpTableDataReducer;
