import React, {Component} from 'react'
import ReactApexChart from "react-apexcharts";
import SvGraphics from "./assets/SvGraphics";
import utils from "./utils/utils";
import $ from "jquery";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

class PatientChartConnected extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visibleGraphData: 0,
            maxData: 3,
        }
    }

    convertSeries (series) {
        for (let i in series){

            let serieVar = series[i]

            let data = serieVar.data
            for (let i = 0; i < data.length; i++){
                data[i] = data[i]!==""? parseFloat(data[i]) +0.5:""
            }
            data = [...data]

            utils.set(serieVar,'data',data)
            series[i] = serieVar
        }
        return series
    }

    slideGraphBy(val){
        this.setState({visibleGraphData:this.state.visibleGraphData+val})
    }
    componentDidMount() {


    }

    render() {
        let chartColors= this.props.colors

        let {t}=this.props

        let chartOptions= {
            legend: {show: false},
            chart: {
                type: 'bar',
                height: 430,
                toolbar: {
                    show: false
                }, zoom: {
                    enabled: false,
                },
            },
            colors: chartColors,
            plotOptions: {
                bar: {
                    columnWidth: '45px',
                    horizontal: false,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val.toString()!==""&& val.toString() !=="NaN" ?(val - 0.5).toFixed(2):"";
                },
                offsetY: -20,
                position: 'bottom',
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }

            },
            grid: {
                borderColor: 'white',
                row: {
                    opacity: 0.5
                },
            },

            stroke: {
                show: true,
                width: 1.5,
                colors: ['#fff']
            },
            xaxis: {
                categories: [],
                title: {
                    text: this.props.xtitle,
                    style: {
                        fontSize: '15px',
                        fontFamily: 'OpenSan, sans-serif',
                        fontWeight: 300,
                        letterSpacing: '0.35px'
                    },
                },
                labels: {
                    formatter: function (val) {
                        return val.toString()!==""&& val.toString() !=="NaN" ?val:"";
                    }
                }
            },
            yaxis: {
                min: 0,
                forceNiceScale: true,
                max: this.props.maxYValue,
                tickAmount: 4,
                title: {
                    text: this.props.ytitle,
                    style: {
                        fontSize: '15px',
                        fontFamily: 'OpenSan, sans-serif',
                        fontWeight: 300,
                        letterSpacing: '0.35px'
                    }
                },
                labels: {
                    formatter: function (val, index) {
                        return val.toString()!=="" && val.toString() !=="NaN" ?(val - 0.5).toFixed(2):"";
                        /*return val;*/
                    }
                }
            },
            tooltip: {
                y: {
                    formatter: function (val) {

                        return val.toString()!=="" && val.toString() !=="NaN"  ?(val - 0.5).toFixed(2):"";
                    }
                }
            }
        }



        let showRightArrow = false
        let showLeftArrow = false
        let seriesNum= this.props.seriesNum

        let visit= this.props.series
        let firstSeries=[]
        let secondSeries=[]
        let thirdSeries=[]
        let fourthSeries=[]
        let dates = []

        if(visit){
            firstSeries = visit[0].data.slice(0+this.state.visibleGraphData, this.state.maxData+this.state.visibleGraphData)
            if(seriesNum >= 2 ) {
                secondSeries = visit[1].data.slice(0+this.state.visibleGraphData, this.state.maxData+this.state.visibleGraphData)
                if(seriesNum>=3){
                    thirdSeries = visit[2].data.slice(0+this.state.visibleGraphData, this.state.maxData+this.state.visibleGraphData)
                    if(this.props.isFusional){
                        fourthSeries = visit[3].data.slice(0+this.state.visibleGraphData, this.state.maxData+this.state.visibleGraphData)
                    }
                }
            }
            dates= this.props.xCategories.slice(0+this.state.visibleGraphData, this.state.maxData+this.state.visibleGraphData)
            showRightArrow = !(visit[0].data.length > this.state.maxData && this.state.visibleGraphData+this.state.maxData >= visit[0].data.length) && visit[0].data.length > this.state.maxData
            showLeftArrow = !(visit[0].data.length <= this.state.maxData || this.state.visibleGraphData-this.state.maxData < 0)
        }
        let series=[]
        if(seriesNum === 2){
            series = [{
                name: 'HorizontalDeviation',
                data: firstSeries
            }, {
                name: 'VerticalDeviation',
                data: secondSeries
            }]
        }else if (seriesNum===3){
            if(this.props.isFusional){
                series = [{
                    name: 'Convergence',
                    data: firstSeries
                }, {
                    name: 'Positive Recovery',
                    data: secondSeries
                }, {
                    name: 'Divergence',
                    data: thirdSeries
                },{
                    name: 'Negative Recovery',
                    data: fourthSeries
                }]
            }
            else{
                series = [{
                    name: 'Left',
                    data: firstSeries
                }, {
                    name: 'Right',
                    data: secondSeries
                }, {
                    name: 'Binocular',
                    data: thirdSeries
                }]
            }

        }else{
            series = [{
                name: '',
                data: firstSeries
            }
            ]
            chartOptions.plotOptions.bar.columnWidth='15px'

        }
        series = this.convertSeries(series)

        if(this.props.type==="stereo"){

            chartOptions.tooltip.y= {
                formatter: function (val) {
                    if(val.toString()!=="NaN"&& val ===1000.5){
                        return t('general.No_Stereo')
                    }else if (val.toString()!=="NaN"&& val ===1001.5){
                        return t('general.No_Result')
                    }else{
                        return (val - 0.5).toFixed(2)
                    }
                }

            }

            chartOptions.dataLabels.y= {
                formatter: function (val) {
                    console.log(val)

                    return val.toString()!=="NaN"?val:""
                }
            }
            chartOptions.yaxis.labels= {
                formatter: function (val) {
                    console.log(val)

                    return val.toString()!=="NaN"?val:""
                }
            }


            chartOptions.yaxis.min= 0
            chartOptions.yaxis.max= this.props.maxYValue
            chartOptions.yaxis.tickAmount= 4
            chartOptions.plotOptions.bar.columnWidth='15px'
        }

        chartOptions.xaxis.categories=dates

        return(

            <div className={'chart-area'}>
                <SvGraphics style={{visibility:showLeftArrow?'visible':'hidden'}} svgname={'arrow-left'} className={'chart-arrow chart-arrow-left'} height={'10px'}
                            width={'10px'} onClick={e => this.slideGraphBy(-this.state.maxData)}/>
                <div className={'chart-container'}>
                    <ReactApexChart options={chartOptions} series={series} type={this.props.chartType}/>
                </div>
                <SvGraphics style={{visibility:showRightArrow?'visible':'hidden'}} svgname={'arrow-right'} className={'chart-arrow chart-arrow-right'} height={'10px'}
                            width={'10px'} onClick={e => this.slideGraphBy(this.state.maxData)}/>
            </div>
            /*
                        <ReactApexChart options={options} series={this.props.series} type={this.props.chartType}/>
            */


        );
    }


}

const PatientChart = compose(
    withTranslation(),
    connect(
    )
)(PatientChartConnected)

export default PatientChart
