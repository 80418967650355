import React from "react";
import utils from "./utils";



    function getTimeFormat(date) {
       return date.getHours() + ":" + date.getMinutes()
    }

    function getDateFormat(date) {
        return date.toISOString().substring(8, 10)+ "/" + date.toISOString().substring(5, 7) + "/"+ date.toISOString().substring(0,4)

/*
        return date.getUTCDate() + '/'+ date.getUTCMonth()  + '/' + date.toISOString().substring(2,4)
*/
    }

    function dateTimeArr2(dateTimeArr){

        let date= utils.getDateFormatWithYear(dateTimeArr)

        let min=dateTimeArr.getMinutes()+""
        if(min.length===1){
            min="0"+min
        }
        let sec=dateTimeArr.getSeconds()+""
        if(sec.length===1){
            sec="0"+sec
        }
        let time =dateTimeArr.getHours() + ":" + min + ":" + sec

        return [date,time]
    }
    function dateTimeArr(dateTimeArr){
        let currentDateTime=dateTimeArr.split('T')
        let allDate =currentDateTime[0]
        let date = allDate.substring(8, 10)+ "/" + allDate.substring(5, 7) + "/"+ allDate.substring(0,4)
        let currentTime =currentDateTime[1].split('.')
        let time =currentTime[0]
        return [date,time]
    }

    function getTime(dateTimeArr){

        let min=dateTimeArr.getMinutes()+""
        if(min.length===1){
            min="0"+min
        }

        return dateTimeArr.getHours() + ":" + min

    }



export {getTimeFormat,getDateFormat,dateTimeArr,getTime,dateTimeArr2}
