import React, {Component} from 'react'
import ReturnNav from "../../components/single_components/ReturnNav";
import '../../components_style/rtl_css/PatientForm.css'

import '../../components_style/PatientForm.css'
import utils from "../../utils/utils";
import {doctorApi} from "../../services/ApiService";
import {connect} from "react-redux";
import {editPatientData} from "../../redux/actions/Tabs-Actions";
import * as Yup from "yup";
import validationHelper from "../../utils/validationHelper";
import FormElementContainer from "../../components/forms_builder/FormElementContainer";
import PatientDetails from "../../components/forms_builder/PatientDetails";
import PatientRemoteTesting from "../../components/forms_builder/PatientRemoteTesting";
import Address from "../../components/forms_builder/Address";
import PersonalNotes from "../../components/forms_builder/PersonalNotes";
import {makeError} from "../../components/modals/ErrorModal";
import AlertConfirm from "../../components/modals/Confirm";
import Constants from "../../utils/constants";
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class EditPatientConnected extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entry: {},
            errors: {},
            errorsOnSave:{},
        }
    }

    initValidation = async (entry) => {
        let validationSchema = Yup.object().shape({
            FirstName:Yup.string().required('First Name is required'),
            LastName:Yup.string().required('Last Name is required'),
            Birthdate:Yup.string().required('Birth date is required'),
            /*PhoneNumber:Yup.string(),
            Gender:Yup.string(),
            Email:Yup.string().matches(Constants.emailRegExp, 'Email is not valid'),
             */
/*            Street:Yup.string(),
            Apartment:Yup.string(),
            City:Yup.string(),
            Country:Yup.string(),
            ZIPCode:Yup.string(),
            State: Yup.string().when("Country", {
                is: value => {
                    let us = ["us","u.s","u.s.","usa","u.s.a","u.s.a.","united states","united states of america"]
                    return us.includes(String(value).toLowerCase())
                },
                then: Yup.string().required(
                    "State name in the U.S. is required"
                ).nullable(),
                otherwise: Yup.string().nullable()
            })*/
        })
        let errors = await validationHelper.validate(entry,validationSchema)
        await this.setState({
            validationSchema,
            errors
        })

    }

    async componentWillMount() {
        if (this.props.history.location.state.patient){
            let patientValues = utils.removeEmptyOrNull(this.props.history.location.state.patient)
            let entry = this.removeIrrlivantValues(patientValues)
            this.setState({entry})
            await this.initValidation(this.props.history.location.state.patient)
        }
    }

    componentDidMount = ()  => {

    }

    removeIrrlivantValues = (patientValues) => {
        let entry = {}
        let labels = ['FirstName','LastName','Gender','Birthdate',
            'PhoneNumber','Email',
            'Street','Apartment','City','Country','State','ZIPCode','DoctorStorageID','UserStorageID']
        labels.forEach(label => {
            utils.set(entry,label,patientValues[label])
        })
        let optionalLabels = ['SocialID',
            'Notes']
        optionalLabels.forEach(label => {
            if (patientValues[label] !== null && patientValues[label] !== undefined)
            utils.set(entry,label,patientValues[label])
        })
        return entry;
    }

    onNavBack = async (isClose) => {
        if (isClose){
            let confirm = await AlertConfirm({
                options:{
                    title: `Withdraw edited Patient's data`,
                }},`Are you sure you want to withdraw the edited Patient's data?`)
            if (confirm) {
                this.props.history.goBack()
            }
        } else {
            this.props.history.goBack()
        }
    }

    onValueChange = async (name,value) => {
        let {entry,validationSchema} = this.state
        utils.set(entry,name,value)
        let entryValidator = utils.removeEmptyOrNull(entry)
        let errors = await validationHelper.validate(entryValidator, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;
        if (utils.get(errorsOnSave,name)){
            utils.set(errorsOnSave,name,utils.get(errors,name))
        }
        this.setState({
            entry,
            errors,
            errorsOnSave
        })
    }

    savePatient = async () => {
        let {t}=this.props
        if (!utils.isEmpty(this.state.errors)){
            this.setState({errorsOnSave:this.state.errors});
            await makeError({
                proceedLabel: 'Ok',
                options:{
                    title: 'Could not edit patient',
                }
            },[t('general.Fill_all_required')]);
            return;
        }
        let {entry} = this.state
        entry = utils.ObjectEmptiesToNulls(entry)
        entry = utils.removeEmptyOrNull(entry)
        let confirm = await AlertConfirm({
            options:{
                title: `Edit Patient's Details`,
            }},`Are you sure you want to save patient's details?`)
        if (confirm) {

            let response = await doctorApi.editPatient(entry,entry.UserStorageID)
            if (response) {
                this.props.editPatientData(response.data)
                this.onNavBack(false)
                this.setState({entry:{}})
            }
        }
    }

    render() {
        let {errorsOnSave} = this.state
        return (
            <div className={'Patient-form'}>
                <ReturnNav onNavBackClick={() => this.onNavBack(true)}/>

                <div className={'nova-patient-form-body container'}>

                    <FormElementContainer title={'Patient Details'}
                                        Component={PatientDetails}
                                        rest={{
                                            t:this.props.t,
                                            onValueChange:(name,value) => this.onValueChange(name,value) ,
                                            entry:this.state.entry,
                                            errors:errorsOnSave
                                        }}/>

                    <FormElementContainer title={'Remote Testing'}
                                        Component={PatientRemoteTesting}
                                        rest={{
                                            t:this.props.t,
                                            onValueChange:(name,value) => this.onValueChange(name,value) ,
                                            entry:this.state.entry,
                                            errors:errorsOnSave
                                        }}/>

                    <FormElementContainer title={'Address'}
                                        Component={Address}
                                        rest={{
                                            t:this.props.t,
                                            onValueChange:(name,value) => this.onValueChange(name,value) ,
                                            entry:this.state.entry,
                                            errors:errorsOnSave
                                        }}/>

                    <FormElementContainer title={'Notes'}
                                        Component={PersonalNotes}
                                        rest={{
                                            t:this.props.t,

                                            onValueChange:(name,value) => this.onValueChange(name,value) ,
                                            entry:this.state.entry,
                                            errors:errorsOnSave
                                        }}/>

                </div>

                <div className={'nova-patient-form-footer container'}>
                    <button type="button" className="btn btn-primary Patient-Form-next-button" onClick={this.savePatient.bind(this)}>Save</button>
                </div>

                <div className={'nova-patient-form-footer container'}/>

            </div>

        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        editPatientData: patientData => dispatch(editPatientData(patientData)),
    };
}

const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData
    };
};

const EditPatient = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(EditPatientConnected);
export default EditPatient;
