import {
    CLEAN_DEVICE,
    DEVICES_COUNT,
    DEVICES_DATA,
    DEVICES_SELECTION,
    DEVICES_STATUSES,
    FILTER_DEVICESDATA,
} from "../../constants/Tabs-Action-Types";
import utils from "../../../utils/utils";
import ConstantsUtils from "../../../utils/ConstantsUtils";


const initialState = {
    initiateDeviceData:[],
    deviceData:[],
    selectedDevicesData:[],
    deviceTableFilter:{},
    devicesCount:0,
    onUpdateDevicesCount: 0,
    onErrorDevicesCount: 0
};

const isUpdateRequired = (type,value,lastSystemVersion) => {
    if (lastSystemVersion === null || lastSystemVersion === undefined) return false
    switch (type) {
        case 'ET': {
            if (!lastSystemVersion.hasOwnProperty('ESAPP')) return false
            //let ESAPP = lastSystemVersion['CSAPP']
            let ESVersion = lastSystemVersion['ESAPP']
            if (!ESVersion.hasOwnProperty('max_ver')) return false
            let VersionNO =  ESVersion['max_ver']
            return VersionNO !== value
        }
        case 'Windows': {
            if (!lastSystemVersion.hasOwnProperty('WIN')) return false
            let WIN = lastSystemVersion['WIN']
            if (!WIN.hasOwnProperty('max_ver')) return false
            let VersionNO =  WIN['max_ver']
            return VersionNO !== value
        }
        case 'ETFirmware': {
            if (!lastSystemVersion.hasOwnProperty('ET')) return false
            let ET = lastSystemVersion['ET']
            if (!ET.hasOwnProperty('max_ver')) return false
            let VersionNO =  ET['max_ver']
            return VersionNO !== value
        }
        case 'TV': {
            if (!lastSystemVersion.hasOwnProperty('TV')) return false
            let ET = lastSystemVersion['TV']
            if (!ET.hasOwnProperty('max_ver')) return false
            let VersionNO =  ET['max_ver']
            return VersionNO !== value
        }
        case 'EMITTER': {
            if (!lastSystemVersion.hasOwnProperty('EMITTER')) return false
            let ET = lastSystemVersion['EMITTER']
            if (!ET.hasOwnProperty('max_ver')) return false
            let VersionNO =  ET['max_ver']
            return VersionNO !== value
        }
    }
    return false
}

const deviceTableDataReducer = (state = initialState, action)=> {

    switch(action.type){
        case DEVICES_COUNT:{
            state = {
                ...state,
                devicesCount: action.payload
            };
            break;
        }
        case DEVICES_DATA:
            let collection = []
            let onUpdateDevicesCount = 0
            let onErrorDevicesCount = 0
            let devices = action.payload.Devices
            let lastSystemVersion = action.payload.LastSystemVersion
            devices.forEach(device =>{
                    let ESUpdateRequired = !!device ? isUpdateRequired('ET',device.ESVersion,lastSystemVersion): false
                    let WindowsUpdateRequired = !!device ? isUpdateRequired('Windows',device.WindowsVersion,lastSystemVersion): false
                    let ETFirmwareUpdateRequired = !!device ? isUpdateRequired('ETFirmware',device.ETFirmwareVersion,lastSystemVersion): false
                    let EmitterFirmwareUpdateRequired = !!device ? isUpdateRequired('EMITTER',device.EmitterFirmwareVersion,lastSystemVersion): false
                    let TVVersionUpdateRequired = !!device ? isUpdateRequired('TV',device.TVVersion,lastSystemVersion): false
                    let updateRequired = (ESUpdateRequired || WindowsUpdateRequired || ETFirmwareUpdateRequired || EmitterFirmwareUpdateRequired || TVVersionUpdateRequired)
                    if (updateRequired){
                        onUpdateDevicesCount++
                    }

                if (!!device && device.SystemStatus !== null && device.SystemStatus !== undefined && device.SystemStatus.toUpperCase()!=="OK"){
                        onErrorDevicesCount++
                    }
                    collection.push({
                        id: device.DeviceStorageID,
                        SiteStorageID: device.SiteStorageID,
                        SiteName: device.Site.SiteName,
                        DeviceErrors:device.DeviceErrors,
                        DateOfActivation: device.DateOfActivation,
                        DateOfActivationLabel: utils.getDateFormatWithYear(new Date(device.DateOfActivation)),
                        /*
                        LastRun: device.LastRun !== null? device.LastRun : '',
*/
                        LastRun: device.LastRun !== null? utils.getDateFormatWithYear(new Date(device.LastRun)) : '',
                        DeviceStorageID:  !!device ? device.DeviceStorageID : null,
                        DeviceID: !!device ? device.DeviceID : null,
                        SystemStatus: !!device ? !!device.SystemStatus ? device.SystemStatus : "Ok" : null ,
                        ESVersion: !!device ? device.ESVersion : null,
                        ESVersionUpdateRequired: ESUpdateRequired,
                        WindowsVersion: !!device ? device.WindowsVersion : null,
                        WindowsVersionUpdateRequired: WindowsUpdateRequired,
                        ETFirmwareVersion: !!device ? device.ETFirmwareVersion : null,
                        ETFirmwareVersionUpdateRequired: ETFirmwareUpdateRequired,
                        EmitterFirmwareVersion: !!device ? device.EmitterFirmwareVersion : null,
                        EmitterFirmwareVersionRequired: EmitterFirmwareUpdateRequired,
                        TVVersion: !!device ? device.TVVersion : null,
                        TVVersionUpdateRequired: TVVersionUpdateRequired,
                        updateRequired: updateRequired,
                        Location: device.Location
                    })
            })
            let _pdata = JSON.parse(JSON.stringify(collection));
            state = {
                ...state,
                initiateDeviceData:_pdata,
                deviceData : _pdata,
                devicesCount: collection.length,
                onUpdateDevicesCount: onUpdateDevicesCount,
                onErrorDevicesCount: onErrorDevicesCount
            };
            break;
        case CLEAN_DEVICE:{
            state = {
                ...state,
                deviceTableFilter: {},
                selectedDevicesData: []
            };
            break;
        }
        case FILTER_DEVICESDATA:{
            let type = action.searchType
            let payload = action.payload
            let deviceTableFilter = state.deviceTableFilter
            let filteredDeviceData = state.initiateDeviceData
            let visibleFilterData = []
            let visiableData = []

            if (type === 'status' && payload === 'total'){
                delete deviceTableFilter.status
            } else {
                utils.set(deviceTableFilter,type,payload)
            }

            for (const [key, value] of Object.entries(deviceTableFilter)) {
                if (key === 'search'){

                    let val = value.toLowerCase()
                    filteredDeviceData = filteredDeviceData.filter(x=>
                        String(x.SiteName).toLowerCase().indexOf(val) > -1 ||
                        String(x.DeviceID).toLowerCase().indexOf(val) > -1 ||
                        String(x.UserStorageID).toLowerCase().indexOf(val) > -1 ||
                        String(x.ECP).toLowerCase().indexOf(val) > -1 ||
                        String(x.AccountStatusLabel).toLowerCase().indexOf(val) > -1 ||
                        String(x.DateOfActivationLabel).toLowerCase().indexOf(val) > -1 ||
                        String(x.CSVersion).toLowerCase().indexOf(val) > -1 ||
                        String(x.WindowsVersion).toLowerCase().indexOf(val) > -1 ||
                        String(x.ETFirmwareVersion).toLowerCase().indexOf(val) > -1 ||
                        String(x.SystemStatus).toLowerCase().indexOf(val) > -1 ||
                        String(x.EmitterFirmwareVersion).toLowerCase().indexOf(val) > -1 ||
                        String(x.ESVersion).toLowerCase().indexOf(val) > -1 ||
                        String(x.TreatmentProgressLabel).toLowerCase().indexOf(val) > -1
                    )
                    visibleFilterData = filteredDeviceData
                } else if (key === 'status') {

                    switch (value) {
                        case 'onUpdate':{
                            filteredDeviceData = filteredDeviceData.filter(device => device['updateRequired'] === true)
                            break;
                        }
                        case 'onError':{
                            filteredDeviceData = filteredDeviceData.filter(device => device['SystemStatus'] !== null || (device['SystemStatus']).toLowerCase() !== 'ok')
                            break;
                        }
                        default:{
                            break;
                        }
                    }
                    visibleFilterData = filteredDeviceData
                } else {
                    let devices = JSON.parse(JSON.stringify(filteredDeviceData))
                    let valArr = JSON.parse(JSON.stringify(value))


                    if (Array.isArray(valArr)){
                        let res = []
                        valArr.forEach(val => {
                            let deviceArr = devices.filter(device => {
                                let deviceVal = device[key]
                                return String(deviceVal).toLowerCase() === String(val).toLowerCase()
                            })
                            deviceArr.forEach(device => res.push(device))
                        })
                        filteredDeviceData = res
                    }
                }
            }
            visiableData = filteredDeviceData

            let _fpdata = JSON.parse(JSON.stringify(visibleFilterData));
            let _vdata = JSON.parse(JSON.stringify(visiableData));
            let _pfdata = JSON.parse(JSON.stringify(deviceTableFilter));
            state = {
                ...state,
                filteredDeviceData: _fpdata,
                deviceData: _vdata,
                deviceTableFilter: _pfdata
            };

            break;
        }
        case DEVICES_SELECTION:{
            let selection = action.payload
            let initiateDeviceData = state.initiateDeviceData
            let newSelection = []
            Object.entries(selection).forEach(([key,value]) => {
                if (value){
                    let index = initiateDeviceData.findIndex((obj => obj.id === key));
                    let DeviceID = initiateDeviceData[index].DeviceID
                    newSelection.push({id:key,name:DeviceID})
                }
            })
            state = {
                ...state,
                selectedDevicesData: newSelection
            };
            break;
        }
        default:
            break;
    }
    return state;
};
export default deviceTableDataReducer;
