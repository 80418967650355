import React, {Component} from 'react'
import SvGraphics from "../../assets/SvGraphics";
import PropTypes from "prop-types";
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import '../../components_style/FilterTooltip.css'
import '../../components_style/rtl_css/FilterTooltip.css'
import utils from "../../utils/utils";

class FilterTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selected: this.props.filterData,
            isInitial: true
        }
    }

    getFiltersData = () => {
        let data = []
        let mainUid = utils.getUID()
        data.push(
            <tr className={'filter-tr'} key={mainUid}>
                <input
                type="checkbox"
                className=" all-filter-checkbox checkbox"
                value={0}
                id={mainUid}
                defaultChecked={this.state.selected.length === this.props.filterData.length || this.state.isInitial}
                onChange={() => {
                    let res = {}
                    if (this.state.selected.length === this.props.filterData.length){
                        utils.set(res,this.props.field,[])
                        this.props.onFilterDataSelected(res)
                        this.setState({selected: [], isInitial: false})
                    } else if (this.state.selected.length === 0){
                        utils.set(res,this.props.field,this.props.filterData)
                        this.props.onFilterDataSelected(res)
                        this.setState({selected: this.props.filterData, isInitial: false})
                    } else {
                        utils.set(res,this.props.field,this.props.filterData)
                        this.props.onFilterDataSelected(res)
                        this.setState({selected: this.props.filterData, isInitial: false})
                    }

                }}
            />
            <label htmlFor={mainUid} style={{fontWeight:'bold'}} className={'filter-label'}>Select all</label>
            </tr>
        )
        this.props.filterData.forEach(obj => {
            data.push(
                <tr className={'filter-tr'} key={utils.getUID()}>
                        <input
                        type="checkbox"
                        className="checkbox"
                        value={obj}
                        defaultChecked={this.state.selected.indexOf(obj) > -1  || this.state.isInitial || this.state.selected.length === this.props.filterData.length}
                        id={obj}
                        onChange={async () => {
                            let selected = this.state.selected
                            let i = selected.indexOf(obj)
                            if (i === -1) {
                                selected.push(obj)
                            }
                            else {
                                selected.splice(i, 1)
                            }
                            if (selected.length === 0) {
                                let res = {}
                                utils.set(res, this.props.field, [])
                                this.props.onFilterDataSelected(res)
                            } else if (selected.length === this.props.filterData.length){
                                let res = {}
                                utils.set(res,this.props.field,this.props.filterData)
                                this.props.onFilterDataSelected(res)
                                this.setState({selected: [], isInitial: false})
                            } else {
                                let res = {}
                                utils.set(res,this.props.field,selected)
                                this.props.onFilterDataSelected(res)
                            }
                            await this.setState({selected, isInitial: false})


                        }}
                    />
                    {
                        !!this.props.filterConverter ?
                            <label className={'filter-label'} htmlFor={obj}>{
                                this.props.filterConverter.find(
                                    constant => String(constant.value).toLowerCase()
                                    ===
                                    String(obj).toLowerCase()).label
                            }</label> :
                            <label className={'filter-label'} htmlFor={obj}>{obj}</label>
                    }

                </tr>
            )
        })
        return data;
    }

    render() {
        return (
            <OverlayTrigger
                placement={"bottom"}
                delay={{show: 250, hide: 250}}
                trigger={"click"}
                rootClose
                if
                overlay={
                    <Popover id="popover-basic">
                        <Popover.Content>
                            <table className={'filter-table'}>
                                <tbody>
                                {this.getFiltersData()}
                                </tbody>
                            </table>
                        </Popover.Content>
                    </Popover>
                }>
                <SvGraphics className={'filter-toggle table-filter'} svgname={'filter-filled'} style={this.props.filterStyle}/>
            </OverlayTrigger>
        );
    }
}

FilterTooltip.propTypes = {
    onFilterDataSelected: PropTypes.func.isRequired,
    filterData: PropTypes.array.isRequired,
    filterStyle: PropTypes.object
};
export default FilterTooltip;

