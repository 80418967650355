
import React, {Component} from 'react'
import '../../components_style/PatientScreen.css'
import '../../components_style/rtl_css/PatientScreen.css'
import utils from "../../utils/utils";
import SvGraphics from "../../assets/SvGraphics";
import {doctorApi} from "../../services/ApiService";
import ConstantsUtils from "../../utils/ConstantsUtils";
import Constants from "../../utils/constants";
import {history} from "../../utils/history";
import {sendMessage} from "../modals/MessageModal";
import AlertConfirm from "../modals/Confirm";
import {filterPatientsTable} from "../../redux/actions/Tabs-Actions";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";


class PatientPersonalConnected extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patient: null,
            isPersonalInfoOpen: false,
            isContactInfoOpen: false,
            isNotesOpen: false
        }
    }

    async componentWillMount() {

    }

    componentDidMount = () => {
        let patient = this.props.patient
        this.setState({patient})
    }

    manageInfoExpand = (infoField, target) => {
        let {isPersonalInfoOpen, isContactInfoOpen, isNotesOpen} = this.state
        if (infoField === 'pi') this.setState({isPersonalInfoOpen:!isPersonalInfoOpen})
        else if (infoField === 'ci') this.setState({isContactInfoOpen:!isContactInfoOpen})
        else if (infoField === 'pn') this.setState({isNotesOpen:!isNotesOpen})
    }

    deletePatient = async (patient) => {
        let confirm = await AlertConfirm({
            options:{
                extraMessage:'This action cannot be undone',
                title: `Delete patient`,
                warningRed: true
            }},`Are you sure you want to delete the patient: ${this.props.patientName}?`)
        if (confirm) {
            let response = await doctorApi.deletePatient(patient.UserStorageID)
            if (response) {
                let UserStorageID = patient.UserStorageID
                await this.props.deletePatient(UserStorageID)
                this.props.onNavBack()
            }
        }
    }

    editPatientDetails = (patientData) => {
        let patient = Object.assign({},patientData)
        Object.entries(patient.PII).forEach(entry => {
            let [key, value] = entry
            patient[key] = value
        })
        delete patient.PII
        history.push({
            pathname: `/dashboard/EditPatient`,
            state: {
                patient,
                from: {
                    fromPath: `/dashboard`,
                }
            },
        })
    }

    sendMessage = async () => {
        let Method = await sendMessage({
        },[{id:this.props.patientId,name:this.props.patientName}]);
        if (Method){
            let PatientsIDs = [this.props.patientId]
            await doctorApi.sendMessageToPatients({Method,PatientsIDs})
        }
    }

    render() {
    let {t} = this.props

    let patient = this.props.patient
        let device = patient ? patient.Device : undefined

        let lastVisit = this.props.lastVisit ? this.props.lastVisit : {}
        let AmblyopicEye = lastVisit ?  lastVisit['AmblyopicEye']: undefined

        let glassModel = ''
        if (!!AmblyopicEye) {
            if (AmblyopicEye.toString() === '1') {glassModel = 'B'} else if (AmblyopicEye.toString() === '2') {glassModel = 'A'}
        }
        let accountStatus = patient ? t(ConstantsUtils.getPatient_AccountStatus(patient.AccountStatus)) : undefined
        /*let lockedStatus = patient ? patient.PII.User.Enabled : 1*/
        let lockedStatusLabel = 'Locked'
//
        let socialId= patient?patient.PII.SocialID:""
        let genderCheck=Constants.Genders.find(base => base.value === patient.PII.Gender)
        let gender =  patient && genderCheck ? genderCheck.label : undefined
        let birthdate =  patient && patient.PII.Birthdate ? utils.getDateFormatWithYear(new Date(patient.PII.Birthdate)): ""
        birthdate=birthdate.includes("NaN")?"":birthdate
        let category =  patient ? patient.Category: undefined
        let address = patient ?
            <>{`${patient.PII.Apartment} ${patient.PII.Street}`}<br/>{`${patient.PII.State}${patient.PII.State?' ':''}${patient.PII.Country}`}</>
            : undefined


        let PhoneNumber = patient ? patient.PII.PhoneNumber : undefined
        let Email = patient ? patient.PII.Email : undefined
        let street =  patient.PII.Street?patient.PII.Street:""
        let city =  patient.PII.City?patient.PII.City:""
        let Country =  patient.PII.Country?patient.PII.Country:""
        let adress = patient ? street + " " + city + " "+ Country : undefined

        let notes = patient ? patient.Notes : ''

        let Status = patient ? patient.Status : 0

        return (
            <div className={'nova-patient-left inline'}>

                {Status === 1 && <div className={'nova-patient-screen-Status'}>
                    <div className={'status-icon'}>
                        <SvGraphics svgname={'warning-red'} className={'h-centered'} style={{width: '30px'}}/>
                    </div>
                    <div className={'status-label red '}>
                        <label className={'red h-centered'}>
                            {'Missed more than 30%'}
                        </label>
                    </div>
                    <div className={'status-send-message red h-centered'} onClick={this.sendMessage.bind(this)}>
                        <label className={'red link h-centered'}>
                            Send Message
                        </label>
                    </div>
                </div>}
                <div className={'nova-patient-screen-info'}>
                    <div className={'patient-device-info'}>
                        <label className={'patient-info-title-label'}> {t('ecp.patient.form.personal_info')}</label>
                        <div className={'patient-device'}>
                            <table className={'patient-personal-table'}>
                                <tr>
                                    <th className={'empty-th-table'}/>
                                </tr>
                                <tr className={'patient-personal-tr'}>
                                    <td className={'patient-personal-td-label-left'}><label
                                        className={'patient-personal-td-label'}>{t('ecp.patient.form.id')}</label>
                                    </td>

                                    <td className={'patient-personal-td-right'}><label
                                        className={'patient-personal-td-label bolded'}>{socialId}</label>
                                    </td>
                                </tr>
                                <tr className={'patient-personal-tr'}>
                                    <td className={'patient-personal-td-label-left'}><label
                                        className={'patient-personal-td-label'}>{t('ecp.archive.table.gender')}</label>
                                    </td>

                                    <td className={'patient-personal-td-right'}><label
                                        className={'patient-personal-td-label bolded'}>{gender}</label>
                                    </td>
                                </tr>
                                <tr className={'patient-personal-tr'}>
                                    <td className={'patient-personal-td-label-left'}><label
                                        className={'patient-personal-td-label'}>{t('ecp.info_filters.prev_visit')}</label>
                                    </td>

                                    <td className={'patient-personal-td-right'}><label
                                        className={'patient-personal-td-label bolded'}>{this.props.lastVisitDate}</label>
                                    </td>
                                </tr>
                                <tr className={'patient-personal-tr'}>
                                    <td className={'patient-personal-td-label-left'}><label
                                        className={'patient-personal-td-label'}>{t('ecp.patient.table.dob')}</label>
                                    </td>

                                    <td className={'patient-personal-td-right'}><label
                                        className={'patient-personal-td-label bolded'}>{birthdate}</label>
                                    </td>
                                </tr>
                                <tr className={'patient-personal-tr'}>
                                    <td className={'patient-personal-td-label-left'}><label
                                        className={'patient-personal-td-label'}>{t('ecp.archive.table.category')}</label>
                                    </td>

                                    <td className={'patient-personal-td-right'}><label
                                        className={'patient-personal-td-label bolded'}>{category}</label>
                                    </td>
                                </tr>
                            </table>
                        </div>

                    </div>
                    <div className={'patient-info-options'}>
                        <div className={'patient-info-options-element patient-personal-information-edit-container h-centered'}>
                            <label onClick={this.manageInfoExpand.bind(this,'pi')} className={'patient-personal-label patient-personal-information-edit-label'}>{t('ecp.patient.form.contact_info')}</label>
                            <SvGraphics onClick={this.manageInfoExpand.bind(this,'pi')} className={'patient-personal-svg'} svgname={this.state.isPersonalInfoOpen? 'arrow-down' : 'arrow-right'} height={'25px'} width={this.state.isPersonalInfoOpen ? '15px': '9px'}/>
                            {this.state.isPersonalInfoOpen && <div className={'patient-personal-information-view'}>
                                <div className={'patient-device'}>
                                    <table className={'patient-personal-table'}>
                                        <tr className={'patient-personal-tr'}>
                                            <td className={'patient-contact-td-label-left'}><label
                                                className={'patient-personal-td-label'}>{t('ecp.patient.form.address')}</label>
                                            </td>

                                            <td className={'patient-personal-td-right'}><label
                                                className={'patient-personal-td-label bolded'}>{adress}</label>
                                            </td>
                                        </tr>
                                        <tr className={'patient-personal-tr'}>
                                            <td className={'patient-contact-td-label-left'}><label
                                                className={'patient-personal-td-label '}>{t('ecp.patient.form.phone_number')}</label>
                                            </td>

                                            <td className={'patient-personal-td-right'}><label
                                                className={'patient-personal-td-label bolded'}>{PhoneNumber}</label>
                                            </td>
                                        </tr>
                                        <tr className={'patient-personal-tr'}>
                                            <td className={'patient-contact-td-label-left'}><label
                                                className={'patient-personal-td-label'}>{t('ecp.patient.form.email')}</label>
                                            </td>

                                            <td className={'patient-personal-td-right'}><label
                                                className={'patient-personal-td-label bolded'}>{Email}</label>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>}
                        </div>

                        <div className={'patient-info-options-element patient-notes-edit-container'}>
                            <label onClick={this.manageInfoExpand.bind(this,'pn')} className={'patient-personal-label patient-notes-edit-label'}>{t('ecp.patient.form.notes')}</label>
                            <SvGraphics onClick={this.manageInfoExpand.bind(this,'pn')} className={'patient-personal-svg'}
                                        svgname={this.state.isNotesOpen? 'arrow-down' : 'arrow-right'} height={'25px'} width={this.state.isNotesOpen ? '15px': '9px'}/>
                            {this.state.isNotesOpen && <div className={'patient-notes-view'}>
                                <div className={'patient-notes-view-container patient-personal-td-label h-centered'}>
                                    {notes}
                                </div>
                            </div>}
                        </div>
                    </div>
                        <div className={'patient-options h-centered'}>
                        <button disabled={this.props.completed} onClick={() => this.editPatientDetails(patient)} className={'patient-options-btn patient-options-btn-update btn btn-primary patient-visit-date-button'}>{t('ecp.patient.form.update_patient_information')}</button>
                        </div>
                        <div className={'patient-options h-centered'}>
                        <button onClick={() => this.deletePatient(patient)} className={'patient-options-btn patient-options-btn-delete btn btn-primary patient-visit-date-button'}>{t('ecp.patient.form.del_patient')}</button>
                        </div>
                </div>
            </div>
        )
    }
}
function mapDispatchToProps(dispatch) {
    return {
        filterPatientsTable:(filterType,payload) => dispatch(filterPatientsTable(filterType,payload)),
    };
}
const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData,
        patientsCount:state.patientsTableDataReducer.patientsCount,
        activePatientsCount:state.patientsTableDataReducer.activePatientsCount,
        pendingPatientsCount:state.patientsTableDataReducer.pendingPatientsCount,
        lockedPatientsCount:state.patientsTableDataReducer.lockedPatientsCount,
    };
};

const PatientPersonal = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(PatientPersonalConnected)
export default PatientPersonal;

