import React, {Component} from 'react'
import PropTypes from "prop-types";
import utils from "../../utils/utils";
import Error from "../single_components/Error";
import PhoneInput from "react-phone-input-2";

class PatientRemoteTesting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entry: this.props.entry,
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.entry !== prevProps.entry) {
            this.setState({
                entry: this.props.entry
            })
        }
    }


    render() {
        let{t}=this.props
        return (
            <div className={'Patient-personal-Form'}>
                <div className={'Patient-personal-form-container'}>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('ecp.patient.form.phone_number')}</label>
                        <div className={'form-search'}>
                            <PhoneInput
                                country={'us'}
                                value={utils.get(this.props.entry,'PhoneNumber') !== null &&
                                utils.get(this.props.entry,'PhoneNumber') !== undefined?
                                    utils.get(this.props.entry,'PhoneNumber') :''}
                                inputClass={'nova-form-input'}
                                containerClass={'nova-PhoneInput-container'}
                                buttonClass={'nova-PhoneInput-button'}
                                dropdownClass={`nova-patient-PhoneInput-dropdown`}
                                searchClass={'nova-patient-PhoneInput-search'}
                                enableSearch={true}
                                countryCodeEditable={false}
                                autoFormat={true}
                                onChange={phone =>this.props.onValueChange('PhoneNumber',phone)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'PhoneNumber')} isShown={!!utils.get(this.props.errors, 'PhoneNumber')}/>
                        </div>
                    </div>
                    <div className={'Patient-personal-form-element'}>
                        <label className={'patient-form-label nova-form-label'}>{t('ecp.patient.form.email')}</label>
                        <div className={'form-search'}>
                            <input
                                type={"text"}
                                maxLength={100}
                                disabled={this.props.isDisabled}
                                className={"nova-form-input"}
                                defaultValue={utils.get(this.props.entry,'Email') !== null &&
                                utils.get(this.props.entry,'Email') !== undefined?
                                    utils.get(this.props.entry,'Email') :''}
                                name={'Email'}
                                onChange={(event) => this.props.onValueChange('Email', event.target.value)}
                            />
                            <Error errorMessage={utils.get(this.props.errors,'Email')} isShown={!!utils.get(this.props.errors, 'Email')}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

PatientRemoteTesting.propTypes = {
    onValueChange:PropTypes.func.isRequired,
    entry: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool
}

export default PatientRemoteTesting;
