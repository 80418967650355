import {
    ECP_DATA,
    FILTER_ECPDATA,
    ECP_SELECTION,
    NEW_ECP,
    EDIT_ECP,
    ECPS_COUNT,
    ECP_LOCKED_STATUS,

    SITES_DATA,
    FILTER_SITESDATA,
    SITES_SELECTION,
    NEW_SITE,
    EDIT_SITE,
    SITES_COUNT,

    DEVICES_DATA,
    FILTER_DEVICESDATA,
    DEVICES_SELECTION,
    DEVICES_COUNT,

    FILTER_PENDINGSDATA,
    PENDING_DATA,
    DELETE_PENDING,
    PENDINGS_COUNT,

    ADMINS_DATA,
    FILTER_ADMINSDATA,
    ADMINS_SELECTION,
    ADMIN_LOCKED_STATUS,
    NEW_ADMIN,
    EDIT_ADMIN,
    ADMINS_COUNT,
    DELETE_ADMIN,

    PATIENTS_DATA,
    FILTER_PATIENTSDATA,
    PATIENTS_SELECTION,
    EDIT_PATIENT,
    PATIENTS_COUNT,
    NEW_PATIENT,
    PATIENT_LOCKED_STATUS,
    DELETE_PATIENT,

    PATIENTS_ARCHIVE_DATA,
    FILTER_PATIENTS_ARCHIVEDATA,
    PATIENTS_ARCHIVE_SELECTION,
    PATIENTS_ARCHIVE_COUNT,
    VERSIONS_DATA,
    FILTER_VERSIONSDATA,
    VERSIONS_SELECTION,
    NEW_VERSION,
    EDIT_VERSION,
    VERSIONS_COUNT,
    DELETE_VERSION,

    NEW_ARCHIVE_PATIENT,
    PATIENTS_STATUSES,
    DEVICES_STATUSES,
    CLEAN_ECP,
    CLEAN_SITE,
    CLEAN_DEVICE,
    CLEAN_ADMIN,
    CLEAN_PATIENT, CLEAN_PATIENT_ARCHIVE, CLEAN_VERSIONS, DELETE_SITE_ECPS, DELETE_SITE
} from "../constants/Tabs-Action-Types";


//ECPS-table
export function setEcpData(payload) {
    return { type: ECP_DATA, payload };
}
export function filterEcpTable(searchType, payload) {
    return { type: FILTER_ECPDATA, searchType, payload };
}
export function setEcpDataSelection(payload) {
    return { type: ECP_SELECTION, payload };
}
export function addNewECP(payload) {
    return {type: NEW_ECP, payload}
}
export function editECP(payload) {
    return {type: EDIT_ECP, payload}
}
export function SETECPsCount(payload) {
    return {type: ECPS_COUNT, payload}
}
export function setEcpLockedStatus(payload) {
    return {type: ECP_LOCKED_STATUS, payload}
}
export function cleanEcp() {
    return {type: CLEAN_ECP}
}
//SITES-table
export function setSitesData(payload) {
    return { type: SITES_DATA, payload };
}
export function filterSitesTable(searchType, payload) {
    return { type: FILTER_SITESDATA, searchType, payload };
}
export function setSiteDataSelection(payload) {
    return { type: SITES_SELECTION, payload };
}
export function addNewSite(payload) {
    return {type: NEW_SITE, payload}
}
export function editSite(payload) {
    return {type: EDIT_SITE, payload}
}
export function SETSitesCount(payload) {
    return {type: SITES_COUNT, payload}
}
export function cleanSite() {
    return {type: CLEAN_SITE}
}
//DEVICES-table
export function setDevicesData(payload) {
    return { type: DEVICES_DATA, payload };
}
export function filterDevicesTable(searchType, payload) {
    return { type: FILTER_DEVICESDATA, searchType, payload };
}
export function SETDevicesCount(payload) {
    return {type: DEVICES_COUNT, payload}
}
export function setDevicesDataSelection(payload) {
    return { type: DEVICES_SELECTION, payload };
}
export function setDevicesStatuses(payload) {
    return { type: DEVICES_STATUSES, payload };
}
export function cleanDevice() {
    return {type: CLEAN_DEVICE, }
}

//Pending Table

export function filterPendingsTable(searchType, payload ) {
    return {type: FILTER_PENDINGSDATA,searchType, payload }
}
export function setPendingsData(payload) {
    return { type: PENDING_DATA, payload };
}
export function SETPendingsCount(payload) {
    return { type: PENDINGS_COUNT, payload };
}
export function deletePendingUser(payload) {
    return { type: DELETE_PENDING, payload };
}

//ADMINS-table
export function setAdminsData(payload) {
    return { type: ADMINS_DATA, payload };
}
export function filterAdminsTable(searchType, payload) {
    return { type: FILTER_ADMINSDATA, searchType, payload };
}
export function setAdminDataSelection(payload) {
    return { type: ADMINS_SELECTION, payload };
}
export function addNewAdmin(payload) {
    return {type: NEW_ADMIN, payload}
}
export function editAdmin(payload) {
    return {type: EDIT_ADMIN, payload}
}
export function SETAdminCount(payload) {
    return {type: ADMINS_COUNT, payload}
}
export function deleteAdmin(payload) {
    return {type: DELETE_ADMIN, payload}
}
export function setAdminLockedStatus(payload) {
    return {type: ADMIN_LOCKED_STATUS, payload}
}

export function cleanAdmin() {
    return {type: CLEAN_ADMIN}
}
//PATIENTS-table
export function setPatientsData(payload) {
    return { type: PATIENTS_DATA, payload };
}
export function filterPatientsTable(searchType, payload) {
    return { type: FILTER_PATIENTSDATA, searchType, payload };
}
export function setPatientsDataSelection(payload) {
    return { type: PATIENTS_SELECTION, payload };
}
export function SETPatientsCount(payload) {
    return {type: PATIENTS_COUNT, payload}
}
export function addNewPatient(payload) {
    return {type: NEW_PATIENT, payload}
}
export function editPatientData(payload) {
    return {type: EDIT_PATIENT, payload}
}
export function setPatientLockedStatus(payload) {
    return {type: PATIENT_LOCKED_STATUS, payload}
}
export function deletePatient(payload) {
    return {type: DELETE_PATIENT, payload}
}
export function setPatientsStatuses(payload) {
    return { type: PATIENTS_STATUSES, payload };
}
export function cleanPatient() {
    return {type: CLEAN_PATIENT}
}
//PATIENTS_ARCHIVE-table
export function setPatients_ArchiveData(payload) {
    return { type: PATIENTS_ARCHIVE_DATA, payload };
}
export function filterPatients_ArchiveTable(searchType, payload) {
    return { type: FILTER_PATIENTS_ARCHIVEDATA, searchType, payload };
}
export function addNewArchivePatient(payload) {
    return {type: NEW_ARCHIVE_PATIENT, payload}
}
export function setPatients_ArchiveDataSelection(payload) {
    return {type: PATIENTS_ARCHIVE_SELECTION, payload};
}
export function SETPatients_ArchiveCount(payload) {
    return {type: PATIENTS_ARCHIVE_COUNT, payload}
}
export function cleanPatient_Archive() {
    return {type: CLEAN_PATIENT_ARCHIVE}
}
//VERSIONS-table
export function setVersionsData(payload) {
    return { type: VERSIONS_DATA, payload };
}
export function filterVersionsTable(searchType, payload) {
    return { type: FILTER_VERSIONSDATA, searchType, payload };
}
export function setVersionDataSelection(payload) {
    return { type: VERSIONS_SELECTION, payload };
}
export function addNewVersion(payload) {
    return {type: NEW_VERSION, payload}
}
export function editVersion(payload) {
    return {type: EDIT_VERSION, payload}
}
export function deleteVersion(payload) {
    return {type: DELETE_VERSION, payload}
}
export function SETVersionsCount(payload) {
    return {type: VERSIONS_COUNT, payload}
}
export function cleanVersion() {
    return {type: CLEAN_VERSIONS}
}
export function deleteSite(siteId) {
    return {type: DELETE_SITE, siteId}
}
export function deleteSiteEcps(siteId) {
    return {type: DELETE_SITE_ECPS, siteId}
}

