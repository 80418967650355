import React, {Component} from 'react'

import Middleware from "../../../routes/Middleware";
import PrivateRoute from "../../../routes/PrivateRoute";
import NovaTab from "../../../components/single_components/NovaTab";
import {history} from "../../../utils/history";
import Config from "../../../config/Config";
import signedUserHelper from "../../../utils/signedUserHelper";

import ECPsTab from "./ECPsTab";
import SitesTab from "./SitesTab";
import DevicesTab from "./DevicesTab";
import ECPsTabTools from "../../../components/ecp/ECPsTabTools";
import SitesTabTools from "../../../components/site/SitesTabTools";
import DevicesTabTools from "../../../components/device/DevicesTabTools";
import AdminTabTools from "../../../components/admin/AdminsTabTools";
import AdminsTab from "./AdminsTab";
import {adminApi} from "../../../services/ApiService";
import utils from "../../../utils/utils";

//REDUX
import { connect } from "react-redux";
import {
    setEcpData,
    filterEcpTable,
    addNewECP,
    SETECPsCount,

    setSitesData,
    filterSitesTable,
    addNewSite,
    SETSitesCount,

    setDevicesData,
    filterDevicesTable,
    SETDevicesCount,

    setAdminsData,
    filterAdminsTable,
    addNewAdmin,
    SETAdminCount,

    setPendingsData,
    filterPendingsTable,
    setVersionsData,
    filterVersionsTable,
    addNewVersion,
    SETPendingsCount,

    setDevicesStatuses,
} from "../../../redux/actions/Tabs-Actions.js";
import {authenticationService} from "../../../services/AuthenticationService";
import {SETVersionsCount} from "../../../redux/actions/Tabs-Actions";
import VersionsTabTools from "../../../components/version/VersionsTabTools";
import VersionsTab from "./VersionsTab";
import Switch from "react-bootstrap/Switch";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import PendingsTabTools from "../../../components/pending/PendingsTabTools";
import PendingsTab from "./PendingsTab";



class AdminTabsConComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentUser: null,
            isEcp: false,
            isSites: false,
            isDevices: false,
            isAdmins: false,
            isPendingTab: false,
            isVersions: false,
        }

    }

    componentWillMount() {

    }

    componentDidMount = async ()  => {
        let currentUser = authenticationService.currentUserValue;
        this.props.SETECPsCount(currentUser.profile.NumOfECP)
        this.props.SETSitesCount(currentUser.profile.NumOfSites)
        this.props.SETDevicesCount(currentUser.profile.NumOfDevices)
        this.props.SETAdminCount(currentUser.profile.NumOfAdmins)
        this.props.SETVersionsCount(currentUser.profile.NumOfVersions ? currentUser.profile.NumOfVersions : 0)
        this.setState({isEcp: true});
        let devices = await adminApi.getAllDevices()
        if (devices && !utils.isEmpty(devices.data)) {
            this.props.setDevicesData(devices.data)
        }
        await this.handleNavigationButton(`/dashboard/ecps`,0)
    }

    async handleNavigationButton(path,tab_id){


        let isEcp, isSites, isDevices, isAdmins, isVersions,isPendingTab;
        if (tab_id === 0) {
            isEcp = true;
            isSites = isDevices = isAdmins = isVersions = isPendingTab = false;
            this.setState({isEcp, isSites, isDevices, isAdmins,isVersions,isPendingTab})
            let ecps = await adminApi.getAllECPs()
            if (ecps) this.props.setEcpData(ecps.data)
        } else if (tab_id === 1) {
            isSites = true;
            isEcp = isDevices = isAdmins = isVersions = isPendingTab = false
            this.setState({isEcp, isSites, isDevices, isAdmins,isVersions,isPendingTab})
            let sites = await adminApi.getAllSites()
            if (sites) this.props.setSitesData(sites.data)
        } else if (tab_id === 2) {
            isDevices = true
            isEcp = isSites = isAdmins = isVersions = isPendingTab = false
            this.setState({isEcp, isSites, isDevices, isAdmins,isVersions,isPendingTab})
            let devices = await adminApi.getAllDevices()
            if (devices) {
                await this.props.setDevicesData(devices.data)
            }
        } else if (tab_id === 3) {
            isAdmins = true
            isEcp = isSites = isDevices = isVersions = isPendingTab = false
            this.setState({isEcp, isSites, isDevices, isAdmins,isVersions,isPendingTab})
            let admins = await adminApi.getAllAdmins()
            if (admins) this.props.setAdminsData(admins.data)
        } else if (tab_id === 4) {
            isVersions = true
            isEcp = isSites = isDevices = isAdmins = isPendingTab = false
            this.setState({isEcp, isSites, isDevices, isAdmins,isVersions,isPendingTab})
            let versions = await adminApi.getAllVersions()
            if (versions) this.props.setVersionsData(versions.data)
        }else if (tab_id === 5) {
            isPendingTab = true
            isEcp = isSites = isDevices = isAdmins = isVersions=false
            this.setState({isEcp, isSites, isDevices, isAdmins,isVersions,isPendingTab})
            let pendings = await adminApi.getAllPendings()
            console.log("pendings",pendings)
            if (pendings) this.props.setPendingsData(pendings.data)
        }

        history.push({
            pathname: path,
            state: {
                from: {
                    fromPath: `/dashboard`,
                }
            },
        })
    }

    navLinks = () => {
        let navLinks = [];
        let {t} = this.props
        navLinks.push(Middleware.linkToDisplay(['userAccessLink'],
            <NovaTab key={'ECPs'} status={this.state.isEcp} onClick={this.handleNavigationButton.bind(this, `/dashboard/ecps`, 0)}
                     value={t('admin.ecp.ecps')} count={this.props.ecpCount}/>,
            {roles: [Config.roles.EyeSwiftSuperAdmin, Config.roles.EyeSwiftAdmin]}));
        navLinks.push(Middleware.linkToDisplay(['userAccessLink'],
            <NovaTab key={'Sites'} status={this.state.isSites} onClick={this.handleNavigationButton.bind(this, `/dashboard/sites`, 1)}
                     value={t('admin.site.sites')} count={this.props.sitesCount}/>,
            {roles: [Config.roles.EyeSwiftSuperAdmin, Config.roles.EyeSwiftAdmin]}));
        navLinks.push(Middleware.linkToDisplay(['userAccessLink'],
            <NovaTab key={'Devices'}  status={this.state.isDevices} onClick={this.handleNavigationButton.bind(this, `/dashboard/devices`, 2)}
                     value={t('admin.device.devices')} count={this.props.devicesCount}/>,
            {roles: [Config.roles.EyeSwiftSuperAdmin, Config.roles.EyeSwiftAdmin]}));
        navLinks.push(Middleware.linkToDisplay(['userAccessLink'],
            <NovaTab key={'Admins'}  status={this.state.isAdmins} onClick={this.handleNavigationButton.bind(this, `/dashboard/admins`, 3)}
                     value={t('admin.admin.admins')} count={this.props.adminsCount}/>,
            {roles: [Config.roles.EyeSwiftSuperAdmin]}));
        navLinks.push(Middleware.linkToDisplay(['userAccessLink'],
            <NovaTab key={'Versions'}  status={this.state.isVersions} onClick={this.handleNavigationButton.bind(this, `/dashboard/versions`, 4)}
                     value={t('admin.version.versions')} count={this.props.versionsCount}/>,
            {roles: [Config.roles.EyeSwiftSuperAdmin, Config.roles.EyeSwiftAdmin]}));

        navLinks.push(Middleware.linkToDisplay(['userAccessLink'],
            <NovaTab key={'Pendings'}  status={this.state.isPendingTab} onClick={this.handleNavigationButton.bind(this, `/dashboard/pending`, 5)}
                     value={t('defaults.pending')} count={this.props.pendingsCount}/>,
            {roles: [Config.roles.EyeSwiftSuperAdmin, Config.roles.EyeSwiftAdmin]}));
        return navLinks;
    }

    filterEcpTable(val){
        //this.render()
        this.setState({searchValue:val})
        this.props.filterEcpTable('search',val)
    }

    filterSitesTable(val){
        //this.render()
        this.setState({searchValue:val})
        this.props.filterSitesTable('search',val)
    }

    searchDevicesTable(val){
        //this.render()
        this.setState({searchValue:val})
        this.props.filterDevicesTable('search',val)
    }

    searchPendingsTable(val){
        this.setState({searchValue:val})
        this.props.filterPendingsTable('search',val)
    }

    filterAdminsTable(val){
        //this.render()
        this.setState({searchValue:val})
        this.props.filterAdminsTable('search',val)
    }
    filterVersionsTable(val){
        //this.render()
        this.setState({searchValue:val})
        this.props.filterVersionsTable('search',val)
    }

    render() {
        return (
            <div className={'nova-context-area'}>
                <div className={'nova-table-tabs-container'}>
                    <div className={'nav'}>
                        <Switch>{this.navLinks()}</Switch>
                        {
                            this.state.isEcp
                            ? Middleware.linkToDisplay(['userAccessLink'],
                                <ECPsTabTools
                                    t={e => this.props.t(e)}
                                    filterEcpTable={val => this.filterEcpTable(val)}
                                    recipients={this.props.selectedEcpData}
                                    addNewECP={val => this.props.addNewECP(val)}
                                    siteData={this.props.siteData}
                                    isSmall={signedUserHelper.getSignedRole() === Config.roles.EyeSwiftSuperAdmin}/>,
                                {roles: [Config.roles.EyeSwiftSuperAdmin, Config.roles.EyeSwiftAdmin]})
                            : this.state.isSites
                            ? Middleware.linkToDisplay(['userAccessLink'],
                                <SitesTabTools
                                    t={e => this.props.t(e)}
                                    filterSiteTable={val => this.filterSitesTable(val)}
                                    addNewSite={val => {
                                        this.props.addNewSite(val)
                                    }}
                                    isSmall={signedUserHelper.getSignedRole() === Config.roles.EyeSwiftSuperAdmin}/>,
                                    {roles: [Config.roles.EyeSwiftSuperAdmin, Config.roles.EyeSwiftAdmin]})
                            : this.state.isPendingTab
                            ? Middleware.linkToDisplay(['userAccessLink'],
                                <PendingsTabTools
                                    t={e => this.props.t(e)}
                                    recipients={this.props.pendingData}
                                    searchPendingsTable={val => this.searchPendingsTable(val)}
                                    isSmall={signedUserHelper.getSignedRole() === Config.roles.EyeSwiftSuperAdmin}/>,
                                    {roles: [Config.roles.EyeSwiftSuperAdmin, Config.roles.EyeSwiftAdmin]})
                            : this.state.isDevices
                            ? Middleware.linkToDisplay(['userAccessLink'],
                                    <DevicesTabTools
                                        t={e => this.props.t(e)}
                                        recipients={this.props.selectedDevicesData}
                                        searchDevicesTable={val => this.searchDevicesTable(val)}
                                        isSmall={signedUserHelper.getSignedRole() === Config.roles.EyeSwiftSuperAdmin}/>,
                                                {roles: [Config.roles.EyeSwiftSuperAdmin, Config.roles.EyeSwiftAdmin]})
                            :  this.state.isAdmins
                            ? Middleware.linkToDisplay(['userAccessLink'],
                                <AdminTabTools
                                    t={e => this.props.t(e)}
                                    addNewAdmin={val => this.props.addNewAdmin(val)}
                                    filterAdminsTable={val => this.filterAdminsTable(val)}/>,
                                            {roles: [Config.roles.EyeSwiftSuperAdmin]})
                            : this.state.isVersions
                            ? Middleware.linkToDisplay(['userAccessLink'],
                                            <VersionsTabTools
                                                t={e => this.props.t(e)}
                                                addNewVersion={val => this.props.addNewVersion(val)}
                                                filterVersionsTable={val => this.filterVersionsTable(val)}
                                                isSmall={signedUserHelper.getSignedRole() === Config.roles.EyeSwiftSuperAdmin}/>,
                                            {roles: [Config.roles.EyeSwiftSuperAdmin, Config.roles.EyeSwiftAdmin]})
                            : <div/>
                        }

                    </div>
                    <div className={'context-area'}>
                        <Switch>
                            <PrivateRoute exact path={`/dashboard/pending`} component={PendingsTab}/>
                            <PrivateRoute exact path={`/dashboard/ecps`} component={ECPsTab}/>
                            <PrivateRoute path={`/dashboard/sites`} component={SitesTab}/>
                            <PrivateRoute path={`/dashboard/devices`} component={DevicesTab}/>
                            <PrivateRoute path={`/dashboard/admins`} component={AdminsTab}/>
                            <PrivateRoute path={`/dashboard/versions`} component={VersionsTab}/>
                        </Switch>
                    </div>
                </div>
            </div>
        )
    }
}



function mapDispatchToProps(dispatch) {
    return {
        setEcpData: ecpData => dispatch(setEcpData(ecpData)),
        filterEcpTable:(searchType, payload) => dispatch(filterEcpTable(searchType, payload)),
        addNewECP: ecp => dispatch(addNewECP(ecp)),
        SETECPsCount: count => dispatch(SETECPsCount(count)),

        setSitesData: siteData => dispatch(setSitesData(siteData)),
        filterSitesTable:(searchType, payload) => dispatch(filterSitesTable(searchType, payload)),
        addNewSite:site => dispatch(addNewSite(site)),
        SETSitesCount: count => dispatch(SETSitesCount(count)),

        setDevicesData: deviceData => dispatch(setDevicesData(deviceData)),
        filterDevicesTable:(filterType,payload) => dispatch(filterDevicesTable(filterType,payload)),
        SETDevicesCount: count => dispatch(SETDevicesCount(count)),

        setAdminsData: adminData => dispatch(setAdminsData(adminData)),
        filterAdminsTable:(searchType, payload) => dispatch(filterAdminsTable(searchType, payload)),
        addNewAdmin:admin => dispatch(addNewAdmin(admin)),
        SETAdminCount: count => dispatch(SETAdminCount(count)),

        setPendingsData: pendingData => dispatch(setPendingsData(pendingData)),
        filterPendingsTable:(searchType, payload) => dispatch(filterPendingsTable(searchType, payload)),

        setVersionsData: versionData => dispatch(setVersionsData(versionData)),
        filterVersionsTable:(searchType, payload) => dispatch(filterVersionsTable(searchType, payload)),
        addNewVersion:versionData => dispatch(addNewVersion(versionData)),
        SETVersionsCount: count => dispatch(SETVersionsCount(count)),
        SETPendingsCount: count => dispatch(SETPendingsCount(count)),
    };
}
const mapStateToProps = state => {
    return {
        ecpData:state.ecpTableDataReducer.ecpData,
        ecpCount:state.ecpTableDataReducer.ecpCount,
        ecpTableFilter:state.ecpTableDataReducer.ecpTableFilter,
        selectedEcpData:state.ecpTableDataReducer.selectedEcpData,

        pendingData:state.pendingTableDataReducer.pendingData,
        pendingsCount:state.pendingTableDataReducer.pendingsCount,

        siteData:state.siteTableDataReducer.siteData,
        sitesCount: state.siteTableDataReducer.sitesCount,
        siteTableFilter:state.siteTableDataReducer.siteTableFilter,

        deviceData:state.deviceTableDataReducer.deviceData,
        devicesCount: state.deviceTableDataReducer.devicesCount,
        deviceTableFilter:state.deviceTableDataReducer.deviceTableFilter,
        selectedDevicesData:state.deviceTableDataReducer.selectedDevicesData,

        adminData:state.adminTableDataReducer.adminData,
        adminsCount: state.adminTableDataReducer.adminsCount,
        adminTableFilter:state.adminTableDataReducer.adminTableFilter,

        versionsCount:state.versionTableDataReducer.versionsCount,
    };
};

const AdminTabs = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(AdminTabsConComposed);

export default AdminTabs;
