import {
    CLEAN_SITE, DELETE_SITE, DELETE_SITE_ECPS,
    EDIT_SITE,
    FILTER_SITESDATA,
    NEW_SITE,
    SITES_COUNT,
    SITES_DATA,
    SITES_SELECTION
} from "../../constants/Tabs-Action-Types";
import utils from "../../../utils/utils";


const initialState = {
    initiateSiteData:[],
    siteData:[],
    siteTableFilter:{},
    sitesCount: 0
};

function calculateDevices(site) {
    let ecps = site.EyeCareProviders?site.EyeCareProviders.length:0
    if (ecps === 0) return 0

    let numberOfDevices = 0
    site.EyeCareProviders.forEach(ecp => {
        if (ecp.Patients)
        numberOfDevices += ecp.Patients.length
    })
    return numberOfDevices
}

const siteTableDataReducer = (state = initialState, action)=> {

    switch(action.type){
        case SITES_COUNT:{
            let _idata = JSON.parse(JSON.stringify(action.payload));
            state = {
                ...state,
               sitesCount: _idata
            };
            break;
        }
        case SITES_DATA:
            let collection = []
            action.payload.forEach(site =>{
                collection.push({
                    id:site.SiteStorageID,
                    SiteStorageID: site.SiteStorageID,
                    SiteName: site.SiteName,
                    LinkedECPs: site.NumOfECP,
                    LinkedDevices: site.NumOfDevices,
                    SiteEmail: site.SiteEmail,
                    SiteState: site.SiteState,
                    Enabled: true,
                    SiteCountry: site.SiteCountry,
                    SiteCity: site.SiteCity,
                    SiteStreet: site.SiteStreet,
                    SiteApartment: site.SiteApartment?site.SiteApartment:site.Apartment,
                    SitePhoneNumber: site.SitePhoneNumber,
                    AccountStatus: site.AccountStatus,
                    EyeCareProviders: site.EyeCareProviders,
                })
            })

            let _pdata = JSON.parse(JSON.stringify(collection));
            state = {
                ...state,
                initiateSiteData:_pdata,
                siteData : _pdata,
                sitesCount: collection.length
            };
            break;
        case CLEAN_SITE:{
            state = {
                ...state,
                siteTableFilter: {}
            };
            break;
        }
        case FILTER_SITESDATA:{
            let type = action.searchType
            let payload = action.payload
            let siteTableFilter = state.siteTableFilter
            let filteredSitesData = state.initiateSiteData
            let visibleFilterData = []
            let visiableData = []

            if (type === 'search' && (payload === '' || payload === null)){
                delete siteTableFilter.search
            } else {
                utils.set(siteTableFilter,type,payload)
            }

            for (const [key, value] of Object.entries(siteTableFilter)) {
                if (key === 'search'){
                    let val = value.toLowerCase()
                    filteredSitesData = filteredSitesData.filter(x =>
                        String(x.SiteName).toLowerCase().indexOf(val) > -1 ||
                        String(x.LinkedECPs).toLowerCase().indexOf(val) > -1 ||
                        String(x.LinkedDevices).toLowerCase().indexOf(val) > -1 ||
                        String(x.SiteEmail).toLowerCase().indexOf(val) > -1 ||
                        String(x.SitePhoneNumber).toLowerCase().indexOf(val) > -1
                    )
                    visibleFilterData = filteredSitesData
                } else {
                    let sites = JSON.parse(JSON.stringify(filteredSitesData))
                    let valArr = JSON.parse(JSON.stringify(value))


                    if (Array.isArray(valArr)){
                        let res = []
                        valArr.forEach(val => {
                            let siteArr = sites.filter(site => {
                                let siteVal = site[key]
                                return String(siteVal).toLowerCase() === String(val).toLowerCase()
                            })
                            siteArr.forEach(site => res.push(site))
                        })
                        filteredSitesData = res
                    }
                }
            }
            visiableData = filteredSitesData

            let _fpdata = JSON.parse(JSON.stringify(visibleFilterData));
            let _vdata = JSON.parse(JSON.stringify(visiableData));
            let _pfdata = JSON.parse(JSON.stringify(siteTableFilter));
            state = {
                ...state,
                filteredSitesData: _fpdata,
                siteData: _vdata,
                siteTableFilter: _pfdata
            };
            break;
        }
        case SITES_SELECTION:{
            let selection = action.payload
            let initiateSiteData = state.initiateSiteData

            let newSelection = []
            Object.entries(selection).forEach(([key,value]) => {
                if (value){
                    let index = initiateSiteData.findIndex((obj => obj.SiteStorageID === key));
                    let name = initiateSiteData[index].SiteName
                    newSelection.push({id:key,name})
                }
            })
            state = {
                ...state,
                selectedSiteData: newSelection
            };
            break;
        }
        case DELETE_SITE: {
            let siteId = action.siteId

            let siteData = state.siteData
            let initiateSiteData = state.initiateSiteData

            let newsiteData = siteData.filter(site => site.SiteStorageID !== siteId)
            let newinitiateSiteData = initiateSiteData.filter(site => site.SiteStorageID !== siteId)

            let _pdata = JSON.parse(JSON.stringify(newsiteData));
            let _idata = JSON.parse(JSON.stringify(newinitiateSiteData));

            state = {
                ...state,
                initiateSiteData:_idata,
                siteData : _pdata,
                sitesCount: newinitiateSiteData.length
            };
            break;
        }
        case NEW_SITE:{
            let site = action.payload
            let siteData = state.siteData
            let initiateSiteData = state.initiateSiteData

            let newData = [{
                id:site.SiteStorageID,
                SiteStorageID: site.SiteStorageID,
                SiteName: site.SiteName,
                LinkedECPs: site.EyeCareProviders?site.EyeCareProviders.length:0,
                LinkedDevices: site.LinkedDevices?site.LinkedDevices.length:0,
                SiteEmail: site.SiteEmail,
                SiteState: site.SiteState,
                Enabled: true,
                SiteCountry: site.SiteCountry,
                SiteCity: site.SiteCity,
                SiteStreet: site.SiteStreet,
                SiteApartment: site.SiteApartment?site.SiteApartment:site.Apartment,
                SitePhoneNumber: site.SitePhoneNumber,
                AccountStatus: site.AccountStatus,
                EyeCareProviders: site.EyeCareProviders, //
            }]
            let newsiteData = siteData.concat(newData)
            let newinitiateSiteData = initiateSiteData.concat(newData)
            let _pdata = JSON.parse(JSON.stringify(newsiteData));
            let _idata = JSON.parse(JSON.stringify(newinitiateSiteData));
            state = {
                ...state,
                initiateSiteData:_idata,
                siteData : _pdata,
                sitesCount: newinitiateSiteData.length
            };
            break;
        }
        case EDIT_SITE: {
            let site = action.payload
            let siteData = state.siteData
            let initiateSiteData = state.initiateSiteData

            let indexData = siteData.findIndex((obj => obj.id === site.id));
            let indexInit = initiateSiteData.findIndex((obj => obj.id === site.id));

            siteData[indexData]['SiteName'] = site.SiteName
            siteData[indexData]['SitePhoneNumber'] = site.SitePhoneNumber
            siteData[indexData]['SiteEmail'] = site.SiteEmail
            siteData[indexData]['SiteState'] = site.SiteState
            siteData[indexData]['SiteCountry'] = site.SiteCountry
            siteData[indexData]['SiteCity'] = site.SiteCity
            siteData[indexData]['SiteStreet'] = site.SiteStreet
            siteData[indexData]['SiteApartment'] = site.SiteApartment
            siteData[indexData]['SitePhoneNumber'] = site.SitePhoneNumber
            siteData[indexData]['SiteName'] = site.SiteName
            siteData[indexData]['SiteName'] = site.SiteName

            initiateSiteData[indexInit]['SiteName'] = site.SiteName
            initiateSiteData[indexInit]['SitePhoneNumber'] = site.SitePhoneNumber
            initiateSiteData[indexInit]['SiteEmail'] = site.SiteEmail
            initiateSiteData[indexInit]['SiteState'] = site.SiteState
            initiateSiteData[indexInit]['SiteCountry'] = site.SiteCountry
            initiateSiteData[indexInit]['SiteCity'] = site.SiteCity
            initiateSiteData[indexInit]['SiteStreet'] = site.SiteStreet
            initiateSiteData[indexInit]['SiteApartment'] = site.SiteApartment
            initiateSiteData[indexInit]['SitePhoneNumber'] = site.SitePhoneNumber
            initiateSiteData[indexInit]['SiteName'] = site.SiteName
            initiateSiteData[indexInit]['SiteName'] = site.SiteName


            let _pdata = JSON.parse(JSON.stringify(siteData));
            let _idata = JSON.parse(JSON.stringify(initiateSiteData));
            state = {
                ...state,
                initiateSiteData:_idata,
                siteData : _pdata
            };
            break;
        }
        default:
            break;
    }
    return state;
};
export default siteTableDataReducer;
