import React, {Component} from 'react'
import {authenticationService} from '../../services/AuthenticationService';

import SvGraphics from "../../assets/SvGraphics";
import Error from "../../components/single_components/Error";
import * as Yup from "yup";
import validationHelper from "../../utils/validationHelper";
import utils from "../../utils/utils";
import Constants from "../../utils/constants";
import AlertConfirm from "../../components/modals/Confirm";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import Reaptcha from "reaptcha";

class ResetPasswordComposed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username:'',
            auth:{},
            isErr:false,
            errMessage:'',
            errors: {},
            errorsOnSave:{},
        }
        this.recaptchaRef = React.createRef();
    }

    initValidation = async () => {
        let {t} = this.props
        let validationSchema = Yup.object().shape({
            username:Yup.string().matches(Constants.emailRegExp, t('defaults.rst_pwd.username_inv')).required(t('defaults.rst_pwd.username_req'))})
        let errors = await validationHelper.validate(this.state.auth,validationSchema)
        await this.setState({
            validationSchema,
            errors
        })
    }

    async componentWillMount() {
        await this.initValidation()
    }

    onSubmit = async () => {
        if (!utils.isEmpty(this.state.errors)){
            this.setState({errorsOnSave:this.state.errors});
            return;
        }
        try {
            await this.recaptchaRef.execute();
        } catch (err) {
            this.setState({
                isErr: true,
                errMessage: 'Blocked By ReCAPTCHA',
            })
        }
    }

    submitData = async () => {
        let {t} = this.props

        let {username} = this.state
        let response = await authenticationService.resetPassword(username);
        if (response.status < 400) {
            let confirm = await AlertConfirm({
                options:{
                    title: t('defaults.rst_pwd.pwd_rst'),
                    cancelLabel: 'none',
                    proceedLabel: t('defaults.rst_pwd.Ok')
                }},`${t('defaults.rst_pwd.new_pwd_sent')}: ${username}`)
            if (confirm) {
                this.props.history.goBack()
            }
        } else {
            this.setState({
                isErr:true,
                errMessage:response.data.message,
                username: '',
                password: '',
                auth: {}
            })
        }
    }

    setUserName = async (e) => {
        let {auth,validationSchema} = this.state
        this.setState({username:e.target.value,isErr:false})
        utils.set(auth,'username',e.target.value)
        let errors = await validationHelper.validate(auth, validationSchema);
        let errorsOnSave = this.state.errorsOnSave;
        if (utils.get(errorsOnSave,'username')){
            utils.set(errorsOnSave,'username',utils.get(errors,'username'))
        }
        this.setState({
            auth,
            errors,
            errorsOnSave
        })
    }


    render() {
        let {t} = this.props
        let SVGName ='eye_swift_blue'
        let bottomSVG= <SvGraphics svgname={'nova_sight'} className={'nova-sight-login-logo'} style={{width:'183px'}}/>
        if(process.env.REACT_APP_ENV === "essilorstaging" || process.env.REACT_APP_ENV === "essilorproduction" )
        {
            SVGName ='eyeviz'
            bottomSVG =<img src={require('../../assets/Essilor-img.png')} style={{width:'500px'}} />
        }
        return (
            <div className={"login-page"}>
                <div className={'h-centered eye-swift-login-logo-container'}>
                    <SvGraphics svgname={SVGName} className={'eye-swift-login-logo'} style={{width:'340px'}}/>
                </div>
                <div className={'centered eye-swift-login-main-board'}>
                    <label className={'eye-swift-login-label'}>{t('defaults.rst_pwd.rst_pwd')}</label>
                    <div className={'eye-swift-login-form-container'}>
                        <div className={'h-centered eye-swift-login-form'}>
                            <Error isNonFloat={true} errorMessage={utils.get(this.state.errorsOnSave ,'username')} isShown={!!utils.get(this.state.errorsOnSave ,'username')}/>
                            {/*<Error isNonFloat={true} errorMessage={'Email does not exist'} isShown={this.state.isErr}/>*/}
                            <div className={"login-input-wrapper form-group eye-swift-login-form-group"}>
                                <input className={"nova-input eye-swift-login-input-field"}
                                       placeholder={t('defaults.rst_pwd.username')}
                                       type={"text"}
                                       name={"Username"}
                                       id={"search"}
                                       onChange={e => this.setUserName(e)} />
                                <SvGraphics className={"input-pic"} svgname={'person'} style={{width: '20px', height: '20px'}}/>
                            </div>
                            <div className="form-group eye-swift-login-form-group">
                                <button className="btn h-centered btn-primary eye-swift-login-form-button" onClick={this.onSubmit}>{t('defaults.rst_pwd.rst_pwd')}</button>
                            </div>
                        </div>
                    </div>
                    <div className={'eye-swift-login-form-group loginError red h-centered'}>
                        <Error isNonFloat={true} errorMessage={t('defaults.rst_pwd.auth_err') + ': ' + this.state.errMessage} isShown={this.state.isErr}/>
                    </div>
                    <div className={'h-centered eye-swift-login-footer-container'}
                    onClick={() => this.props.history.goBack()}>
                        <a>{t('defaults.rst_pwd.back_to_login')}</a>
                    </div>
                </div>
                <div className={'h-centered nova-sight-login-logo-container'}>
                    {/*<SvGraphics svgname={SVGLogo} className={'nova-sight-login-logo'} style={{width:'183px'}}/>*/}
                    {bottomSVG}
                </div>
                <Reaptcha
                    ref={ref => (this.recaptchaRef = ref)}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    size={'invisible'}
                    explicit={false}
                    onVerify={token => {
                        if (!!token) this.submitData()
                        else {
                            this.setState({
                                isErr: true,
                                errMessage: 'Blocked By ReCAPTCHA',
                            })
                        }
                    }}
                    onExpire={() => {this.setState({
                        isErr: true,
                        errMessage: 'ReCAPTCHA Expired, please refresh the page',
                    })}}
                    onError={err => {this.setState({
                        isErr: true,
                        errMessage: err,
                    })}}
                />
            </div>

        )
    }
}

let ResetPassword = compose(
    withTranslation()
)(ResetPasswordComposed)

export default ResetPassword;
