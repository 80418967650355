import React, {Component} from 'react'
import ReturnNav from "../../components/single_components/ReturnNav";
import PatientHeader from "../../components/patient_profile/PatientHeader";
import '../../components_style/PatientScreen.css'
import '../../components_style/rtl_css/PatientScreen.css'
import {doctorApi} from "../../services/ApiService";
import PatientPersonal from "../../components/patient_profile/PatientPersonal";
import utils from "../../utils/utils";
import {
    deletePatient,
    addNewArchivePatient,
    filterPatientsTable,
    setPatientsData
} from "../../redux/actions/Tabs-Actions";
import {connect} from "react-redux";
import {history} from "../../utils/history";
import CTPatientBlock from "./CTPatientBlock";
import PatientTestStatistics from "./PatientTestStatistics";
import PatientVisitsHistory from "./PatientVisitsHistory";
import PatientTestResult from "./PatientTestResult";
import ACTPatientChart from "./ACTPatientChart";
import ColorTestBlock from "./ColorTestBlock";
import AutoCSBlock from "./AutoCSBlock";
import PatientReadingBlock from "./PatientReadingBlock";
import ChartsVABlock from "./ChartsVABlock";
import PatientFusionalChart from "./PatientFusionalChart";
import ChartCSBlock from "./ChartCSBlock";
import AutoVABlock from "./AutoVABlock";
import Worth4DotBlock from "./Worth4DotBlock";
import PatientStereoTestBlock from "./PatientStereoTestBlock";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {getDateFormat} from "../../utils/getDateTimeFormat";



class PatientConnected extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            patientId:null,
            patientName: "",
            patient: null,
            lastVisit: null,
            nextVisit: null,
            visits: [],
            completed: false,
            isAdvanced: false,
            patientStorage: null,
            act: false,
            ct:false,
            chartsva: false,
            chartsct: false,
            fusional:false,
            reading:false,
            stereotest:false,
            autocs:false,
            autova:false,
            colortest:false,
            worth4dot: false,
            selecttests:false,
            totalVisits:0,
            totalTests:0,
            lastVisitData: null,
            lastVisitDate: null,
            lastVisitsData: [],
            actLastVisit:"",
            autoCSLastVisit:"",
            visualUnits:"LogMar",
            visualUnit:"LogMar",
            autoVALastVisit:"",
            chartCSLastVisit:"",
            chartVALastVisit:"",
            colorLastVisit:"",
            ctLastVisit:"",
            fusionalLastVisit:"",
            readingLastVisit:"",
            stereoLastVisit:"",
            worth4dotLastVisit:"",
            checkAll: true,
            actCheck:true,
            ctCheck: true,
            chartVACheck:true,
            chartCSCheck:true,
            fusionalCheck:true,
            readingCheck:true,
            stereoCheck:true,
            autoCSCheck:true,
            autoVACheck:true,
            colorCheck:true,
            worth4DotCheck:true
        }
    }

    getDate(date){
        return date.substring(8, 10)+ "/" + date.substring(5, 7) + "/"+ date.substring(0,4)
    }


    setTotalTest(){
        console.log("HI")
        this.setState({totalTests: this.state.totalTests + 1})
    }
     getLastTest (visits){
        if(visits){
            for (const visit of visits) {
                for (const testResult of visit.TestsResults) {
                    if(testResult) {
                        if (testResult.ACTTestStorageID > 0 && !this.state.actLastVisit) {
                            this.state.actLastVisit = this.getDate(visit.VisitDate)
                        }
                        if (testResult.AutoCSTestStorageID > 0 && !this.state.autoCSLastVisit) {
                            this.state.autoCSLastVisit = this.getDate(visit.VisitDate)
                        }
                        if (testResult.AutoVATestStorageID > 0 && !this.state.autoVALastVisit) {
                            this.state.autoVALastVisit = this.getDate(visit.VisitDate)
                        }
                        if (testResult.ChartsCSTestStorageID > 0 && !this.state.chartCSLastVisit) {
                            this.state.chartCSLastVisit = this.getDate(visit.VisitDate)
                        }
                        if (testResult.ChartsVATestStorageID > 0 && !this.state.chartVALastVisit) {
                            this.state.chartVALastVisit = this.getDate(visit.VisitDate)
                        }
                        if (testResult.ColorVisionTestStorageID > 0 && !this.state.colorLastVisit) {
                            this.state.colorLastVisit = this.getDate(visit.VisitDate)
                        }
                        if (testResult.CTTestStorageID > 0 && !this.state.ctLastVisit) {
                            this.state.ctLastVisit = this.getDate(visit.VisitDate)

                        } if (testResult.FusionalReserveTestStorageID > 0 && !this.state.fusionalLastVisit) {
                            this.state.fusionalLastVisit = this.getDate(visit.VisitDate)

                        } if (testResult.ReadingTestStorageID > 0 && !this.state.readingLastVisit) {
                            this.state.readingLastVisit = this.getDate(visit.VisitDate)

                        }if (testResult.StereoAcuityTestStorageID > 0 && !this.state.stereoLastVisit) {
                            this.state.stereoLastVisit = this.getDate(visit.VisitDate)

                        } if (testResult.Worth4DotTestStorageID > 0 && !this.state.worth4dotLastVisit) {
                            this.state.worth4dotLastVisit = this.getDate(visit.VisitDate)
                        }
                    }
                }
            }
        }
     }

    componentDidMount = async () => {
        let visual_unit= document.getElementById('visual_acuity_units')
        if(visual_unit){
            visual_unit.addEventListener('change', (event) => {
                this.changeAcuityUnits(visual_unit.value);
            });
        }

        let rowInfo = this.props.location.state.rowInfo
        let patientId = rowInfo.id
        let patientName = rowInfo.FullName.includes(null)?"":rowInfo.FullName
        let completed = this.props.location.state.completed
        let response = await doctorApi.gePatientById(patientId)
       let visitData = await doctorApi.getAllPatientVisitsData(patientId)

        if(response){
            let nextDate=  response.data.NextVisitDate
            if(nextDate){
                let nextVisitDate = response.data.NextVisitDate.substring(0, 10)
                this.state.nextVisitDate = nextVisitDate.substring(8, 10) + "/" + nextVisitDate.substring(5, 7) + "/" + nextVisitDate.substring(0, 4)
            }

        }

        if(visitData){
        this.state.totalVisits = visitData.data.length

        for (const visit of visitData.data) {
            this.state.totalTests = this.state.totalTests + visit.TestsResults.length
        }
        this.state.lastVisitData = visitData.data[0]
            if (this.state.lastVisitData) {
                let visitDate = visitData.data[0].VisitDate.substring(0, 10)
                this.state.lastVisitDate = visitDate.substring(8, 10) + "/" + visitDate.substring(5, 7) + "/" + visitDate.substring(0, 4)
                this.state.lastVisitsData = visitData.data
                this.getLastTest(this.state.lastVisitsData)
            }
            this.state.loading=false
            this.render()

        }


        let patient = response && response.hasOwnProperty('data') ? response.data : {}
        utils.set(patient,'Status',rowInfo.Status)
        let visits = visitData ?(visitData.data) : []

        let lastVisit = visits && visits.length>0?visits[visits.length-1] : {}
        lastVisit['NextVisit'] = !!patient ? patient.NextVisit : null
        let patientStorage = patient ? patient.UserStorageID : null
        this.setState({patientId,patientName,patient,lastVisit,visits,completed,patientStorage})
    }

    onNavBack = () => {
        if (!this.state.completed) {
            history.push({
                pathname: `/dashboard/patients`,
                state: {
                    navTabTo: 0,
                    from: {
                        fromPath: `/dashboard/Patient`,
                    }
                },
            })
        }
        else {
            history.push({
                pathname: `/dashboard/archive`,
                state: {
                    navTabTo: 1,
                    from: {
                        fromPath: `/dashboard/Patient`,
                    }
                },
            })
        }
    }


    onNextVisitChange(date){
        let lastVisit = this.state.lastVisit
        utils.set(lastVisit,'NextVisit',date)
        this.setState({lastVisit})
    }

    updateCheckbox(checkbox){
        if(checkbox ==="checkAll"){
            this.setState({checkAll: !this.state.checkAll })
            let boolCheck= !this.state.checkAll
            this.setState({actCheck: boolCheck})
            this.setState({ctCheck: boolCheck})
            this.setState({chartVACheck: boolCheck})
            this.setState({chartCSCheck: boolCheck })
            this.setState({fusionalCheck: boolCheck})
            this.setState({readingCheck:boolCheck })
            this.setState({stereoCheck: boolCheck})
            this.setState({autoCSCheck: boolCheck})
            this.setState({autoVACheck: boolCheck})
            this.setState({colorCheck: boolCheck })
            this.setState({worth4DotCheck: boolCheck})
        }
        if (checkbox ==="actCheck" )
            this.setState({actCheck: !this.state.actCheck})
        if (checkbox === "ctCheck")
            this.setState({ctCheck: ! this.state.ctCheck })
        if (checkbox ===  "chartVACheck")
            this.setState({chartVACheck: ! this.state.chartVACheck })
        if (checkbox === "chartCSCheck")
            this.setState({chartCSCheck: ! this.state.chartCSCheck })
        if (checkbox ===  "fusionalCheck")
            this.setState({fusionalCheck: ! this.state.fusionalCheck })
        if (checkbox ===  "readingCheck")
            this.setState({readingCheck: ! this.state.readingCheck })
        if (checkbox === "stereoCheck")
            this.setState({stereoCheck: ! this.state.stereoCheck })
        if (checkbox ===  "autoCSCheck")
            this.setState({autoCSCheck: ! this.state.autoCSCheck })
        if (checkbox ===  "autoVACheck")
            this.setState({autoVACheck: ! this.state.autoVACheck })
        if (checkbox ===  "colorCheck")
            this.setState({colorCheck: ! this.state.colorCheck })
        if (checkbox ===  "worth4DotCheck")
            this.setState({worth4DotCheck: ! this.state.worth4DotCheck })

        if(this.state.checkAll && checkbox!=="checkAll"){
            this.setState({checkAll: !this.state.checkAll })

        }
    }

    deletePatient = (patientID) => {
        this.props.deletePatient(patientID)
    }


    AdvancedToggle = () => {
        let {isAdvanced} = this.state
        this.setState({
            isAdvanced: !isAdvanced
        })
    }
    changeAcuityUnits = (value) => {
        this.setState({visualUnit:value})
    }



    render() {
        let patientId =  this.state.patientId
        let patientName =  this.state.patientName
        let patient = this.state.patient
        let lastVisit = this.state.lastVisit
        let DateOfActivation = patient ? patient.DateOfActivation : null
        let {t} = this.props

        if (this.state.loading) {
            return <div class="loader"/>
        }
        return (


            <div className={'Patient-Screen'}><ReturnNav onNavBackClick={this.onNavBack.bind(this)}/>
                <div className={'nova-patient-screen-header'}>
                {
                patientName && patientId &&
                <PatientHeader addNewArchivePatient={patient => this.props.addNewArchivePatient(patient)}
                               ompleted={this.state.completed} patientName={patientName} patientId={patientId} onNavBack={this.onNavBack.bind(this)}/>
                }
                </div>
                <div className={'nova-patient container'}>
                    <PatientPersonal lastVisit={lastVisit} completed={this.state.completed}
                                     patientName={patientName} patientId={patientId} patient={patient}
                                     deletePatient={UserStorageID => this.deletePatient(UserStorageID)} onNavBack={this.onNavBack.bind(this)}
                                     lastVisitDate={this.state.lastVisitDate}/>
                    <div className={'nova-patient-body flex'}>
                        <PatientTestStatistics totalTests={this.state.totalTests} totalVisits={this.state.totalVisits} lastVisit={this.state.lastVisitData}
                                               actLastVisit={this.state.actLastVisit}
                                               autoCSLastVisit={this.state.autoCSLastVisit}
                                               autoVALastVisit={this.state.autoVALastVisit}
                                               chartCSLastVisit={this.state.chartCSLastVisit}
                                               chartVALastVisit={this.state.chartVALastVisit}
                                               colorLastVisit={this.state.colorLastVisit}
                                               ctLastVisit={this.state.ctLastVisit}
                                               fusionalLastVisit={this.state.fusionalLastVisit}
                                               readingLastVisit={this.state.readingLastVisit}
                                               stereoLastVisit={this.state.stereoLastVisit}
                                               worth4dotLastVisit={this.state.worth4dotLastVisit}/>
                        <PatientVisitsHistory lastVisitsData={this.state.lastVisitsData} lastVisitDate={this.state.lastVisitDate}
                                              nextVisitDate={this.state.nextVisitDate}  patientId={patientId}
                                              onNextVisitChange={e => this.onNextVisitChange(e)}
                        />
                        <div className={'nova-patient-visits-history-title'}> {t('ecp.info_filters.testresults')}
                        </div>

                        <PatientTestResult actLastVisit={this.state.actLastVisit}
                                           autoCSLastVisit={this.state.autoCSLastVisit}
                                           autoVALastVisit={this.state.autoVALastVisit}
                                           chartCSLastVisit={this.state.chartCSLastVisit}
                                           chartVALastVisit={this.state.chartVALastVisit}
                                           colorLastVisit={this.state.colorLastVisit}
                                           ctLastVisit={this.state.ctLastVisit}
                                           fusionalLastVisit={this.state.fusionalLastVisit}
                                           readingLastVisit={this.state.readingLastVisit}
                                           stereoLastVisit={this.state.stereoLastVisit}
                                           worth4dotLastVisit={this.state.worth4dotLastVisit}
                                           updateCheckbox={e => this.updateCheckbox(e)}


                        />
                        {
                            !!this.state.actLastVisit && this.state.actCheck &&
                            <div className={'patient-Charts-Block'} id={"act"}>
                                <div className={'patient-charts-block-label'}>
                                    <label className={'patient-act-chart-text'}> {t('general.ACT')}</label>
                                    <label className= {'patient-act-chart-last'}> {t('ecp.info_filters.lastTest')} {this.state.actLastVisit}</label>
                                </div>
                                <div className={'patient_act_charts flex'}>
                                    <ACTPatientChart  chartType={'bar'} chartTitle={t('ecp.info_filters.without_glasses')} firstTopic={t('ecp.info_filters.horizontal_deviation')}
                                                      secondTopic={t('ecp.info_filters.vertical_deviation')} svgName={'noglasses'} firstTopicColor={'#0575e6'} secondTopicColor={'#25a6ff'}
                                                      lastVisitsData={this.state.lastVisitsData}
                                        /*
                                                                                              setTotalTest={() => this.setTotalTest()}
                                        */
                                                      />
                                    <ACTPatientChart  chartType={'bar'} chartTitle={t('ecp.info_filters.with_glasses')} firstTopic={t('ecp.info_filters.horizontal_deviation')}
                                                      secondTopic={t('ecp.info_filters.vertical_deviation')} svgName={'glasses'} firstTopicColor={'#0575e6'} secondTopicColor={'#25a6ff'}
                                                      lastVisitsData={this.state.lastVisitsData}
/*
                                                      setTotalTest={() => this.setTotalTest()}
*/
                                                      />
                                </div>
                            </div>
                        }


                        {
                            !!this.state.ctLastVisit && this.state.ctCheck &&
                            <div className={'patient-Charts-Block'} id={"ct"}>
                                <div className={'patient-charts-block-label'}>
                                    <label className={'patient-act-chart-text'}> {t('general.CT')}</label>
                                    <label
                                        className={'patient-act-chart-last'}> {t('ecp.info_filters.lastTest')} {this.state.ctLastVisit}</label>
                                </div>
                                <div className={'patient_act_charts flex'}>
                                    <CTPatientBlock chartType={'bar'} chartTitle={t('ecp.info_filters.without_glasses')}
                                                    firstTopic={t('ecp.info_filters.horizontal_deviation')}
                                                    secondTopic={t('ecp.info_filters.vertical_deviation')}
                                                    svgName={'noglasses'} firstTopicColor={'#0575e6'}
                                                    secondTopicColor={'#25a6ff'}
                                                    lastVisitsData={this.state.lastVisitsData}
                                                    />
                                    <CTPatientBlock chartType={'bar'} chartTitle={t('ecp.info_filters.with_glasses')}
                                                    firstTopic={t('ecp.info_filters.horizontal_deviation')}
                                                    secondTopic={t('ecp.info_filters.vertical_deviation')}
                                                    svgName={'glasses'} firstTopicColor={'#0575e6'}
                                                    secondTopicColor={'#25a6ff'}
                                                    lastVisitsData={this.state.lastVisitsData}
                                                    />
                                </div>
                            </div>
                        }

                        {
                            !!this.state.chartVALastVisit && this.state.chartVACheck &&
                            <div className={'patient-Charts-Block'} id={"chartva"}>
                                <div className={'patient-charts-block-label'}>
                                    <label className={'patient-act-chart-text'}> {t('general.ChartVA')}</label>
                                    <label
                                        className={'patient-act-chart-last'}> {t('ecp.info_filters.lastTest')} {this.state.chartVALastVisit}</label>
                                </div>
                                <div className={'patient_act_charts flex'}>
                                    <ChartsVABlock chartTitle={t('ecp.info_filters.without_glasses')}
                                                   svgName={'noglasses'} lastVisitsData={this.state.lastVisitsData}
                                                   visualUnit={this.state.visualUnit}/>
                                    <ChartsVABlock chartTitle={t('ecp.info_filters.with_glasses')} svgName={'glasses'}
                                                   lastVisitsData={this.state.lastVisitsData}
                                                   visualUnit={this.state.visualUnit}/>
                                </div>
                            </div>
                        }

                        {
                            !!this.state.chartCSLastVisit && this.state.chartCSCheck &&
                            <div className={'patient-Charts-Block'} id={"chartscs"}>
                                <div className={'patient-charts-block-label'}>
                                    <label className={'patient-act-chart-text'}> {t('general.ChartCS')}</label>
                                    <label
                                        className={'patient-act-chart-last'}> {t('ecp.info_filters.lastTest')} {this.state.chartCSLastVisit}</label>
                                </div>
                                <div className={'patient_act_charts flex'}>
                                    <ChartCSBlock chartTitle={t('ecp.info_filters.without_glasses')}
                                                  svgName={'noglasses'} lastVisitsData={this.state.lastVisitsData}
                                                  visualUnit={this.state.visualUnit}/>
                                    <ChartCSBlock chartTitle={t('ecp.info_filters.with_glasses')} svgName={'glasses'}
                                                  lastVisitsData={this.state.lastVisitsData}
                                                  visualUnit={this.state.visualUnit}/>
                                </div>
                            </div>
                        }

                        {
                            !!this.state.fusionalLastVisit &&  this.state.fusionalCheck &&
                            <div className={'patient-Charts-Block'} id={"fusional"}>
                                <div className={'patient-charts-block-label'}>
                                    <label className={'patient-act-chart-text'}> {t('general.Fusional')}</label>
                                    <label
                                        className={'patient-act-chart-last'}> {t('ecp.info_filters.lastTest')} {this.state.fusionalLastVisit}</label>
                                </div>
                                <div className={'patient_act_charts flex'}>
                                    <PatientFusionalChart chartTitle={t('ecp.info_filters.without_glasses')}
                                                          svgName={'noglasses'}
                                                          lastVisitsData={this.state.lastVisitsData}
                                                         />
                                    <PatientFusionalChart chartTitle={t('ecp.info_filters.with_glasses')}
                                                          svgName={'glasses'}
                                                          lastVisitsData={this.state.lastVisitsData}
                                                          />
                                </div>
                            </div>
                        }
                        {
                            !!this.state.readingLastVisit && this.state.readingCheck &&
                            <PatientReadingBlock readingLastVisit={this.state.readingLastVisit}
                                                 lastVisitsData={this.state.lastVisitsData}
                                                />
                        }

                        {
                            !!this.state.stereoLastVisit && this.state.stereoCheck &&
                            <div className={'patient-Charts-Block'} id={"stereotest"}>
                                <div className={'patient-charts-block-label'}>
                                    <label className={'patient-act-chart-text'}> {t('general.Stereo_Test')}</label>
                                    <label
                                        className={'patient-act-chart-last'}> {t('ecp.info_filters.lastTest')} {this.state.stereoLastVisit}</label>
                                </div>
                                <PatientStereoTestBlock lastVisitsData={this.state.lastVisitsData}
                                                        />
                            </div>
                        }

                        {
                            !!this.state.autoCSLastVisit && this.state.autoCSCheck &&
                            <div className={'patient-Charts-Block'} id={"autocs"}>
                                <div className={'patient-charts-block-label'}>
                                    <label className={'patient-act-chart-text'}>{t('general.AutoCS')}</label>
                                    <label
                                        className={'patient-act-chart-last'}> {t('ecp.info_filters.lastTest')} {this.state.autoCSLastVisit}</label>
                                </div>
                                <div className={'patient-AutoCS-Block'}>
                                    <AutoCSBlock chartTitle={t('ecp.info_filters.without_glasses')}
                                                 svgName={'noglasses'} lastVisitsData={this.state.lastVisitsData}
                                                 visualUnit={this.state.visualUnit}/>
                                    <AutoCSBlock chartTitle={t('ecp.info_filters.with_glasses')} svgName={'glasses'}
                                                 lastVisitsData={this.state.lastVisitsData}
                                                 visualUnit={this.state.visualUnit}/>
                                </div>
                            </div>
                        }

                        {
                            !!this.state.autoVALastVisit && this.state.autoVACheck &&
                            <div className={'patient-Charts-Block'} id={"autova"}>
                                <div className={'patient-charts-block-label'}>
                                    <label className={'patient-act-chart-text'}> {t('general.AutoVA')}</label>
                                    <label
                                        className={'patient-act-chart-last'}> {t('ecp.info_filters.lastTest')} {this.state.autoVALastVisit}</label>
                                </div>
                                <div className={'patient-AutoCS-Block'}>
                                    <AutoVABlock chartTitle={t('ecp.info_filters.without_glasses')}
                                                 svgName={'noglasses'} lastVisitsData={this.state.lastVisitsData}
                                                 visualUnit={this.state.visualUnit}/>
                                    <AutoVABlock chartTitle={t('ecp.info_filters.with_glasses')} svgName={'glasses'}
                                                 lastVisitsData={this.state.lastVisitsData}
                                                 visualUnit={this.state.visualUnit}/>
                                </div>
                            </div>
                        }
                        {
                            !!this.state.colorLastVisit &&  this.state.colorCheck &&
                            <div className={'patient-Charts-Block'} id={"colortest"}>
                                <div className={'patient-charts-block-label'}>
                                    <label className={'patient-act-chart-text'}> {t('general.Color_Test') }</label>
                                    <label
                                        className={'patient-act-chart-last'}> {t('ecp.info_filters.lastTest')} {this.state.colorLastVisit}</label>
                                </div>
                                <div className={'patient-AutoCS-Block'}>
                                    <ColorTestBlock chartTitle={t('ecp.info_filters.without_glasses')}
                                                    svgName={'noglasses'} lastVisitsData={this.state.lastVisitsData}/>
                                    <ColorTestBlock chartTitle={t('ecp.info_filters.with_glasses')} svgName={'glasses'}
                                                    lastVisitsData={this.state.lastVisitsData}/>
                                </div>

                            </div>
                        }
                        {
                            !!this.state.worth4dotLastVisit &&this.state.worth4DotCheck &&
                            <div className={'patient-Charts-Block'} id={"worth4dot"}>
                                <div className={'patient-charts-block-label'}>
                                    <label className={'patient-act-chart-text'}> {t('general.Worth4Dot')}</label>
                                    <label
                                        className={'patient-act-chart-last'}> {t('ecp.info_filters.lastTest')} {this.state.worth4dotLastVisit}</label>
                                </div>
                                <div className={'patient-AutoCS-Block'}>
                                    <Worth4DotBlock chartTitle={t('ecp.info_filters.without_glasses')}
                                                    svgName={'noglasses'} lastVisitsData={this.state.lastVisitsData}
                                                   />
                                    <Worth4DotBlock chartTitle={t('ecp.info_filters.with_glasses')} svgName={'glasses'}
                                                    lastVisitsData={this.state.lastVisitsData}
                                                    />
                                </div>

                            </div>
                        }
                    </div>
                </div>
        </div>

        )
    }
}


function mapDispatchToProps(dispatch) {
    return {

        filterPatientsTable:(filterType,payload) => dispatch(filterPatientsTable(filterType,payload)),
        deletePatient: patientID => dispatch(deletePatient(patientID)),
        addNewArchivePatient: patientData => dispatch(addNewArchivePatient(patientData))
    };
}
const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData,
        patientsCount:state.patientsTableDataReducer.patientsCount,
        activePatientsCount:state.patientsTableDataReducer.activePatientsCount,
        pendingPatientsCount:state.patientsTableDataReducer.pendingPatientsCount,
        lockedPatientsCount:state.patientsTableDataReducer.lockedPatientsCount,
    };
};

const Patient = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(PatientConnected)

export default Patient;
