import React, {Component} from 'react'
import PropTypes from "prop-types";
import {ReactDatez} from 'react-datez'

import '../../components_style/NovaDate.css'
import "react-datez/dist/css/react-datez.css";
class NovaDate extends Component {

    constructor(props) {
        super(props);
        this.state = {term: '',allowFuture:false};
    }

    async componentDidMount() {
        if (this.props.term){
            await this.setState({term:this.props.term});
        }
        if (this.props.allowFuture){
            await this.setState({allowFuture:this.props.allowFuture});
        }
    }

    async dateChange(term){
        await this.setState({term});
        this.props.onSearchTermChange(term)
    }

    render() {
        const placeholder = this.props.placeholder || 'Select a date';
        let allowPast = this.props.allowPast !== null && this.props.allowPast !== undefined ? this.props.allowPast : true
        return (
            <div className="nova-date-search-box">
                <ReactDatez
                    wrapperStyle={{width:'100%'}}
                    value={this.props.term}
                    handleChange={(term)=>this.dateChange(term)}
                    inputClassName={`nova-form-input nova-date-search-input ${this.props.className}`}
                    placeholder={placeholder}
                    dateFormat={'DD/MM/YYYY'}
                    disable={this.props.disable}
                    allowPast={allowPast}
                    allowFuture={this.state.allowFuture}/>
            </div>
        );
    }
}


NovaDate.propTypes = {
    onSearchTermChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    term:PropTypes.string,
    allowFuture: PropTypes.bool,
    disable: PropTypes.bool,
    className: PropTypes.string
}

export default NovaDate;
