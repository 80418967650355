import React, {Component} from 'react'
import {connect} from "react-redux";
import MyTable from "../../../components/NovaTable/Table";
import SiteEditForm from "../../../components/site/SiteEditForm";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {adminApi} from "../../../services/ApiService";
import AlertConfirm from "../../../components/modals/Confirm";
import * as Yup from "yup";
import {filterPendingsTable,setPendingsData,deletePendingUser} from "../../../redux/actions/Tabs-Actions";

class PendingsTabConComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entry: null,
        }
    }

    setPendingMoreFunctions = () => {
        let {t} = this.props
        return [
            {
                name: 'Delete',
                display: t('defaults.Pending.delete'),
                call: (user => this.deletePending(user))
            }
        ]
    }

    async deletePending(user) {
        let {t} = this.props
        let confirm = await AlertConfirm({
            options:{
                title: t('defaults.Pending.delete'),
            }},`${t('defaults.Pending.delete_confirm')}`)
        if (confirm) {
            let resp = await adminApi.deletePending(user.id)
/*            let data = resp  ? resp['data']:""
            let status = data  ? data.status :""*/
            if (resp.status >= 200){
                this.props.deletePendingUser(user.id)
                }
            }

    }

    onFilterDataSelected = (filter) => {
        this.props.filterPendingsTable(Object.keys(filter)[0],Object.values(filter)[0])
    }

    getColumns = (t) => {
        return [
            {accessor: 'FirstName', Header: t('defaults.Pending.first_name'), resizable:false},
            {accessor: 'LastName', Header: t('defaults.Pending.last_name'), resizable:false},
            {accessor: 'Email', Header: t('defaults.Pending.email'), resizable:false},
            {accessor: 'SiteName', Header: t('defaults.Pending.SiteName'), resizable:false},
            {accessor: 'Phone', Header: t('defaults.Pending.Phone'), resizable:false},
        ]
    }


    render() {
        let {t} = this.props
        let columns = this.getColumns(t)
        let {entry,isSideBarOpen} = this.state
        console.log(this.props.pendingData)
        return (
            <div className={'context-area'}>
                <MyTable
                    data={this.props.pendingData}
                    columns={columns}
                    initialData={this.props.pendingData}
                    moreFunctions={this.setPendingMoreFunctions()}
                    onFilterDataSelected={filter => this.onFilterDataSelected(filter)}
                    hasMore={true}
                    hasShowInfo={false}
                />

            </div>

        )
    }
}

function mapDispatchToProps(dispatch) {
    return {

        filterPendingsTable: (type,payload) => dispatch(filterPendingsTable(type,payload)),
        setPendingsData: pendingData => dispatch(setPendingsData(pendingData)),
        deletePendingUser: pendingData => dispatch(deletePendingUser(pendingData)),
    };
}

const mapStateToProps = state => {
    return {
        pendingData:state.pendingTableDataReducer.pendingData,

    };
};


const PendingsTab = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(PendingsTabConComposed);

export default PendingsTab;
