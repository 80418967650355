import React, {Component} from 'react'
import '../../components_style/PatientScreen.css'
import '../../components_style/rtl_css/PatientScreen.css'
import SvGraphics from "../../assets/SvGraphics";
import {withTranslation} from "react-i18next";
import {filterPatientsTable} from "../../redux/actions/Tabs-Actions";
import {compose} from "redux";
import {connect} from "react-redux";

class PatientTestStatisticsConComposed extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {t} = this.props

        return(
            <div className={'nova-patient-statistics'}>

            <div className={'nova-patient-statistics-title'}>{t('ecp.info_filters.testStatistics')}</div>

        <div className={'nova-patient-statistics-boxes'}>
            <div className={'nova-patient-statistics-left-box'}>
                <label className={'patient-test-block-value'}>{this.props.totalTests}</label>
                <label className={'patient-test-block-label'}>{t('ecp.info_filters.tests')} </label>
            </div>
            <div className={'nova-patient-statistics-left-box'}>
                <label className={'patient-test-block-value'}>{this.props.totalVisits}</label>
                <label className={'patient-test-block-label'}>{t('ecp.info_filters.visits')}</label>
            </div>
            <div className={'nova-patient-statistics-right-box'}>
                <div className={'nova-patient-statistics-right-box-title'}>{t('ecp.archive.table.last_visit')}</div>
                <div className={'right-box-items'}>
                    <div className={'right-box-items-1st'}>

                        <div className={'label-svg'}>
                            <label className={'label'}> {t('general.ACT')} </label>
                            {!!this.props.actLastVisit &&
                            <SvGraphics svgname={'check'} className={'check-box-style'}/>
                            }
                        </div>
                        <div className={'label-svg'}>
                            <label className={'label'}> {t('general.AutoCS')} </label>
                            {!!this.props.autoCSLastVisit &&
                            <SvGraphics svgname={'check'} className={'check-box-style'}/>
                            }
                        </div>
                        <div className= {'label-svg'}>
                            <label className={'label'}> {t('general.AutoVA')}</label>
                            {!!this.props.autoVALastVisit &&
                            <SvGraphics svgname={'check'} className={'check-box-style'}/>
                            }
                        </div>
                    </div>
                    <div className={'right-box-items-1st'}>
                        <div className={'label-svg'}>
                            <label className={'label'}>{t('general.ChartCS')}</label>
                            {!!this.props.chartCSLastVisit &&
                            <SvGraphics svgname={'check'} className={'check-box-style'}/>
                            }
                        </div>
                        <div className={'label-svg'}>
                            <label className={'label'}>{t('general.ChartVA')}</label>
                            {!!this.props.chartVALastVisit &&
                            <SvGraphics svgname={'check'} className={'check-box-style'}/>
                            }

                        </div>
                        <div className={'label-svg'}>
                            <label className={'label'}> {t('general.Color_Test') }</label>
                            {!!this.props.colorLastVisit &&
                            <SvGraphics svgname={'check'} className={'check-box-style'}/>
                            }
                        </div>
                    </div>
                    <div className={'right-box-items-1st'}>
                        <div className={'label-svg'}>
                            <label className={'label'}> {t('general.CT')} </label>
                            {!!this.props.ctLastVisit &&
                            <SvGraphics svgname={'check'} className={'check-box-style'}/>
                            }
                        </div>
                        <div className={'label-svg'}>
                            <label className={'label'}> {t('general.Fusional')} </label>
                            {!!this.props.fusionalLastVisit &&
                            <SvGraphics svgname={'check'} className={'check-box-style'}/>
                            }
                        </div>
                        <div className={'label-svg'}>
                            <label className={'label'}> {t('general.Reading')} </label>
                            {!!this.props.readingLastVisit &&
                            <SvGraphics svgname={'check'} className={'check-box-style'}/>
                            }
                        </div>
                    </div>
                    <div className={'right-box-items-1st'}>
                        <div className={'label-svg'}>
                            <label className={'label'}> {t('general.Stereo_Test')}</label>
                            {!!this.props.stereoLastVisit &&
                            <SvGraphics svgname={'check'} className={'check-box-style'}/>
                            }

                        </div>
                        <div className={'label-svg'}>
                            <label className={'label'}> {t('general.Worth4Dot')} </label>
                            {!!this.props.worth4dotLastVisit &&
                            <SvGraphics svgname={'check'} className={'check-box-style'}/>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
            </div>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return {
        filterPatientsTable:(filterType,payload) => dispatch(filterPatientsTable(filterType,payload)),
    };
}
const mapStateToProps = state => {
    return {
        patientsData:state.patientsTableDataReducer.patientsData,
        patientsCount:state.patientsTableDataReducer.patientsCount,
        activePatientsCount:state.patientsTableDataReducer.activePatientsCount,
        pendingPatientsCount:state.patientsTableDataReducer.pendingPatientsCount,
        lockedPatientsCount:state.patientsTableDataReducer.lockedPatientsCount,
    };
};

const PatientTestStatistics = compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(PatientTestStatisticsConComposed)


    export default PatientTestStatistics