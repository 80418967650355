// component's config object.
import Config from "./Config";


const EyeCareProviderComponents = {
    EyeCareProviderDashboard: {
        component: 'ECPDashboardComponent',
        url: '/dashboard',
        access: [Config.roles.Doctor],
        exact: true,
    },
    PatientFormComponent: {
        component: 'PatientFormComponent',
        url: '/dashboard/AddPatient',
        access: [Config.roles.Doctor]
    },
    EditPatientComponent: {
        component: 'EditPatientComponent',
        url: '/dashboard/EditPatient',
        access: [Config.roles.Doctor]
    },
    NewVisitComponent: {
        component: 'NewVisitComponent',
        url: '/dashboard/NewVisit',
        access: [Config.roles.Doctor]
    },
    VisitReportComponent: {
        component: 'VisitReportComponent',
        url: '/dashboard/visit-report',
        access: [Config.roles.Doctor]
    },
    PatientComponent: {
        component: 'PatientComponent',
        url: '/dashboard/Patient',
        access: [Config.roles.Doctor]
    },
};

const NovaAdminComponents = {
    AdminDashboardComponent: {
        component: 'AdminDashboardComponent',
        url: '/dashboard',
        access: [Config.roles.EyeSwiftAdmin,Config.roles.EyeSwiftSuperAdmin],
        exact: true,
    },
};

const SiteAdminComponents = {
    SiteAdminDashboardComponent: {
        component: 'SiteAdminDashboardComponent',
        url: '/dashboard/siteadmin',
        access: [Config.roles.Doctor],
        isSiteAdminRequired: true,
        exact: true,
    },
};

const rolesConfig = {
    routes: [
        ...Object.values(EyeCareProviderComponents),
        ...Object.values(NovaAdminComponents),
        ...Object.values(SiteAdminComponents),
    ]
};

export {rolesConfig};
