import React from 'react';
import DoctorDashboard from "../views/eye_care_provider/DoctorDashboard";
import AdminDashboard from "../views/admin/AdminDashboard";
import PatientForm from "../views/eye_care_provider/PatientForm";
import Patient from "../views/eye_care_provider/Patient";
import EditPatient from "../views/eye_care_provider/EditPatient";
import SiteAdminDashboard from "../views/siteAdmin/SiteAdminDashbored";

const EditPatientComponent = ({isNested: isNested = false, props}) => <EditPatient isNested={isNested} {...props}/>
const PatientFormComponent = ({isNested: isNested = false, props}) => <PatientForm isNested={isNested} {...props}/>
const ECPDashboardComponent = ({isNested: isNested = false, props}) => <DoctorDashboard isNested={isNested} {...props}/>
const PatientComponent = ({isNested: isNested = false, props}) => <Patient isNested={isNested} {...props}/>
const AdminDashboardComponent = ({isNested: isNested = false, props}) => <AdminDashboard isNested={isNested} {...props}/>
const SiteAdminDashboardComponent = ({isNested: isNested = false, props}) => <SiteAdminDashboard isNested={isNested} {...props}/>



export {
    PatientFormComponent,
    ECPDashboardComponent,
    PatientComponent,
    EditPatientComponent,
    SiteAdminDashboardComponent,
    AdminDashboardComponent
}
