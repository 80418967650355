import React, {Component} from 'react'
import {Navbar,NavDropdown} from "react-bootstrap";
import SvGraphics from "../../assets/SvGraphics";
import ReactFlagsSelect from 'react-flags-select';
/*
import 'react-flags-select/css/react-flags-select.css';
*/
import '../../components_style/NovaNavbar.css'
import '../../components_style/rtl_css/NovaNavbar.css'
import PropTypes from "prop-types";
import Translate from "../../utils/Translate";
import {authenticationService} from "../../services/AuthenticationService";
import {history} from "../../utils/history";
import Faqs from "./Faqs";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import Settings from "./Settings";

class NovaNavbarComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentUser: null,
            isDoctor:false
        }
    }

    componentDidMount = () => {
        if (this.props && this.props.currentUser) {
            this.setState({currentUser: this.props.currentUser})
        }
    }

    async onSelectFlag(e){
        let lang = Translate.getLanguage(e);
        let language = await Translate.setLanguage(lang);
        const { i18n } = this.props;
        await i18n.changeLanguage(language);
        if (Translate.rtl.includes(language)) document.getElementsByTagName("html")[0].setAttribute("dir","rtl")
        else document.getElementsByTagName("html")[0].setAttribute("dir","ltr")
    }

    handleLogout = async () => {
        authenticationService.logout();
        history.push('/login')
    };

    render() {
        let {currentUser,isDoctor} = this.state;
        let {t} = this.props
        let currentUsername;
        let isSiteAdmin = false
        if (currentUser) {
            currentUsername = currentUser.profile.FirstName + ' ' + currentUser.profile.LastName
            if(currentUser?.profile?.Doctor){
                isSiteAdmin = currentUser.profile.Doctor.isSiteAdmin
                isDoctor=true
            }

        }
        let history = this.props.history
        let SVGName ='eye_swift_blue'
        let bottomSVG= <SvGraphics svgname={'nova_sight'} className={'nova-sight-login-logo'} style={{width:'130px'}}/>
        let hrefValue="https://nova-sight.com/"
        if(process.env.REACT_APP_ENV === "essilorstaging" || process.env.REACT_APP_ENV === "essilorproduction" )
        {
            SVGName ='eyeviz'
            bottomSVG =<img src={require('../../assets/Essilor-img.png')} style={{width:'250px'}} />
            hrefValue ="https://www.essilor-instruments.com/"
        }
        return (
                <Navbar className={'nova-navbar container'} variant={"dark"}>
                    <Navbar.Brand><SvGraphics svgname={SVGName} width={'190px'}/></Navbar.Brand>
                    <Navbar.Collapse className={"justify-content-end"}>
                        <div className={'nova-left-navbar'}>
                            {isDoctor &&
                            <div>
                                <label className={"nova-navbar-visual-label"}>{t('general.Visual_acuity_units')}</label>
                                <select className={'nova-left-navbar-visual'} id="visual_acuity_units" placeholder="visual"
                                onChange={''}>
                                    <option value="LogMar">LogMar</option>
                                    <option value="6/6">6/6</option>
                                    <option value="20/20">20/20</option>
                                    <option value="Decimal">Decimal</option>
                                </select>
                            </div>

                            }
                            { isSiteAdmin && history &&
                                <div className={'nova-left-navbar-content'}>
                                    <Settings history={history}/>
                                </div>
                            }
                            <div className={'nova-left-navbar-content'}><Faqs/></div>
                        </div>
                        <div className={'nova-right-navbar'}>
                           {/* <SvGraphics className={'nova-right-navbar-content'} svgname={'worldSymbol'} width={'20px'} height={'20px'}
                            />*/}

                        {/*    <ReactFlagsSelect className={'nova-right-navbar-content'}
                                              defaultCountry={Translate.getCurrentCountry()} countries={Translate.getCountries()} showSelectedLabel={false}
                                              showOptionLabel={false} selectedSize={14} onSelect={e => this.onSelectFlag(e)}/>*/}

                            <ReactFlagsSelect selectButtonClassName={'language-select-button'} className={'nova-right-navbar-content'}
                                              placeholder={t('defaults.navbar.choose_language')}
                                              defaultCountry={Translate.getCurrentCountry()} countries={Translate.getCountries()} showSelectedLabel={false}
                                              showOptionLabel={false} selectedSize={14} onSelect={e => this.onSelectFlag(e)}/>
                        </div>
                        <div className={'nova-middle-navbar'}>
                            <NavDropdown title={
                                <div className={'nova-middle-navbar-dropdown-title'}>
                                    <div className={'nova-middle-navbar-dropdown-title-content'}>
                                        <SvGraphics className={'nova-navbar-singedin-user'} svgname={'person'} width={'20px'} height={'20px'}/>
                                        <label className={'nova-navbar-singedin-user'}>{`${t('defaults.navbar.hello')} ${currentUsername}`}</label>
                                    </div>
                                </div>
                            } id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={this.handleLogout.bind(this)}>{t('defaults.navbar.logout')}</NavDropdown.Item>
                                {/*<NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>*/}
                                {/*<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                            </NavDropdown>
                        </div>

                        <Navbar.Brand href={hrefValue}>
                            {/*<SvGraphics svgname={'nova_sight'} width={'130px'}/>*/}
                            {bottomSVG}
                        </Navbar.Brand>
                    </Navbar.Collapse>
                </Navbar>
        )
    }
}


let NovaNavbar = compose(
    withTranslation()
)(NovaNavbarComposed)

NovaNavbar.propTypes = {
    currentUser: PropTypes.object
};

export default NovaNavbar;
