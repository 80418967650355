import React, {Component} from 'react'
import SvGraphics from "../../assets/SvGraphics";
import '../../components_style/BackButton.css'
import '../../components_style/rtl_css/BackButton.css'
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class BackButtonComposed extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount = () => {

    }

    onClick = () => {
       this.props.onBackClick()
    }

    render() {
        let {t}=this.props
        return (
            <button className={'back-button-container v-centered'} onClick={this.onClick.bind(this)}>
                <SvGraphics className={'inline back-arrow back-path'} svgname={'back'} height={'15px'}/>
                <label className={'inline back-label'}>{this.props.label?this.props.label:t('ecp.patient.form.back')}</label>
            </button>
        )
    }
}

let BackButton = compose(
    withTranslation()
)(BackButtonComposed)

BackButton.propTypes = {
    onBackClick: PropTypes.func.isRequired,
    label: PropTypes.string
}

export default BackButton;
